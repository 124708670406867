import { MetricsBoxGenderComponent } from "@admin/statistics/metrics-box-total/metrics-box-gender.component";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, forwardRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiModule } from 'src/_api';
import { environment } from 'src/environments/environment';
import { ChannelComponent } from './admin/channel/channel.component';
import { ChannelCreateComponent } from './admin/channel/create/channel-create.component';
import { ClientCreateComponent } from "./admin/client/client-create/client-create.component";
import { ClientComponent } from './admin/client/client.component';
import { CustomProgressBarComponent } from './admin/custom-progress-bar/custom-progress-bar.component';
import { CustomerCreateComponent } from './admin/customer/create/customer-create.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { ContactPersonCreateModal } from './admin/customer/person-create/contact-person-create.modal';
import { BreadcrumbComponent } from './admin/media/breadcrumb/breadcrumb.component';
import { MediaCreateComponent } from './admin/media/create/media-create.component';
import { FolderCreateComponent } from './admin/media/folder-create/folder-create.component';
import { FolderCreateModalComponent } from './admin/media/folder/folder-create-modal/folder-create-modal.component';
import { MediaDetailsComponent } from './admin/media/media-details/media-details.component';
import { MediaCreateModal } from './admin/media/media-edit/media-create.modal';
import { MediaEditComponent } from './admin/media/media-edit/media-edit.component';
import { MediaFiltersComponent } from './admin/media/media-filters/media-filters.component';
import { MediaSelectModal } from './admin/media/media-select.modal';
import { MediaStorageInfoComponent } from './admin/media/media-storage-info/media-storage-info.component';
import { MediaComponent } from './admin/media/media.component';
import { CategoryComponent } from './admin/person-of-interest/category/category/category.component';
import { CreateCategoryComponent } from './admin/person-of-interest/category/create-category/create-category.component';
import { DeleteCategoryWarningModalComponent } from './admin/person-of-interest/category/delete-category-warning-modal/delete-category-warning-modal.component';
import { PersonOfInterestCreateComponent } from './admin/person-of-interest/create/person-of-interest-create.component';
import { PersonOfInterestComponent } from './admin/person-of-interest/person-of-interest.component';
import { PoiFiltersModalComponent } from './admin/person-of-interest/poi-filters-modal/poi-filters-modal.component';
import { BulkUpdateAiAddComponent } from './admin/screen/bulk-update-ai-add/bulk-update-ai-add.component';
import { CreateScreenGroupModalComponent } from './admin/screen/create-screen-group-modal/create-screen-group-modal.component';
import { ScreenCreateComponent } from './admin/screen/create/screen-create.component';
import { DeleteScreneGroupModalComponent } from './admin/screen/delete-screne-group-modal/delete-screne-group-modal.component';
import { ScreenComponent } from './admin/screen/screen.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { PercentageChangePipe } from './admin/shared/percentage-change.pipe';
import { SmsChartsComponent } from './admin/sms/sms-charts/sms-charts.component';
import { SmsComponent } from './admin/sms/sms.component';
import { StartComponent } from './admin/start/start.component';
import { MetricsBoxDetailsComponent } from "./admin/statistics/metrics-box-details/metrics-box-details.component";
import { AgeDistributionGraphComponent } from './admin/statistics/people-count/age-distribution-graph/age-distribution-graph.component';
import { AverageTimeInChannelComponent } from './admin/statistics/people-count/average-time-in-channel/average-time-in-channel.component';
import { GenderGraphComponent } from './admin/statistics/people-count/gender-graph/gender-graph.component';
import { MoodChartComponent } from './admin/statistics/people-count/mood-chart/mood-chart.component';
import { PeopleCountGenderComponent } from './admin/statistics/people-count/people-count-gender/people-count-gender.component';
import { PeopleCountKpisComponent } from './admin/statistics/people-count/people-count-kpis/people-count-kpis.component';
import { PeopleCountTargetGroupDailyComponent } from './admin/statistics/people-count/people-count-target-group-daily/people-count-target-group-daily.component';
import { PeopleCountTargetGroupWeeklyMonthlyComponent } from './admin/statistics/people-count/people-count-target-group-weekly-monthly/people-count-target-group-weekly-monthly.component';
import { PeopleCountComponent } from './admin/statistics/people-count/people-count.component';
import { VisitorsPerHourGraphComponent } from './admin/statistics/people-count/visitors-per-hour-graph/visitors-per-hour-graph.component';
import { WeatherChartComponent } from './admin/statistics/people-count/weather-chart/weather-chart.component';
import { ReachComponent } from './admin/statistics/reach/reach.component';
import { ReportsDataComponent } from './admin/statistics/reports/reports-data/reports-data.component';
import { ReportsComponent } from './admin/statistics/reports/reports.component';
import { StatisticsFilterComponent } from './admin/statistics/statistics-filter.component';
import { AverageAttentionTimeComponent } from './admin/statistics/views/average-attention-time/average-attention-time.component';
import { DistributionOfExposuresOverHoursComponent } from './admin/statistics/views/distribution-of-exposures-over-hours/distribution-of-exposures-over-hours.component';
import { ViewsMonthlyComponent } from './admin/statistics/views/monthly/views-monthly.component';
import { ViewChartTotalExposuresComponent } from './admin/statistics/views/view-chart-total-exposures/view-chart-total-exposures.component';
import { ViewsChartHeaderComponent } from './admin/statistics/views/views-chart-header/views-chart-header.component';
import { ViewsComponent } from './admin/statistics/views/views.component';
import { ViewsWeeklyComponent } from './admin/statistics/views/weekly/views-weekly.component';
import { UserCreateComponent } from './admin/user/create/user-create.component';
import { UserComponent } from './admin/user/user.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './core/api.interceptor';
import { AuthService } from './core/auth/auth.service';
import { ConfirmEmailComponent } from './core/auth/confirm-email/confirm-email.component';
import { LoginComponent } from './core/auth/login/login.component';
import { RegisterComponent } from './core/auth/register/register.component';
import { LoaderComponent } from './core/loader/loader.component';
import { NavSettingsComponent } from './core/nav-settings/nav-settings.component';
import { SidenavComponent } from './core/sidenav/sidenav.component';
import { BaseInputDirective } from './shared/base-input.directive';
import { ClickOutsideDirective } from './shared/click-outside.directive';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { CustomSnackbarComponent } from './shared/custom-snackbar/custom-snackbar.component';
import { GenericDialogComponent } from './shared/generic-dialog/generic-dialog.component';
import { WeekPickerComponent } from './shared/date/week-picker/week-picker.component';
import { YearAndMonthPickerHeader } from './shared/date/year-and-month-picker/year-and-month-picker-header.component';
import { YearAndMonthPickerComponent } from './shared/date/year-and-month-picker/year-and-month-picker.component';
import { DatetimePickerComponent } from './shared/datetime-picker/datetime-picker.component';
import { DecimalNumberLimitDirective } from './shared/decimal-number-limit.directive';
import { DropdownButtonComponent } from './shared/dropdown-button/dropdown-button.component';
import { DropdownInputComponent } from './shared/dropdown-input/dropdown-input.component';
import { FilesizePipe } from './shared/filesize-pipe/filesize.pipe';
import { FormFieldGroupComponent } from './shared/form-field-group/form-field-group.component';
import { LanguageModalComponent } from './shared/language-modal/language-modal.component';
import { MoveFileToFolderModalComponent } from './shared/move-file-to-folder-modal/move-file-to-folder-modal.component';
import { MultiSelectAutocompleteComponent } from './shared/multi-select-autocomplete/multi-select-autocomplete.component';
import { MultiSelectChipsComponent } from './shared/multi-select-chips/multi-select-chips.component';
import { PasswordResetConfirmModalComponent } from './shared/password-reset-confirm-modal/password-reset-confirm-modal.component';
import { IsRemovedAdvertisementPipe } from './shared/pipes/is-removed-advertisement.pipe';
import { RangeSliderComponent } from './shared/range-slider/range-slider.component';
import { RangeDirective } from './shared/range.directive';
import { SharedModule } from './shared/shared.module';

import { VehiclesDailyComponent } from './admin/statistics/vehicles/vehicles-daily/vehicles-daily.component';
import { VehiclesWeeklyComponent } from './admin/statistics/vehicles/vehicles-weekly/vehicles-weekly.component';
import { TableComponent } from './shared/table/table.component';
import { TriggerMediaModal } from './shared/trigger-media-model/trigger-media-model.component';
import { ViewMediaModal } from './shared/view-media-modal/view-media.modal';

import { TimeFormatDirective } from "@shared/time-format.directive";
import { ScreenGroupTableComponent } from './admin/screen/screen-group-table/screen-group-table.component';
import { ChooseTenantComponent } from './core/auth/choose-tenant/choose-tenant.component';
import { DashboardFilterComponent } from './admin/statistics/dashboard-filter/dashboard-filter.component';
import { PublicLayotComponent } from './layout/public-layot/public-layot.component';
import { PrivateLayotComponent } from './layout/private-layot/private-layot.component';
import {MediaUploadExternalComponent} from "@admin/media-upload-external/media-upload-external.component";

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    LoaderComponent,
    LoginComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    SidenavComponent,
    AppComponent,
    StartComponent,
    MediaComponent,
    ScreenComponent,
    ViewsComponent,
    StatisticsFilterComponent,
    UserComponent,
    ChannelComponent,
    UserCreateComponent,
    ChannelCreateComponent,
    ScreenCreateComponent,
    MediaCreateComponent,
    MediaCreateModal,
    MediaSelectModal,
    MultiSelectChipsComponent,
    CustomerComponent,
    CustomerCreateComponent,
    FormFieldGroupComponent,
    PersonOfInterestComponent,
    PersonOfInterestCreateComponent,
    ContactPersonCreateModal,
    ViewsMonthlyComponent,
    ViewsWeeklyComponent,
    ViewsChartHeaderComponent,
    ViewMediaModal,
    MultiSelectAutocompleteComponent,
    PeopleCountComponent,
    PeopleCountTargetGroupWeeklyMonthlyComponent,
    PeopleCountTargetGroupDailyComponent,
    PeopleCountGenderComponent,
    YearAndMonthPickerComponent,
    YearAndMonthPickerHeader,
    WeekPickerComponent,
    ConfirmationModalComponent,
    PasswordResetConfirmModalComponent,
    LanguageModalComponent,
    ReportsComponent,
    ReportsDataComponent,
    MediaEditComponent,
    CustomProgressBarComponent,
    FolderCreateModalComponent,
    MediaStorageInfoComponent,
    BreadcrumbComponent,
    CustomSnackbarComponent,
    MoveFileToFolderModalComponent,
    ViewChartTotalExposuresComponent,
    DistributionOfExposuresOverHoursComponent,
    GenderGraphComponent,
    AgeDistributionGraphComponent,
    CategoryComponent,
    CreateCategoryComponent,
    DeleteCategoryWarningModalComponent,
    VisitorsPerHourGraphComponent,
    SmsComponent,
    SmsChartsComponent,
    SettingsComponent,
    ClientComponent,
    ClientCreateComponent,
    CreateScreenGroupModalComponent,
    DeleteScreneGroupModalComponent,
    BulkUpdateAiAddComponent,
    AverageAttentionTimeComponent,
    ReachComponent,
    DatetimePickerComponent,
    TableComponent,
    DecimalNumberLimitDirective,
    WeatherChartComponent,
    TriggerMediaModal,
    RangeDirective,
    RangeSliderComponent,
    NavSettingsComponent,
    ClickOutsideDirective,
    AverageTimeInChannelComponent,
    PoiFiltersModalComponent,
    BaseInputDirective,
    DropdownInputComponent,
    DropdownButtonComponent,
    IsRemovedAdvertisementPipe,
    MetricsBoxDetailsComponent,
    TimeFormatDirective,
    ScreenGroupTableComponent,
    MetricsBoxGenderComponent,
    MediaFiltersComponent,
    MediaDetailsComponent,
    FolderCreateComponent,
    PeopleCountKpisComponent,
    PercentageChangePipe,
    MoodChartComponent,
    VehiclesWeeklyComponent,
    VehiclesDailyComponent,
    AverageTimeInChannelComponent,
    ChooseTenantComponent,
    GenericDialogComponent,
    DashboardFilterComponent,
    PublicLayotComponent,
    PrivateLayotComponent,
    MediaUploadExternalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PlatformModule,
    ReactiveFormsModule,
    MatSelectCountryModule.forRoot('en'),
    HttpClientModule,
    ApiModule.forRoot({rootUrl: environment.apiBaseUrl}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: forwardRef(() => ApiInterceptor),
      multi: true,
      deps: [AuthService, MatSnackBar]
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, firstDayOfWeek: 1}},
    {
      provide: MAT_DATE_FORMATS, useValue: {
        ...MAT_LUXON_DATE_FORMATS, parse: {
          ...MAT_LUXON_DATE_FORMATS.parse,
          dateInput: 'yyyy-L-d',
        },
        display: {
          ...MAT_LUXON_DATE_FORMATS.display,
          dateInput: 'yyyy-LL-dd',
        },
      }
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
