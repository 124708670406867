import {FormControl, FormGroup} from '@angular/forms';
import { Channel, Customer } from 'src/_api';
import {IdName} from "@api/models/idname";
import {DateTime} from "luxon";

export const peopleTargetGroups = [
  // { label: 'Oidentifierad', value: NotSet },
  { label: 'peopleCount.targetGroups.under18', value: 1 },
  { label: 'peopleCount.targetGroups.18-24', value: 2 },
  { label: 'peopleCount.targetGroups.25-34', value: 3 },
  { label: 'peopleCount.targetGroups.35-44', value: 4 },
  { label: 'peopleCount.targetGroups.45-54', value: 5 },
  { label: 'peopleCount.targetGroups.55-64', value: 6 },
  { label: 'peopleCount.targetGroups.65+', value: 7 },
  { label: 'peopleCount.targetGroups.under18', value: 8 },
  { label: 'peopleCount.targetGroups.18-24', value: 9 },
  { label: 'peopleCount.targetGroups.25-34', value: 10 },
  { label: 'peopleCount.targetGroups.35-44', value: 11 },
  { label: 'peopleCount.targetGroups.45-54', value: 12 },
  { label: 'peopleCount.targetGroups.55-64', value: 13 },
  { label: 'peopleCount.targetGroups.65+', value: 14 }
]

export interface PeopleCountFormGroup {
  customer: FormControl<Customer>;
  channels: FormControl<Channel[]>;
  screens: FormControl<IdName[]>;
  timeframe: FormControl<number>;
  start: FormControl<DateTime>;
  end: FormControl<DateTime>;
  rangeCompare: FormGroup<{start: FormControl<DateTime>, end: FormControl<DateTime>}>;
}
