import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { isEqual } from 'lodash-es';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Observable, Subscription, takeUntil } from 'rxjs';
import { PeopleCounterRequest, StatisticsApi } from 'src/_api';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { TargetGroupHelper } from '../../target-group/target-group.helper';
import { StatisticsService } from '../statistics.service';
import { peopleTargetGroups } from './people-count-models';
import { EnumService } from 'src/app/core/services/enum.service';
import { StatisticsFilterValues } from 'src/app/models/statistics-filter-values';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: ''
})
export abstract class AbstractPeopleCountTargetGroup extends unsubscribeMixin() implements OnInit {
  @Input() filter: StatisticsFilterValues;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  peopleTargetGroups = peopleTargetGroups

  loading$ = new BehaviorSubject(true);
  statistics: PeopleCounterResponse;
  statisticsComparable: PeopleCounterResponse;
  statsRequest: Subscription;
  statsRequestCompare: Subscription;
  weekDays = []
  public chartPlugins = [DataLabelsPlugin];


  constructor(
    protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected targetGroupHelper: TargetGroupHelper,
    protected translateConfigService: TranslateConfigService,
    protected enumService: EnumService,
    protected translateService: TranslateService
  ) {
    super();
    this.weekDays = enumService.weekDays;
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter?.currentValue) {
      this.updateData();
    }
  }
  override ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.statsRequest?.unsubscribe();
  }

  //this method is used to be overridden in the component ts
  protected updateChartDataWithTranslation() {
  }

  protected updateData(): void {
    this.statsRequest?.unsubscribe();
    this.statsRequest = this.doApiRequest()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$))
      .subscribe((response) => {
        if (isEqual(response, this.statistics)) {
          return;
        }
        this.statistics = response;
        this.setChartData(response, this.statisticsComparable);
        this.updateChartDataWithTranslation()
      });
  }
  protected updateComparableData(): void {
    this.statsRequestCompare?.unsubscribe();
    this.statsRequestCompare = this.doApiRequestCompare()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$))
      .subscribe((response) => {
        this.statisticsComparable = response;
        this.setChartData(this.statistics, response)
        this.updateChartDataWithTranslation()
      });
  }

  protected doApiRequest(): Observable<PeopleCounterResponse> {
    return this.statisticsApi
      .reachPerDay(this.getRequestBody())
  }
  protected doApiRequestCompare(): Observable<PeopleCounterResponse> {
    return this.statisticsApi
      .reachPerDay(this.getRequestBodyCompare())
  }
  protected getRequestBodyCompare?(): PeopleCounterRequest {
    return this.getRequestBody()
  }

  protected translateLabels(labels: string[]): string[] {
    return labels.map(label => this.translateConfigService.instant(label));
  }
  abstract getRequestBody(): PeopleCounterRequest
  abstract setChartData(statistics?: PeopleCounterResponse, statisticsToCompare?: PeopleCounterResponse): void

}