import { MonthEnum } from "@api/models/months-enum";

export const MonthTranslations = {
    [MonthEnum.January]: 'global.months.january',
    [MonthEnum.February]: 'global.months.february',
    [MonthEnum.March]: 'global.months.march',
    [MonthEnum.April]: 'global.months.april',
    [MonthEnum.May]: 'global.months.may',
    [MonthEnum.June]: 'global.months.june',
    [MonthEnum.July]: 'global.months.july',
    [MonthEnum.August]: 'global.months.august',
    [MonthEnum.September]: 'global.months.september',
    [MonthEnum.October]: 'global.months.october',
    [MonthEnum.November]: 'global.months.november',
    [MonthEnum.December]: 'global.months.december',
  };