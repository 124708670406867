import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ChartConfiguration, ChartData} from 'chart.js';
import {DateTime} from 'luxon';
import {PeopleCounterResponse} from 'src/_api/models/people-counter-response';
import {TranslateConfigService} from "@core/translate-config.service";
import {EnumService} from "@core/services/enum.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseChartDirective} from "ng2-charts";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

@Component({
  selector: 'flow-people-count-target-group-weekly-monthly',
  templateUrl: './people-count-target-group-weekly-monthly.component.html',
  styleUrls: ['./people-count-target-group-weekly-monthly.component.scss']
})
export class PeopleCountTargetGroupWeeklyMonthlyComponent implements OnInit, OnChanges {
  chartDataWithTranslation: ChartData<'bar', number[], string | string[]> = null;
  date = new FormControl(DateTime.local());
  isMonth: boolean = false;
  startDate: string;
  endDate: string;
  noChartData: boolean = false;
  numberOfOriginalWeek: number;
  numberOfComparedWeek: number;
  firstWeek: string;
  secondWeek: string;
  oppToSee: number = 0;
  oppToSeeCompare: number = 0;
  public chartPlugins = [DataLabelsPlugin];

  // define array of colors for all the chart colors because OpportunityToSee is added as a last element in each dataset with a different color

  chartBackgroundColorsMen = Array(7).fill('rgba(31, 87, 146, 1)').concat('rgba(239, 194, 169, 1)');
  chartHoverColorsMen = Array(7).fill('rgba(31, 87, 146, 0.8)').concat('rgba(239, 194, 169, 1)');
  chartBackgroundColorsWomen = Array(7).fill('rgba(250, 98, 0, 1)').concat('rgba(239, 194, 169, 1)');
  chartHoverColorsWomen = Array(7).fill('rgba(250, 98, 0, 0.8)').concat('rgba(239, 194, 169, 1)');

  chartCompareBackgroundColorsMen = Array(7).fill('rgba(162, 195, 214, 1)').concat('rgba(89, 143, 174, 1)');
  chartCompareHoverColorsMen = Array(7).fill('rgba(162, 195, 214, 0.8)').concat('rgba(89, 143, 174, 1)');
  chartCompareBackgroundColorsWomen = Array(7).fill('rgba(239, 194, 169, 1)').concat('rgba(89, 143, 174, 1)');
  chartCompareHoverColorsWomen = Array(7).fill('rgba(239, 194, 169, 0.8)').concat('rgba(89, 143, 174, 1)');

  @Input() statisticsData!: PeopleCounterResponse;
  @Input() compareStatisticsData!: PeopleCounterResponse;
  @Input() start!: DateTime;
  @Input() end!: DateTime;
  @Input() rangeCompare!: any;
  @Input() compareChart!: boolean;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public chartOptions: ChartConfiguration['options'] = {
    indexAxis: 'y',
    scales: {
      x: {
        grace: '5%',
        grid: {
          display: true,
          tickColor: 'white',
        },
      },
      y: {
        grid: {
          display: true,
          tickColor: 'white'
        },
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 3,
          boxHeight: 30,
          textAlign: 'right',
          padding: 4,
          boxPadding: 200
        }
      },
      datalabels: {
        display: function (context) {
          if (context.dataIndex === 7 && (context.datasetIndex === 1 || context.datasetIndex === 3)) {
            return false
          } else {
            if (context.dataset.data[context.dataIndex] !== 0) {
              return true;
            } else {
              return false;
            }
          }
        },
        anchor: 'end',
        align: 'right'
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataIndex === 7) {
              return `${context.dataset.label.split(" ").slice(0, -1).join(" ")} Opportunity to see: ${context.dataset.data[7]}`
            } else {
              return `${context.dataset.label}: ${context.dataset.data[context.dataIndex]}`
            }
          }
        }
      }
    }
  };

  public chartData: ChartData<'bar', number[], string | string[]> = null;

  constructor(
    protected translateConfigService: TranslateConfigService,
    protected enumService: EnumService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
      this.onWeekCompareSelected()
      this.setChartData(this.statisticsData, this.compareStatisticsData);
      this.updateChartDataWithTranslation()

  }

  onWeekCompareSelected(): void {
    if(this.compareChart) {
      this.numberOfOriginalWeek = this.start.weekNumber;
      this.numberOfComparedWeek = this.rangeCompare.start.weekNumber;
      this.firstWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek}: ${this.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this.end.toFormat('yyyy-MM-dd')}`;
      this.secondWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfComparedWeek}: ${this.rangeCompare.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this.rangeCompare.end.toFormat('yyyy-MM-dd')}`;
    }
  }

  formatChartDatasets(firstDatasetlabel: string, secondDatasetLabel: string, firtsDatasetColor: string[], firtsDatasetHoverColor: string[], secondDatasetColor: string[], secondDatasetHoverColor: string[]) {
    return [
      {
        label: firstDatasetlabel,
        data: Array(7).fill(0),
        backgroundColor: firtsDatasetColor,
        borderColor: firtsDatasetColor,
        hoverBackgroundColor: firtsDatasetHoverColor,
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 1
      },
      {
        label: secondDatasetLabel,
        data: Array(7).fill(0),
        backgroundColor: secondDatasetColor,
        borderColor: secondDatasetColor,
        hoverBackgroundColor: secondDatasetHoverColor,
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 1
      }
    ]
  }

  setDatasetsData(statistics: PeopleCounterResponse, datasets: any, compareMode?: boolean) {
    this.oppToSee = 0;
    this.oppToSeeCompare = 0;
    statistics.screenHours.forEach(statistic => {
      statistic.peoples.forEach(result => {
        const {targetGroup, count} = result;
        const isWoman = targetGroup <= 7;
        const datasetIndex = isWoman ? 1 : 0;
        const ageRangeIndex = isWoman ? targetGroup - 1 : targetGroup - 8;
        datasets[datasetIndex].data[ageRangeIndex] += count;
      });
      if (compareMode) {
        this.oppToSeeCompare += statistic.opportunityToSee;
      } else {
        this.oppToSee += statistic.opportunityToSee
      }
    });
    return datasets;
  }

  setChartData(statistics?: PeopleCounterResponse, statisticsCompare?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return

    this.startDate = this.start.toFormat('yyyy-MM-dd');
    this.endDate = this.end.toFormat('yyyy-MM-dd');
    let formattedChartData: any = {};

    formattedChartData = {
      labels: ['under18', '18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'opportunityToSee'],
      datasets: this.formatChartDatasets(this.translateConfigService.instant('peopleCount.men'), this.translateConfigService.instant('peopleCount.women'), this.chartBackgroundColorsMen, this.chartHoverColorsMen, this.chartBackgroundColorsWomen, this.chartHoverColorsWomen)
    };
    formattedChartData.datasets = this.setDatasetsData(statistics, formattedChartData.datasets);

    formattedChartData.datasets[0].data.push(this.oppToSee)
    formattedChartData.datasets[1].data.push(this.oppToSee)

    if (statisticsCompare && !this.isMonth) {
      formattedChartData = {
        labels: ['under18', '18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'opportunityToSee'],
        datasets: this.formatChartDatasets(`${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek} ${this.translateConfigService.instant('peopleCount.men')}`, `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek} ${this.translateConfigService.instant('peopleCount.women')}`, this.chartBackgroundColorsMen, this.chartHoverColorsMen, this.chartBackgroundColorsWomen, this.chartHoverColorsWomen)
      }
      formattedChartData.datasets = this.setDatasetsData(statistics, formattedChartData.datasets);
      formattedChartData.datasets[0].data.push(this.oppToSee)
      formattedChartData.datasets[1].data.push(this.oppToSee)

      let compareDatasets = this.formatChartDatasets(`${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfComparedWeek} ${this.translateConfigService.instant('peopleCount.men')}`, `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfComparedWeek} ${this.translateConfigService.instant('peopleCount.women')}`, this.chartCompareBackgroundColorsMen, this.chartCompareHoverColorsMen, this.chartCompareBackgroundColorsWomen, this.chartCompareHoverColorsWomen);
      compareDatasets = this.setDatasetsData(statisticsCompare, compareDatasets, true);

      compareDatasets[0].data.push(this.oppToSeeCompare);
      compareDatasets[1].data.push(this.oppToSeeCompare);

      formattedChartData = {
        labels: formattedChartData.labels,
        datasets: [...formattedChartData.datasets, ...compareDatasets]
      }
    }
    this.chartData = formattedChartData;
    this.chart?.update();
  }

  updateChartDataWithTranslation() {
    this.chartDataWithTranslation = {...this.getChartDataWithTranslations()}
    if (this.chartDataWithTranslation?.datasets[0]?.data.every(num => num === 0) && this.chartDataWithTranslation?.datasets[1]?.data.every(num => num === 0)) {
      this.noChartData = true;
    } else {
      this.noChartData = false;
    }
  }

  getChartDataWithTranslations(): ChartData<'bar', number[], string | string[]> {
    if (this.chartData) {
      return {
        ...this.chartData,
        labels: this.chartData.labels.map(label => this.translateConfigService.instant(`peopleCount.targetGroups.${label}`)),
        datasets: this.chartData.datasets.map((d) => {
          const splitParts = d.label.split(" ")
          if (splitParts.length > 1) {
            const label = splitParts.map((sp) => this.translateConfigService.instant(sp)).join(" ")
            return {...d, label}
          }
          return {...d, label: this.translateConfigService.instant(d.label)}
        })

      }
    }
    return {datasets: [], labels: []}
  }

}
