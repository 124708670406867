
export const emptyRowPeopleCount = {
  date: null,
  screen: {
    id: null,
    name: ""
  },
  customer: {
    id: null,
    name: ""
  },
  channel: {
    id: null,
    name: ""
  },
  placement: "",
  visitors: "",
  distingtTargetGroups: [],
  hourlyCalculated: null
}

export const emptyRowExposures = {

  bookingType: null,
  bookingNumber: null,
  bookingName: null,
  mediaFile: {
    id: null,
    name: ""
  },
  fileType: null,
  totalExposures: null,
  primaryExposures: null,
  secondaryExposures: null,
  shareOfVoice: null,
  reach: null,
  date: null,
  customer: {
    id: null,
    name: ""
  },
  screen: {
    id: null,
    name: ""
  },
  channel: {
    id: null,
    name: ""
  },
  placement: null,
  distingtTargetGroups: [

  ]

}


export const targetGroupTableHeaders = ['targetGroup1', 'targetGroup2', 'targetGroup3', 'targetGroup4', 'targetGroup5', 'targetGroup6', 'targetGroup7', 'targetGroup8', 'targetGroup9', 'targetGroup10', 'targetGroup11', 'targetGroup12', 'targetGroup13', 'targetGroup14']