import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject, map, startWith } from 'rxjs';


@Component({
  selector: 'flow-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrls: ['./multi-select-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: MultiSelectAutocompleteComponent }
  ],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id'
  },
})
export class MultiSelectAutocompleteComponent implements OnDestroy, MatFormFieldControl<any[]>, ControlValueAccessor, OnChanges {
  static nextId = 0;
  filteredOptions: Observable<any[]>;
  inputControl: FormControl<string | any> = new FormControl('');
  eventOptionWasSelected = false;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Input() options: any[];
  @Input() formControlName: string;
  @Input() displayFn: (option: any) => string;
  @Input() newOptionFn: (value: string) => any;
  @Input() isLimitReached: boolean = false;
  @Input() isOneChipNeeded: boolean;
  @Input() inputTouched: boolean;
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.stateChanges.next();
  }
  _value: any[] | null;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.inputControl.disable() : this.inputControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input('aria-describedby') userAriaDescribedBy: string;

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'multi-select-autocomplete';
  id = `multi-select-autocomplete-${MultiSelectAutocompleteComponent.nextId++}`;

  get empty() {
    return !this.inputControl.value && !this.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.inputControl.invalid && this.touched;
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this.filteredOptions = this.inputControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const label = typeof value === 'string' ? value : this.displayFn(value);
        return label ? this.filterOptions(label as string) : this.options?.slice() || [];
      }),
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isLimitReached?.currentValue) {
      this.inputControl.disable();
      this.focused = false;
    } else {
      this.inputControl.enable();
    }

    if (changes?.inputTouched?.currentValue) {
      this.touched = true
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
  onFocusIn(event:Event) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }
  onInputEnter(event:Event): void {
    if (this.eventOptionWasSelected && !this.isOneChipNeeded) {
      this.eventOptionWasSelected = false;
      return;
    }

    let alreadyExists = false;
    this.filteredOptions.subscribe(options => {
      options.filter(item => {
        if (item.name == this.inputControl.value) {
          this.addOption(item);
          alreadyExists = true;
        }
      });
    });
    if (alreadyExists) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const inputVal = this.inputControl.value;
    if (!inputVal) {
      return;
    }
    if (this.isOneChipNeeded) {
      this.autocomplete.closePanel();
    }
    const newOption = this.newOptionFn(inputVal);
    this.addOption(newOption);
    this.stateChanges.next();
  }
  onFocusOut(event:FocusEvent) {
    setTimeout(() => {
      const clickedElement = event.relatedTarget as HTMLElement;
      if (!clickedElement || !clickedElement.classList.contains('mat-option')) {
        this.inputControl.setValue('');
      }
    });
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.eventOptionWasSelected = true;
    this.addOption(event.option.value);
    this.stateChanges.next();
  }
  addOption(option): void {
    if (this.isOptionSelected(option)) {
      return;
    }

    this.value = this.value?.length ? [...this.value, option] : [option];
    this.onChange(this.value);
    this.inputControl.setValue('');
  }
  isOptionSelected(option): boolean {
    const label = this.displayFn(option);
    return this.value?.some(opt => this.displayFn(opt) === label);
  }
  onSelectedOptionRemoved(option: any) {
    this.value = this.value.filter(o => o !== option);
    this.onChange(this.value);
  }
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.multi-select-autocomplete-container',
    )!;
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }
  private filterOptions(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options?.filter(option => this.displayFn(option).toLowerCase().includes(filterValue)) || [];
  }
  writeValue(val: any[] | null): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
