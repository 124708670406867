import { Injectable } from '@angular/core';
import { BookingRequest } from '@api/models/booking';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingsFilterService {
  private selectedFilters: BehaviorSubject<BookingRequest> = new BehaviorSubject<BookingRequest>(null);
  selectedFilters$ = this.selectedFilters.asObservable();

  private totalBookings: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalBookings$ = this.totalBookings.asObservable();

  setFilters(filters: BookingRequest) {
    this.selectedFilters.next(filters)
  }

  setTotalBookings (total: number) {
    this.totalBookings.next(total)
  }

}
