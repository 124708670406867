import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { AuthService, UserRoleEnum } from '../auth/auth.service';
import { indicateLoading } from '../indicate-loading';
import { unsubscribeMixin } from '../unsubscribe';
import { PasswordResetConfirmModalComponent } from 'src/app/shared/password-reset-confirm-modal/password-reset-confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminApi, Customer } from 'src/_api';
import { CurrentUser } from '../auth/current-user';

@Component({
  selector: 'flow-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent extends unsubscribeMixin() implements OnInit {
  @Input() navMini = false;
  @ViewChild(MatExpansionPanel) userPanel: MatExpansionPanel;
  reachOpened: boolean = false;
  exposuresOpened: boolean = false;
  poiOpened: boolean = false;
  peopleCounterOpened: boolean = false;
  customers: Customer[];
  selectedCustomer: Customer;
  activeNavGroup: string;
  isDev = environment.env === 'dev';
  isAdmin: boolean;
  isRole: boolean;
  currentRoute = '';
  user: CurrentUser;

  loading$ = {
    customers: new BehaviorSubject(false)
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminApi: AdminApi,
    public appService: AppService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.userChanged.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user) {
          this.getUserCustomers();
        } else {
          this.selectedCustomer = null;
        }
      });
    if (this.authService.isLoggedIn()) {
      this.getUserCustomers();
    }

    this.router.events.pipe(takeUntil(this.ngUnsubscribe), filter(event => event instanceof NavigationEnd))
      .subscribe(navigationEnd => {
        this.currentRoute = (navigationEnd as NavigationEnd)?.url;
      });
  }

  logout(): void {
    this.panelOpenState = false;
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  getUserCustomers(): void {
    this.user = this.authService.userData
    this.isAdmin = this.user?.roleId === UserRoleEnum.Admin
    this.isRole = this.user?.roleId === UserRoleEnum.Admin || this.user?.roleId === UserRoleEnum.Customer
    forkJoin([
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin ? this.adminApi.getCustomerById(this.user.customerId) : of(null)
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.customers, this.cdr))
      .subscribe(([customers, customer]) => {
        this.customers = this.isAdmin ? customers : [customer]
        this.selectedCustomerChange(this.user);
      });
  }

  onUserPanelOpened() {
    this.getUserCustomers();
  }

  onCustomerChange(customer: Customer) {
    const customerId = customer.id === -1 ? null : customer.id;
    if (customerId === this.authService.user.currentCustomerId) {
      return;
    }
    const currentUser = this.authService.setCustomer(this.authService.user, customerId)
    this.selectedCustomerChange(currentUser);
    this.userPanel.close();
    this.router.navigate(['/home']).then(() => window.location.reload());
  }


  selectedCustomerChange(currentUser) {
    if (this.isRole) {
      this.selectedCustomer = this.isAdmin ? (currentUser.currentCustomerId
        ? this.customers?.find(c => c.id === currentUser.currentCustomerId)
        : null) : this.customers?.find(c => c.id === currentUser.customerId);
    }
    if (this.isAdmin) {
      const all = { id: -1, name: 'Admin' };
      this.customers.splice(0, 0, all);
      if (!this.selectedCustomer) {
        this.selectedCustomer = all;
      }
    }
  }

  checkIfAdminCustomerOrUser() {
    return this.isAdmin && this.selectedCustomer?.id === -1;
  }

  compareCustomers(c1: Customer, c2: Customer) {
    return c1?.id == c2?.id;
  }

  onPasswordResetLink(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(PasswordResetConfirmModalComponent, {
      width: '700px',
    })
  }
  isActive(path: string): boolean {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };
    return this.router.isActive(path, matchOptions);
  }
  openNavigationMenu(type:string) {
    switch(type){
      case 'reach': {
        this.reachOpened = true;
        this.exposuresOpened = false;
        this.peopleCounterOpened = false;
        this.poiOpened = false;
      } break;
      case 'exposures': {
        this.reachOpened = false;
        this.exposuresOpened = true;
        this.peopleCounterOpened = false;
        this.poiOpened = false;
      } break;
      case 'peopleCounter': {
        this.reachOpened = false;
        this.exposuresOpened = false;
        this.peopleCounterOpened = true;
        this.poiOpened = false;
      } break;
      case 'poi': {
        this.reachOpened = false;
        this.exposuresOpened = false;
        this.peopleCounterOpened = false;
        this.poiOpened = true;
      } break;
    }
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!event.target['closest']('.dropdown-container-reach') && !event.target['closest']('.dropdown-container-exposures') &&
        !event.target['closest']('.dropdown-container-people-counter') && !event.target['closest']('.dropdown-container-poi')) {
      this.reachOpened = false;
      this.exposuresOpened = false;
      this.peopleCounterOpened = false;
      this.poiOpened = false;
    }
  }
}
