<div class="h-full flex flex-col justify-between">
  <mat-dialog-title class="flex flex-row justify-between border-b border-gray-200 p-0">
    <span class="title">{{'media.chooseMedia' | translate}}</span>
    <app-icon stroke="#202020" [ngStyle]="{'cursor': 'pointer'}" (click)="onCancel()" size="md" iconName="x"></app-icon>
  </mat-dialog-title>
  <mat-dialog-content class="py-2">
    <flow-media
      class="max-w-full"
      [isModal]="true"
      [customerIdModal]="data.customerIdModal"
      [multipleSelect]="multipleSelect"
      (mediaSelected)="onSelected($event)"
      (cancel)="onCancel()"></flow-media>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="h-10 justify-end items-start gap-4 inline-flex">
      <flow-button theme="secondary" variation="outline" (click)="onCancel()">{{ 'global.cancel' | translate }}</flow-button>
      <flow-button theme="primary" cdkFocusInitial [disabled]="!selectedMedias || selectedMedias.length === 0" (click)="onAdd()">
        {{ 'global.add' | translate }}
      </flow-button>
    </div>
  </mat-dialog-actions>
</div>
