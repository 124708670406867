<div class="relative w-full h-full aspect-video max-w-5xl flex items-center justify-center bg-gray-800 overflow-hidden">
  <img
    *ngIf="mediaItems.type === mediaTypeEnum.Image"
    [src]="mediaItems.url"
    alt="Media Image"
    class="max-w-full max-h-full object-contain"
  />

  <video
    *ngIf="mediaItems.type === mediaTypeEnum.Video"
    #videoPlayer
    [src]="mediaItems.url"
    (ended)="onVideoEnded()"
    autoplay
    [muted]="true"
    class="max-w-full max-h-full object-contain"
  ></video>
</div>
