import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaTypeEnum } from '@api/models/media-type-enum';
import { environment } from '@environments/environment';
import { MediaPreviewSlideComponent } from './media-preview-slide/media-preview-slide.component';
import { MediaItemDetails } from './media-preview.model';

@Component({
  selector: 'flow-media-preview-carousel',
  templateUrl: './media-preview-carousel.component.html',
  styleUrls: ['./media-preview-carousel.component.scss'],
})
export class MediaPreviewCarouselComponent implements OnInit {
  @ViewChildren(MediaPreviewSlideComponent) slides!: QueryList<MediaPreviewSlideComponent>;
  mediaItems: MediaItemDetails[];
  currentIndex: number = 0;
  timeoutId?: number;
  apiBaseUrl = environment.apiBaseUrl;
  MediaTypeEnum = MediaTypeEnum;

  currentSlide = 0;
  lastFrameTime: number | null = null;
  elapsedTime: number = 0;
  animationFrameId: number | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<MediaPreviewCarouselComponent>) {}

  ngOnInit(): void {
    if (this.data?.advertisements && Array.isArray(this.data.advertisements)) {
      this.mediaItems = this.data.advertisements.map(item => {
        const media = item.media ?? item.mediaDetails;
        if (!media?.imageUrl) {
          return null;
        }
        const duration = item.presentationTime ? item.presentationTime * 1000 : media.duration ? media.duration * 1000 : 0;
        return {
          url: `${this.apiBaseUrl}${media.imageUrl}`,
          type: media.type,
          duration,
        };
      });
    }
  }

  ngAfterViewInit() {
    this.startAutoplay();
  }

  ngOnDestroy() {
    this.stopAutoplay();
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.mediaItems.length;
    this.resetCurrentMedia();
    this.startAutoplay();
  }

  prevSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.mediaItems.length) % this.mediaItems.length;
    this.resetCurrentMedia();
    this.startAutoplay();
  }

  startAutoplay() {
    this.stopAutoplay();
    this.lastFrameTime = null;
    this.elapsedTime = 0;

    this.animationFrameId = requestAnimationFrame(this.runAutoplay.bind(this));
  }

  private runAutoplay(timestamp: number) {
    if (!this.lastFrameTime) {
      this.lastFrameTime = timestamp;
    }

    const timeDifference = timestamp - this.lastFrameTime;
    this.lastFrameTime = timestamp;
    this.elapsedTime += timeDifference;

    const currentItem = this.mediaItems[this.currentSlide];

    if (currentItem.type === MediaTypeEnum.Image && currentItem.duration && this.elapsedTime >= currentItem.duration) {
      this.nextSlide();
      return;
    }

    if (currentItem.type === MediaTypeEnum.Video) {
      const slide = this.slides?.get(this.currentSlide);
      if (slide && this.elapsedTime === timeDifference) {
        slide.playMedia();
      }
    }

    this.animationFrameId = requestAnimationFrame(this.runAutoplay.bind(this));
  }

  stopAutoplay() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  resetCurrentMedia() {
    const slide = this.slides?.get(this.currentSlide);
    if (slide) {
      slide.resetMedia();
    }
  }

  handleVideoEnded() {
    this.nextSlide();
  }

  close() {
    this.dialogRef.close();
  }

  get currentSlideHasTriggers(): boolean {
    const triggers = this.data.advertisements[this.currentSlide]?.triggers;
    if (!triggers) {
      return false;
    }
    return Object.values(triggers).some(value => 
      (Array.isArray(value) ? value.length > 0 : value !== null && value !== undefined));
  }
}
