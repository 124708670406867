import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ChartConfiguration, ChartData} from 'chart.js';
import {DateTime} from 'luxon';
import {PeopleCounterResponse, TimeframeEnum} from 'src/_api';
import {TranslateConfigService} from "@core/translate-config.service";
import {EnumService} from "@core/services/enum.service";
import {TranslateService} from "@ngx-translate/core";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import {BaseChartDirective} from "ng2-charts";
import {StatisticsService} from "@admin/statistics/statistics.service";

@Component({
  selector: 'flow-people-count-gender',
  templateUrl: './people-count-gender.component.html',
  styleUrls: ['./people-count-gender.component.scss']
})
export class PeopleCountGenderComponent implements OnInit, OnChanges {
  chartDataWithTranslation: ChartData<'line', number[], string | string[]> = null;
  date = new FormControl(DateTime.local());
  isMonth: boolean = false;
  startDate: string;
  endDate: string;
  noChartData: boolean = false;
  numberOfOriginalWeek: number;
  firstWeek: string;
  secondWeek: string;
  aggregatedStatistics: any;


  @Input() statisticsData!: PeopleCounterResponse;
  @Input() compareStatisticsData!: PeopleCounterResponse;
  @Input() start!: DateTime;
  @Input() end!: DateTime;
  @Input() timeframe!: TimeframeEnum
  @Input() rangeCompare!: any
  @Input() compareChart: boolean = false;


  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public chartPlugins = [DataLabelsPlugin];
  public chartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        type: 'category',
        grid: {
          display: true,
          tickColor: 'white',
          lineWidth: 2
        },
        ticks: {
          padding: 20,
          autoSkip: true,
          maxTicksLimit: 31
        },
      },
      y: {
        grid: {
          display: true,
          tickColor: 'white'
        },
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 1,
          boxHeight: 30,
          textAlign: 'left',
          padding: 5
        }
      },
      datalabels: {
        display: false
      }
    }
  };
  public chartData: ChartData<'line', number[], string | string[]> = null;

  constructor(
    protected translateConfigService: TranslateConfigService,
    protected enumService: EnumService,
    protected translateService: TranslateService,
    protected statisticsService: StatisticsService,
  ) {
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setChartData(this.statisticsData, this.compareStatisticsData);
    this.updateChartDataWithTranslation()

    this.numberOfOriginalWeek = this.start.weekNumber
    this.firstWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek}: ${this.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this.end.toFormat('yyyy-MM-dd')}`;
    this.secondWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.rangeCompare.start?.weekNumber}: ${this.rangeCompare?.start?.startOf('week').toFormat('yyyy-MM-dd')} - ${this.rangeCompare?.end?.toFormat('yyyy-MM-dd')}`;
  }

  getDaySuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  generateLabelsForMonth = () => {
    const daysInMonth = this.date.value.daysInMonth;
    const labels = [];
    for (let i = 1; i <= daysInMonth; i++) {
      labels.push(`${i}${this.getDaySuffix(i)}`);
    }

    return labels.map((label, index) => {
      return (index % 7 === 0) ? label : '';
    });
  };

  generateAllDatesForMonth(year: number, month: number) {
    let dates = [];
    let date = new Date(year, month, 1);

    while (date.getMonth() === month) {
      let day = String(date.getDate()).padStart(2, '0');
      let monthFormatted = String(month + 1).padStart(2, '0'); // month is 0-based
      let yearFormatted = date.getFullYear();
      dates.push(`${yearFormatted}-${monthFormatted}-${day}`);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getWeekdayIndex(dateString: string) {
    const date = new Date(dateString);
    return (date.getUTCDay() + 6) % 7;
  }


  groupDailyData(statistics?: PeopleCounterResponse) {
    const aggregatedData = {};

    statistics.screenHours.forEach(statistic => {
      const date = new Date(statistic.time).toISOString().split('T')[0];

      if (!aggregatedData[date]) {
        aggregatedData[date] = {peoples: {}, opportunityToSee: 0};
      }

      statistic.peoples.forEach(result => {
        const {targetGroup, count} = result;
        if (!aggregatedData[date].peoples[targetGroup]) {
          aggregatedData[date].peoples[targetGroup] = 0;
        }
        aggregatedData[date].peoples[targetGroup] += count;
      });

      aggregatedData[date].opportunityToSee += statistic.opportunityToSee || 0;
    });

    this.aggregatedStatistics = {
      screenHours: Object.keys(aggregatedData).map(date => {
        return {
          time: `${date}T00:00:00.000Z`,
          peoples: Object.keys(aggregatedData[date].peoples).map(targetGroup => ({
            targetGroup: Number(targetGroup),
            count: aggregatedData[date].peoples[targetGroup]
          })),
          opportunityToSee: aggregatedData[date].opportunityToSee
        };
      })
    };
  }

  calculateSum = (allDates, comparisonFn) => {
    return allDates.map(date => {
      const item = this.aggregatedStatistics.screenHours.find(d => d.time.startsWith(date));
      if (item) {
        return item.peoples.reduce((sum, target) =>
          comparisonFn(target.targetGroup) ? sum + target.count : sum, 0);
      }
      return 0;
    });
  }

  setDatasetsData(statistics: PeopleCounterResponse, datasets: any) {
    statistics.screenHours.forEach(statistic => {
      const dayIndex = this.getWeekdayIndex(statistic.time);
      statistic.peoples.forEach(result => {
        const {targetGroup, count} = result;
        const isWoman = targetGroup <= 7;
        const datasetIndex = isWoman ? 1 : 0;
        datasets[datasetIndex].data[dayIndex] += count;
      });
    });
    return datasets;
  }

  formatChartDatasets(firstDatasetlabel: string, secondDatasetLabel: string, firtsDatasetColor: string, secondDatasetColor: string, firstDatasetData?: any, secondDatasetData?: any) {
    return [
      {
        label: firstDatasetlabel,
        data: firstDatasetData || Array(7).fill(0),
        backgroundColor: firtsDatasetColor,
        borderColor: firtsDatasetColor,
        borderWidth: 3,
        fill: false,
        pointRadius: 0,
        lineTension: 0.5
      },
      {
        label: secondDatasetLabel,
        data: secondDatasetData || Array(7).fill(0),
        backgroundColor: secondDatasetColor,
        borderColor: secondDatasetColor,
        borderWidth: 3,
        fill: false,
        pointRadius: 0,
        lineTension: 0.5
      }
    ]
  }

  setChartData(statistics?: PeopleCounterResponse, statisticsCompare?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return

    const start = this.start;
    const end = this.end;
    const [startDT, endDT] = this.getStartAndEndDT(start, end);
    this.startDate = start.toFormat('yyyy-MM-dd');
    this.endDate = end.toFormat('yyyy-MM-dd');
    const weekdays = this.statisticsService.buildPeriod(startDT, endDT);
    let formattedChartData: any = {};
    this.groupDailyData(statistics);

    if (this.timeframe == TimeframeEnum.CurrentMonth || this.timeframe == TimeframeEnum.PreviousMonth) {
      const allDates = this.generateAllDatesForMonth(this.date.value.year, this.start.month - 1);
      const menData = this.calculateSum(allDates, targetGroup => targetGroup > 7);
      const womenData = this.calculateSum(allDates, targetGroup => targetGroup <= 7);
      formattedChartData = {
        labels: this.generateLabelsForMonth(),
        datasets: this.formatChartDatasets(this.translateConfigService.instant('peopleCount.men'), this.translateConfigService.instant('peopleCount.women'), 'rgba(31, 87, 146, 1)', 'rgba(250, 98, 0, 1)', menData, womenData)
      };
      formattedChartData.datasets = this.setDatasetsData(statistics, formattedChartData.datasets);
    }
    if (this.timeframe === TimeframeEnum.Custom) {
      const allDates = statistics.screenHours.map(date => {
        const dt = DateTime.fromISO(date.time);
        return dt.toFormat('dd-MM');
      });
      const newDates = statistics.screenHours.map(date => (date.time))
      const menData = this.calculateSum(newDates, targetGroup => targetGroup > 7);
      const womenData = this.calculateSum(newDates, targetGroup => targetGroup <= 7);

      formattedChartData = {
        labels: allDates,
        datasets: this.formatChartDatasets(this.translateConfigService.instant('peopleCount.men'), this.translateConfigService.instant('peopleCount.women'), 'rgba(31, 87, 146, 1)', 'rgba(250, 98, 0, 1)', menData, womenData)
      }
    }
    if (this.timeframe == TimeframeEnum.CurrentWeek || this.timeframe == TimeframeEnum.PreviousWeek) {
      formattedChartData = {
        labels: this.isMonth ? this.generateLabelsForMonth() : weekdays.map(item => item.label),
        datasets: this.formatChartDatasets(this.translateConfigService.instant('peopleCount.men'), this.translateConfigService.instant('peopleCount.women'), 'rgba(31, 87, 146, 1)', 'rgba(250, 98, 0, 1)')
      }
      formattedChartData.datasets = this.setDatasetsData(statistics, formattedChartData.datasets);
    }
    if (statisticsCompare) {
      formattedChartData = {
        labels: this.isMonth ? this.generateLabelsForMonth() : weekdays.map(item => item.label),
        datasets: this.formatChartDatasets(`${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek} ${this.translateConfigService.instant('peopleCount.men')}`, `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek} ${this.translateConfigService.instant('peopleCount.women')}`, 'rgba(31, 87, 146, 1)', 'rgba(250, 98, 0, 1)')
      }
      formattedChartData.datasets = this.setDatasetsData(statistics, formattedChartData.datasets);

      let compareDatasets = this.formatChartDatasets(`${this.translateConfigService.instant('peopleCount.week')} ${this.rangeCompare.start?.weekNumber} ${this.translateConfigService.instant('peopleCount.men')}`, `${this.translateConfigService.instant('peopleCount.week')} ${this.rangeCompare.start?.weekNumber} ${this.translateConfigService.instant('peopleCount.women')}`, 'rgba(162, 195, 214, 1)', 'rgba(239, 194, 169, 1)');
      compareDatasets = this.setDatasetsData(statisticsCompare, compareDatasets);

      formattedChartData = {
        labels: formattedChartData.labels,
        datasets: [...formattedChartData.datasets, ...compareDatasets]
      }
    }
    this.chartData = formattedChartData;
    this.chart?.update();
  }

  updateChartDataWithTranslation() {
    this.chartDataWithTranslation = {...this.getChartDataWithTranslations()}
    if (this.chartDataWithTranslation?.datasets[0]?.data.every(num => num === 0) && this.chartDataWithTranslation?.datasets[1]?.data.every(num => num === 0)) {
      this.noChartData = true;
    } else {
      this.noChartData = false;
    }
  }

  getChartDataWithTranslations(): ChartData<'line', number[], string | string[]> {
    if (this.chartData) {
      return {
        ...this.chartData,
        labels: this.chartData.labels,
        datasets: this.chartData.datasets?.map((d) => {
          const splitParts = d.label.split(" ")
          if (splitParts.length > 1) {
            const label = splitParts.map((sp) => this.translateConfigService.instant(sp)).join(" ")
            return {...d, label}
          }
          return {...d, label: this.translateConfigService.instant(d.label)}
        })
      }
    }
    return {datasets: [], labels: []}
  }

  private getStartAndEndDT(start: DateTime, end: DateTime) {
    const startDT = start?.startOf('week');
    const endDT = end?.endOf('week');

    return [startDT, endDT]
  }

}
