<div class="flex items-center justify-between visitors">
  <div>
    <h2 class="reach-section-title"> {{ "peopleCount.visitorsPerHourCurrentWeek" | translate }}</h2>
  </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between py-4">
    <div *ngIf="!compare" class="flex">
      <span> {{ startDate }} - {{ endDate }}</span>
    </div>
    <div *ngIf="compare">
      <span class="pr-8">{{ "global.compareWeeks" | translate }}</span>
      <span class="pr-5"> {{'peopleCount.week' | translate}} {{weekNumber}}:  {{ startDate }} {{endDate}}</span>
      <span> {{'peopleCount.week' | translate}} {{weekNumberCompare}}: {{ compareStartDate }} {{compareEndDate}}</span>
    </div>
</div>
<mat-divider *ngIf="chartData"></mat-divider>

<div *ngIf="!hasData" class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
  <canvas baseChart height="50"></canvas>
</div>

<div *ngIf="hasData" class="mt-4">
<canvas [data]="chartData"
        [options]="chartOptions"
        [plugins]="chartPlugins"
        [type]="'line'"
        baseChart
        height="90vh">
</canvas>
</div>
