import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flow-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.scss']
})
export class CustomProgressBarComponent implements OnInit {

  @Input() totalCount: number;
  @Input() counter: number;
  @Input() fileSizes: number[];

  progressWidth: string;

  constructor() { }

  ngOnInit(): void {
    this.calculateProgress();
  }
  
  calculateProgress(): void {
    if (this.totalCount && this.counter >= 0 && this.fileSizes.length > 0) {
      const totalSize = this.fileSizes.reduce((acc, size) => acc + size, 0);
      const uploadedSize = this.fileSizes.slice(0, this.counter).reduce((acc, size) => acc + size, 0);
      const percent = (uploadedSize / totalSize) * 100;
      this.progressWidth = Math.floor(percent) + '%';
    } else {
      this.progressWidth = '0%';
    }
  }

}


