<p class="title">{{ 'poi.filters' | translate }}
  <a [mat-dialog-close]="true"
     class="cursor-pointer">
    <app-icon iconName="x"
              size="lg">
    </app-icon>
  </a></p>
<div class="divider"></div>


<div class="py-2 poi-filters"
     mat-dialog-content>
  <div [formGroup]="filterForm" class="form-container flex flex-col">
    <mat-label>{{ 'poi.form.text' | translate }}</mat-label>
    <mat-form-field appearance="outline" class="mr-3">
      <input formControlName="search"
             matInput
             type="text"
             placeholder="{{ 'poi.form.text' | translate }}"
      >
    </mat-form-field>
    <ng-container *ngIf="isAdmin">
      <mat-label>{{ 'poi.form.customer' | translate }}</mat-label>
      <mat-form-field
        appearance="outline"
        class="mr-3">
        <mat-select formControlName="customerIds" placeholder="{{ 'poi.form.customer' | translate }}">
          <mat-option *ngFor="let customer of customers"
                      [value]="customer.id">
            {{ customer.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-label>{{ 'poi.form.categories' | translate }}</mat-label>
    <mat-form-field appearance="outline" class="mr-3">
      <mat-select
        placeholder="{{ 'poi.form.categories' | translate }}"
        formControlName="categories"
        multiple>
        <mat-option *ngFor="let category of categories"
                    [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ 'poi.form.tags' | translate }}</mat-label>
    <mat-form-field appearance="outline" class="mr-3">
      <mat-select formControlName="tags"
                  placeholder="{{ 'poi.form.tags' | translate }}"
                  multiple>
        <mat-option *ngFor="let tag of tags"
                    [value]="tag.id">
          {{ tag.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ 'poi.form.datePickerLabel' | translate }}</mat-label>
    <mat-form-field appearance="outline" class="mr-3">
      <mat-date-range-input [rangePicker]="picker"
                            formGroupName="created">
        <input [placeholder]="'poi.form.datePickerFrom' | translate"
               formControlName="start"
               matStartDate>
        <input [placeholder]="'poi.form.datePickerTo' | translate"
               formControlName="end"
               matEndDate>
      </mat-date-range-input>
      <app-icon (click)="clearDatePicker()"
                *ngIf="filterForm.get('created').get('start').value"
                [ngStyle]="{cursor:'pointer',position: 'absolute',right:'-6px', top:'6.5px'}"
                iconName="close"
                size="xl"></app-icon>
      <mat-datepicker-toggle [for]="picker"
                             matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions>
  <div class=" w-full flex justify-between items-baseline">
    <a (click)="clearForm()" class="text-[#1F5792] cursor-pointer underline">{{ 'poi.clearAll' | translate}}</a>
    <button (click)="filter()" color="primary" mat-flat-button>{{ 'poi.filter' | translate }}</button>
  </div>
</mat-dialog-actions>
