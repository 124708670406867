<flow-helper-card *ngIf="!consentGiven" consentKey="poi-consent" description="poi.consentDescription" title="POI"></flow-helper-card>
<flow-header [title]="'Persons of Interest (POI)'">
  <div class="flex flex-row flex-1 w-full gap-4">
    <button type="button" color="primary" mat-flat-button [matMenuTriggerFor]="menu">
      <span class="flex flex-row items-center">
        {{'poi.add' | translate}}
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <a *flowHasPermission="['UpdatePoiInsights']; disabled true;" [routerLink]="['/person-of-interest/create']" mat-menu-item>
        {{ 'poi.personOFInterest' | translate}}
      </a>
      <a *flowHasPermission="['UpdatePoiCategoriesInsights']; disabled true;" [routerLink]="['/categories/create']" mat-menu-item>
        {{'poi.poiCategory' | translate}}
      </a>
    </mat-menu>
    <button (click)="onDeleteSelected($event)" *ngIf="isButtonShown()" class="btn-primary-s h-[40px] bg-brand-red">
      {{ 'poi.deletePoi' | translate }}
    </button>
  </div>

  <button
    *ngIf="selectedTabIndex==0"
    (click)="openFilters()"
    class="relative flex flex-row border border-gray-400 px-3 py-2 h-9 rounded-md mr-5 items-center hover:bg-gray-100 overflow-visible">
    <app-icon fill="none" [stroke]="'#202020'" iconName="filter-lines" size="md"></app-icon>
    <span class="pl-2">{{'global.filter' | translate}}</span>
    <span *ngIf="filtersNumber > 0" class="filters-count">{{filtersNumber}}</span>
  </button>
</flow-header>

<div class="mt-5 px-2">
  <mat-tab-group
    (selectedTabChange)="tabChange($event)"
    [(selectedIndex)]="selectedTabIndex"
    animationDuration="0ms"
    class="mat-tabs-override">
    <mat-tab [disabled]="disablePoiInsights" label="{{'poi.overview' | translate}}">
      <br />
      <ng-container *ngIf="dataSource?.data?.length===0;else poiTable">
        <div class="no-data-selected-container">
          <img src="../../../../assets/no-data.png" />
          <p>{{'global.noDataFound' | translate }}</p>
          <p class="no-data-subtitle">{{'global.pleaseSelectDifferentFilters' | translate}}</p>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab [disabled]="disablePoiCategoriesInsights" label="{{'poi.categories' | translate}}">
      <br />
      <flow-category [deletedPOI]="deletedPOI"></flow-category>
    </mat-tab>
    <mat-tab [disabled]="disablePoiReportsInsights" label="{{'poi.reports' | translate}}">
      <br />
      <flow-reports [reportType]="ActiveReportEnum.POI" [permissions]="['ExportPoiReportsInsights']"></flow-reports>
    </mat-tab>
  </mat-tab-group>

  <ng-template #poiTable>
    <div>
      <mat-paginator
        (page)="handlePageEvent($event)"
        [pageSize]="params.pageSize"
        [pageIndex]="params.pageNumber"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        showFirstLastButtons></mat-paginator>
      <table
        [dataSource]="dataSource"
        class="table-hover"
        mat-table
        matSort
        (matSortChange)="handleSortEvent($event)"
        matSortActive="name"
        matSortDirection="asc"
        [matSortDisableClear]="true">
        <ng-container *ngIf="!isAdmin" matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox
              color="primary"
              (change)="selectMedias($event,null)"
              [checked]="isAllSelected"
              id="select-media-checkbox-all"></mat-checkbox>
          </th>
          <td *matCellDef="let poi" mat-cell>
            <mat-checkbox
              color="primary"
              (change)="selectMedias($event, poi.media)"
              [checked]="poi.isSelected"
              [id]="'select-media-checkbox'+poi.id"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.id' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.id }}</td>
        </ng-container>

        <ng-container matColumnDef="media">
          <th *matHeaderCellDef mat-header-cell>{{ 'poi.table.file' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>
            <flow-media-preview [media]="poi.media" [type]="poi.media?.type"></flow-media-preview>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.name' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.name }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.category' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.category?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="tags">
          <th *matHeaderCellDef mat-header-cell>{{ 'poi.table.tags' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>
            <mat-chip-list *ngIf="poi?.tags?.length" class="chip-list-small">
              <mat-chip *ngFor="let tag of poi.tags">
                {{ tag?.name }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.fileName' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.fileName }}</td>
        </ng-container>

        <ng-container matColumnDef="customer">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.customer' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.customer?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.size' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.size | filesize }}</td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'poi.table.created' | translate }}</th>
          <td *matCellDef="let poi" mat-cell>{{ poi.createdDate | date: 'yyyy-MM-dd HH:mm' : 'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions-col" mat-header-cell></th>
          <td *matCellDef="let poi" [style.width]="'1px'" mat-cell>
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *flowHasPermission="['UpdatePoiInsights']; disabled true;" (click)="showCreatePoiModal($event,poi.id)" mat-menu-item>
                {{ 'global.edit' | translate }}
              </button>
              <button *flowHasPermission="['UpdatePoiInsights']; disabled true;" (click)="onDeleteClick($event, poi)" mat-menu-item>
                {{ 'global.delete' | translate }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
          (dblclick)="showCreatePoiModal($event, poi.id)"
          *matRowDef="let poi; columns: displayedColumns;"
          mat-row
          class="hover:bg-gray-200 cursor-pointer"></tr>
      </table>
    </div>
  </ng-template>
</div>
