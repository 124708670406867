import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[flowDecimalNumberLimit]'
})
export class DecimalNumberLimitDirective {
  @Input() limit = 6;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,6}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, home keys, ctrl+c, ctrl+v and ctrl+a
    if (this.specialKeys.indexOf(event.key) !== -1 ||
        (event.ctrlKey && (event.key === 'v' || event.key === 'a' || event.key === 'c')) ||
        (event.metaKey && (event.key === 'v' || event.key === 'a' || event.key === 'c'))) {
      return;
    }
    this.preventText(event, event.key);
  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedInput = clipboardData.getData('text');
    // Check if the pasted input is a valid number with the specified decimal limit
    this.preventText(event, pastedInput, true);
  }
  private preventText(event, key, isPaste?) {
    let current: string = isPaste ? '' : this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), key == 'Decimal' ? '.' : key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
