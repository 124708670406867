<div id="helper-card" class="helper-card-container">
    <div class="flex items-center justify-between pb-2">
        <span class="font-bold">{{ title | translate }}</span>
        <span>
            <button (click)="hideHelperCard()">
                <app-icon
                    fill="#FA6200"
                    iconName="close"
                    size="md">
                </app-icon>
            </button>
        </span>
    </div>
    <div class="pb-2">{{ description | translate }}</div>
    <!-- hide the Wiki link for now as it is currently not in use -->
    <!-- <div class="pb-2 wiki-link flex items-center" (click)="navigateToWiki()">
        <span class="pr-2.5">{{ "global.helperCards.wikiPage" | translate }}</span>
        <mat-icon class="bc-start-arrow">arrow_right_alt</mat-icon>
    </div> -->
    <mat-checkbox (change)="toggleConsent($event)">
        <span class="text-wrap">{{ "global.helperCards.hideMessage" | translate }}</span>
    </mat-checkbox>
</div>