import { ActiveReportEnum } from "@api/models/active-report-enum";

const reportsFormConfig = (filterInput: string, activeReport: ActiveReportEnum) => {
  const filter = {
    timeframe: {
      label: 'reports.form.selectTimeframe',
      data: [],
      type: 'dropdown',
      formControlName: 'timeframe',
      isRequired: true,
      isChecked: null,
      hasParams: true
    },
    start: {
      label: 'reports.form.datePickerFrom',
      data: [],
      type: 'datepicker',
      formControlName: 'start',
      isRequired: false,
      isChecked: null,
      placeholder: 'reports.form.datePickerFrom',
      picker: 'pickerStart'
    },
    end: {
      label: 'reports.form.datePickerTo',
      data: [],
      type: 'datepicker',
      formControlName: 'end',
      isRequired: false,
      isChecked: null,
      placeholder: 'reports.form.datePickerTo',
      picker: 'pickerEnd'
    },
    customerIds: {
      label: 'reports.form.selectCustomers',
      data: [],
      type: 'dropdown',
      formControlName: 'customerIds',
      isMultipleDropdown: true,
      isRequired: true,
      isChecked: null,
      hasSelectAll: true
    },
    channelIds: {
      label: 'reports.form.selectChannels',
      data: [],
      type: 'dropdown',
      formControlName: 'channelIds',
      isMultipleDropdown: true,
      isRequired: true,
      isChecked: null,
      hasSelectAll: true
    },
    screenIds: {
      label: activeReport === ActiveReportEnum.PeopleCounter ? 'reports.form.selectScreensOrSensors' : 'reports.form.selectScreens',
      data: [],
      type: 'dropdown',
      formControlName: 'screenIds',
      isMultipleDropdown: true,
      isRequired: true,
      isChecked: null,
      hasSelectAll: true
    },
    placement: {
      label: 'reports.form.placement',
      data: [],
      type: 'dropdown',
      formControlName: 'placement',
      isMultipleDropdown: true,
      hasSelectAll: true,
      isRequired: true,
      isChecked: null,
      isFilled: true,
      isTranslatable: true
    },
    tracking: {
      label: 'reports.form.tracking',
      data: [],
      type: 'dropdown',
      formControlName: 'tracking',
      isRequired: false,
      isChecked: null,
      isMultipleDropdown: true,
      isFilled: true,
      isTranslatable: true
    },
    bookingTypes: {
      label: 'reports.form.bookingType',
      data: [],
      type: 'dropdown',
      formControlName: 'bookingTypes',
      isRequired: true,
      isChecked: null,
      isMultipleDropdown: true,
      hasSelectAll: true,
      isFilled: true,
      isTranslatable: true
    },
    clientIds: {
      label: 'reports.form.selectClients',
      data: [],
      type: 'dropdown',
      formControlName: 'clientIds',
      isRequired: false,
      isChecked: null,
      isMultipleDropdown: true,
      hasSelectAll: true
    },
    bookingNumbers: {
      label: 'reports.form.bookingNumberName',
      data: [],
      type: 'dropdown',
      formControlName: 'bookingNumbers',
      isRequired: true,
      isChecked: null,
      isMultipleDropdown: true,
      hasSelectAll: true,
      search: true
    },
    mediaFiles: {
      label: 'reports.form.mediaFile',
      data: [],
      type: 'dropdown',
      formControlName: 'mediaFiles',
      isRequired: true,
      isChecked: null,
      isMultipleDropdown: true,
      hasSelectAll: true,
      search: true
    },
    poiCategoryName: {
      label: 'reports.form.poiCategoryName',
      data: [],
      type: 'dropdown',
      formControlName: 'poiCategoryName',
      isRequired: true,
      isMultipleDropdown: true,
      isChecked: null,
      hasSelectAll: true
    },
    poiName: {
      label: 'reports.form.poiName',
      data: [],
      type: 'dropdown',
      formControlName: 'poiName',
      isRequired: true,
      isMultipleDropdown: true,
      isChecked: null,
      hasSelectAll: true,
      isTranslatable: true
    },
    includeDemographicalBreakdown: {
      label: 'reports.form.demographicalBreakdown',
      data: [],
      type: 'checkbox',
      formControlName: 'includeDemographicalBreakdown',
      isRequired: false,
      isChecked: false
    },
    includeHourlyBreakdown: {
      label: 'reports.form.hourlyBreakdown',
      data: [],
      type: 'checkbox',
      formControlName: 'includeHourlyBreakdown',
      isRequired: false,
      isChecked: false
    },
    includeVehicleData: {
      label: 'reports.form.includeVehicleData',
      data: [],
      type: 'checkbox',
      formControlName: 'includeVehicleData',
      isRequired: false,
      isChecked: false
    },
    includePrimaryOrSecoundaryExposureBreakdown: {
      label: 'reports.form.exposureBreakdown',
      data: [],
      type: 'checkbox',
      formControlName: 'includePrimaryOrSecoundaryExposureBreakdown',
      isRequired: false,
      isChecked: true
    },
    includeShareOfVoice: {
      label: 'reports.form.shareOfVoice',
      data: [],
      type: 'checkbox',
      formControlName: 'includeShareOfVoice',
      isRequired: false,
      isChecked: true
    },
    includeExtendedStatistics: {
      label: 'reports.form.extendedStatistics',
      data: [],
      type: 'checkbox',
      formControlName: 'includeExtendedStatistics',
      isRequired: false,
      isChecked: true
    },
    includeReach: {
      label: 'reports.form.reachPerPerson',
      data: [],
      type: 'checkbox',
      formControlName: 'includeReach',
      isRequired: false,
      isChecked: false
    },
    includePOIAdministrativeDetails: {
      label: 'reports.form.includePOIAdministrativeDetails',
      data: [],
      type: 'checkbox',
      formControlName: 'includePOIAdministrativeDetails',
      isRequired: false,
      isChecked: false
    },
    includeSMSNotifications: {
      label: 'reports.form.includeSMSNotifications',
      data: [],
      type: 'checkbox',
      formControlName: 'includeSMSNotifications',
      isRequired: false,
      isChecked: false
    },
    includeAverageTimeInChannel: {
      label: 'reports.form.includeAvgTimeInChannel',
      data: [],
      type: 'checkbox',
      formControlName: 'includeAverageTimeInChannel',
      isRequired: false,
      isChecked: false
    }
  }

  return filter[filterInput];
}

export default reportsFormConfig;
