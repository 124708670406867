export interface ScreenServicesStateData {
    advertiserServiceIsAlive: boolean;
    aiServiceIsAlive?: boolean;
    redisIsAlive: boolean;
    aiFps: AiFPS;
    advertiserServiceLastAliveDate?: Date;
    aiServiceLastAliveDate?: Date;
    redisLastAliveDate?: Date;
}

export interface AiFPS {
    fpsThreshold: string;
    fps: string;
}

export enum ScreenAsleepStatusEnum {
    Awake = 1,
    Asleep = 2
}