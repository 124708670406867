import { AfterViewInit, Directive, ElementRef, EventEmitter, forwardRef, Injector, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, Validators  } from '@angular/forms';

@Directive({
  selector: '[flowBaseInput]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BaseInputDirective),
    multi: true
  }]
})
export class BaseInputDirective implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  labelInput:HTMLElement;
  @Output() valueChange = new EventEmitter<string>();

  private onChange: any = () => {};
  private container: HTMLElement | null = null;
  private control: NgControl | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2, private injector: Injector) {}

  ngOnInit() {
    this.createContainer();
  }
  ngAfterViewInit(): void {
      this.control = this.injector.get<NgControl>(NgControl, null);
      if (this.hasRequiredValidator()) {
        if (this.hasRequiredValidator()) {
          const star = this.renderer.createText(' *');
          this.renderer.appendChild(this.labelInput, star);
        }
      }
  }
  private createContainer() {
    const parent = this.el.nativeElement.parentNode;
    const inputElement = this.el.nativeElement;

    // Create and style the wrapper div
    const wrapper = this.renderer.createElement('div');
    this.renderer.addClass(wrapper, 'w-full');
    this.renderer.addClass(wrapper, 'h-full');
    this.renderer.addClass(wrapper, 'flex-col');
    this.renderer.addClass(wrapper, 'justify-start');
    this.renderer.addClass(wrapper, 'items-start');
    this.renderer.addClass(wrapper, 'gap-2');
    this.renderer.addClass(wrapper, 'flex');

    // Create and style the label
    this.labelInput = null;
    if (this.label) {
      this.labelInput = this.renderer.createElement('div');
      this.renderer.addClass(this.labelInput, 'text-neutral-800');
      this.renderer.addClass(this.labelInput, 'text-sm');
      this.renderer.addClass(this.labelInput, 'font-normal');
      this.renderer.addClass(this.labelInput, 'font-["Inter"]');
      this.renderer.addClass(this.labelInput, 'leading-[0.875rem]');
      const labelText = this.renderer.createText(this.label);
      this.renderer.appendChild(this.labelInput, labelText);
    }

    // Create and style the container div
    const container = this.renderer.createElement('div');
    this.renderer.addClass(container, 'self-stretch');
    this.renderer.addClass(container, 'h-[3rem]');
    this.renderer.addClass(container, 'rounded');
    this.renderer.addClass(container, 'border');
    this.renderer.addClass(container, 'border-zinc-300');
    this.renderer.addClass(container, 'justify-between');
    this.renderer.addClass(container, 'items-center');
    this.renderer.addClass(container, 'inline-flex');

    // Save reference to the container
    this.container = container;

    // Style the input element
    if (this.placeholder)
      this.renderer.setAttribute(inputElement, 'placeholder', this.placeholder);
    this.renderer.addClass(inputElement, 'w-full');
    this.renderer.addClass(inputElement, 'text-sm');
    this.renderer.addClass(inputElement, 'font-normal');
    this.renderer.addClass(inputElement, 'font-["Inter"]');
    this.renderer.addClass(inputElement, 'leading-[1rem]');
    this.renderer.addClass(inputElement, 'outline-none');
    this.renderer.addClass(inputElement, 'bg-transparent');
    this.renderer.addClass(inputElement, 'border-none');

    // Add a class for black text color when writing
    this.renderer.listen(inputElement, 'input', () => {
      if (inputElement.value) {
        this.renderer.removeClass(inputElement, 'text-zinc-400');
        this.renderer.addClass(inputElement, 'text-black');
      } else {
        this.renderer.removeClass(inputElement, 'text-black');
        this.renderer.addClass(inputElement, 'text-zinc-400');
      }
      const newValue = inputElement.value;
      this.onChange(newValue);
      this.emitValue(newValue);
    });

    // Append the input to the container
    this.renderer.appendChild(container, inputElement);

    // Append label and container to the wrapper
    if (this.label)
      this.renderer.appendChild(wrapper, this.labelInput);
    this.renderer.appendChild(wrapper, container);

    // Insert the wrapper into the parent element
    this.renderer.insertBefore(parent, wrapper, inputElement.nextSibling);
  }
  writeValue(value: any): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  private emitValue(value: string) {
    if (this.control.invalid) {
      this.renderer.addClass(this.container, 'border-[#FC4B47]');
    } else {
      this.renderer.removeClass(this.container, 'border-[#FC4B47]');
    }
    this.valueChange.emit(value);
  }
  private hasRequiredValidator(): boolean {
    if (this.control && this.control.control) {
      const validator = this.control.control.validator;
      if (validator) {
        const validationResult = validator({} as any);
        return validationResult && validationResult.required ? true : false;
      }
    }
    return false;
  }
}
