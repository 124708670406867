<flow-nav-bar [currentUser]="currentUser"></flow-nav-bar>

<div class="flex flex-col bg-white">
  <!-- Backdrop -->
  <div
    *ngIf="!appService.navClosed && appService.modeOverQuery.matches"
    class="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"
    (click)="appService.navClosed = true"></div>

  <div class="overflow-hidden">
    <!-- Drawer -->
    <flow-sidenav class="fixed top-[5.5rem] left-8" *ngIf="!appService.navHidden" [navMini]="appService.navClosed"></flow-sidenav>

    <!-- Main Content -->
    <div class="flex flex-col flex-1 ml-32 xl:ml-72" [ngClass]="{'pr-[2rem] mt-[5.5rem] mb-[2.5rem]':isLoggedIn}">
      <div #top [ngClass]="{ 'has-toolbar-form-actions': appService.hasToolbarFormActions, 'h-[100%] flex-1': !appService.navHidden }">
        <router-outlet></router-outlet>

        <div class="fixed bottom-10 right-10 z-50">
          <flow-button *ngIf="showNavigateToTopBtn" variant="primary" (click)="scrollToTop()">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
            </svg>
          </flow-button>
        </div>
      </div>
    </div>
  </div>
</div>
