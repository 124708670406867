import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StatisticsService } from 'src/app/admin/statistics/statistics.service';

@Component({
  selector: 'flow-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnInit {
  @Input() input:any;
  @Output() filterChangeEvent = new EventEmitter<string>()

  constructor(public statisticsService: StatisticsService) { }

  ngOnInit(): void {
  }
  clearDatePicker(formControlName: string, isGroup: boolean) {
    if (isGroup){
      const group = this.statisticsService.form.get(formControlName) as FormGroup;
      Object.keys(group.controls).filter(controlKey => group.get(controlKey).setValue(null));
    } else {
      this.statisticsService.form.get(formControlName).setValue(null);
    }
    this.filterChangeEvent.emit(formControlName);
  }
  preventDefault(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
  onFilterChange(formControlName: string) {
    this.filterChangeEvent.emit(formControlName);
  }
}
