import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'flow-public-layot',
  templateUrl: './public-layot.component.html',
  styleUrls: ['./public-layot.component.scss']
})
export class PublicLayotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
