import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

@Injectable({ providedIn: 'any' })
export class GeneralInfoForm extends FormGroup {
  constructor() {
    super({
      name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      bookings: new FormControl<any[]>([], { validators: Validators.required, nonNullable: true }),
    });
  }

  resetForm() {
    this.reset();
  }
}

@Injectable({ providedIn: 'any' })
export class FiltersForm extends FormGroup {
  constructor() {
    super({
      customer: new FormControl(0, { nonNullable: true }),
      channels: new FormControl<string[]>([], { nonNullable: true }),
      placements: new FormControl<string[]>([], { nonNullable: true }),
    });
  }

  resetForm() {
    this.reset();
  }
}

@Injectable({ providedIn: 'any' })
export class MediaFormGroup extends FormGroup {
  constructor() {
    super({
      advertisementDetails: new FormArray([], Validators.required),
      defaultMediaDetails: new FormControl<null | string>(null, { nonNullable: false }),
      defaultMediaPresentationTime: new FormControl<null | number>(null, { nonNullable: false }),
      presentationTime: new FormControl<null | number>(null, { nonNullable: false }),
    });
  }

  resetForm() {
    this.reset();

    const adFormArray = this.get('advertisementDetails') as FormArray;
    adFormArray.clear();
  }
}

@Injectable({ providedIn: 'any' })
export class MarketingAutomationFormGroup extends FormGroup {
  constructor() {
    super({
      isEnabled: new FormControl<boolean | null>(null),
      marketingAutomation: new FormGroup({
        childPlayListId: new FormControl<number | null>(null),
        engagementName: new FormControl<string | null>(null),
        playlistMediaFiles: new FormArray([], Validators.required),
        hasAdvancedSettings: new FormControl<boolean | null>(null),
        advancedSettings: new FormGroup({
          numberOfPeople: new FormControl<number | null>(null),
          numberOfMinutes: new FormControl<number | null>(null),
          configurationRuleOneActivated: new FormControl<boolean | null>(null),
          configurationRuleTwoActivated: new FormControl<boolean | null>(null),
        }),
        sensitivitySettings: new FormControl<string | null>(null),
      }),
    });
  }

  resetForm() {
    this.reset();
  }
}
