<div mat-dialog-content class="py-2">
    <form [formGroup]="form">
        <mat-card class="form-container mb-5 pt-3">
            <h3 class="header">{{'triggerModal.temperature' | translate}}</h3>
            <flow-range-slider *ngIf="isChanged" [newSelectionValues]="newSelectionValues" (temperatureValuesChange)="onTemperatureValuesChange($event)"></flow-range-slider>
        </mat-card>
        <mat-card class="form-container mb-5 pt-3" style="margin-bottom: 3px !important;">
            <h3 class="header">{{'triggerModal.weatherConditions' | translate}}</h3>
            <div class="weather-condition">
                <div class="icon-container" *ngFor="let condition of skyConditionIcons" [class.selected]="selectedIcons.value.indexOf(condition) !== -1">
                    <app-icon
                    [matTooltip]="('triggerModal.conditions.' + (condition | lowercase)) | translate"
                    [ngStyle]="{cursor:'pointer', fill: 'none'}"
                    size="md"
                    color="#202020"
                    (click)="toggleIcon(condition)"
                    [iconName]="condition | lowercase"
                    ></app-icon>
                </div>
            </div>
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="justify-content: space-between;">
    <div class="clear-button" (click)="clearTriggers()">clear all</div>
    <span>
        <button mat-button
                mat-dialog-close
                type="button"
                (click)="onClose()">{{'global.cancel' | translate}}</button>
        <button mat-raised-button
                type="button"
                [ngStyle]="{background:'#1F5792', color: '#FFFFFF'}"
                (click)="onConfirm()">{{'global.confirm' | translate}}</button>
    </span>
</div>