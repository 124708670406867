<mat-card class="storage-card mb-3 mt-10 max-w-4xl mat-elevation-z1">
  <div>
    <h2 *ngIf="!this.isAdmin">{{ 'media.storageLimitSize' | translate:{ number:storageLimitSize } }} </h2>
  </div>
  <div>
    <h4>{{ 'media.sizeOfMediaLibrary' | translate }} {{ sizeOfMediaAndFolders | filesize }}</h4>
    <mat-progress-bar [bufferValue]="100"
                      mode="determinate"
                      color="warn"
                      [value]="calculateProgress(sizeOfMediaAndFolders, storageLimitSize)"></mat-progress-bar>
  </div>

  <div class="mt-2">
    <h4>{{ 'media.currentFolderSize' | translate }} {{ currentFolderSize | filesize }}</h4>
    <mat-progress-bar [bufferValue]="100"
                      mode="determinate"
                      color="primary"
                      [value]="calculateProgress(currentFolderSize, storageLimitSize)"></mat-progress-bar>
  </div>
</mat-card>