import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'flow-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnChanges {
  @Output() temperatureValuesChange = new EventEmitter<any>();
  relevantValues = { min: -50, max: 50 };
  @Input() newSelectionValues: any = { min: 10, max: 20 };
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.newSelectionValues && Object.keys(changes.newSelectionValues.currentValue).length > 0) {
      this.newSelectionValues = changes.newSelectionValues.currentValue || this.newSelectionValues;
    }
  }
  onTemperatureValuesChange(values: any) {
    this.temperatureValuesChange.emit(values);
  }
}
