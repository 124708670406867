import { Component, OnInit, } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from "@angular/material/paginator";
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, takeUntil, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { GlobalService } from '@core/services/global.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { indicateLoading } from "@core/indicate-loading";
import { PermissionsService } from '@core/services/permissions.service';
import { BackendErrorsTranslate } from '@core/be-errors-translations';
import { Screen, ScreenApi } from '@api/index';
import { TrackingEnum } from '@api/models/tracking.enum';
import { PaginatorParams } from "@api/models/paginator";
import { BackendErrorsEnum } from '@api/models/be-errors-enum';
import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { ContentData, CustomSnackbarComponent } from '@shared/custom-snackbar/custom-snackbar.component';
import { BulkUpdateAiAddComponent } from "./bulk-update-ai-add/bulk-update-ai-add.component";

@Component({
  selector: 'flow-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent extends unsubscribeMixin() implements OnInit {
  displayedColumns: string[] = ['checkbox', 'id', 'humanUniqueIdentifier', 'customerName', 'type', 'channelName', 'channelDescription', 'trackingType', 'coordinates', 'layout', 'defaultMediaName'];
  dataSource: MatTableDataSource<ScreenListItem>;
  isAdmin: boolean;
  hideBox = false;
  TrackingEnum = TrackingEnum;
  screenData = []
  customerId: number;
  isAllSelected = false;
  screenIds: number[] = [];
  selectedScreens: any[] = [];
  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  params: ScreenParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'humanUniqueIdentifier',
    sortDirection: 'asc'
  }
  selectedIndex: number = 0;
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];
  showScreensFromGroup: boolean = false;

  loading$ = {
    init: new BehaviorSubject(true),
  }
  protected readonly indicateLoading = indicateLoading;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    public platform: Platform,
    private screenApi: ScreenApi,
    private globalService: GlobalService,
    public systemMessageService: SystemMessageService,
    private permissionsService: PermissionsService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    if (!this.isAdmin) {
      this.displayedColumns.shift();
      this.displayedColumns.splice(2, 1);
      this.displayedColumns = this.displayedColumns.concat(['actions']);
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.customerId = userCustomerId;
      let params = this.params;
      if (this.customerId) {
        params.customerIds = [this.customerId]
      }
      this.getScreens(params);
    } else {
      this.displayedColumns = this.displayedColumns.concat(['aiVersion', 'advertiserVersion', 'powerSchedule', 'enable', 'actions']);
      this.getScreens(this.params);
    }
  }

  getScreens(params?: ScreenParams): void {
    this.screenData = [];
    this.screenApi.getScreens(params)
      .pipe(takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe((data: any) => {
        this.params = { ...this.params, pageNumber: data.currentPage };
        this.screenData = data.items
        this.totalCount = data.totalCount
        this.dataSource = new MatTableDataSource(this.screenData);
      })
  }

  selectScreens(event: MatCheckboxChange, screen?: ScreenListItem,) {
    this.isAllSelected = event.checked;
    if (!screen && event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = true;
        this.screenIds.push(m.id)
        this.selectedScreens.push(m)
      });
    } else if (!screen && !event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = false;
      });
      this.screenIds = [];
      this.selectedScreens = [];
    } else if (screen && event.checked) {
      screen.isSelected = event.checked;
      this.screenIds.push(screen.id);
      this.selectedScreens.push(screen)
    } else if (screen && !event.checked) {
      screen.isSelected = event.checked;
      let index = this.screenIds.indexOf(screen.id);
      if (index !== -1) {
        this.screenIds.splice(index, 1);
        this.selectedScreens.splice(index, 1);
      }
    }
  }

  onDeleteClick(screen: ScreenListItem): void {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveScreen(screen, dialogRef),
        logic: true
      }
    })
  }

  private onRemoveScreen(screen: ScreenListItem, dialogRef: MatDialogRef<ConfirmationModalComponent, any>) {
    this.screenApi.removeScreen(screen.id)
      .pipe(
        catchError((error) => {
          dialogRef.close();
          return throwError(() => error);
        }),
        takeUntil(this.ngUnsubscribe
      ))
      .subscribe({
        next: () => {
          this.dialog.closeAll();
          this.getScreens(this.params);
        },
        error: (error: ScreenBackendError) => {
          if ( error?.errorType && (error?.errorType === BackendErrorsEnum.ScreenInUse)) {
            this.openErrorModal(error)
          }
        }
      });
  }

  openErrorModal(error: ScreenBackendError) {
    const message = this.setErrorMessage(error);

    this.dialog.open(CustomSnackbarComponent, {
      width: '600px',
      data: {
        msg: message,
      },
    });
  }

  setErrorMessage(error: ScreenBackendError) {
    const screenName = error.screenName;
    const listContent = error.screenUsedAtEngagement.map((screen: string): string => {
      const html = '<li><strong>' + screenName + ':</strong> ' + screen + '</li>';
      return html;
    }).join('');

    return {
      title: 'screens.cannotDelete',
      content: this.translateService.instant(BackendErrorsTranslate[error?.errorType]),
      preText: this.translateService.instant('screens.usedScreenList'),
      listContent,
    } as ContentData;
  }

  selectedTabChange($event: any) {
    this.selectedIndex = $event.index;
    this.isAllSelected = false
    this.screenIds = [];
    this.selectedScreens = [];
    this.dataSource.data.filter(s => {
      s.isSelected = false
    })
    this.showScreensFromGroup = false

    if ($event.index === 0) {
      this.params.pageNumber = 0;
      this.params.pageSize = 10;
      let params = this.params;
      if (this.customerId)
        params.customerIds = [this.customerId];
      this.getScreens(this.params);
    }
  }

  redirectToEditPage(screen: any): void {
    if (this.permissionsService.hasPermissions(['UpdateScreensAdmin'])) {
      if (screen.screens) {
        return;
      }
      this.router.navigate(['/screens', screen.id]);
    }
  }
  formatDate(datetime: DateTime) {
    return this.globalService.UTCToLocalDateTime(datetime);
  }

  openBulkUpdateModal(event: MouseEvent, type) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(BulkUpdateAiAddComponent, {

      maxHeight: 700, width: '600px', data: {
        screens: this.selectedScreens, screenId: this.screenIds, type: type
      }
    })

    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result?.save) {
          this.getScreens(this.params)
          this.screenIds = []
          this.selectedScreens = []
          this.isAllSelected = false
        }
      })
  }
  redirectToMaps(latitude: number, longitude: number) {
    if (latitude && longitude) {
      let url = `https://maps.google.com/?q=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }


  handlePageEvent(e: PageEvent) {
    this.params.pageNumber = e.pageIndex;
    this.params.pageSize = e.pageSize;
    let params = this.params;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(this.params);
  }

  handleSortEvent(sort: Sort) {
    this.params.pageNumber = 0;
    this.params.sortBy = sort?.active || this.params.sortBy;
    this.params.sortDirection = sort?.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc';
    let params = this.params;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(this.params);
  }

  onToggle($event, screen, isActive: boolean) {
    $event.stopPropagation();

    this.screenApi.activateScreen(screen.id, { activate: isActive })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  showScreensInGroup($event: number) {
    this.params.pageNumber = 0;
    this.params.pageSize = 10;
    let params = this.params;
    if ($event)
      params.screenGroupId = $event;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(params)
    this.showScreensFromGroup = !!$event;
  }
}

interface ScreenListItem extends Screen {
  customerName?: string;
  isSelected?: boolean;
  channelName?: string;
  defaultMediaName?: string;
  layout?: string;
  isGroup?: boolean
  canBeDeleted?: boolean
  screens?: ScreenListItem[] | MatTableDataSource<ScreenListItem>
}

interface ScreenParams extends PaginatorParams {
  customerIds?: number[]
  screenGroupId?: number
}

interface ScreenBackendError {
  canBeDeleted: boolean;
  errorType: string | number;
  screenName: any;
  screenUsedAtEngagement: any[];
}