<p class="title">{{ 'selectLanguageModal.title' | translate }}
  <a class="cursor-pointer"
     [mat-dialog-close]="true">
     <app-icon [ngStyle]="{cursor:'pointer'}"
               stroke="#202020"
               size="md"
               iconName="x">
     </app-icon>
  </a></p>
<div class="divider"></div>
<div mat-dialog-content
     class="py-2">

  <form [formGroup]="form">
    <div  class="languages"
          *ngFor="let language of shortLanguages; let i = index">
    <div class="language-option">
      <input type="radio" formControlName="language" id="radio" [value]="language.name" name="language" class="radio">
      <app-icon [ngStyle]="{cursor:'pointer'}"
                size="md"
                [iconName]="language.name"></app-icon>
      <p class="language-label">{{LanguageLongTranslate[i]}}</p>

    </div>
    </div>
  </form>

  <div class="buttons">
    <button class="btn-base-s" [mat-dialog-close]="true">{{'global.cancel' | translate}}</button>
    <button class="btn-primary-s" (click)="onChange()">{{'global.save' | translate}}</button>
  </div>

</div>
