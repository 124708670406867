
export { ApiConfiguration } from './api-configuration';
export { ApiModule } from './api.module';
export { BaseService } from './base-service';
export { AssignPermissionsRequest, BatchPermissionAssignmentRequest } from './models/permissions/assign-permissions-request';
export { Permissions, Action, SinglePermission } from './models/permissions/permission-list';
export { CustomerPermissionsConfig, PermissionsConfig, Node } from './models/permissions/customer-permissions-config';
export { Group, CustomerGroups } from './models/permissions/group-roles';
export { AdSlot } from './models/ad-slot';
export { AdToDisplayRequest } from './models/ad-to-display-request';
export { AdToDisplayRequest2 } from './models/ad-to-display-request-2';
export { AddScreenActivityRequest } from './models/add-screen-activity-request';
export { AddScreenActivityRequest2 } from './models/add-screen-activity-request-2';
export { Advertisement } from './models/advertisement';
export { AdvertisementDetail } from './models/advertisement-detail';
export { AdvertisementRequestTag } from './models/advertisement-request-tag';
export { AdvertisementResponse } from './models/advertisement-response';
export { AdvertisementResponse2 } from './models/advertisement-response-2';
export { AdvertisementSlot } from './models/advertisement-slot';
export { AlertRequest } from './models/alert-request';
export { AlertTypeEnum } from './models/alert-type-enum';
export { BookPlaylist } from './models/book-playlist';
export { BookSegment } from './models/book-segment';
export { BookSlot } from './models/book-slot';
export { BookingSummary } from './models/booking-summary';
export { Channel } from './models/channels';
export { Characteristic } from './models/characteristic';
export { CharacteristicInput } from './models/characteristic-input';
export { CharacteristicTypeEnum } from './models/characteristic-type-enum';
export { ChildPlaylist } from './models/child-playlist';
export { CreateFolderRequest } from './models/create-folder-request';
export { CurrentUserInfoResponse } from './models/current-user-info-response';
export { Customer } from './models/customer';
export { CustomerPerson } from './models/customer-person';
export { CustomerPersonTypeEnum } from './models/customer-person-type-enum';
export { CustomerTokenRequest } from './models/customer-token-request';
export { CustomerTypeEnum } from './models/customer-type-enum';
export { DetailMedia } from './models/detail-media';
export { DownloadModelRequest } from './models/download-model-request';
export { DownloadServiceRequest } from './models/download-service-request';
export { DummyResult } from './models/dummy-result';
export { Engagement } from './models/engagement';
export { Engagement2 } from './models/engagement-2';
export { EngagementMediaFile } from './models/engagement-media-file';
export { EngagementTypeEnum } from './models/engagement-type-enum';
export { Face } from './models/face';
export { FolderFile } from './models/folder-file';
export { GetBookingRequest } from './models/get-booking-request';
export { GetBookingResponse } from './models/get-booking-response';
export { ImageToken } from './models/image-token';
export { InvoiceDeliveryTypeEnum } from './models/invoice-delivery-type-enum';
export { MediaFile } from './models/media-file';
export { MediaTypeEnum } from './models/media-type-enum';
export { PeopleCounterDailyRequest } from './models/people-counter-daily-request';
export { PeopleCounterDailySummaryItem } from './models/people-counter-daily-summary-item';
export { PeopleCounterItem } from './models/people-counter-item';
export { PeopleCounterReportsRequest } from './models/people-counter-reports-request';
export { PeopleCounterRequest } from './models/people-counter-request';
export { PeopleCounterResponse } from './models/people-counter-response';
export { PersonOfInterest } from './models/person-of-interest';
export { Placement } from './models/placement';
export { PlaylistEditRequest } from './models/playlist-edit-request';
export { PlaylistMediaFileSettings } from './models/playlist-media-file-settings';
export { PlaylistResponse } from './models/playlist-response';
export { AditionalProps, DistingtTargetGroups, ReportsResponse, Screen2 } from './models/reports-response';
export { SaveAdvertisementDetailRequest } from './models/save-advertisement-detail-request';
export { SaveAdvertisementRequest } from './models/save-advertisement-request';
export { SaveChannelRequest } from './models/save-channel-request';
export { SaveCharacteristicRequest } from './models/save-characteristic-request';
export { SaveCustomerPerson } from './models/save-customer-person';
export { SaveCustomerPersonRequest } from './models/save-customer-person-request';
export { SaveCustomerRequest } from './models/save-customer-request';
export { SaveMediaRequest } from './models/save-media-request';
export { SavePersonOfInterestRequest } from './models/save-person-of-interest-request';
export { SaveScreenRequest } from './models/save-screen-request';
export { SaveSegmentAdvertisements } from './models/save-segment-advertisements';
export { SaveSegmentRequest } from './models/save-segment-request';
export { SaveSegmentSlot } from './models/save-segment-slot';
export { SaveTargetGroupRequest } from './models/save-target-group-request';
export { Screen } from './models/screen';
export { Segment } from './models/segment';
export { Segment2Customer } from './models/segment-2-customer';
export { Segment2Slot } from './models/segment-2-slot';
export { SegmentAdvertisementTargetGroup } from './models/segment-advertisement-target-group';
export { SegmentEditRequest } from './models/segment-edit-request';
export { SegmentResponse } from './models/segment-response';
export { Slot } from './models/slot';
export { StandardMedia } from './models/standard-media';
export { StatisticsCurrentMonth } from './models/statistics-current-month';
export { StatisticsEngagementMediaFiles } from './models/statistics-engagement-media-files';
export { StatisticsEngagementMediaFilesCount } from './models/statistics-engagement-media-files-count';
export { StatisticsEngagementMediaFilesRequest } from './models/statistics-engagement-media-files-request';
export { StatisticsEngagementMediaFilesResponse } from './models/statistics-engagement-media-files-response';
export { StatisticsEngagementMediaFilesScreenDay } from './models/statistics-engagement-media-files-screen-day';
export { StatisticsFilteredRequest } from './models/statistics-filtered-request';
export { StatisticsPerDay } from './models/statistics-per-day';
export { StatisticsPerDay2 } from './models/statistics-per-day-2';
export { StatisticsResponse } from './models/statistics-response';
export { StatisticsResponse2 } from './models/statistics-response-2';
export { StatisticsSummaryRequest } from './models/statistics-summary-request';
export { StatisticsSummaryResponse } from './models/statistics-summary-response';
export { Stream } from './models/stream';
export { Tag } from './models/tag';
export { TargetGroup } from './models/target-group';
export { TargetGroupEnum } from './models/target-group-enum';
export { TimeframeEnum } from './models/timeframe-enum';
export { TypeEnum } from './models/type-enum';
export { UpdateFolderRequest } from './models/update-folder-request';
export { User } from './models/user';
export { UserLoginRequest } from './models/user-login-request';
export { UserLoginResponse } from './models/user-login-response';
export { UserRegisterRequest } from './models/user-register-request';
export { VehicleType } from './models/vehicle-type-enum';
export { Versions } from './models/versions';
export { VersionsRequest } from './models/versions-request';
export { VersionsResponse } from './models/versions-response';
export { WeekDayEnum } from './models/week-day-enum';
export { RequestBuilder } from './request-builder';
export { AdminApi } from './services/admin-api';
export { EngagementsAdminApi } from './services/engagement-admin-api';
export { PersonOfInteresetApi } from './services/person-of-intereset-api';
export { ScreenApi } from './services/screen-api';
export { StatisticsApi } from './services/statistics-api';
export { UserApi } from './services/user-api';
export { StrictHttpResponse } from './strict-http-response';
export { MarketingAutomationConfig } from './models/marketing-automation-config';
export { MarketingAutomationCreateRequest } from './models/marketing-automation-create-request';
export { MarketingAutomationManage } from './models/marketing-automation-manage';
export { UnusedScreens } from './models/unused-screens';
export { BookEngagement } from './models/booking/book-engagement';
export { EngagementSlot } from './models/booking/engagement-slot';
export { BookingSlot } from './models/booking/booking-slot';
export { BookingsResponse } from './models/booking/bookings-response';
export { Bookings } from './models/booking/bookings';
export { BookingsRequest } from './models/booking/bookings-request';
export { UsersApi } from './services/users-api';
export { CustomerApi } from './services/customer-api';
export { GroupApi } from './services/group-api';
export { UserPermissionApi } from './services/user-permission-api';

