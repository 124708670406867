import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { MediaTypeEnum } from '@api/index';
import { MediaPreviewAction } from '@api/models/media-preview-enum';
import { MediaModalService } from '@app/core/services/media-modal.service';
import { Media } from '@app/core/services/media.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'flow-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPreviewComponent { 
  @Input() name!: string;
  @Input() index!: number;
  @Input() media!: any;  
  @Input() type!: MediaTypeEnum;  
  @Input() fixedSize: boolean = true;
  @Output() action = new EventEmitter<{ action: MediaPreviewAction, media: Media }>();

  apiBaseUrl = environment.apiBaseUrl;
  MediaTypeEnum = MediaTypeEnum;
  
  constructor(
    private mediaModalService: MediaModalService
  ) {}

  openViewMedia(media: Media, $event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openViewMediaModal(media);
  }

  openCreateMedia(media: Media) {
    this.action.emit({ action: MediaPreviewAction.CREATE, media });
  }

  openSelectMedia(media: Media) {
    this.action.emit({ action: MediaPreviewAction.SELECT, media });
  }
}