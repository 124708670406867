import {HttpClient, HttpEvent, HttpEventType, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetMediasAndFoldersResponse } from "@api/models/get-medias-and-folders-response";
import {map, Observable} from "rxjs";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import {SelectedFile} from "@models/media-files";

@Injectable({
  providedIn: 'root',
})
export class MediaApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetMediaPath = '/medias';

  getMediasAndFolders(params): Observable<any> {
    return this.http.get<GetMediasAndFoldersResponse>(`${this.rootUrl}${MediaApi.GetMediaPath}/mediasAndFolders`, { params });
  }
  getMediaById(id) {
    return this.http.get(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}`);
  }
  editMedia(media): Observable<any> {
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}`, media);
  }
  removeMedias(params): Observable<any> {
    return this.http.delete(`${this.rootUrl}${MediaApi.GetMediaPath}`, {params});
  }
  mediaUploadImage(body) {
    const formData = new FormData();
    formData.append('file', body.file);

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/images/upload/?${body.paramsString}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
  mediaUploadVideo(body) {
    const formData = new FormData();
    formData.append('file', body.file);
    
    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/videos/upload/?${body.paramsString}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getPendingMedias(params) {
    return this.http.get<any>(`${this.rootUrl}${MediaApi.GetMediaPath}/pending`, {params});
  }

  approveMediaTransfer(id){
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}/approve`,id);
  }

  rejectMediaTransfer(id){
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}/reject`,id);
  }

  crateMediaTransferLink(body){
    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/linktransfertoken`, body);
  }

  uploadMediaForExternalUsers(body:{token:string, files: SelectedFile[]}){
    const params = new HttpParams().set('token', body.token);
    const formData = new FormData();
    body.files.forEach((file) => formData.append('medias', file.selectedFile));

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/transfer`, formData, {
      params,
      reportProgress: true,
      observe: 'events', }).pipe(
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            return event.total ? Math.round((100 * event.loaded) / event.total) : 0;

          case HttpEventType.Response:
            return 100;

          default:
            return 0;
        }
      }),
    );
  }
}
