import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { ReportsResponse } from 'src/_api';
import { ExposuresReportsRequest } from '../models/exposures-reports-request';
import { PeopleCounterDailyRequest } from '../models/people-counter-daily-request';
import { PeopleCounterReportsRequest } from '../models/people-counter-reports-request';
import { PeopleCounterRequest } from '../models/people-counter-request';
import { PeopleCounterResponse } from '../models/people-counter-response';
import { SmsStatisticsResponse } from '../models/sms-statistics-response';
import { StatisticsEngagementMediaFilesRequest } from '../models/statistics-engagement-media-files-request';
import { StatisticsEngagementMediaFilesResponse } from '../models/statistics-engagement-media-files-response';
import { ReachReportsRequest } from '@api/models/reach-reports-request';
import {
  AgeRangeDistributionResponse,
  GenderDistribution,
  VisitorsResponse
} from "@admin/statistics/people-count/people-count.component";

@Injectable({
  providedIn: 'root',
})
export class StatisticsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly GetStatisticsPath = '/statistics';

  getVisitorsPerHour(params){
    return this.http.get<VisitorsResponse>(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/visitorsPerHour`, {
      params: params
    })
  }

  getAgeDistribution(params){
    return this.http.get<AgeRangeDistributionResponse>(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/ageDistribution`, {
      params: params
    })
  }

  getGenderDistribution(params){
    return this.http.get<GenderDistribution>(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/genderDistribution`, {
      params: params
    })
  }



  getPOIReports(params?) {
    let body = {};
    if (params) {
      body = {
        params: {
          StartDate: new Date(params.startDate).toISOString() || '',
          EndDate: new Date(params.endDate).toISOString() || '',
          PersonOfInterestIds: params.poiName || [],
          AdministrativeInclusion: params.includePOIAdministrativeDetails,
          SMSNotificationDetailsInclusion: params.includeSMSNotifications,
          ScreenIds: params.screenIds,
          CategoryIds: params.poiCategoryName,
          CustomerIds: params.customerIds,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sortBy: params.sortBy,
          sortDirection: params.sortDirection
        },
      }
    }
    return this.http.get<ReportsResponse>(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/personsOfInterestStatistics`, body)
  }

  downloadPOIReports(body?) {
    const bodyToSend = {
      startDate: body.startDate,
      endDate: body.endDate,
      personOfInterestIds: body.poiName,
      customerIds: body.customerIds,
      administrativeInclusion: body.includePOIAdministrativeDetails,
      smsNotificationDetailsInclusion: body.includeSMSNotifications,
      screenIds: body.screenIds,
      categoryIds: body.poiCategoryName,
      pageNumber: body.pageNumber,
      pageSize: body.pageSize,
      sortBy: body.sortBy,
      sortDirection: body.sortDirection
    }

    return this.http.post(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/downloadPersonsOfInterestReports`, bodyToSend, {
      responseType: 'blob',
    })
  }


  getSmsStatistics(params) {
    let body = {};
    if (params) {
      body = {
        params: {
          customerId: params.customerId || '',
          startDate: params.startDate || '',
          endDate: params.endDate || ''
        }
      }
    }
    return this.http.get<SmsStatisticsResponse>(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/sms`, body);

  }

  getWeather(params){
    let body ={}
    if(params){
      body = {
        params: {
          currentDate: new Date(params.date).toLocaleDateString() || '',
          screenId: params.screenId
        }
      }
    }
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/weatherforscreen`, body)
  }

  getMoods(params){
    let body ={}
    if(params){
      body = {
        params: {
          currentDate: new Date(params.date).toLocaleDateString() || '',
          screenIds: params.screenIds
        }
      }
    }
    return this.http.get(`${this.rootUrl}/statistics/moodtrackingperday`, body)
  }

  getVehicles(params) {
    let body = {};
    if (params) {
      body = {
        startTime: params.startTime || '',
        endTime: params.endTime || '',
        customerId: params.customerId,
        channelIds: params.channelIds,
        screenIds: params.screenIds
      };
    }
    return this.http.post<any>(`${this.rootUrl}/statistics/reach/vehicles`, body);
  }

  getVehiclesDaily(params) {
    let body = {};
    if (params) {
      body = {
        date: new Date(params.date).toISOString() || '',
        customerId: params.customerId,
        channelIds: params.channelIds,
        screenIds: params.screenIds
      }
    }
    return this.http.post(`${this.rootUrl}/statistics/reach/vehicles/daily`, body)
  }

  static readonly GetAdvertisementStatisticsStatisticsPath = '/statistics/advertisements/{advertisementId}';

  getAdvertisementStatistics$Response(params: {
    advertisementId: number;
    body?: StatisticsEngagementMediaFilesRequest
  }): Observable<StrictHttpResponse<StatisticsEngagementMediaFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.GetAdvertisementStatisticsStatisticsPath, 'post');
    if (params) {
      rb.path('advertisementId', params.advertisementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsEngagementMediaFilesResponse>;
      })
    );
  }

  getAdvertisementStatistics(params: {
    advertisementId: number;
    body?: StatisticsEngagementMediaFilesRequest;
  }): Observable<StatisticsEngagementMediaFilesResponse> {
    return this.getAdvertisementStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsEngagementMediaFilesResponse>) => r.body as StatisticsEngagementMediaFilesResponse)
    );
  }

  static readonly GetPlaylistMediaSettingsStatisticsStatisticsPath = '/statistics/playlistMediaFiles/{playlistMediaFileId}';

  getPlaylistMediaSettingsStatistics$Response(params: {
    playlistMediaFileId: number;
    body?: StatisticsEngagementMediaFilesRequest;
  }): Observable<StrictHttpResponse<StatisticsEngagementMediaFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.GetPlaylistMediaSettingsStatisticsStatisticsPath, 'post');
    if (params) {
      rb.path('playlistMediaFileId', params.playlistMediaFileId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsEngagementMediaFilesResponse>;
      })
    );
  }

  getPlaylistMediaSettingsStatistics(params: {
    playlistMediaFileId: number;
    body?: StatisticsEngagementMediaFilesRequest;
  }): Observable<StatisticsEngagementMediaFilesResponse> {
    return this.getPlaylistMediaSettingsStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsEngagementMediaFilesResponse>) => r.body as StatisticsEngagementMediaFilesResponse)
    );
  }

  static readonly GetEngagementMediaFilesStatisticsPath = '/statistics/engagementsMedias';

  getEngagementMediaFiles(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}/statistics/engagementsMedias`, { params });
  }

  static readonly PeoplePerHourStatisticsPath = '/statistics/reach/daily';

  peoplePerHour$Response(params?: {
    body?: PeopleCounterDailyRequest;
  }): Observable<StrictHttpResponse<PeopleCounterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeoplePerHourStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PeopleCounterResponse>;
      })
    );
  }

  peoplePerHour(params?: {
    body?: PeopleCounterDailyRequest;
  }): Observable<PeopleCounterResponse> {
    return this.peoplePerHour$Response(params).pipe(
      map((r: StrictHttpResponse<PeopleCounterResponse>) => r.body as PeopleCounterResponse)
    );
  }

  static readonly PeopleCounterReportsStatisticsPath = '/statistics/peoplecounterreports';

  peopleCounterReports$Response(params?: {
    body?: PeopleCounterReportsRequest;
  }): Observable<StrictHttpResponse<ReportsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeopleCounterReportsStatisticsPath, 'post');
    if (params) {
      rb.body({ ...params.body, ...params }, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportsResponse>;
      })
    );
  }

  getPeopleCounterReports(params?: {
    body?: PeopleCounterReportsRequest;
  }): Observable<ReportsResponse> {
    return this.peopleCounterReports$Response(params).pipe(
      map((r: StrictHttpResponse<ReportsResponse>) => r.body as ReportsResponse)
    );
  }

  static readonly DownloadPeopleCounterReportsStatisticsPath = '/statistics/downloadpeoplecounterreports';

  downloadPeopleCounterReports$Response(params?: {
    body?: PeopleCounterReportsRequest;
  }): Observable<StrictHttpResponse<ReportsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.DownloadPeopleCounterReportsStatisticsPath, 'post');
    if (params) {
      rb.body({ ...params.body, ...params }, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  downloadPeopleCounterReports(params?: {
    body?: PeopleCounterReportsRequest;
  }): Observable<any> {
    return this.downloadPeopleCounterReports$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  static readonly DownloadReachReportsStatisticsPath = '/statistics/downloadreachreports';

  downloadReachReports$Response(params?: {
    body?: ReachReportsRequest;
  }): Observable<StrictHttpResponse<ReportsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.DownloadReachReportsStatisticsPath, 'post');
    if (params) {
      rb.body({ ...params.body, ...params }, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  downloadReachReports(params?: {
    body?: ReachReportsRequest;
  }): Observable<any> {
    return this.downloadReachReports$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }
  static readonly DownloadExposuresReportsStatisticsPath = '/statistics/downloadexposuresreports';

  downloadExposuresReports$Response(params?: {
    body?: ExposuresReportsRequest;
  }): Observable<StrictHttpResponse<ReportsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.DownloadExposuresReportsStatisticsPath, 'post');
    if (params) {
      rb.body({ ...params.body, ...params }, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  downloadExposuresReports(params?: {
    body?: ExposuresReportsRequest;
  }): Observable<any> {
    return this.downloadExposuresReports$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  static readonly ExposuresReportsStatisticsPath = '/statistics/exposuresreports';

  exposuresReports$Response(params?: {
    body?: ExposuresReportsRequest;
  }): Observable<StrictHttpResponse<ReportsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.ExposuresReportsStatisticsPath, 'post');
    if (params) {
      rb.body({ ...params.body, ...params }, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportsResponse>;
      })
    );
  }

  getExposuresReports(params?: {
    body?: ExposuresReportsRequest;
  }): Observable<ReportsResponse> {
    return this.exposuresReports$Response(params).pipe(
      map((r: StrictHttpResponse<ReportsResponse>) => r.body as ReportsResponse)
    );
  }

  getStatisticsCategories(params): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/categories`, {
      params
    });
  }

  getStatisticsPersonsOfInterest(params): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/personsOfInterest`, {
      params
    });
  }

  getPeopleCounterStatistics(params): Observable<any> {
    params.currentTime = new Date().toLocaleTimeString();
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/peoplecounter`, {params});
  }

  reachPerDay(params?: PeopleCounterRequest): Observable<any> {
    return this.http.post(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/reach`, params);
  }

  getExposuresStatistics(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/exposures`, {params:params?.body});
  }

  averageTimePerHour(params): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/averagetimeperhour`, {params});
  }

  getCustomers(): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/customers`);
  }

  getClients(customerIds?: any[]): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/clients`, {params: {customerIds}});
  }

  getPlacements(customerIds: any[]): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/placements`, {params: {customerIds}});
  }

  getFilteredScreensByPlacements(placementIds: number[], trackingTypes: number[], includeSensors: boolean = false): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/filter-screens-by-placements`, {
      params: {
        placementIds,
        trackingTypes,
        includeSensors
      }});
  }

  getTags(customerIds: any[]): Observable<any> {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/tags`, {params: {customerIds}});
  }

  getScreensWithOrientation(params) {
    return this.http.get(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/screenGroups/screens`, {params})
  }
  getReachReports(params) {
    return this.http.post(`${this.rootUrl}${StatisticsApi.GetStatisticsPath}/reachreports`, params)
  }
}
