import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TabsItemComponent } from './tabs-item/tabs-item.component';

@Component({
  selector: 'flow-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() disableRipple = true;
  @Input() animationDuration = '0ms';
  @Input() selectedIndex = 0;
  @Input() borderOnTop = false
  @Output() selectedTabChange: EventEmitter<any> = new EventEmitter<any>()
  @ContentChildren(TabsItemComponent, {descendants: true}) tabsList!: QueryList<TabsItemComponent>;

  selectedTab(event: MatTabChangeEvent) {
    this.selectedTabChange.next(event)
  }
}
