import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

import { TypeEnum, Customer, Channel, Placement, Bookings, MediaDetails } from '@api/index';
import { EnumObject } from '@core/services/enum.service';
import { IdName } from '@api/models/idname';
import { ChannelsInfo, CopyBookingPlacementInfo } from '@api/models/booking';
import { BookingRouterState } from '@app/shared/models/enums/bookingRouterState.enum';
import { AdvertisementDetailForm } from '@api/models/advertisement-detail';

export interface BookFormGroup {
  name: FormControl<string>;
  customerId: FormControl<number>;
  channels: FormControl<number[]>;
  placements: FormControl<Placement[]>;
  calendarSpan: FormGroup<BookCalendarSpanFormGroup>;
  presentationTime?: FormGroup<number>;
}

export interface BookCalendarSpanFormGroup {
  start: FormControl<DateTime>;
  end: FormControl<DateTime>;
}

export interface Book {
  name?: string;
  type?: EnumObject<TypeEnum>;
  customer?: Customer;
  channels?: Channel[];
  calendarSpan?: BookCalendarSpan;
}

export interface BookCalendarSpan {
  start?: DateTime;
  end?: DateTime;
}

export interface BookSlot {
  endDate: string;
  screenId: number;
  startDate: string;
  id: number;
  type: TypeEnum;
}

export enum EngagementTypeRouteEnum {
  Segments = 'segment',
  Playlists = 'playlist',
}

export interface StateBookData {
  channel: number;
  customer: number;
  placements: number[];
  startDate: DateTime;
}

export enum BOOK_STEPS {
  GENERAL_INFO = 'general-info',
  MEDIA = 'media',
  MARKETING_AUTOMATION = 'marketing-automation',
}

export enum BOOK_STEP_INDEX {
  GENERAL_INFO = 0,
  MEDIA = 1,
  MARKETING_AUTOMATION = 2,
}

export interface SummaryBooking extends Bookings {
  showAll?: boolean;
  disabled?: boolean;
}

export interface BookingDetailsResponse {
  booking: IdName;
  channels: ChannelsInfo[];
  customer: IdName;
  dateOfFirstEngagementSlot: string;
  engagementType: number;
  placements: CopyBookingPlacementInfo[];
}

export interface BookingInfo {
  name?: string;
  bookState?: BookingRouterState;
  details?: BookingDetailsResponse;
  customerId?: number;
  engagementId?: number;
  engagementType?: number;
  activeStep?: number;
  presentationTime?: number;
  isEdit?: boolean;
  isCopy?: boolean;
  continueCreateMode?: boolean;
  loading?: boolean;
  error?: any;
}

export interface MediaForm {
  bookedScreenNames: string[];
  advertisementDetails: AdvertisementDetailForm[];
  defaultMediaDetails: null | MediaDetails;
  defaultMediaPresentationTime: null | number;
  presentationTime: null | number;
}

export interface BookValidityModalData {
  save: Function;
  discard: Function;
  formInvalid: boolean;
  subject: any;
}

export enum BookValidityModalAction {
  SAVE = 0,
  DISCARD = 1,
  CANCEL = 2,
}