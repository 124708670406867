<mat-dialog-title class="flex flex-row border-b border-gray-200 pb-4">
   <div class="flex flex-row flex-1 justify-between items-center">
      <span class="title">{{'media.createMedia' | translate}}</span>
      <app-icon stroke="#202020"
                [ngStyle]="{'cursor': 'pointer'}"
                (click)="onCancel()"
                size="md"
                iconName="x">
      </app-icon>
   </div>
</mat-dialog-title>
<mat-dialog-content class="py-4 overflow-x-hidden">
   <div class="h-full flex-col justify-start items-start gap-10 inline-flex">
      <form [formGroup]="form">
         <div class="w-full flex-col justify-center items-start gap-4 flex">
            <div class="justify-start items-start gap-2 inline-flex">
               <mat-checkbox formControlName="isClientBoxChecked"
                             color="primary"></mat-checkbox>
               <div class="text-gray-900 text-[0.75rem] font-normal">
                  {{'createMedia.moveClientMediaToFolder' | translate}}
               </div>
            </div>
            <div class="input-dimensions">
               <mat-label>{{'media.form.name' | translate}} *</mat-label>
               <mat-form-field class="w-full">
                  <input matInput
                         type="text"
                         formControlName="name" />
               </mat-form-field>
            </div>
            <div class="input-dimensions">
               <mat-label>{{ 'media.form.client' | translate }}</mat-label>
               <mat-form-field class="w-full">
                  <mat-select formControlName="clientId"
                              placeholder="{{'media.form.clientPlaceholder' | translate}}">
                     <mat-option *ngFor="let client of clients"
                                 [value]="client.id">
                        {{ client.name | translate }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
            <div class="input-dimensions">
               <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
               <mat-form-field class="w-full">
                  <flow-multi-select-autocomplete formControlName="tags"
                                                  [options]="tags"
                                                  [displayFn]="displayTag"
                                                  [newOptionFn]="newTag"></flow-multi-select-autocomplete>
               </mat-form-field>
            </div>
            <div class="flex-row justify-start items-end gap-2 flex cursor-pointer" [ngClass]="{'opacity-50 pointer-events-none': data.isDefault && mediasArray.length > 0}">
               <flow-drag-to-upload (selectedFile)="selectFile($event)"
                                    [reset]="reset"></flow-drag-to-upload>
               <button mat-flat-button
                       color="primary"
                       class="min-w-fit p-2"
                       (click)="isEdit ? saveFile() : addFile()"
                       [disabled]="!selectedFile || !form.valid">
                  <app-icon stroke="#fafafa"
                            size="md"
                            [iconName]="selectedFile && form.valid || isEdit ? 'checked' : 'plus'"></app-icon>
               </button>
            </div>

            <div class="flex flex-col w-full max-h-40 overflow-y-auto gap-4"
                 *ngIf="mediasArray?.length > 0">
               <div *ngFor="let media of mediasArray; let i = index"
                    class="flex flex-col w-[365px] p-1 justify-between items-center rounded border-2"
                    [ngClass]="{
                     'bg-chathams-blue-400': isEdit && selectedFile?.index === media.index,
                     'bg-gray-50': !(isEdit && selectedFile?.index === media.index),
                     'border-red-500 bg-red-50': media?.status === 'error' && uploadingDone,
                     'border-green-500 bg-green-50': media?.status === 'success' && uploadingDone
                   }">
                  <div class="flex flex-row w-full mb-1">
                     <div class="flex flex-1 items-center gap-2">
                        <div class="w-[60px] h-[49px] rounded flex items-center justify-center">
                           <img *ngIf="media?.format === 'image'"
                                class="max-h-full max-w-full object-contain"
                                [src]="media.filePreview"
                                alt="Media preview" />
                           <mat-icon *ngIf="media?.format === 'video'" alt="Video preview">play_circle</mat-icon>
                        </div>
                        <div class="flex-col justify-center items-start gap-1 inline-flex">
                           <div class="text-gray-800 text-sm font-normal leading-[14px]">
                              {{media.name}}
                           </div>
                           <div class="w-[102px] text-gray-800 text-xs font-normal leading-none">
                              {{media.fileSize | filesize}}
                           </div>
                        </div>
                     </div>

                     <div class="flex justify-end items-center gap-2"
                          *ngIf="media?.status !== 'success'">
                        <button mat-flat-button
                                color="primary"
                                (click)="editFile(media)"
                                class="flex justify-start items-start bg-gray-50 rounded min-w-fit p-2">
                           <app-icon [ngStyle]="{cursor:'pointer'}"
                                     iconName="edit-pencil"
                                     size="md"
                                     stroke="#202020">
                           </app-icon>
                        </button>

                        <button mat-flat-button
                                color="primary"
                                (click)="removeFile(media.id)"
                                class="flex justify-start items-start bg-gray-50 rounded min-w-fit p-2">
                           <app-icon [ngStyle]="{cursor:'pointer'}"
                                     iconName="x"
                                     size="md"
                                     stroke="#202020">
                           </app-icon>
                        </button>
                     </div>
                  </div>
                  <!-- *ngIf="!uploadingDone" -->
                  <div class="flex w-full">
                     <mat-progress-bar mode="determinate"
                                       [value]="media.uploadProgress"
                                       class="w-full rounded"></mat-progress-bar>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</mat-dialog-content>
<mat-dialog-actions>
   <div class="flex flex-row w-full h-10 justify-end items-start gap-4 ">
      <button (click)="onCancel()"
              mat-flat-button
              color="secondary">
         {{'global.cancel' | translate}}
      </button>
      <button (click)="onSave()"
              [disabled]="disableUpload"
              [ngClass]="{'disabled': mediasArray.length === 0 }"
              mat-flat-button
              color="primary">
         {{'global.add' | translate}}
      </button>
   </div>
</mat-dialog-actions>