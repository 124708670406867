<div class="start-container">
  <div class="start-header">
    <div class="start-title">
      <span class="start-title-text">{{ getTimeOfDay() }}, {{ firstName }}!</span>
      <app-icon size="lg" stroke="#202020" [iconName]="getTimeIconName()"></app-icon>
    </div>
    <div class="start-sub-text">{{'home.info' | translate}}</div>
  </div>
  <div class="flex gap-6">
    <div class="w-fit flex flex-col p-4 gap-6 rounded border border-gray-200">
      <span class="text-brand-bright-orange text-xs font-semibold capitalize leading-none">{{'screens.title' | translate}}</span>
      <div class="flex gap-14">
        <div class="flex flex-col gap-2">
          <span class="text-xs font-normal leading-none">{{'global.total' | translate}}</span>
          <span *flowHasPermission="['ViewScreensAdmin'] adminNav true" class="text-2xl font-semibold leading-7">
            {{screensWithIssues.screensAndSensorsCount}}
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-xs font-normal leading-none">{{'global.offline' | translate}}</span>
          <span *flowHasPermission="['ViewScreensAdmin'] adminNav true" class="text-2xl font-semibold leading-7">
            {{screensWithIssues.offlineScreensAndSensorsCount}}
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-xs font-normal leading-none">{{'global.online' | translate}}</span>
          <span *flowHasPermission="['ViewScreensAdmin'] adminNav true" class="text-2xl font-semibold leading-7">
            {{screensWithIssues.onlineScreensAndSensorsCount}}
          </span>
        </div>
      </div>
    </div>
    <div *flowHasPermission="['ViewScreensAdmin']" class="w-[400px] flex flex-col p-4 gap-4 rounded border border-gray-200">
      <div class="flex flex-col gap-2">
        <span class="text-xs font-medium leading-tight">{{'home.offlineScreens&SensorsTitle' | translate}}</span>
        <span class="text-xs font-normal leading-[1.2rem]">{{'home.tableOffline.statusInfo' | translate}}</span>
      </div>
      <a class="text-xs font-normal leading-none" href="mailto:support@bizlab.se">support@bizlab.se</a>
    </div>
  </div>
  <ng-container *flowHasPermission="['ViewScreensAdmin']">
    <div *ngIf="dataSourceOffline && dataSourceOffline.data.length > 0">
      <h2>
        {{'home.offlineScreens&SensorsTitle' |
      translate}}
      </h2>
      <mat-table
        [dataSource]="dataSourceOffline"
        class="table-hover"
        [matSort]="sortBroken"
        (matSortChange)="handleSortBrokenEvent($event)"
        matSortDisableClear>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.status" | translate}}</mat-header-cell>
          <mat-cell class="flex" *matCellDef="let screen">
            <flow-pill-box [color]="screen.status.color" [label]="screen.status.label" [tooltip]="screen.status.tooltip"></flow-pill-box>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.name" | translate}}</mat-header-cell>
          <mat-cell class="flex" *matCellDef="let screen">
            <span>{{screen?.screen.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.type" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{DeviceTypeTranslations[screen.type] | translate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.customer" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{ screen?.customer?.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="channel">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.channel" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{screen?.channel?.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="placement">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.placement" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">
            {{screen?.placement?.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tracking">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.tracking" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">
            {{screen?.trackingType > 1 ? (TrackingEnum[screen?.trackingType] | translate) : ''}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastOnline">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.tableOffline.lastOnline" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen" style="padding-left: 15px;">
            {{screen?.lastAliveDate | date:'YYYY-MM-dd HH:mm:ss'}}
          </mat-cell>
        </ng-container>

        <mat-header-row class="bg-gray-200" *matHeaderRowDef="displayedColumnsOffline"></mat-header-row>
        <!-- Condition to be updated -->
        <mat-row
          *matRowDef="let customer; columns: displayedColumnsOffline; let index = index; let odd = odd"
          [ngClass]="{'missingConfig': customer?.engagementId, 'bg-gray-50': odd }"
          (dblclick)="redirectToEditPage(customer.id)"></mat-row>
      </mat-table>
    </div>
  </ng-container>
  <ng-container>
    <div class="flex gap-6">
      <div class="w-fit flex flex-col p-4 gap-6 rounded border border-gray-200">
        <span class="text-brand-bright-orange text-xs font-semibold capitalize leading-none">{{'sidebar.manage' | translate}}</span>
        <div class="flex gap-14">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <span class="text-xs font-normal leading-none">{{'global.total' | translate}}</span>
              <span *flowHasPermission="['ViewBookingCms'] adminNav true" class="text-2xl font-semibold leading-7">
                {{bookingsMetrics.bookingsCountForThisPeriod}}
              </span>
            </div>
            <div *flowHasPermission="['ViewBookingCms']" class="flex gap-6">
              <div class="flex flex-col gap-2">
                <div class="flex gap-1">
                  <app-icon
                    [iconName]="bookingsMetrics.bookingsCountForThisPeriod > bookingsMetrics.bookingsCountForPreviousPeriod ? 'arrow-up' : 'arrow-down'"
                    size="sm"
                    [stroke]="bookingsMetrics.bookingsCountForThisPeriod > bookingsMetrics.bookingsCountForPreviousPeriod ? '#177578' : '#FC4B47'"></app-icon>
                  <span
                    class="text-center text-sm font-medium leading-tight"
                    [ngClass]="bookingsMetrics.bookingsCountForThisPeriod > bookingsMetrics.bookingsCountForPreviousPeriod ? 'text-brand-green' : 'text-brabg-brand-red'">
                    {{ bookingsMetrics.bookingsCountForThisPeriod | percentageChange: bookingsMetrics.bookingsCountForPreviousPeriod }}
                  </span>
                </div>
                <span class="text-gray-800 text-xs font-medium leading-none">
                  {{'home.compareDataBookings' | translate: {text: numbersOfWeekForFiltering } }}
                </span>
              </div>
              <app-icon
                width="90px"
                height="50px"
                [iconName]="bookingsMetrics.bookingsCountForThisPeriod > bookingsMetrics.bookingsCountForPreviousPeriod ? 'chart-up' : 'chart-down'"></app-icon>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <span class="text-xs font-normal leading-none">{{'global.segments' | translate}}</span>
              <span *flowHasPermission="['ViewBookingCms'] adminNav true" class="text-2xl font-semibold leading-7">
                {{bookingsMetrics.segmentCountForThisPeriod}}
              </span>
            </div>
            <div *flowHasPermission="['ViewBookingCms']" class="flex gap-6">
              <div class="flex flex-col gap-2">
                <div class="flex gap-1">
                  <app-icon
                    size="sm"
                    [iconName]="bookingsMetrics.segmentCountForThisPeriod > bookingsMetrics.segmentCountForPreviousPeriod ? 'arrow-up' : 'arrow-down'"
                    [stroke]="bookingsMetrics.segmentCountForThisPeriod > bookingsMetrics.segmentCountForPreviousPeriod ? '#177578' : '#FC4B47'"></app-icon>
                  <span
                    class="text-center text-sm font-medium leading-tight"
                    [ngClass]="bookingsMetrics.segmentCountForThisPeriod > bookingsMetrics.segmentCountForPreviousPeriod ? 'text-brand-green' : 'text-brabg-brand-red'">
                    {{ bookingsMetrics.segmentCountForThisPeriod | percentageChange: bookingsMetrics.segmentCountForPreviousPeriod }}
                  </span>
                </div>
                <span class="text-gray-800 text-xs font-medium leading-none">
                  {{'home.compareDataBookings' | translate: {text: numbersOfWeekForFiltering } }}
                </span>
              </div>
              <app-icon
                width="90px"
                height="50px"
                [iconName]="bookingsMetrics.segmentCountForThisPeriod > bookingsMetrics.segmentCountForPreviousPeriod ? 'chart-up' : 'chart-down'"></app-icon>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <span class="text-xs font-normal leading-none">{{'global.playlists' | translate}}</span>
              <span *flowHasPermission="['ViewBookingCms'] adminNav true" class="text-2xl font-semibold leading-7">
                {{bookingsMetrics.playlistCountForThisPeriod}}
              </span>
            </div>
            <div *flowHasPermission="['ViewBookingCms']" class="flex gap-6">
              <div class="flex flex-col gap-2">
                <div class="flex gap-1">
                  <app-icon
                    size="sm"
                    [iconName]="bookingsMetrics.playlistCountForThisPeriod > bookingsMetrics.playlistCountForPreviousPeriod ? 'arrow-up' : 'arrow-down'"
                    [stroke]="bookingsMetrics.playlistCountForThisPeriod > bookingsMetrics.playlistCountForPreviousPeriod ? '#177578' : '#FC4B47'"></app-icon>
                  <span
                    class="text-center text-sm font-medium leading-tight"
                    [ngClass]="bookingsMetrics.playlistCountForThisPeriod > bookingsMetrics.playlistCountForPreviousPeriod ? 'text-brand-green' : 'text-brabg-brand-red'">
                    {{ bookingsMetrics.playlistCountForThisPeriod | percentageChange: bookingsMetrics.playlistCountForPreviousPeriod }}
                  </span>
                </div>
                <span class="text-gray-800 text-xs font-medium leading-none">
                  {{'home.compareDataBookings' | translate: {text: numbersOfWeekForFiltering } }}
                </span>
              </div>
              <app-icon
                width="90px"
                height="50px"
                [iconName]="bookingsMetrics.playlistCountForThisPeriod > bookingsMetrics.playlistCountForPreviousPeriod ? 'chart-up' : 'chart-down'"></app-icon>
            </div>
          </div>
        </div>
      </div>
      <div *flowHasPermission="['ViewBookingCms']" class="w-[400px] flex flex-col p-4 gap-4 rounded border border-gray-200">
        <div class="flex flex-col gap-2">
          <span class="text-xs font-medium leading-tight">{{'home.screensInfo' | translate}}</span>
          <span class="text-xs font-normal leading-none">{{'home.upcomingBookingsInfo' | translate}}</span>
        </div>
        <div class="flex flex-col gap-2">
          <span
            [routerLink]="['/bookings/calendar']"
            class="text-brand-chathams-blue text-xs font-normal underline leading-none cursor-pointer">
            {{'home.bookingCalendarLink' | translate}}
          </span>
          <span
            [routerLink]="['/bookings/list']"
            class="text-brand-chathams-blue text-xs font-normal underline leading-none cursor-pointer">
            {{'home.bookingsLink' | translate}}
          </span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *flowHasPermission="['ViewBookingCms', 'ViewScreensAdmin']">
    <div *ngIf="dataSource && dataSource.data.length > 0">
      <h2>
        <span *ngIf="numberOfScreens > 0">
          {{('home.screensInfo' | translate) }} {{'home.weeksTotal' | translate: {text: numbersOfWeekForFiltering } }}
        </span>
        <span *ngIf="numberOfScreens === 0">
          {{('home.noScreensInfo' | translate) }} {{'home.weeksTotal' | translate: {text: numbersOfWeekForFiltering } }}
        </span>
      </h2>
      <mat-paginator
        showFirstLastButtons
        (page)="handlePageEvent($event)"
        [disabled]="!isReady"
        [pageSize]="params.pageSize"
        [pageIndex]="params.pageNumber"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"></mat-paginator>
      <mat-table
        [dataSource]="dataSource"
        class="table-hover"
        [matSort]="sortUnused"
        (matSortChange)="handleSortEvent($event)"
        matSortDisableClear>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.id" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{screen?.id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="week">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.week" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{screen?.weekNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.name" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{screen?.humanUniqueIdentifier}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.customer" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{ screen?.customer.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="channel">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.channel" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">{{screen?.channel.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="placement">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.placement" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">
            {{screen?.placement.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="screenLayout">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{"home.table.screenLayout" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">
            <div *ngIf="screen.orientation == 2">{{'screens.table.landscape' | translate}}</div>
            <div *ngIf="screen.orientation == 1">{{'screens.table.portrait' | translate}}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mediaName">
          <mat-header-cell *matHeaderCellDef>{{"home.table.defaultMedia" | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let screen">
            <div *ngIf="screen?.screenDefaultMedias?.length > 0">
              <a [matMenuTriggerFor]="screenDefaultMedias " class="text-brand-chathams-blue underline cursor-pointer">
                {{ 'screens.table.view' | translate}}
              </a>
            </div>
            <mat-menu #screenDefaultMedias="matMenu">
              <div *ngFor="let file of screen.screenDefaultMedias; let i = index" class="flex gap-2 m-4">
                <div>{{ file.media.name }}</div>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="actions-col"></mat-header-cell>
          <mat-cell *matCellDef="let screen" [style.width]="'1px'">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngIf="!screen?.engagementId">
                <button
                  *flowHasPermission="['UpdateBookingCms']; disabled true;"
                  mat-menu-item
                  (click)="onEngagementBookClick('/segments/book', screen)">
                  <span>{{ 'sidebar.bookSegment' | translate }}</span>
                </button>
                <button
                  *flowHasPermission="['UpdateBookingCms']; disabled true;"
                  mat-menu-item
                  (click)="onEngagementBookClick('/playlists/book', screen)">
                  <span>{{ 'sidebar.bookPlaylist' | translate }}</span>
                </button>
              </ng-container>
              <ng-container *ngIf="screen?.engagementId">
                <button
                  *flowHasPermission="['UpdateBookingCms']; disabled true;"
                  mat-menu-item
                  [routerLink]="[screen.engagementType?.toLowerCase(), screen.engagementId]">
                  <span>{{ 'global.edit' | translate }}</span>
                </button>
              </ng-container>
              <button *flowHasPermission="['UpdateScreensAdmin']; disabled true;" mat-menu-item [routerLink]="['/devices', screen.id]">
                <span>{{ 'home.table.editScreen' | translate }}</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row class="bg-gray-50" *matHeaderRowDef="displayedColumns"></mat-header-row>
        <!-- Condition to be updated -->
        <mat-row
          *matRowDef="let customer; columns: displayedColumns; let index = index"
          [ngClass]="{'missingConfig': customer?.engagementId, 'bg-white': index % 2 == 0, 'bg-gray-50': index % 2 != 0 }"
          (dblclick)="redirectToEditPage(customer.id)"></mat-row>
      </mat-table>
    </div>
  </ng-container>
</div>
