import {
  Component, Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { EnumService } from 'src/app/core/services/enum.service';

@Component({
  selector: 'views-chart-header',
  templateUrl: './views-chart-header.component.html',
  styleUrls: ['./views-chart-header.component.scss']
})
export class ViewsChartHeaderComponent extends unsubscribeMixin() {
  @Input() groupByFC: FormControl
  @Input() hideDefaultAdFC: FormControl
  @Input() label = ''
  public groupData = []

  constructor(public enumService: EnumService) {
    super();
    this.groupData = enumService.groupData
  }

}
