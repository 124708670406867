import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemMessageService } from '../../services/system-message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { unsubscribeMixin } from '../../unsubscribe';
import { AuthService } from '../auth.service';

@Component({
  selector: 'flow-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent extends unsubscribeMixin() implements OnInit {

  loadingCE = false;
  navigating = false;
  hide = true;
  form: FormGroup; // Declare the email form variable

  constructor(
    public authService: AuthService,
    private router: Router,
    private systemMessageService: SystemMessageService,
    private formBuilder: FormBuilder // Inject the FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    // Create the email form with validation
    this.form = this.formBuilder.group({
      email: ['']
    });
  }

  onConfirmEmail() {
    if (this.form.invalid) {
      return;
    }

    this.loadingCE = true;
    const email = this.form.value.email;

    this.authService.resetPassword(email).subscribe({
      next: (resp: string) => {
        this.loadingCE = false;
        this.systemMessageService.success(resp);
        this.router.navigateByUrl('/login');
      },
      error: () => {
        this.loadingCE = false;
      }
    });
  }
}