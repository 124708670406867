<h1 mat-dialog-title>
  <span>{{ 'poi.category.categoryCantBeDeleted' | translate }}</span>
  <a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a></h1>
<div mat-dialog-content
     class="py-2">
  <div>
    <div>{{ 'poi.category.categoryInUse' | translate }}</div>
    <div class="mt-2">
      {{ 'poi.category.usedPois' | translate }}
      <ul class="list-disc ml-10 mt-1">
        <li class="font-bold" *ngFor="let poi of pois">{{poi.name}}</li>
      </ul>
    </div>
  </div>
</div>
<div
     mat-dialog-actions>
  <button mat-button
          mat-stroked-button
          [mat-dialog-close]="true"
          class="btn-cancel"
          type="button">{{'global.cancel' | translate}}</button>

</div>
