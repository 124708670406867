import { DateTime } from "luxon";
import { Channel } from "../../../_api/models/channels";
import { Customer } from "../../../_api/models/customer";
import { IdName } from "../../../_api/models/idname";
import { TrackingEnum } from "../../../_api/models/tracking.enum";
import { ScreenOrientationEnum } from "../screen/screen-orientation";
import { ScreenAsleepStatusEnum, ScreenServicesStateData } from "../screen/screen.model";

export interface OfflineScreens {
  screen: IdName;
  type: string;
  customer: Customer;
  channel: Channel;
  placement: IdName;
  tracking: TrackingEnum;
  screenAliveStatus: ScreenAliveStatusEnum;
  screenAsleepStatus: ScreenAsleepStatusEnum;
  lastAliveDate?: Date;
  screenServicesStateData: ScreenServicesStateData;
}

export interface BrokenScreensResponse {
  screensWithIssues: OfflineScreens[];
  screensAndSensorsCount: number;
  offlineScreensAndSensorsCount: number;
  onlineScreensAndSensorsCount: number;
}

export interface BookingsMetricsModel {
  bookingsCountForThisPeriod: number;
  segmentCountForThisPeriod: number;
  playlistCountForThisPeriod: number;
  bookingsCountForPreviousPeriod: number;
  segmentCountForPreviousPeriod: number;
  playlistCountForPreviousPeriod: number;
}

export interface UnusedScreen {
  id?: number;
  placement?: IdName;
  humanUniqueIdentifier?: null | string;
  channel?: Channel;
  defaultMediaId?: null | number;
  mediaName?: null | string;
  width?: null | number;
  height?: null | number;
  customer?: Customer;
  hasMediaConfiguration?: boolean;
  startDate?: DateTime;
  endDate?: DateTime;
  weekNumber?: number;
  engagementType?: string;
  engagementId?: string;
  screenOrientation?: ScreenOrientationEnum;
  screenLayout?: string;
}

export enum ScreenAliveStatusEnum {
  NotAlive = 0,
  Alive = 1
}