import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnumService } from 'src/app/core/services/enum.service';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { LanguageLongTranslate } from 'src/app/core/translations';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'flow-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {
  checked: boolean = false;
  shortLanguages = [];
  currentLanguage: string;
  LanguageLongTranslate = LanguageLongTranslate;
  form: FormGroup<{ language: FormControl<string | null> }>;
  constructor(
    public dialogRef: MatDialogRef<LanguageModalComponent>,
    public dialog: MatDialog,
    private transalteConfigService: TranslateConfigService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private enumService: EnumService,
  ) {
    this.shortLanguages = enumService.translations;
    this.currentLanguage = transalteConfigService.currentLanguage
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      language: new FormControl(this.currentLanguage),
    });
  }

  onChange() {
    let lang = this.form.get('language').value
    this.data.changeLanguage(lang);
    this.dialog.closeAll()
  }

}

interface ModalData {
  changeLanguage: Function;
}
