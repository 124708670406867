import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Params } from "@angular/router";

import { BookingDetails, BookingDetailsRequest, BookingList, BookingRequest, BookingWeek } from "@api/models/booking";
import { ApiConfiguration } from "@api/api-configuration";
import { BaseService } from "@api/base-service";
import { PaginationResult } from "@api/models/paginator";


@Injectable({
  providedIn: 'root',
})
export class BookingApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  path = '/bookings';

  filterProps(obj: { [x: string]: any; }) {
    return Object.keys(obj)
      .filter(key => !!obj[key] && (!Array.isArray(obj[key]) || obj[key].length > 0))
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  }

  getBookings(params: BookingRequest): Observable<BookingWeek[]> {
    const httpParams = this.filterProps(params);
    return this.http.get<BookingWeek[]>(`${this.rootUrl}${this.path}/overview/calendar`, { params: httpParams });
  }

  getBookingDetails(params: BookingDetailsRequest): Observable<BookingDetails[]> {
    const httpParams = this.filterProps(params);
    return this.http.get<BookingDetails[]>(`${this.rootUrl}${this.path}/overview/calendar/details`, { params: httpParams });
  }

  getBookingTotal(params: BookingRequest): Observable<number> {
    const httpParams = this.filterProps(params);
    return this.http.get<number>(`${this.rootUrl}${this.path}/overview/calendar/total`, { params: httpParams });
  }

  getBookingsList(params: BookingRequest): Observable<PaginationResult<BookingList>> {
    const httpParams = this.filterProps(params);
    return this.http.get<PaginationResult<BookingList>>(`${this.rootUrl}${this.path}`, { params: httpParams });
  }

  deleteBooking(id: number): Observable<undefined> {
    return this.http.delete<undefined>(`${this.rootUrl}${this.path}/${id}`);
  }
}



