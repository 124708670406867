<div class="nav-bar">
  <img src="../../../../assets/svg/logo.svg"
       alt="Logo">
</div>


<div class="confirm-email-wrapper">

  <div class="confirm-email-card"
            *ngIf="!navigating">
    <div class="header">
      <h3>Forgot password</h3>
      <p>We’ll send a password reset link to this email, if it matches an existing BizLab account.</p>
    </div>
    <form [formGroup]="form"
          (ngSubmit)="onConfirmEmail()">
      <mat-card-content>
        <div class="label">
          <label>Email</label>
        </div>
        <mat-form-field appearance="outline"
                        class="w-full">
          <input matInput
                 placeholder="email"
                 type="email"
                 formControlName="email"
                 id="email">
        </mat-form-field>
      </mat-card-content>
      <button
              class="btn-primary-l w-full"
              [disabled]="loadingCE">Confirm
      </button>

      <a
        class="btn-base-l w-full"
        [routerLink]="['/login']">Back
      </a>

    </form>
  </div>

  <div class="footer">© BizLab Analytics 2024</div>

</div>

