<div #dropdownContainer class="dropdown-button-container">
    <div #dropdownToggle
         (click)="toggleDropdown()"
         class="dropdown-button">
        <div class="dropdown-button-text"
             [ngClass]="disabled ? 'button-disabled' : 'cursor-pointer'">
             <ng-content></ng-content>
        </div>
        <div class="dropdown-button-icon">
            <app-icon fill="#FFFFFF"
                      [ngStyle]="{cursor:'pointer'}"
                      iconName="chevron-down-filled"
                      size="md">
            </app-icon>
        </div>
    </div>
    <div #dropdownMenu
         class="dropdown-button-menu"
         *ngIf="dropdownOpen && options.length > 0">
        <div class="dropdown-button-item-container">
            <ng-container *ngFor="let option of options">
                <div *flowHasPermission="option.permissions; disabled true;"
                    class="dropdown-button-item"
                    (click)="selectOption(option)">
                    <span class="flex-grow">{{ option.name | translate }}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>