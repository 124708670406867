import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { AbstractViewsComponent } from '../abstract-views-component';


@Component({
  selector: 'flow-views-weekly',
  templateUrl: './views-weekly.component.html',
  styleUrls: ['./views-weekly.component.scss'],
})
export class ViewsWeeklyComponent extends AbstractViewsComponent {
  chartDataWithTranslation: ChartData<'bar'> = null;
  range = new FormGroup({
    start: new FormControl<DateTime | null>(DateTime.local().startOf('week')),
    end: new FormControl<DateTime | null>(DateTime.local().endOf('week')),
  });

  getColumnType(): 'day' {
    return 'day'
  }

  getStartAndEndTime() {
    const { start, end } = this.range.value
    const startTime = start?.startOf('week');
    const endTime = end?.endOf('week');

    return {
      startTime,
      endTime,
    }
  }

  override updateChartDataWithTranslation() {
    this.chartDataWithTranslation = { ...this.getChartDataWithTranslations() };
  }

  getChartDataWithTranslations(): ChartData<'bar'> {
    if (this.chartData) {
      const data = {
        ...this.chartData,
        labels: this.weekDays.map((w) => this.translateConfigService.instant(w.name)),
        datasets: this.chartData.datasets.map((d) => ({ ...d, label: this.translateConfigService.instant(d.label) }))
      }
      return data
    }
    return { labels: [], datasets: [] }
  }

}

