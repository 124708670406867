<h3 mat-dialog-title class="flex justify-between items-center">
  <div class="flex flex-col">
    <span>{{ data.title }}</span>
    <span class="text-sm text-gray-600">{{ data.subtitle }}</span>
  </div>
  <button mat-icon-button [mat-dialog-close]="true" tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </button>
</h3>
<div mat-dialog-content>
  <ng-container *ngIf="data.type === 'list'">
    <ul class="list-none">
      <li *ngFor="let item of data.items" class="mb-2">{{ item }}</li>
    </ul>
  </ng-container>
  <ng-container *ngIf="data.type === 'custom'">
    <ng-template [ngTemplateOutlet]="data.customTemplate"></ng-template>
  </ng-container>
</div>