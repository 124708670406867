import { Directive, ViewContainerRef } from '@angular/core';
import { PopoverService } from './popover.service';

@Directive({
  selector: '[flowPopover]'
})
export class PopoverDirective {
  
  constructor(
    private popoverService: PopoverService,
    private viewRef: ViewContainerRef
  ) {
    this.popoverService.viewRef = this.viewRef
   }
}