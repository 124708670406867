<div class="w-full h-full flex flex-col gap-8"
     *ngIf="isReady">
  <div class="flex flex-row">

      <span class="text-gray-900 text-2xl font-semibold leading-normal">{{(mediaId ? 'editMedia.title' : 'createMedia.enterName') | translate}}</span>
      <div class="flex flex-col justify-center ml-5" *ngIf="totalFilesToUpload > 0">
        <p class="m-0 mb-2 p-0 text-gray-800 text-sm text-center leading-3">
          {{ "media.uploading" | translate: { currentFile: uploadingFilesCount, totalFiles: totalFilesToUpload} }}
        </p>
        <flow-custom-progress-bar 
          [totalCount]="totalFilesToUpload"                                
          [counter]="uploadingFilesCount"   
          [fileSizes]="fileSizes"
          [uploadedData]="uploadedData">
        </flow-custom-progress-bar>
      </div>
    
  </div>
  <div class="h-full w-full justify-start items-start gap-10 inline-flex overflow-auto">
    <div class="h-full flex-col justify-start items-start gap-10 inline-flex">
      <form [formGroup]="form">
        <div class="w-[324px] flex-col justify-center items-start gap-4 flex">
          <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
            <div class="justify-start items-center gap-2 flex">
              <app-icon iconName="annotation-info"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598fae"></app-icon>
            </div>
            <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}</div>
          </div>
          <div *ngIf="!mediaId" class="justify-start items-start gap-2 inline-flex">
                <mat-checkbox color="primary" formControlName="isClientBoxChecked" class="client-checkbox"></mat-checkbox>
                <span class="text-gray-900 text-[0.75rem] font-normal ">{{'createMedia.moveClientMediaToFolder' | translate}}</span>
            </div>
          <div class="input-dimensions">
            <mat-label>{{ 'media.form.customer' | translate }} *</mat-label>
            <mat-form-field class="w-full">
              <mat-select formControlName="customerId"
                          placeholder="{{'media.form.customerPlaceholder' | translate}}"
                          (selectionChange)="getDataByCustomer()">
                <mat-option *ngFor="let customer of customers"
                            [value]="customer.id">
                  {{ customer.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container *ngIf="mediaId">
            <div class="input-dimensions">
              <mat-label>{{'media.table.name' | translate}} *</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <input matInput
                       type="text"
                       formControlName="name">
              </mat-form-field>
            </div>
            <div class="input-dimensions">
              <mat-label>{{ 'media.form.client' | translate }}</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <mat-select formControlName="client"
                            placeholder="{{'media.form.clientPlaceholder' | translate}}">
                  <mat-option *ngFor="let client of clients"
                              [value]="client.id">
                    {{ client.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-dimensions">
              <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <flow-multi-select-autocomplete formControlName="tags"
                                                [options]="tags"
                                                [displayFn]="displayTag"
                                                [newOptionFn]="newTag"></flow-multi-select-autocomplete>
              </mat-form-field>
            </div>

            <div class="grid-folder-container bg-gray-50" (click)="openViewMedia(media, $event)">
              <img *ngIf="media.type === MediaTypeEnum.Image && media.imageUrl"  
                    class="self-center"
                    [src]="media.imageUrl"
                    [width]="media.renderedWidth"
                    [height]="media.renderedHeight"
                    [alt]="media.name">
              <div *ngIf="media.type === MediaTypeEnum.Video" class="thumbnail-placeholder flex justify-center">
                <mat-icon>play_circle</mat-icon>
              </div>
              <div *ngIf="media.type === MediaTypeEnum.Image && !media.imageUrl" class="thumbnail-placeholder flex justify-center">
                <mat-icon>visibility</mat-icon>
              </div>
            </div>
  
          </ng-container>
          <ng-container *ngIf="!mediaId && (form.get('customerId').value || customerId)" formArrayName="medias">
            <div *ngFor="let mediaGroup of mediasFormArray?.controls; let i = index"
                 [formGroupName]="i"
                 [ngClass]="{'p-4 rounded flex-col justify-start items-start gap-4 inline-flex': !mediaId, 'bg-gray-50': !mediaId && mediaGroup.value?.id != selectedMedia?.id, 'bg-chathams-blue-400': !mediaId && mediaGroup.value?.id == selectedMedia?.id,
                            'hidden': (!selectedMedia && mediaGroup.value.name && mediaGroup.value.selectedFile) || (selectedMedia && mediaGroup.value?.id != selectedMedia.id)}">
              <div class="create-input-dimensions">
                <mat-label>{{'media.table.name' | translate}} *</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <input matInput
                         type="text"
                         formControlName="name">
                </mat-form-field>
              </div>
              <div class="create-input-dimensions">
                <mat-label>{{ 'media.form.client' | translate }}</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <mat-select formControlName="client"
                              placeholder="{{'media.form.clientPlaceholder' | translate}}">
                    <mat-option *ngFor="let client of clients"
                                [value]="client.id">
                      {{ client.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="create-input-dimensions">
                <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <flow-multi-select-autocomplete formControlName="tags"
                                                  [options]="tags"
                                                  [displayFn]="displayTag"
                                                  [newOptionFn]="newTag"></flow-multi-select-autocomplete>
                </mat-form-field>
              </div>
              
              <div *ngIf="!mediaId"
                   class="create-input-dimensions h-[186px] flex-col justify-start items-start gap-2 flex cursor-pointer"
                   [ngClass]="{ 'clear-visible' : !!selectedFile }">
                <div class="text-gray-900 text-sm font-normal leading-[18px]">{{'createMedia.selectMedia' | translate}}
                </div>

                <div class="flex gap-4 items-end">
                  <flow-drag-to-upload (selectedFile)="selectFile($event, mediaGroup.value?.id)"></flow-drag-to-upload>

                  <div (click)="selectedMedia ? saveMediaRow() : addMediaRow()" class="w-fit h-fit p-2 bg-surface-surface-brand rounded justify-center items-center gap-2 inline-flex">
                    <app-icon stroke="#fafafa" size="md" [iconName]="selectedMedia ? 'checked' : 'plus'"></app-icon>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="!mediaId" class="w-full h-full flex flex-col gap-4">
            <div *ngFor="let mediaGroup of mediasFormArray?.controls; let i = index"
                 class="w-[365px] justify-between items-center inline-flex p-1 border-b border-gray-200"
                 [ngClass]="{'bg-gray-50': mediaGroup.value?.id != selectedMedia?.id, 'bg-chathams-blue-400': mediaGroup.value?.id == selectedMedia?.id, 'hidden': (selectedMedia && !mediaGroup.value.selectedFile) || (!selectedMedia && !mediaGroup.value.selectedFile)}">
              <div class="justify-center items-center gap-2 flex">
                <div class="w-[60px] h-[49px] rounded flex justify-center items-center">
                  <img *ngIf="mediaGroup.value?.selectedFile?.format === 'image'" class="max-w-[60px] max-h-[49px] rounded" [src]="mediaGroup.value?.selectedFile?.filePreview" />
                  <mat-icon *ngIf="mediaGroup.value?.selectedFile?.format === 'video'">play_circle</mat-icon>
                </div>
                <div class="flex-col justify-center items-start gap-1 inline-flex">
                  <div class="text-gray-900 text-sm font-normal leading-[14px]">{{mediaGroup.value?.name}}</div>
                  <div class="w-[102px] text-gray-800 text-xs font-normal leading-none">{{mediaGroup.value?.selectedFile?.fileSize | filesize}}
                  </div>
                </div>
              </div>
              <div *ngIf="!mediaId" class="flex gap-2">
                <div (click)="selectMedia(mediaGroup.value)"
                      class="p-2 bg-gray-50 rounded justify-start items-start gap-2 flex">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            iconName="edit-pencil"
                            size="md"
                            stroke="#202020">
                  </app-icon>
                </div>
                <div (click)="onMediaFileSettingsRow(mediaGroup, i)"
                      class="p-2 bg-gray-50 rounded justify-start items-start gap-2 flex">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            iconName="x"
                            size="md"
                            stroke="#202020">
                  </app-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-full justify-between items-start flex">
      <div class="self-stretch p-4 bg-white rounded flex-col justify-center items-start gap-2 inline-flex">
        <div class="px-2 border-l-4 border-chathams-blue-600 justify-center items-start gap-2 inline-flex">
          <div class="w-[330px] text-gray-800 text-xs font-normal leading-[18px]">{{'global.note' | translate}}</div>
        </div>
        <div class="w-[330px] text-gray-800 text-xs font-normal leading-[18px]">{{(mediaId ? 'editMedia.note' :
          'createMedia.addMediaNote') | translate}}</div>
      </div>
      <div class="self-stretch justify-end items-start gap-4 inline-flex">
        <div (click)="onCancelClick()"
             class="p-4 bg-white rounded border border-gray-600 justify-start items-center gap-2 flex cursor-pointer">
          <div class="text-gray-800 text-sm font-medium leading-[14px]">{{'global.cancel' | translate}}</div>
        </div>
        <div (click)="onSaveClick()"
             [ngClass]="{'disabled': mediaId ? form.invalid : false }"
             class="p-4 bg-surface-surface-brand rounded justify-start items-center gap-2 flex cursor-pointer">
          <div class="text-white text-sm font-medium leading-[14px]">{{ (mediaId ? 'global.save' : 'global.add') |
            translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>