import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@api/models/customer';
import { PaginationResult } from '@api/models/paginator';
import { Observable } from 'rxjs';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { Channel } from '../models/channels';
import { ConfigurationSettings } from '../models/configuration-settings';

@Injectable({
  providedIn: 'root',
})
export class AdminApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetSettingsAdminPath = '/admin/configurationDetails';
  getSettings(): Observable<any> {
    return this.http.get<ConfigurationSettings>(`${this.rootUrl}${AdminApi.GetSettingsAdminPath}`);
  }

  static readonly UpdateSettingsAdminPath = '/admin/configuration';
  updateSettings(params): Observable<any> {
    return this.http.put<ConfigurationSettings>(`${this.rootUrl}${AdminApi.UpdateSettingsAdminPath}`, params);
  }

  static readonly ChannelsAdminPath = '/admin/channels';

  getChannels(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}${AdminApi.ChannelsAdminPath}`, params ? { params } : {});
  }

  getChannelsList(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}/admin/channelslist`, params ? { params } : {});
  }

  saveChannel(channel): Observable<any> {
    return this.http.post<Channel>(`${this.rootUrl}${AdminApi.ChannelsAdminPath}`, channel);
  }

  editChannel(channel): Observable<any> {
    return this.http.put<Channel>(`${this.rootUrl}${AdminApi.ChannelsAdminPath}`, channel);
  }

  getChannelById(channelId: number): Observable<any> {
    return this.http.get(`${this.rootUrl}${AdminApi.ChannelsAdminPath}/${channelId}`);
  }

  static readonly GetCustomersAdminPath = '/admin/customers';

  getCustomers(): Observable<any> {
    return this.http.get(`${this.rootUrl}${AdminApi.GetCustomersAdminPath}`);
  }

  getCustomersList(params?: any): Observable<PaginationResult<Customer>> {
    return this.http.get<PaginationResult<Customer>>(`${this.rootUrl}/admin/customerslist`, {params});
  }

  getCustomerById(id: number): Observable<any> {
    return this.http.get(`${this.rootUrl}${AdminApi.GetCustomersAdminPath}/${id}`);
  }

  saveCustomer(customer): Observable<any> {
    return this.http.post<Channel>(`${this.rootUrl}${AdminApi.GetCustomersAdminPath}`, customer);
  }

  removeCustomer(customerId: number): Observable<any> {
    return this.http.delete(`${this.rootUrl}${AdminApi.GetCustomersAdminPath}/${customerId}`);
  }
}
