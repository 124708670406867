import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'filterRemoved'
})
export class IsRemovedAdvertisementPipe implements PipeTransform {
  transform(formArray: AbstractControl[], isRemovedValue: boolean): AbstractControl[] {
    return formArray
      .filter(control => control instanceof FormGroup)
      .map(control => control as FormGroup)
      .filter(group => group.get('isRemoved')?.value === isRemovedValue);
  }
}
