import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { StatisticsApi } from "@api/services/statistics-api";
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DateTime } from "luxon";
import { BaseChartDirective } from 'ng2-charts';
import { AverageTimeInChannelPerHour } from 'src/_api/models/people-counter-statistics-response';

@Component({
  selector: 'flow-average-time-in-channel',
  templateUrl: './average-time-in-channel.component.html',
  styleUrls: ['./average-time-in-channel.component.scss']
})
export class AverageTimeInChannelComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() form: FormGroup;
  @Input() averageTimeReportPerHour: AverageTimeInChannelPerHour[];
  @Input() startDate!: string;
  @Input() endDate!: string;

  showStatisticsPreviousWeek = false;

  public chartPlugins = [DataLabelsPlugin];
  public chartData: ChartData<'line', number[], string | string[]> = null;

  public chartOptions: ChartConfiguration['options'] = {};
  hasData:boolean
  currentTime: string




  constructor(protected translateService: TranslateService,
              private statisticsApi: StatisticsApi,) {}

  ngOnInit(): void {
    this.setChartOptions();
    this.currentTime = this.getRelevantDate(this.startDate, this.endDate).toISODate()
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.currentTime = this.getRelevantDate(this.startDate, this.endDate).toISODate()
    if (changes?.averageTimeReportPerHour) {
      const currentValue = changes?.averageTimeReportPerHour?.currentValue;
      if (currentValue)
        this.updateChartData();
    }
  }
  updateChartData() {
    this.hasData = !!this.averageTimeReportPerHour?.length;
    const color = '#1F5792';
    const totalAvgInTimeArray = [];
    let hours = [];

    this.averageTimeReportPerHour?.forEach((item, index) => {
      hours.push(item.hour.toString().padStart(2, '0') + ':00')
      totalAvgInTimeArray[index] = item?.averageTimeInChannelMinutes;
    });

    const dataSet = {
      pointRadius: 0,
      label: 'Minutes',
      data: totalAvgInTimeArray,
      fill: false,
      backgroundColor: color,
      borderColor: color,
    };
    this.chartData = {
      labels: hours,
      datasets: [dataSet],
    };
    this.chart?.update();
  }
  updateCheckbox() {
    this.showStatisticsPreviousWeek = !this.showStatisticsPreviousWeek;
    this.updateChartData();
  }
  private setChartOptions() {
    this.chartOptions = {
      elements: {
        line: { tension: 0.5 }
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        },
        y: {
          title: {
            display: false,
          },
          grid: {
            display: false,
          },
          offset:false,
          ticks: {
            stepSize: 1,
          }
        },
      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 1,
            boxHeight: 30,
            textAlign: 'left',
            padding: 5
          }
        },
        datalabels: { display: false },
      }
    }
  }

  private getRelevantDate(startDate, endDate): DateTime {
    const today = DateTime.local().startOf('day');
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).startOf('day');

    if (today >= start && today <= end) {
      return today;
    } else {
      return end;
    }
  }

  getAverageTimePerHour(date) {
    this.currentTime = date;
    let channelIds = this.form.get('channels').value.map((l) => l.id);
    this.statisticsApi.averageTimePerHour({channelIds: channelIds || [], currentDate: this.currentTime})
      .subscribe(response => {
        this.averageTimeReportPerHour = response;
        this.updateChartData()
      });
  }

}


