<flow-helper-card
  *ngIf="!consentGiven"
  consentKey="screen-consent"
  description="screens.infoDeviceStatus"
  title="screens.title">
</flow-helper-card>

<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ 'screens.title' | translate }}</h1>
  <div class="flex-1 ">
    <button [matMenuTriggerFor]="menu" color="primary" mat-flat-button type="button">
      <span class="flex flex-row items-center">{{ 'poi.add' | translate }}
        <mat-icon>arrow_drop_down</mat-icon></span>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="isAdmin">
        <a *flowHasPermission="['UpdateScreensAdmin']; disabled true;" [routerLink]="['/screens/create']" mat-menu-item>{{ 'screens.device' | translate }}</a>
      </ng-container>
      <a *flowHasPermission="['UpdateScreensAdmin']; disabled true;" [routerLink]="['/screenGroup/create']" mat-menu-item>{{'screens.screenGroupBtn' | translate}}</a>
    </mat-menu>
  </div>
</div>

<flow-tabs (selectedTabChange)="selectedTabChange($event)"
           [borderOnTop]=true [selectedIndex]="selectedIndex">
  <flow-tabs-item title="{{'screens.devices' | translate}}">
    <div *ngIf="screenIds.length > 0" class="flex gap-2 mt-4">
      <button (click)="openBulkUpdateModal($event, 'ai')"
              mat-stroked-button>
        {{ 'screens.changeAiVersion' | translate }}
      </button>
      <button (click)="openBulkUpdateModal($event, 'ad')"
              mat-stroked-button>
        {{ 'screens.changeAdvertiserVersion' | translate }}
      </button>
    </div>

    <ng-container *ngTemplateOutlet="screensTable"></ng-container>
  </flow-tabs-item>
  <flow-tabs-item title="{{'screens.screenGroups' | translate}}">

    <div class="flex gap-2 mt-4">
      <button (click)="showScreensFromGroup = false" *ngIf="showScreensFromGroup"
              mat-stroked-button>
        {{ 'global.back' | translate }}
      </button>
      <ng-container *ngIf="screenIds.length > 0 && showScreensFromGroup">
        <button (click)="openBulkUpdateModal($event, 'ai')"
                mat-stroked-button>
          {{ 'screens.changeAiVersion' | translate }}
        </button>
        <button (click)="openBulkUpdateModal($event, 'ad')"
                mat-stroked-button>
          {{ 'screens.changeAdvertiserVersion' | translate }}
        </button>
      </ng-container>
    </div>
    <ng-container *ngIf="showScreensFromGroup; then screensTable else groupTable">
    </ng-container>
  </flow-tabs-item>
</flow-tabs>

<ng-template #groupTable>
  <flow-screen-group-table class="w-full" (showScreensInGroup)="showScreensInGroup($event)"></flow-screen-group-table>
</ng-template>

<!--This needs to be a separate component-->
<ng-template #screensTable>
  <div class="w-full">
    <mat-paginator
      (page)="handlePageEvent($event)"
      [length]="totalCount"
      [pageIndex]="params.pageNumber"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="params.pageSize"
      showFirstLastButtons>
    </mat-paginator>
    <table
      (matSortChange)="handleSortEvent($event)"
      [dataSource]="dataSource"
      class="table-hover"
      mat-table
      matSort
      matSortActive="humanUniqueIdentifier"
      matSortDirection="asc"
      multiTemplateDataRows
      [matSortDisableClear]="true">
      <ng-container *ngIf="isAdmin" matColumnDef="checkbox">
        <th *matHeaderCellDef
            mat-header-cell>
          <mat-checkbox (change)="selectScreens($event,null)"
                        [checked]="isAllSelected"
                        color="primary"
                        id="select-media-checkbox-all"></mat-checkbox>
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          <mat-checkbox (change)="selectScreens($event, screen)"
                        [checked]="screen.isSelected"
                        [id]="'select-media-checkbox'+screen.id"
                        color="primary"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.Status' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
              <flow-pill-box
                [color]="screen.status.color"
                [label]="screen.status.label"
                [tooltip]="screen.status.tooltip">
              </flow-pill-box>
              {{screen.color | json}}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.id' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell> {{ screen.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="humanUniqueIdentifier">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.name' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          {{ screen.humanUniqueIdentifier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.type' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          <div>{{ (screen.isSensor ? 'screens.sensor' : 'screens.screen') | translate }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="channelName">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.channel' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell> {{ screen.channelName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.customer' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          {{ screen?.customer?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="channelDescription">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.placement' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell> {{ screen.channelDescription }}
        </td>
      </ng-container>

      <ng-container matColumnDef="trackingType">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.trackingType' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell> {{ screen.trackingType === TrackingEnum.NotApplicable ? '' : TrackingEnum[screen.trackingType] | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="coordinates">
        <th *matHeaderCellDef
            mat-header-cell> {{ 'screens.table.coordinates' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          <div
            class="text-[#1F5792] underline cursor-pointer"
            matTooltip="{{screen.latitude && screen.longitude ? ('screens.table.coordinatesInfo' | translate: {latitude: screen.latitude, longitude: screen.longitude}) : '' }}">
            <a (click)="redirectToMaps(screen.latitude, screen.longitude)">{{'screens.table.view' | translate}}</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="layout">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.screenLayout' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
        <span *ngIf="screen.orientation == 1">
              <app-icon
                fill="none"
                iconName="portrait"
                matTooltip="{{'screens.table.portrait' | translate}}"
                size="md">
              </app-icon>
            </span>
          <span *ngIf="screen.orientation == 2">
              <app-icon
                fill="none"
                iconName="landscape"
                matTooltip="{{'screens.table.landscape' | translate}}"
                size="md">
              </app-icon>
            </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="powerSchedule">
        <th *matHeaderCellDef
            mat-header-cell> {{ 'Scheduling' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>
          <div *ngIf="screen?.powerSchedule?.length > 0">
            <a [matMenuTriggerFor]="powerSchedule "
               class="text-[#1F5792] underline cursor-pointer">
              {{ 'screens.table.view' | translate}}
            </a>
          </div>
          <mat-menu #powerSchedule="matMenu">
            <div *ngFor="let schedule of screen.powerSchedule;let i = index" class="flex gap-2 m-4">
              <div>{{ 'createScreen.days.' + weekDays[i] | translate }}:</div>
              <div>{{ schedule.powerUpHour.toString().length > 1 ? schedule.powerUpHour : '0' + schedule.powerUpHour }}:{{ schedule.powerUpMinutes.toString().length > 1 ? schedule.powerUpMinutes : '0' + schedule.powerUpMinutes }}
              </div>
              <div>-</div>
              <div>{{ schedule.powerDownHour.toString().length > 1 ? schedule.powerDownHour : '0' + schedule.powerDownHour }}:{{ schedule.powerDownMinutes.toString().length > 1 ? schedule.powerDownMinutes : '0' + schedule.powerDownMinutes }}
              </div>
            </div>
          </mat-menu>
        </td>

      </ng-container>


      <ng-container matColumnDef="enable">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.enable' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell>

          <mat-slide-toggle
            (click)="onToggle($event, screen, !screen.enable)"
            [(ngModel)]="screen.enable"
            color="primary">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultMediaName">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.defaultMedia' | translate }}
        </th>
        <td *matCellDef="let screen"
            mat-cell> {{ screen.defaultMediaName }}
        </td>
      </ng-container>

      <ng-container *ngIf="isAdmin" matColumnDef="aiVersion">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.aiVersion' | translate }}
        </th>
        <td *matCellDef="let screen" class="truncate-cell"
            mat-cell> {{ screen.aiVersion }}
        </td>
      </ng-container>

      <ng-container *ngIf="isAdmin" matColumnDef="advertiserVersion">
        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header> {{ 'screens.table.adsVersion' | translate }}
        </th>
        <td *matCellDef="let screen" class="truncate-cell"
            mat-cell> {{ screen.advertiserVersion }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef
            class="actions-col"
            mat-header-cell></th>
        <td *matCellDef="let screen"
            [style.width]="'1px'"
            mat-cell>
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a *flowHasPermission="['UpdateScreensAdmin']; disabled true;" [routerLink]="['/screens', screen.id]" mat-menu-item>{{ 'global.edit' | translate }}</a>
            <ng-container *ngIf="isAdmin">
              <button *flowHasPermission="['UpdateScreensAdmin']; disabled true;" (click)="onDeleteClick(screen)" mat-menu-item>{{ 'global.delete' | translate }}</button>
            </ng-container>
          </mat-menu>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns"
          mat-header-row></tr>
      <tr (dblclick)="redirectToEditPage(screen)"
          *matRowDef="let screen; columns: displayedColumns;"
          mat-row
          class="hover:bg-gray-200 cursor-pointer"
      ></tr>
    </table>
  </div>
</ng-template>
