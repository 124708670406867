<div mat-dialog-title
     class="flex flex-row justify-between border-b border-gray-200 p-0">
    <h3 class="font-semibold">{{'media.filters.title' | translate}}</h3>
    <a class="cursor-pointer"
       (click)="close()">
        <app-icon stroke="#202020"
                  size="md"
                  iconName="x"></app-icon>
    </a>
</div>

<mat-dialog-content class="overflow-hidden">
    <form [formGroup]="form"
          class="input-override">
        <a class="flex flex-row justify-between cursor-pointer h-6 mb-4"
           (click)="togglePeriod()"
           cdkFocusInitial>
            <h3 class="font-semibold">{{'media.form.datePickerLabel' | translate}}</h3>
            <app-icon stroke="#202020"
                      fill="none"
                      iconName="chevron-right"
                      size="md"
                      class="w-6 rotate-90"
                      *ngIf="showPeriod"></app-icon>
            <app-icon stroke="#202020"
                      fill="none"
                      iconName="chevron-left"
                      size="md"
                      class="w-6 rotate-90"
                      *ngIf="!showPeriod"></app-icon>
        </a>

        <div class="flex flex-row mb-2 min-h-[262px]"
             *ngIf="showPeriod">
            <mat-calendar class="w-[50%]"
                          [(selected)]="filteredStartDate"
                          (selectedChange)="clearEndDate()"></mat-calendar>
            <mat-calendar [minDate]="form.get('filteredStartDate').value"
                          [(selected)]="filteredEndDate"></mat-calendar>
        </div>

        <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col">
                <mat-label>{{'media.filters.text' | translate}} *</mat-label>
                <mat-form-field class="w-full">
                  <input matInput
                         type="text"
                         formControlName="text">
                </mat-form-field>
            </div>

            <div class="flex flex-col"
                 *ngIf="types.length > 0">
                <mat-label>{{'media.filters.types' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="filterTypes"
                                multiple>
                        <mat-option *ngFor="let type of types"
                                    [value]="type.id">
                            {{type.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col"
                 *ngIf="customers.length > 0">
                <mat-label>{{'media.filters.customers' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="customerIds"
                                multiple
                                (selectionChange)="onCustomerSelect($event)">
                        <mat-option *ngFor="let customer of customers"
                                    [value]="customer.id">
                            {{customer.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col">
                <mat-label>{{'media.filters.clients' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="clientIds"
                                multiple>
                        <mat-option *ngFor="let client of clients$ | async"
                                    [value]="client.id">
                            {{client.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col">
                <mat-label>{{'media.filters.tags' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="tags"
                                multiple>
                        <mat-option *ngFor="let tag of tags$ | async"
                                    [value]="tag.id">
                            {{tag.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col justify-center">
                <mat-checkbox color="primary"
                              formControlName="isShowFolder">{{ 'media.filters.showFolders' | translate }}
                </mat-checkbox>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-5">
    <div class="flex flex-row justify-between w-full items-center">
        <a class="text-brand-chathams-blue underline hover:text-chathams-blue-600 cursor-pointer"
           (click)="clearAll()">{{'manage.filters.clearAll' | translate}}</a>
        <button class="btn-save"
                type="button"
                color="primary"
                mat-flat-button
                (click)="onSubmit()"
                [disabled]="disableShow">
            {{ 'media.filters.showNumberOfMedias' | translate: {number: ''} }}
        </button>
    </div>
</mat-dialog-actions>