<h1 mat-dialog-title>
  {{data?.title}}
  <a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a>
</h1>
<div mat-dialog-content
     class="py-2">
  <ng-container *ngIf="data?.media.imageUrl">
    <img *ngIf="data.media.type === MediaTypeEnum.Image && data.media.imageUrl"
         [src]="apiBaseUrl + data.media.imageUrl">
    <video *ngIf="data.media.type === MediaTypeEnum.Video && data.media.imageUrl"
           [src]="apiBaseUrl + data.media.imageUrl"
           controls></video>
  </ng-container>
  <p *ngIf="!data?.media.imageUrl">{{'notifications.error.mediaFileCouldNotBeShown' | translate}}</p>
</div>
