import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorParams } from '@api/models/paginator';
import { DateTime } from 'luxon';
import { ReportsResponse, StatisticsApi } from 'src/_api';
import { ActiveReportEnum } from 'src/_api/models/active-report-enum';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { emptyRowExposures, emptyRowPeopleCount, targetGroupTableHeaders } from '../reports-hardcoded';
import { MediaTypeEnum } from './../../../../../_api/models/media-type-enum';

@Component({
  selector: 'flow-reports-data',
  templateUrl: './reports-data.component.html',
  styleUrls: ['./reports-data.component.scss']
})
export class ReportsDataComponent implements OnChanges, AfterViewInit{

  private sort: MatSort;
  @Input() activeReport: ActiveReportEnum;
  @Input() reports: ReportsResponse;
  @Input() includeSmsDetailsPOI: boolean;
  @Input() includeAdminDetailsPOI: boolean;
  @Input() includeVehicleDataReach: boolean;
  @Input() includeAverageTimeInChannel: boolean;
  @Input() isAdmin: boolean;
  @Output() reportShown = new EventEmitter<boolean>();
  @Output() getNewReport = new EventEmitter();
  ActiveReportEnum = ActiveReportEnum;
  isIncludeAverageTimeInChannel: boolean = false;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }
  data;
  columns: string[] = ['date', 'customerName', 'channelName', 'screenName', 'placement'];
  displayedColumns: string[] = [...this.columns];
  dataSource: MatTableDataSource<ReportsTable> = new MatTableDataSource([]);
  timeRanges: string[] = [];
  MediaTypeEnum = MediaTypeEnum;
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'Date',
    sortDirection: 'desc'
  };
  pageSizeOptions = [5, 10, 25, 100];
  totalCount: number = 0;

  filterMapping = {
    "targetGroup1": "TargetGroupW_Under18",
    "targetGroup2": "TargetGroupW18_24",
    "targetGroup3": "TargetGroupW25_34",
    "targetGroup4": "TargetGroupW35_44",
    "targetGroup5": "TargetGroupW45_54",
    "targetGroup6": "TargetGroupW55_64",
    "targetGroup7": "TargetGroupW65_Above",
    "targetGroup8": "TargetGroupM_Under18",
    "targetGroup9": "TargetGroupM18_24",
    "targetGroup10": "TargetGroupM25_34",
    "targetGroup11": "TargetGroupM35_44",
    "targetGroup12": "TargetGroupM45_54",
    "targetGroup13": "TargetGroupM55_64",
    "targetGroup14": "TargetGroupM65_Above",
    "visitors": "Total",
    "00:00 - 01:00": "Hour_0",
    "01:00 - 02:00": "Hour_1",
    "02:00 - 03:00": "Hour_2",
    "03:00 - 04:00": "Hour_3",
    "04:00 - 05:00": "Hour_4",
    "05:00 - 06:00": "Hour_5",
    "06:00 - 07:00": "Hour_6",
    "07:00 - 08:00": "Hour_7",
    "08:00 - 09:00": "Hour_8",
    "09:00 - 10:00": "Hour_9",
    "10:00 - 11:00": "Hour_10",
    "11:00 - 12:00": "Hour_11",
    "12:00 - 13:00": "Hour_12",
    "13:00 - 14:00": "Hour_13",
    "14:00 - 15:00": "Hour_14",
    "15:00 - 16:00": "Hour_15",
    "16:00 - 17:00": "Hour_16",
    "17:00 - 18:00": "Hour_17",
    "18:00 - 19:00": "Hour_18",
    "19:00 - 20:00": "Hour_19",
    "20:00 - 21:00": "Hour_20",
    "21:00 - 22:00": "Hour_21",
    "22:00 - 23:00": "Hour_22",
    "23:00 - 00:00": "Hour_23",
    "client": "clientName",
    "mediaFileName": "mediaFile",
    "totalExposures": "totalVisitors"
  };

  constructor(private translateConfigService: TranslateConfigService, private statisticApi: StatisticsApi) { }

  ngOnChanges(): void {
    this.createTimeRanges();

    this.displayedColumns = [...this.columns];
    this.data = this.reports?.exposureData || this.reports?.peopleCounterDates || this.reports?.reachDates || this.reports?.personOfInterestStatisticsReports;

    if (this.data?.length > 0) {
      this.updateDisplayedColumns();
      this.updateDataSource(this.data);
    }

    if(this.reports.totalCount) {
      this.totalCount = this.reports.totalCount;
    }

    if(this.reports.currentPage) {
      this.params = { ...this.params, pageNumber: this.reports.currentPage };
    }
  }

  ngAfterViewInit(): void {
    this.reportShown.emit(true);
  }

  private updateDisplayedColumns(): void {
    if (this.reports?.exposureData?.length > 0) {
      this.displayedColumns.push(...['bookingType', 'bookingNumber', 'bookingName', 'client', 'mediaFileName', 'fileType', 'totalExposures']);
    }

    if (this.reports?.peopleCounterDates?.length > 0) {
      this.displayedColumns = this.displayedColumns.concat(['trackingType', 'visitors']);
        if (this.isAdmin && this.activeReport == ActiveReportEnum.PeopleCounter && this.includeAverageTimeInChannel) {
          this.isIncludeAverageTimeInChannel = true;
          this.displayedColumns.push('averageTimeInChannel')
        }
    }
    if (this.reports?.reachDates?.length > 0) {
      this.displayedColumns.push('visitors');
      if (this.reports?.includeVehicleData) {
        this.displayedColumns.push('vehicles');
        this.displayedColumns.push('cars');
        this.displayedColumns.push('vans');
        this.displayedColumns.push('buses');
        this.displayedColumns.push('trucks');
        this.displayedColumns.push('opportunityToSeeVehicles');
      }
    }
    if (this.reports?.personOfInterestStatisticsReports?.length > 0) {
      this.displayedColumns.push(...['trackingType','poiCategoryName','poiName'])
      if(this.includeAdminDetailsPOI){
        this.displayedColumns.push('poiCreatedDate', 'poiCreatedBy')
      }
      if(this.includeSmsDetailsPOI){
        this.displayedColumns.push('smsNotifications')
      }
    }

    if (this.reports.includePrimaryAndSecoundaryGroups) {
      this.displayedColumns.push(...['primaryExposures', 'secondaryExposures']);
    }

    if (this.reports.includeSharedOfVoices) {
      this.displayedColumns.push('shareOfVoice');
    }

    if(this.reports.includeExtendedStatistics) {
      this.displayedColumns.push(...['airTime', 'attentionTime', 'impressions'])
    }

    if (this.reports.includeReach) {
      this.displayedColumns.push('reach');
    }

    if (this.reports.includeHourlyBreakdown) {
      this.displayedColumns.push(...this.timeRanges);
    }

    if (this.reports.includeDemographicalBreakdown) {
      this.displayedColumns.push(...targetGroupTableHeaders);
      if (this.activeReport != ActiveReportEnum.Exposures)
        this.displayedColumns.push('opportunityToSee');
    }
    if(this.reports.personOfInterestStatisticsReports){
    }
  }

  private updateDataSource(data: any[]): void {
    if(!this.reports.personOfInterestStatisticsReports) {
      const newEmptyRow = this.reports?.exposureData?.length > 0
        ? {
          ...emptyRowExposures,
          totalExposures: `${this.translateConfigService.instant('reports.table.total')}: ${this.reports?.total}`
        }
        : {
          ...emptyRowPeopleCount,
          visitors: `${this.translateConfigService.instant('reports.table.total')}: ${this.reports?.totalVisitors}`
        };


      const updatedData = data.map((item) => {
        let modifiedData = {...item};

        if (this.reports.includeHourlyBreakdown) {
          this.timeRanges.forEach((range, i) => {
            modifiedData[range] = item.hourlyCalculated ? item?.hourlyCalculated[i] : '';
          });
        }

        if (this.reports.includeDemographicalBreakdown) {
          modifiedData = {
            ...modifiedData,
            targetGroup1: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 1)[0]?.count,
            targetGroup2: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 2)[0]?.count,
            targetGroup3: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 3)[0]?.count,
            targetGroup4: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 4)[0]?.count,
            targetGroup5: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 5)[0]?.count,
            targetGroup6: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 6)[0]?.count,
            targetGroup7: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 7)[0]?.count,
            targetGroup8: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 8)[0]?.count,
            targetGroup9: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 9)[0]?.count,
            targetGroup10: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 10)[0]?.count,
            targetGroup11: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 11)[0]?.count,
            targetGroup12: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 12)[0]?.count,
            targetGroup13: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 13)[0]?.count,
            targetGroup14: item?.distingtTargetGroups?.filter(tg => tg.targetGroup == 14)[0]?.count
          };
        }
        if (this.reports?.includeVehicleData) {
          modifiedData = {
            ...modifiedData,
            cars: item?.distinctVehicles?.filter(tg => tg.vehicleType == 0)[0]?.count,
            vans: item?.distinctVehicles?.filter(tg => tg.vehicleType == 1)[0]?.count,
            buses: item?.distinctVehicles?.filter(tg => tg.vehicleType == 2)[0]?.count,
            trucks: item?.distinctVehicles?.filter(tg => tg.vehicleType == 3)[0]?.count,
          };
        }
        if (this.reports?.exposureData?.length > 0) {
          modifiedData.mediaFileName = item.mediaFile.name || item.mediaFile;
        }

        modifiedData.customerName = item.customer.name || item.customer
        modifiedData.channelName = item.channel.name || item.channel
        modifiedData.screenName = item.screen.name || item.screen
        return modifiedData;
      });

      updatedData.push(newEmptyRow);
      this.dataSource.data = updatedData;
    } else {
      this.dataSource.data = this.reports.personOfInterestStatisticsReports
    }
  }

  private createTimeRanges(): void {
    this.timeRanges = [];
    for (let hour = 0; hour < 24; hour++) {
      const startHour = hour;
      const endHour = (hour + 1) % 24;
      const timeRange = `${DateTime.fromObject({ hour: startHour }).toFormat('HH:mm')} - ${DateTime.fromObject({ hour: endHour }).toFormat('HH:mm')}`;
      this.timeRanges.push(timeRange);
    }
  }

  handlePageEvent(e: PageEvent) {
    this.params = { ...this.params, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.getNewReport.emit(this.params);
  }

  getTargetGroupKey(key: string) {
    return this.filterMapping[key] || key;
  }

  handleSortEvent(sort: Sort) {
    const sortBy = this.getTargetGroupKey(sort?.active);
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getNewReport.emit(this.params);
  }
}

export interface ReportsTable {
  [key: string]: any;
}
