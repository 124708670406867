import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'flow-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownComponent {
  @Input() label!: string;
  @Input() menuItems: { label: string, action: () => void }[] = [];
 }
