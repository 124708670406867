<div class="flex flex-row w-full items-center overflow-visible">
  <h1 class="m-0 mr-8 font-medium">{{'users.title' | translate}}</h1>
  <button *flowHasPermission="['UpdateUsersAdmin']; disabled true;" type="button" color="primary" mat-flat-button [routerLink]="['/users/create']">
    <span class="flex flex-row items-center">{{'global.add' | translate}}</span>
  </button>
</div>

<mat-paginator
  (page)="handlePageEvent($event)"
  [pageSize]="params.pageSize"
  [pageIndex]="params.pageNumber"
  [pageSizeOptions]="pageSizeOptions"
  [length]="totalCount">
</mat-paginator>

<table mat-table [dataSource]="dataSource" (matSortChange)="handleSortEvent($event)" matSort multiTemplateDataRows [matSortDisableClear]="true">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'users.table.id' | translate}} </th>
    <td mat-cell *matCellDef="let user"> {{user.id}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'users.table.email' | translate}} </th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> {{'users.table.name' | translate}} </th>
    <td mat-cell *matCellDef="let user">
      <span>{{ user?.profiles[0]?.name }}</span>
      <span class="ml-1" *ngIf="user.profiles.length > 1">
        <a class="text-blue-600 underline cursor-pointer" (click)="openProfilesDialog(user.profiles);">more</a>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'users.table.role' | translate}} </th>
    <td mat-cell *matCellDef="let user">
      {{user?.role.name | translate}}
    </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef> {{'users.table.customer' | translate}} </th>
    <td mat-cell *matCellDef="let user">
      <span>{{ user?.customers[0]?.name }} </span>
      <span class="ml-1" *ngIf="user.customers.length > 1">
        <a class="text-blue-600 underline cursor-pointer" (click)="openCustomerDialog(user.customers);">more</a>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="justify-items-end"> </th>
    <td mat-cell *matCellDef="let user" class="justify-items-end">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *flowHasPermission="['UpdateUsersAdmin']; disabled true;" mat-menu-item (click)="redirectToEditPage(user)">
          <span>{{ 'manage.table.edit' | translate }}</span>
        </button>
        <button *flowHasPermission="['UpdateUsersAdmin']; disabled true;" mat-menu-item (click)="onDeleteClick(user)">
          <span class="text-red-500">{{ 'manage.table.delete' | translate }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <!-- Header Row Definition -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <!-- Row Definition -->
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="redirectToEditPage(row)"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell text-center" colspan="8">{{ 'manage.table.noData' | translate }}</td>
  </tr>
</table>

