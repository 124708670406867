import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AdminApi, Customer, MediaFile, MediaTypeEnum, Tag } from 'src/_api';
import { AppService } from 'src/app/app.service';
import { AuthService, UserRoleEnum } from 'src/app/core/auth/auth.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { Media, MediaService } from 'src/app/core/services/media.service';
import { SystemMessageService } from 'src/app/core/services/system-message.service';

import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { CustomSnackbarComponent } from 'src/app/shared/custom-snackbar/custom-snackbar.component';
import { Client } from 'src/_api/models/client';
import { ClientApi } from 'src/_api/services/client-api';
import { TagsApi } from '@api/services/tags-api.service';
import { MediaApi } from '@api/services/media-api';

@Component({
  selector: 'flow-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.scss']
})
export class MediaEditComponent extends unsubscribeMixin() implements OnInit {

  @Input() isModal: boolean;
  @Input() customerIdModal: number;
  @Output() mediaSaved = new EventEmitter<MediaFile>();
  @Output() cancel = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;

  mediaId: number;
  media: Media;
  tags: Tag[];
  form: FormGroup<MediaFormGroup>;
  selectedFile: any;
  filePreview: any;
  format: 'video' | 'image';
  MediaTypeEnum = MediaTypeEnum;
  customers: Customer[];
  clients: Client[];
  isAdmin: boolean;
  customerId: number;
  videoDuration: number | null = null;
  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private tagsApi: TagsApi,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private dialog: MatDialog,
    private clientApi: ClientApi,
    private mediaApi: MediaApi
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.mediaId = +this.activatedRoute.snapshot.params['id'] || null;
  }

  ngOnInit(): void {
    this.initForm();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.customerId = this.customerIdModal ? this.customerIdModal : user?.currentCustomerId || user?.customerId;
    this.getData();
  }

  private getData() {
    forkJoin([
      this.isAdmin && !this.customerIdModal ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin || this.customerIdModal ? this.adminApi.getCustomerById(this.customerId) : of(null),
      this.mediaId ? this.mediaApi.getMediaById(this.mediaId) : of(null)
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([customers, customer, mediaResponse]) => {
        this.customers = customers || [customer];
        let media: any = mediaResponse;
        forkJoin([
          this.tagsApi.getTags({ customerIds: this.customerId || media?.customer?.id || this.customerIdModal ? [this.customerId || media?.customer?.id || this.customerIdModal] : this.form.value.customerId ? [this.form.value.customerId] : [] }),
          this.clientApi.getClients(this.customerId ? [this.customerId] : [media?.customer?.id])
        ]).pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.init))
          .subscribe(([tags, clients]) => {
            this.tags = tags || [];
            this.clients = clients || [];
            this.media = media;
            this.initForm(this.media);
          });
      });
  }

  selectFile(event: any): void {
    this.selectedFile = event.target.files && event.target.files[0];
    this.videoDuration = null;
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.filePreview = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);

      if (this.selectedFile.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (this.selectedFile.type.indexOf('video') > -1) {
        this.format = 'video';
        this.getVideoDuration(this.selectedFile);
      }
    }

  }

  getVideoDuration(selectedFile) {
    const video = document.createElement('video');
    video.src = window.URL.createObjectURL(selectedFile);
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      this.videoDuration = video.duration;
    };
  }

  clearFile(): void {
    this.selectedFile = null;
    this.filePreview = null;
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.selectedFile) {

      const isExceeded = this.checkIfStorageIsExceeded();

      if (isExceeded) {
        this.dialog.open(CustomSnackbarComponent, {
          width: '600px',
          data: {
            msg: {
              title: "notifications.error.storageExceededTitle",
              content: this.translateConfigService.instant("notifications.error.storageExceeded")
            }
          }
        });
        return;
      }

      const values = this.form.getRawValue();
      const tagsJson = JSON.stringify(values?.tags || []);
      let paramsString = '';
      if (values.customerId) {
        paramsString += `customerId=${this.form?.getRawValue().customerId}`;
      }
      if (values?.name && values?.name.length > 0) {
        paramsString += `&name=${values?.name}`;
      }
      if (values?.client) {
        paramsString += `&clientId=${values?.client}`;
      }
      if (this.videoDuration) {
        paramsString += `&duration=${this.videoDuration}`;
      }
      paramsString += `&tags=${tagsJson}`;
      const uploadRequest = this.format === 'image' ? this.mediaApi.mediaUploadImage({
        file: this.selectedFile,
        paramsString
      }) :
        this.mediaApi.mediaUploadVideo({
          file: this.selectedFile,
          paramsString
        });
      uploadRequest.pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.upload)
      ).subscribe((media: any) => {
        if (media?.mediaId && !this.isModal) {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate", this.form.value.name));
          this.router.navigate(['/medias']);
        } else if (media?.mediaId) {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate", this.form.value.name));
          this.mediaSaved.emit(media);
        }
      });
    } else if (this.media?.filename) {
      this.saveMedia();
    } else {
      this.systemMessageService.error(this.translateConfigService.instant("notifications.error.pleaceSelectFile"));
    }
  }

  gigabytesToBytes(gigabytes) {
    const bytesInGigabyte = 1024 * 1024 * 1024;
    const bytes = gigabytes * bytesInGigabyte;
    return bytes;
  }

  private checkIfStorageIsExceeded() {
    const customer = this.customers.find((c) => c.id === this.customerId || this.form.get('customerId').value);
    return (this.selectedFile.size + customer.currentStorageSize) > this.gigabytesToBytes(customer.storageLimitSize);
  }

  onCancelClick(): void {
    this.cancel.emit();
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  private saveMedia(): void {
    const value = this.form.value;
    this.mediaApi.editMedia({
      body: {
        id: value.id,
        name: value.name,
        tags: value.tags || [],
        customerId: value.customerId,
        clientId: value.client,
        duration: this.videoDuration
      }
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.save))
      .subscribe((mediaFile) => {
        if (!this.isModal) {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate", this.form.value.name));
          this.router.navigate(['/medias']);
        } else {
          this.mediaSaved.emit(mediaFile);
        }
      });
  }

  private initForm(media?: Media): void {
    this.form = this.formBuilder.group({
      id: this.mediaId,
      name: [media?.name, Validators.required],
      client: [this.clients?.filter(c => c.id == media?.client?.id)[0]?.id || null],
      tags: this.formBuilder.control(media?.tags),
      customerId: this.formBuilder.control(this.customerId || media?.customer?.id || this.customerIdModal),
      duration: media?.duration
    });
    if (media?.client?.id) {
      this.form.get('client').setValidators(Validators.required);
    }
    if (this.mediaId && !media) {
      this.form.disable();
    } else {
      setTimeout(() => {
        this.form.enable();
        // disable the customer if the media is already linked to a client
        if (this.isAdmin && media?.client?.id) {
          this.form.get('customerId').disable();
        }
      });
    }
  }

  getTagsAndClientsByCustomer() {
    if (!this.customerId) {
      forkJoin([
        this.tagsApi.getTags({ customerIds: this.customerId ? [this.customerId] : this.form.value.customerId ? [this.form.value.customerId] : [] }),
        this.clientApi.getClients(this.customerId ? [this.customerId] : [this.form.value.customerId])
      ]).pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
        .subscribe(([tags, clients]) => {
          this.tags = tags || [];
          this.clients = clients || [];
        });
    }
  }
}

interface MediaFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  client: FormControl<number>;
  tags: FormControl<Tag[]>;
  customerId: FormControl<number>,
  duration: FormControl<number>
}
