<h1 mat-dialog-title>
  <span>{{ 'screens.screenGroup.deleteScreenGroup' | translate }}</span>
  <a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a></h1>
<div mat-dialog-content
     class="py-2">
  <div>
    <div>{{ 'screens.screenGroup.cantDeleteScreenGroup' | translate }}</div>
  </div>
</div>
<div
  mat-dialog-actions>
  <button mat-button
          mat-stroked-button
          [mat-dialog-close]="true"
          class="btn-cancel"
          type="button">{{'global.cancel' | translate}}</button>
</div>
