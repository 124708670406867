<h1 mat-dialog-title>{{'passwordResetModal.title' | translate}}<a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a></h1>
<div mat-dialog-content
     class="py-2">
  <div>
    <div>{{'passwordResetModal.text' | translate}}</div>
  </div>
  <mat-checkbox color="primary"
                [(ngModel)]="checked"
                class="example-margin mt-2">{{'passwordResetModal.yes' | translate}}</mat-checkbox>
</div>
<div mat-dialog-actions>
  <button mat-button
          mat-dialog-close
          type="button">{{'global.cancel' | translate}}</button>
  <button mat-raised-button
          type="button"
          color="primary"
          [disabled]="!checked"
          (click)="onConfirm()">{{'global.confirm' | translate}}</button>
</div>