import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { PowerSchedule } from '@api/index';
import { IdName } from '@api/models/idname';
import { TrackingEnum } from '@api/models/tracking.enum';
import { TranslationsEnum } from '@api/models/translations-enum';
import { DeviceType } from '@app/shared/models/enums/deviceType.enum';

export interface ScreenServicesStateData {
  advertiserServiceIsAlive: boolean;
  aiServiceIsAlive?: boolean;
  redisIsAlive: boolean;
  aiFps: AiFPS;
  advertiserServiceLastAliveDate?: Date;
  aiServiceLastAliveDate?: Date;
  redisLastAliveDate?: Date;
}

export interface AiFPS {
  fpS_threshold: string;
  fps: string;
}

export enum ScreenAsleepStatusEnum {
  Awake = 1,
  Asleep = 2,
}

export interface ScreenDefaultMedia {
  media: IdName;
  fileName: string;
  orderId: number;
  presentationTime: number;
  thumbnailImageUrl: string;
  imageUrl: string;
  type: number;
}

export interface ServiceStatus {
  name: string;
  isAlive?: boolean;
  lastAlive?: Date;
  translationKey: string;
}

export interface SaveScreenRequest {
  id: number;
  aiVersion?: string;
  advertiserVersion?: string;
  channelId?: number;
  installationTime?: Date;
  macAddress?: string;
  channelDescription?: string;
  humanUniqueIdentifier?: string;
  orientation: number;
  note?: string;
  deviceType: DeviceType;
  trackingType: TrackingEnum;
  placementId?: number;
  customerId: number;
  latitude: number;
  longitude: number;
  powerSchedule?: PowerSchedule[];
  screenDefaultMediaFiles: ScreenDefaultMediaCreateOrUpdate[];
  isUsedForTrackingVehicle?: boolean;
}

export interface ScreenDefaultMediaCreateOrUpdate {
  orderId: number;
  presentationTime: number;
  mediaId: number;
  fileName: string;
}

export enum DeviceSteps {
  GeneralInfo = 'general-info',
  Details = 'details',
}

export enum DeviceStepIndex {
  GeneralInfo = 0,
  Details = 1,
}

export interface WidgetSetup {
  widgetPlacement: WidgetPlacement;
  dayWidgetLanguage?: TranslationsEnum;
  showDayOfWeek: boolean;
  showDateTime: boolean;
  showWeather: boolean;
  scrollInfoBanner: boolean;
  bannerText?: string;
  backgroundColor: string;
}

export enum WidgetPlacement {
  NotSet = 0,
  Top = 1,
  Bottom = 2,
}

export const WidgetPlacementLabels = {
  [WidgetPlacement.NotSet]: 'createScreen.notSet',
  [WidgetPlacement.Top]: 'createScreen.top',
  [WidgetPlacement.Bottom]: 'createScreen.bottom',
};
export interface generalInfoFormGroup {
  id: FormControl<number>;
  humanUniqueIdentifier: FormControl<string>;
  channelId: FormControl<number>;
  channelDescription: FormControl<any>;
  deviceType: FormControl<number>;
  note: FormControl<string>;
  customerId: FormControl<number>;
  isUsedForTrackingVehicle: FormControl<boolean>;
  shouldSendSmsOnStatusChange: FormControl<boolean>;
  smsNotificationsConfigurations?: FormGroup<any>;
  contactPersonIds?: FormControl<any>;
}

export interface detailsFormGroup {
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  orientation: FormControl<number>;
  aiVersion: FormControl<string>;
  advertiserVersion: FormControl<string>;
  tracking: FormControl<number>;
  scheduleOptions: FormControl<boolean>;
  defaultMediaFiles: FormArray<any>;
  monday?: FormGroup<any>;
  tuesday?: FormGroup<any>;
  wednesday?: FormGroup<any>;
  thursday?: FormGroup<any>;
  friday?: FormGroup<any>;
  saturday?: FormGroup<any>;
  sunday?: FormGroup<any>;
}

export interface widgetsFormGroup {
  isEnabled: FormControl<boolean>,
  widgetsGroup: FormGroup<widgetsGroup>,
}

export interface widgetsGroup {
  widgetPlacement: FormControl<number>,
  showDayOfWeek: FormControl<boolean>,
  dayWidgetLanguage: FormControl<number>,
  showDateTime: FormControl<boolean>,
  showWeather: FormControl<boolean>,
  scrollInfoBanner: FormControl<boolean>,
  bannerText: FormControl<string>,
  backgroundColor: FormControl<string>
}

export interface ScreenSmsNotifications{
  smsAlertOnDeviceDegradation: boolean;
  smsAlertOnDeviceRestored: boolean;
  startTimeSmsNotifications: string,
  endTimeSmsNotifications: string,
  smsContactPersons: IdName[];
  timeZone: string
}

export interface ScreenSmsNotificationsRequest{
  smsStartHour: number;
  smsStartMinutes: number;
  smsEndHour : number;
  smsEndMinutes : number;
  smsAlertOnDeviceDegradation: boolean;
  smsAlertOnDeviceRestored: boolean;
  utcOffset: string
}

export interface ScreenSmsNotifications{
  smsAlertOnDeviceDegradation: boolean;
  smsAlertOnDeviceRestored: boolean;
  startTimeSmsNotifications: string,
  endTimeSmsNotifications: string,
  smsContactPersons: IdName[];
  timeZone: string
}

export interface ScreenSmsNotificationsRequest{
  smsStartHour: number;
  smsStartMinutes: number;
  smsEndHour : number;
  smsEndMinutes : number;
  smsAlertOnDeviceDegradation: boolean;
  smsAlertOnDeviceRestored: boolean;
  utcOffset: string
}