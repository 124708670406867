<div class="flex justify-between items-start bg-gray-50 p-4 gap-4 rounded-l">
    <form class="flex flex-wrap gap-4 input-override" [formGroup]="formGroup">
        <flow-form-input label="media.filters.text" formControlName="text"></flow-form-input>
        <div class="flex flex-col"
            *ngIf="customers.length > 0 && isAdmin">
            <mat-label>{{'media.filters.customers' | translate}}</mat-label>
            <mat-form-field class="w-full">
                <mat-select formControlName="customerIds"
                            multiple
                            (selectionChange)="onCustomerSelect($event)">
                    <mat-option *ngFor="let customer of customers"
                                [value]="customer.id">
                        {{customer.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-col">
            <mat-label>{{'media.filters.clients' | translate}}</mat-label>
            <mat-form-field class="w-full">
                <mat-select formControlName="clientIds"
                            multiple>
                    <mat-option *ngFor="let client of clients"
                                [value]="client.id">
                        {{client.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-col form-field-wrapper">
            <mat-label>{{ "poi.form.datePickerLabel" | translate }}</mat-label>
            <mat-form-field appearance="outline" class="w-full mr-3">
              <mat-date-range-input [rangePicker]="picker" formGroupName="created">
                <input
                  [placeholder]="'media.form.datePickerFrom' | translate"
                  formControlName="start"
                  matStartDate />
                <input
                  [placeholder]="'media.form.datePickerTo' | translate"
                  formControlName="end"
                  matEndDate />
                </mat-date-range-input>
                <button mat-icon-button
                        matSuffix
                        type="button"
                        (click)="clearDatePicker()">
                    <mat-icon>clear</mat-icon>
                </button>
              <mat-datepicker-toggle
                [for]="picker"
                matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>      
    </form>
    <div class="flex gap-4 mt-8">
      <button class="self-end"
                  type="button"
                  mat-stroked-button
                  (click)="clearAll()">
                  {{'media.filters.clearAll' | translate}}
      </button>
      <button class="btn-save self-end"
                  type="button"
                  color="primary"
                  mat-flat-button
                  (click)="submit()">
              {{ 'media.filters.showNumberOfMedias' | translate: {number: ''} }}
      </button>
    </div>
</div>