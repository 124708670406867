import { CountryEnum } from "@api/models/countries";

export const CountryTranslations = {
  [CountryEnum.England]: 'countries.england',
  [CountryEnum.Sweden]: 'countries.sweden',
  [CountryEnum.Norway]: 'countries.norway',
  [CountryEnum.Denmark]: 'countries.denmark',
  [CountryEnum.Finland]: 'countries.finland',
  [CountryEnum.Estonia]: 'countries.estonia',
  [CountryEnum.Germany]: 'countries.germany',
  [CountryEnum.Spain]: 'countries.spain',
  [CountryEnum.Netherlands]: 'countries.netherlands',
  [CountryEnum.Belgium]: 'countries.belgium',
  [CountryEnum.Botswana]: 'countries.botswana',
  [CountryEnum.Israel]: 'countries.israel',
  [CountryEnum.Ireland]: 'countries.ireland',
  [CountryEnum.Indonesia]: 'countries.indonesia',
  [CountryEnum.HongKong]: 'countries.hongkong',
  [CountryEnum.France]: 'countries.france',
  [CountryEnum.UnitedKingdom]: 'countries.unitedkingdom',
  [CountryEnum.Czechia]: 'countries.czechia',
  [CountryEnum.UnitedStates]: 'countries.unitedstates',
  [CountryEnum.Malta]: 'countries.malta',
  [CountryEnum.Austria]: 'countries.austria',
  [CountryEnum.Singapore]: 'countries.singapore',
  [CountryEnum.Switzerland]: 'countries.switzerland',
  [CountryEnum.Portugal]: 'countries.portugal',
  [CountryEnum.Poland]: 'countries.poland',
  [CountryEnum.Australia]: 'countries.australia',
  [CountryEnum.UnitedArabEmirates]: 'countries.unitedarabemirates',
  [CountryEnum.Italy]: 'countries.italy',
  [CountryEnum.Canada]: 'countries.canada',
  [CountryEnum.CaboVerde]: 'countries.caboverde',
  [CountryEnum.China]: 'countries.china',
  [CountryEnum.Cyprus]: 'countries.cyprus',
  [CountryEnum.Luxembourg]: 'countries.luxembourg',
  [CountryEnum.Montenegro]: 'countries.montenegro',
  [CountryEnum.NewZealand]: 'countries.newzealand',
  [CountryEnum.Slovenia]: 'countries.slovenia',
  [CountryEnum.Thailand]: 'countries.thailand',
  [CountryEnum.AlandIslands]: 'countries.alandislands',
  [CountryEnum.Russia]: 'countries.russia',
  [CountryEnum.Iceland]: 'countries.iceland',
  [CountryEnum.Taiwan]: 'countries.taiwan',
  [CountryEnum.Malaysia]: 'countries.malaysia',
};

export const CountryCodes = {
  [CountryEnum.England]: 'EN',
  [CountryEnum.Sweden]: 'SE',
  [CountryEnum.Norway]: 'NO',
  [CountryEnum.Denmark]: 'DK',
  [CountryEnum.Finland]: 'FI',
  [CountryEnum.Estonia]: 'EE',
  [CountryEnum.Germany]: 'DE',
  [CountryEnum.Spain]: 'ES',
  [CountryEnum.Netherlands]: 'NL',
  [CountryEnum.Belgium]: 'BE',
  [CountryEnum.Botswana]: 'BW',
  [CountryEnum.Israel]: 'IL',
  [CountryEnum.Ireland]: 'IE',
  [CountryEnum.Indonesia]: 'ID',
  [CountryEnum.HongKong]: 'HK',
  [CountryEnum.France]: 'FR',
  [CountryEnum.UnitedKingdom]: 'GB',
  [CountryEnum.Czechia]: 'CZ',
  [CountryEnum.UnitedStates]: 'US',
  [CountryEnum.Malta]: 'MT',
  [CountryEnum.Austria]: 'AT',
  [CountryEnum.Singapore]: 'SG',
  [CountryEnum.Switzerland]: 'CH',
  [CountryEnum.Portugal]: 'PT',
  [CountryEnum.Poland]: 'PL',
  [CountryEnum.Australia]: 'AU',
  [CountryEnum.UnitedArabEmirates]: 'AE',
  [CountryEnum.Italy]: 'IT',
  [CountryEnum.Canada]: 'CA',
  [CountryEnum.CaboVerde]: 'CV',
  [CountryEnum.China]: 'CN',
  [CountryEnum.Cyprus]: 'CY',
  [CountryEnum.Luxembourg]: 'LU',
  [CountryEnum.Montenegro]: 'ME',
  [CountryEnum.NewZealand]: 'NZ',
  [CountryEnum.Slovenia]: 'SI',
  [CountryEnum.Thailand]: 'TH',
  [CountryEnum.AlandIslands]: 'AX',
  [CountryEnum.Russia]: 'RU',
  [CountryEnum.Iceland]: 'IS',
  [CountryEnum.Taiwan]: 'TW',
  [CountryEnum.Malaysia]: 'MY',
};