import { Directive, ElementRef, OnDestroy, Renderer2, HostListener, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[flowStickyHeader]',
})
export class StickyHeaderDirective implements OnDestroy, AfterViewInit {
  initialTop: number = 0;
  isSticky: boolean = false;
  elementClasses = [
    'fixed',
    'top-[50px]',
    'z-50',
    'rounded',
    'shadow-xl',
    'border',
    'border-neutral-200',
    'overflow-hidden',
    'transition-all',
    'duration-100',
    'ease-in-out',
  ];
  toggleContainer: any;
  toggleButton: any;
  isExpanded: boolean = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Angular's Router sometimes displays the new page and the old page in the same time for a short period.
    // This causes the DOM to not be in its final state when you're calculating the element's position after navigation.
    // setTimeout is a workaround to ensure the DOM is in its final state before calculating the element's position.
    setTimeout(() => {
      this.initialTop = this.el.nativeElement.getBoundingClientRect().top + window.scrollY;
      this.checkStickyState();
      this.createToggleContainer();
      this.createToggleButton();
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.checkStickyState();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isSticky) {
      const parentWidth = this.el.nativeElement.parentElement.offsetWidth;
      this.renderer.setStyle(this.el.nativeElement, 'width', `${parentWidth}px`);
    }
  }

  createToggleContainer() {
    this.toggleContainer = this.renderer.createElement('div');
    // Insert the container as first child
    this.renderer.insertBefore(this.el.nativeElement, this.toggleContainer, this.el.nativeElement.firstChild);

    // Add classes to container
    const containerClasses = ['relative', 'w-full', 'h-10', 'bg-gray-50', 'border-b', 'border-neutral-200', 'z-50', 'hidden', 'rounded-t'];
    containerClasses.forEach(className => this.renderer.addClass(this.toggleContainer, className));
  }

  createToggleButton() {
    // Create the button
    this.toggleButton = this.renderer.createElement('button');

    // Add classes to button
    const buttonClasses = ['absolute', 'right-1', 'top-1', 'h-[30px]', 'w-[30px]', 'cursor-pointer', 'hover:bg-gray-100', 'rounded'];
    buttonClasses.forEach(className => this.renderer.addClass(this.toggleButton, className));

    // Add arrow icon to button
    this.renderer.setProperty(this.toggleButton, 'innerHTML', '▼');

    // Add click event to button
    this.renderer.listen(this.toggleButton, 'click', () => this.toggleContent());

    // Append button to container
    this.renderer.appendChild(this.toggleContainer, this.toggleButton);
  }

  toggleContent() {
    this.isExpanded = !this.isExpanded;
    const height = this.isExpanded ? 'auto' : '40px';
    const arrowSymbol = this.isExpanded ? '▼' : '▲';
    this.renderer.setProperty(this.toggleButton, 'innerHTML', arrowSymbol);
    this.renderer.setStyle(this.el.nativeElement, 'height', height);
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'all 0.3s ease-in-out');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  checkStickyState() {
    const scrollPosition = window.pageYOffset;

    if (scrollPosition > this.initialTop && !this.isSticky) {
      this.addSticky();
    } else if (scrollPosition <= this.initialTop && this.isSticky) {
      this.removeSticky();
    }
  }

  addSticky() {
    const element = this.el.nativeElement;
    const parentWidth = element.parentElement.offsetWidth;
    const elementHeight = element.offsetHeight;

    this.renderer.setStyle(element, 'width', `${parentWidth}px`);

    this.elementClasses.forEach(className => {
      this.renderer.addClass(element, className);
    });

    const placeholder = this.renderer.createElement('div');
    this.renderer.setStyle(placeholder, 'height', `${elementHeight}px`);
    this.renderer.setAttribute(placeholder, 'id', 'sticky-placeholder');
    this.renderer.insertBefore(element.parentElement, placeholder, element);
    this.renderer.removeClass(this.toggleContainer, 'hidden');
    this.renderer.setProperty(this.toggleButton, 'innerHTML', '▼');

    this.isSticky = true;
  }

  removeSticky() {
    this.restoreOriginalState();

    this.renderer.addClass(this.toggleContainer, 'hidden');
    this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
    this.isExpanded = true;

    const placeholder = document.getElementById('sticky-placeholder');
    if (placeholder) {
      this.renderer.removeChild(placeholder.parentElement, placeholder);
    }

    this.isSticky = false;
  }

  restoreOriginalState() {
    const element = this.el.nativeElement;

    this.elementClasses.forEach(className => {
      this.renderer.removeClass(element, className);
    });

    this.renderer.removeStyle(element, 'width');
  }

  ngOnDestroy() {
    this.restoreOriginalState();
    this.initialTop = 0;
  }
}
