export enum AdvertisingType {
  Other,
  RetailAdvertising,
  BrandAdvertising,
  Goverment,
  Gambling,
  Alcohol
}

export const AdvertisingTypeTranslations = {
  [AdvertisingType.RetailAdvertising]: 'client.enum.retailAdvertising',
  [AdvertisingType.BrandAdvertising]: 'client.enum.branAdvertising',
  [AdvertisingType.Goverment]: 'client.enum.government',
  [AdvertisingType.Gambling]: 'client.enum.gambling',
  [AdvertisingType.Alcohol]: 'client.enum.alcohol',
  [AdvertisingType.Other]: 'client.enum.other',
};
