import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import { Client } from "../models/client";
import { PaginatorParams } from "@api/models/paginator";

@Injectable({
  providedIn: 'root',
})
export class ClientApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetClientsPath = '/clients';
  getClients(customerIds?: number[]): Observable<any> {
    const params = customerIds ? { customerIds: customerIds } : {};
    return this.http.get<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}/all`, { params });
  }

  getClientsWithPaginationAndSorting(params: PaginatorParams, customerId?: number): Observable<any> {
    let requestParams: any = {
      'pageNumber': params.pageNumber || 0,
      'pageSize': params.pageSize || 10,
      'sortBy': params.sortBy || 'id',
      'sortDirection': params.sortDirection || 'asc',
    }
    if (customerId) {
      requestParams.customerId = customerId;
    }
    const body = {
      params: requestParams
    }
    return this.http.get<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}`, body);
  }

  getClientById(clientId: number): Observable<any> {
    return this.http.get<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}/clients/${clientId}`);
  }
  createClient(client): Observable<any> {
    client.country = client.country.alpha2Code;
    return this.http.post<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}`, client);
  }
  editClient(client, id: number): Observable<any> {
    client.id = id;
    client.country = client.country.alpha2Code;
    return this.http.put<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}`, client);
  }
  deleteClient(clientId: number): Observable<any> {
    return this.http.delete<Client>(`${this.rootUrl}${ClientApi.GetClientsPath}/${clientId}`);
  }
}
