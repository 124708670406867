<h1 mat-dialog-title>{{title | translate}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="grid grid-cols-1 gap-4 input-override">
      <div class="flex flex-col" *ngIf="data.isAdmin && !data.isCustomerPage">
        <mat-label>{{'contactPerson.modal.form.selectCustomer' | translate}} *</mat-label>
        <mat-form-field class="w-full" appearance="outline">
          <mat-select formControlName="customerId" (selectionChange)="onSelectedCustomer()">
            <mat-option *ngFor="let customer of customers"
                        [value]="customer.id">
              {{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-col" *ngIf="form && (form.get('customerId').value || (data.isCustomerPage && data.currentCustomerId)) && !isLimitReached">
        <mat-label>{{'contactPerson.modal.form.selectChannels' | translate}}</mat-label>
        <mat-form-field class="w-full" appearance="outline">
          <mat-select formControlName="channelIds"
                      multiple>
            <div class="select-deselect"
                (click)="toggleSelectDeselectChannels(true)">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        size="md"
                        iconName="check"></app-icon>
              <span class="ml-1">{{'global.selectAll' | translate}}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="select-deselect"
                (click)="toggleSelectDeselectChannels(false)">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        size="md"
                        iconName="close"></app-icon>
              <span class="ml-1">{{'global.deselectAll' | translate}}</span>
            </div>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let channel of channels"
                        [value]="channel.id">
              {{channel.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col" *ngIf="form && (form.get('customerId').value || data.isCustomerPage) && !isLimitReached">
        <mat-label>{{'contactPerson.modal.form.name' | translate}} *</mat-label>
        <mat-form-field class="w-full" appearance="outline">
          <input matInput type="text" formControlName="name">
        </mat-form-field>
      </div>
      <div class="flex flex-col" *ngIf="form && (form.get('customerId').value || data.isCustomerPage) && !isLimitReached">
        <mat-label>{{'contactPerson.modal.form.ePostAddress' | translate}} *</mat-label>
        <mat-form-field class="w-full" appearance="outline" >
          <input matInput
                type="mail"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                formControlName="email">
                <mat-error *ngIf="form.get('email')?.invalid">{{ 'global.invalidEmail' | translate }}</mat-error>
          </mat-form-field>
      </div>
      <div class="flex flex-col"*ngIf="form && (form.get('customerId').value || data.isCustomerPage) && !isLimitReached">
        <mat-label>{{'contactPerson.modal.form.telephone' | translate}} *</mat-label>
        <mat-form-field class="w-full mt-1" appearance="outline">
          <ngx-mat-intl-tel-input #phone
              [preferredCountries]="['se']"
              [enableSearch]="true"
              [enablePlaceholder]="true"
              formControlName="phoneNumber">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="form.get('phoneNumber')?.errors?.required">Required Field</mat-error>
          <mat-error *ngIf="form.get('phoneNumber')?.errors?.validatePhoneNumber">{{'global.invalidPhoneNumber' | translate}}</mat-error>
        </mat-form-field>
        <mat-hint>e.g. {{phone.selectedCountry.placeHolder}}</mat-hint>
      </div>
    </div>
  </form>
  <div style="color: red;" *ngIf="isLimitReached">{{ 'contactPerson.limit' | translate: {numberOfContactPersons: limit} }}</div>
</div>
<div mat-dialog-actions>
  <button mat-button
          type="button"
          (click)="onCancel()">{{'global.cancel' | translate}}</button>
  <button mat-raised-button
          type="button"
          (click)="onSave()"
          color="primary"
          mat-flat-button
          [disabled]="!form.valid">{{'global.save' | translate}}</button>
</div>
