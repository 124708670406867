import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ContactPerson } from 'src/_api/models/contact-person';

@Component({
  selector: 'flow-multi-select-chips',
  templateUrl: './multi-select-chips.component.html',
  styleUrls: ['./multi-select-chips.component.scss']
})
export class MultiSelectChipsComponent implements OnInit {

  @Input() control?: AbstractControl;
  @Input() matSelect?: MatSelect;
  @Input() displayKey?: string;
  @Input() displayMethod?: (item: any) => string;
  @Output() removed = new EventEmitter<any>();

  constructor() { }

  get items(): any[] {
    return this.control ? this.control.value : this.matSelect?.ngControl?.value;
  }

  ngOnInit(): void {
    if (!this.control && !this.matSelect) {
      throw 'formControl or matSelect directive is missing';
    }
  }

  onItemRemove(item: ContactPerson): void {
    item.isRemoved = true;
    if (this.control) {
      const items: any[] = this.control.value;
      this.control.setValue(items.filter(i => i !== item));
    } else if (this.matSelect) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
      this.matSelect.ngControl.control.patchValue(this.items);
    }
    this.removed.emit(item);
  }
}
