<div class="flex items-center justify-between">
        <h2 class="reach-section-title">{{ 'peopleCount.moodEachDay' | translate }}</h2>
        <div>
                <button mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                        <div class="flex flex-col">
                                <form [formGroup]="form"
                                      class="p-2">
                                        <mat-form-field appearance="outline"
                                                        class="w-full">
                                                <mat-label>{{'peopleCount.date' | translate}}</mat-label>
                                                <input matInput
                                                       formControlName="date"
                                                       [matDatepicker]="picker"
                                                       (dateChange)="dateChange($event)">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="w-full"
                                                        (click)="$event.stopPropagation()">
                                                <mat-label>{{'sidebar.screens' | translate}}</mat-label>
                                                <mat-select formControlName="screenIds" multiple (selectionChange)="screenSelectionChange($event)">
                                                        <mat-option *ngFor="let screen of screens" [value]="screen.id">
                                                                {{screen.name}}
                                                        </mat-option>
                                                </mat-select>
                                        </mat-form-field>
                                </form>
                        </div>
                </mat-menu>
        </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between py-4">
       <span>{{isoDate}}</span>
</div>
<mat-divider></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate"
     class="flex justify-center flex-col items-center mt-5">
        <img src="../../../../../assets/no-chart-data.png" />
        <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
        <canvas baseChart
                height="50"></canvas>
</div>
<ng-template #chartTemplate>
        <div class="flex gap-14">
                <div class="custom-legend w-[115px] flex flex-col gap-[1px]">
                        <div *ngFor="let legend of legends"
                              (click)="toggleDataset(legend.datasetIndex)"
                              [ngClass]="{'line-through': legend.hidden}"
                              [ngStyle]="{'border-left': '5px solid ' + legend.fillStyle}"
                              class="w-full h-8 px-[16px] py-[6px] justify-end items-center border-b border-b-gray-200 inline-flex gap-2 cursor-pointer">
                              <span class="h-5 flex items-center text-black text-xs font-normal leading-none">{{legend.text}}</span>
                              <app-icon [iconName]="legend.icon" size="md" stroke="#202020"></app-icon>
                        </div>
                </div>
                <div class="w-full">
                        <canvas [data]="chartData"
                                baseChart
                                type='line'
                                [options]="chartOptions"
                                height="90vh">
                        </canvas>
                </div>
        </div>
</ng-template>
