<div class="flex items-center justify-between">
        <div>
                <h2 class="reach-section-title">{{ "exposures.totalNumberOfExposures" | translate }}</h2>
        </div>
        <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                        <div class="date-picker-wrapper">
                                <week-picker class="flex-1"
                                        [range]="range"
                                        [label]="'peopleCount.week' | translate"
                                        [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        (weekSelected)="onWeekSelected()">
                                </week-picker>
                                <week-picker class="flex-1"
                                        [range]="rangeCompare"
                                        [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        [disabled]="disableWeekToCompare"
                                        [disableWeekStartDate]="range.value.start"
                                        [showClearBtn]="true"
                                        [label]="'peopleCount.weekToCompare' | translate"
                                        (weekSelected)="onWeekCompareSelected()">
                                </week-picker>
                        </div>
                </mat-menu>
        </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between selected-day">
        <div>
                <div *ngIf="!compareChart && totalNumberOfExposures">
                        {{ weekStartDate | date: 'yyyy-MM-dd' }} - {{ weekEndDate | date: 'yyyy-MM-dd' }}
                </div>
                <div *ngIf="compareChart">
                        <span class="pr-8">{{ "global.compareWeeks" | translate }}</span>
                        <span class="pr-5">{{ firstWeek }}</span>
                        <span>{{ secondWeek }}</span>
                </div>
        </div>
</div>
<mat-divider *ngIf="totalNumberOfExposures"></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate" class="flex justify-center flex-col items-center mt-5">
        <img src="../../../../../assets/no-chart-data.png" />
        <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
        <canvas baseChart height="50"></canvas>
</div>


<ng-template #chartTemplate>
        <canvas baseChart
                [data]="chartData"
                [type]="'bar'"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                height="90vh">
        </canvas>
</ng-template>
