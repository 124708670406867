import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiConfiguration } from "@api/api-configuration";
import { BaseService } from "@api/base-service";
import { Observable } from "rxjs";
import { BatchPermissionAssignmentRequest, CustomerGroups, PermissionsConfig, User  } from "@api/index";
import { CreateGroupRequest } from "@api/models/permissions/group-roles";

@Injectable({
  providedIn: 'root'
})
export class GroupApi extends BaseService {

  private baseUrl = `${this.rootUrl}/groups`;
  
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  getGroupsForCustomers(customerIds: number[]): Observable<CustomerGroups[]> {
    let params = new HttpParams();
    customerIds.forEach(id => {
      params = params.append('customerIds', id.toString());
    });

    return this.http.get<CustomerGroups[]>(`${this.baseUrl}/customers`, { params });
  }

  getGroupsForCustomer(customerId: number): Observable<PermissionsConfig> {
    return this.http.get<PermissionsConfig>(`${this.baseUrl}/customers/${customerId}`);
  }

  assignGroupToUsers(groupId: number, userIds: number[]): Observable<void> {
    const request = { UserIds: userIds };
    return this.http.post<void>(`${this.baseUrl}/${groupId}/assign`, request);
  }

  editGroup(groupId: number, groupName: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${groupId}`, { groupName });
  }

  createGroup(group: CreateGroupRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}`,  group );
  }

  getGroupNameById(groupId: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/${groupId}`);
  }
  
  deleteGroup(groupId: number) {
    return this.http.delete(`${this.baseUrl}/${groupId}`);
  }

  getUsersInGroup(groupId: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}/${groupId}/users`);
  }

  removeUserFromGroup(groupId: number, userId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${groupId}/users/${userId}`);
  }

  assignPermissionsToGroups(request: BatchPermissionAssignmentRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/batch`, request);
  }
}