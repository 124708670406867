<div class="w-full h-full flex flex-col gap-8" *ngIf="isReady">
    <div class="w-full text-[#202020] text-2xl font-semibold leading-normal">{{(folderId ? 'editFolder.title' : 'createFolder.title') | translate}}</div>
    <div class="h-full w-full justify-start items-start gap-10 inline-flex overflow-auto pr-[2rem]">
      <div class="h-full flex-col justify-start items-start gap-10 inline-flex">
        <form [formGroup]="form">
          <div class="w-[324px] flex-col justify-center items-start gap-4 flex">
              <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
                  <div class="justify-start items-center gap-2 flex">
                      <app-icon iconName="annotation-info"
                                size="md"
                                class="w-8 h-8 bg-[#bfd6e4] rounded-full flex justify-center items-center"
                                stroke="#598FAE"></app-icon>
                  </div>
                  <div class="text-[#202020] text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}</div>
              </div>
              <div class="input-dimensions">
                <mat-label>{{ 'media.form.customer' | translate }} *</mat-label>
                <mat-form-field class="w-full">
                  <mat-select formControlName="customerId"
                              placeholder="{{'media.form.customerPlaceholder' | translate}}"
                              (selectionChange)="getDataByCustomer()">
                    <mat-option *ngFor="let customer of customers"
                                [value]="customer.id">
                      {{ customer.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="input-dimensions">
                <mat-label>{{'media.table.name' | translate}} *</mat-label>
                <mat-form-field class="w-full">
                  <input matInput
                         type="text"
                         formControlName="name">
                </mat-form-field>
              </div>
              <div class="input-dimensions">
                <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
                <mat-form-field class="w-full">
                  <flow-multi-select-autocomplete formControlName="tags"
                                                  [options]="tags"
                                                  [displayFn]="displayTag"
                                                  [newOptionFn]="newTag"></flow-multi-select-autocomplete>
                </mat-form-field>
              </div>
          </div>
        </form>
      </div>
      <div class="w-full justify-between items-start flex">
          <div class="self-stretch p-4 bg-white rounded flex-col justify-center items-start gap-2 inline-flex">
              <div class="px-2 border-l-4 border-[#598fae] justify-center items-start gap-2 inline-flex">
                  <div class="w-[330px] text-[#595959] text-xs font-normal leading-[18px]">{{'global.note' | translate}}</div>
              </div>
              <div class="w-[330px] text-[#595959] text-xs font-normal leading-[18px]">{{(folderId ? 'editFolder.note' : 'createFolder.note') | translate}}</div>
          </div>
          <div class="self-stretch justify-end items-start gap-4 inline-flex">
              <div (click)="onCancelClick()" class="p-4 bg-white rounded border border-[#bbbbbb] justify-start items-center gap-2 flex cursor-pointer">
                  <div class="text-[#595959] text-sm font-medium leading-[14px]">{{'global.cancel' | translate}}</div>
              </div>
              <div (click)="onSaveClick()" [ngClass]="{'disabled': form.invalid }" class="p-4 bg-[#1f5792] rounded justify-start items-center gap-2 flex cursor-pointer">
                  <div class="text-white text-sm font-medium leading-[14px]">{{ (folderId ? 'global.save' : 'global.add') | translate}}</div>
              </div>
          </div>
      </div>
    </div>
  </div>