import { BookEffects } from './book/book.effects';
import { bookReducer } from './book/book.reducer';

// import { UserEffects } from './user/user.effects';
// import { userReducer } from './user/user.reducer';

import { BookingState } from "./book/book.reducer";
// import { UserState } from "./user/user.reducer";

export const storeReducers = {
  booking: bookReducer,
  // user: userReducer
};

export const storeEffects = [
  BookEffects, 
  // UserEffects
];

export interface AppState {
  book: BookingState;
  // user: UserState;
};