import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserListItem } from '@api/models/user';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { EnumService } from '@app/core/services/enum.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginationResult, PaginatorParams } from '@api/models/paginator';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { UsersApi } from '@api/index';
import { GenericDialogComponent } from '@app/shared/generic-dialog/generic-dialog.component';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { PermissionsService } from '@app/core/services/permissions.service';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'flow-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['id', 'email', 'name', 'lastSeen', 'actions'];
  dataSource = new MatTableDataSource<UserListItem>();
  hideBox = false;
  isAdmin: boolean = false;
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'email',
    sortDirection: 'asc'
  }
  pageSizeOptions = [5, 10, 25, 100];
  totalCount: number = 0;
  customerId;

  constructor(
    private userApi: UsersApi,
    private enumService: EnumService,
    public dialog: MatDialog,
    private router: Router,
    public platform: Platform,
    private translateConfigService: TranslateConfigService,
    private permissionsService: PermissionsService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = user.roleId == UserRoleEnum.Admin && !user?.currentCustomerId && !user?.customerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    if (this.isAdmin) {
      this.displayedColumns.splice(3, 0, 'role','customer');
    }
    this.getUsers();
   }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getUsers(): void {
    let requestParams:any = this.params;
    if (this.customerId) requestParams.customerId = this.customerId;
    this.userApi.getActiveUsers(requestParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: PaginationResult<UserListItem>) => {
        this.totalCount = response.totalCount;
        const roles = this.enumService.userRoles;
        const users = response.items?.map(u => {
          const user = u as UserListItem;
          user.role = roles.find(role => role.id === user.roleId) || roles.find(role => role.id === 2);
          user.customers = user.customers.map(customer => ({
            id: customer.id,
            name: `${customer.name} (${customer?.profileName})` ,
          }));
          user.profiles = user.profiles.map(profile => ({
            id: profile.id,
            name: profile.customerName ? `${profile.name} (${profile.customerName})` : profile.name
          }));
          user.lastSeen = u.lastSeen != null ? DateTime.fromISO(u.lastSeen).toLocal().toFormat('yyyy-MM-dd HH:mm') : "";

          return user;
        });
        this.dataSource = new MatTableDataSource(users);
      });
  }

  onDeleteClick(user: UserListItem): void {
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveUser(user.id),
        logic: true

      }
    })
  }

  onRemoveUser(userId) {
    this.userApi.deleteUser({ userId })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialog.closeAll();
        this.getUsers();
      });
  }

  redirectToEditPage(userId: UserListItem): void {
    if (this.permissionsService.hasPermissions(['UpdateUsersAdmin'])) {
      this.router.navigate(['/users', userId.id]);
    }
  }

  handlePageEvent(event: PageEvent): void {
    this.params.pageNumber = event.pageIndex;
    this.params.pageSize = event.pageSize;
    this.getUsers();
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getUsers();
  }

  openCustomerDialog(customers: any[]): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '400px',
      data: {
        title: this.translateConfigService.instant('users.table.customer'),
        type: 'list',
        items: customers.map(customer => customer.name)
      }
    });
  }

  openProfilesDialog(customers: any[]): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '400px',
      data: {
        title: this.translateConfigService.instant('users.table.name'),
        type: 'list',
        items: customers.map(customer => customer.name)
      }
    });
  }
}