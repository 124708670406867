import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'flow-helper-card',
  templateUrl: './helper-card.component.html',
  styleUrls: ['./helper-card.component.scss']
})
export class HelperCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() consentKey: string

  constructor() { }

  ngOnInit(): void {
  }

  hideHelperCard() {
    document.getElementById('helper-card').style.display = 'none';
  }

  toggleConsent(event) {
    if (event.checked) {
      localStorage.setItem(this.consentKey, 'true');
    } else {
      localStorage.removeItem(this.consentKey);
    }
  }

  navigateToWiki() {
    window.open(environment.wikiUrl, '_blank').focus();
  }

}
