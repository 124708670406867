import { createAction, createActionGroup, props } from '@ngrx/store';
import { IdName } from '@api/models/idname';
import { BookingRouterState } from '@app/shared/enums/booking-router-state.enum';

export const BookSetInfoActions = createAction('[Booking] Set Booking Info', props<{ data: any }>());

export const BookCreateActions = createActionGroup({
  source: 'Booking',
  events: {
    'Create Booking': props<{ engagementId: number; isCopy: boolean }>(),
    'Create Booking Success': props<{ data: any; bookState: BookingRouterState; isCopy: boolean; engagementId: number }>(),
    'Create Booking Failure': props<{ error: any }>(),
  },
});

export const BookEditActions = createActionGroup({
  source: 'Booking',
  events: {
    'Edit Booking': props<{ engagementId: number; bookState: BookingRouterState }>(),
    'Edit Booking Success': props<{ data: any; bookState: BookingRouterState }>(),
    'Edit Booking Failure': props<{ error: any }>(),
  },
});

export const BookNewBookingActions = createActionGroup({
  source: 'Booking',
  events: {
    'New Booking': props<{ activeStep: number; engagementType: number; generalInfoForm: any; filtersForm: any }>(),
    'New Booking Success': props<{
      data: any;
      bookState: BookingRouterState;
      engagementId: number;
      engagementType: number;
      continueCreateMode: boolean;
    }>(),
    'New Booking Failure': props<{ error: any }>(),
  },
});

export const BookCopyBookingActions = createActionGroup({
  source: 'Booking',
  events: {
    'Copy Booking': props<{ engagementId: number; engagementType: number; generalInfoForm: any; filtersForm: any }>(),
    'Copy Booking Success': props<{ data: any }>(),
    'Copy Booking Failure': props<{ error: any }>(),
  },
});

export const BookMediaActions = createActionGroup({
  source: 'Booking',
  events: {
    'Load Booking Media': props<{ engagementId: number; engagementType: number }>(),
    'Load Booking Media Success': props<{ data: any }>(),
    'Load Booking Media Failure': props<{ error: any }>(),
  },
});

export const BookResetActions = createAction('[Booking] Reset Booking');

export const BookActiveStep = createAction('[Booking] Set Active Step', props<{ activeStep: number }>());

export const BookFormActions = createAction(
  '[Booking] Set Book Form',
  props<{
    customer?: number;
    channels?: string[];
    placements?: IdName[];
    name?: string;
  }>()
);

export const BookPlacements = createAction('[Booking] Set Placements', props<{ placements: any }>());