<form [formGroup]="form">
  <h1 mat-dialog-title
      *ngIf="!folderId">{{'createFolder.title' | translate}}</h1>
  <h1 mat-dialog-title
      *ngIf="folderId">{{'editFolder.title' | translate}}</h1>
  <mat-form-field *ngIf="!folderId && isAdmin"
                  class="w-full">
    <mat-label>{{'createFolder.customer' | translate}}</mat-label>
    <mat-select formControlName="customerId">
      <mat-option *ngFor="let customer of customers"
                  [value]="customer.id">
        {{customer.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{'createFolder.name' | translate}}</mat-label>
    <input matInput
           type="text"
           formControlName="name">
  </mat-form-field>

  <mat-form-field *ngIf="form.get('customerId').value"
                  class="w-full">
    <mat-label>{{'createFolder.tags' | translate}}</mat-label>
    <flow-multi-select-autocomplete formControlName="tags"
                                    [options]="tags"
                                    [displayFn]="displayTag"
                                    [newOptionFn]="newTag"></flow-multi-select-autocomplete>
  </mat-form-field>
  <div class="flex items-center justify-end mt-8">
    <button mat-button
            type="button"
            mat-dialog-close>{{'global.cancel' | translate}}</button>
    <a (click)="onSaveClick()"
       mat-flat-button
       color="accent">
      <mat-icon class="mr-2"
                inline="true">add</mat-icon>
      {{folderId ? ('global.save' | translate) :( 'global.create' | translate)}}
    </a>
  </div>
</form>