import { TimeframeEnum } from "@api/index";
function getCurrentMonthName(id:number) {
  const months = [
    'january', 'february', 'march', 'april',
    'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'
  ];
  const currentDate = new Date();

  switch(id) {
    case 1: return '';
    case 2: return '';
    case 3: return currentDate.getMonth()+1;
    case 4: return currentDate.getMonth() != 0  ? currentDate.getMonth() : 12;
    case 5: return '';
    default: return '';
  }
}
export const TimeframeTranslations = {
  [TimeframeEnum.CurrentWeek]: `reports.form.currentWeek`,
  [TimeframeEnum.PreviousWeek]: `reports.form.previousWeek`,
  [TimeframeEnum.CurrentMonth]: `reports.form.currentMonth-${getCurrentMonthName(3)}`,
  [TimeframeEnum.PreviousMonth]: `reports.form.previousMonth-${getCurrentMonthName(4)}`,
  [TimeframeEnum.Custom]: `reports.form.custom`
};
