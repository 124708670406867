import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  link: string = '';

  @Input() size: any | undefined;
  @Input() iconName: string = '';
  @Input() fill: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() className: string = '';
  @Input() stroke: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconName) {
      this.link = `/assets/svg/${this.iconName}.svg#${this.iconName}`;
      if (this.className === '')
        this.className = `icon-${this.iconName}`;
    }
  }

}