import { EngagementTypeEnum, TypeEnum } from "@api/index";

export const PlacementTypeTranslations = {
  [TypeEnum.Standard]: 'global.entrance',
  [TypeEnum.Impact]: 'global.all'
};



export const EngagementTypeTranslations = {
  [EngagementTypeEnum.Segment]: 'global.segment',
  [EngagementTypeEnum.Playlist]: 'global.playlist'
};
