import {Component, Input, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs';
import { PeopleCounterDailyRequest } from 'src/_api';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { TargetGroupEnum } from 'src/_api/models/target-group-enum';
import { STATISTICS_START_DATE } from 'src/app/constants';
import { AbstractPeopleCountTargetGroup } from '../abstract-people-count-target-group';
import {IdName} from "@api/models/idname";

@Component({
  selector: 'flow-people-count-target-group-daily',
  templateUrl: './people-count-target-group-daily.component.html',
  styleUrls: ['./people-count-target-group-daily.component.scss']
})
export class PeopleCountTargetGroupDailyComponent extends AbstractPeopleCountTargetGroup {
  chartDataWithTranslation: ChartData<'line'> = null;
  date = new FormControl();
  maxDate = DateTime.local()
  minDate = STATISTICS_START_DATE
  noChartData: boolean = false;
  selectedDay: string;

  @Input('channels') channels: any[];
  @Input('customerId') customerId: any;
  @Input() screenIds!: IdName[];
  @Input() start!: DateTime;
  @Input() end!: DateTime;

  override ngOnInit(): void {
    this.date.setValue(this.getRelevantDate(this.start, this.end))
    this.date.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onDateChange);
    this.updateData();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartData(this.statistics);
      this.updateChartDataWithTranslation();
    });
  }

  override ngOnChanges(changes: SimpleChanges): void {
    this.date.setValue(this.getRelevantDate(this.start, this.end))
  }

  private onDateChange = (): void => {
    this.updateData();
  };

  private getRelevantDate(startDate, endDate) {
    const today = DateTime.local().startOf('day');
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).startOf('day');


    if (today >= start && today <= end) {
      return today;
    } else {
      return end;
    }
  }

  public chartOptions: ChartConfiguration['options'] = {
    elements: {
      line: { tension: 0.5 }
    },
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 1,
          boxHeight: 30,
          textAlign: 'left',
          padding: 5
        }
      },
      datalabels: { display: false },
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        ticks: {
          padding: 10,
        }
      },
      x: {
        grid: {
          tickColor: 'white',
          color: '#F5F5F5',
          lineWidth: 2
        },
        ticks: {
          padding: 20
        },
      }
    }
  };
  public chartData: ChartData<'line', number[], string | string[]> = null;

  override doApiRequest() {
    return this.statisticsApi
      .peoplePerHour({
        body: this.getRequestBody()
      })
  }

  getRequestBody(): PeopleCounterDailyRequest {
    const channelIds = this.channels.map((l) => l.id)
    const day = this.date.value
    const date = day.startOf('day');
    this.selectedDay = day.toFormat('yyyy-MM-dd');

    return {
      date: date.toISODate(),
      screenIds: this.screenIds?.map(s => s.id),
      channelIds,
      customerId: this.customerId
    }
  }

  setChartData(statistics?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return;
    const hoursWithData = statistics.screenHours.map((stat) => Number(DateTime.fromISO(stat.time, { setZone: true }).hour));
    const startHour = Math.min(...hoursWithData);
    const endHour = Math.max(...hoursWithData);
    const menColor = 'rgb(31, 87, 146)';
    const womenColor = 'rgb(250, 98, 0)';

    const statHours = []
    for (let i = startHour; i <= endHour; i++) statHours.push(i)

    const genderGrouping = [
      {
        label: this.translateConfigService.instant('peopleCount.men'),
        targetGroups: [TargetGroupEnum.MenAbove65, TargetGroupEnum.Men55to64, TargetGroupEnum.Men45to54, TargetGroupEnum.Men35to44, TargetGroupEnum.Men25to34, TargetGroupEnum.Men18to24, TargetGroupEnum.MenUnder18],
        backgroundColor: menColor,
        hoverBackgroundColor: menColor,
        borderColor: menColor,
        pointBackgroundColor: menColor
      },
      {
        label: this.translateConfigService.instant('peopleCount.women'),
        targetGroups: [TargetGroupEnum.WomenAbove65, TargetGroupEnum.Women55to64, TargetGroupEnum.Women45to54, TargetGroupEnum.Women35to44, TargetGroupEnum.Women25to34, TargetGroupEnum.Women18to24, TargetGroupEnum.WomenUnder18],
        backgroundColor: womenColor,
        hoverBackgroundColor: womenColor,
        borderColor: womenColor,
        pointBackgroundColor: womenColor
      }
    ]

    this.chartData = {
      datasets: genderGrouping.map((group) => ({
        fill: false,
        lineTension: 0.5,
        pointRadius: 0,

        data: statHours.reduce((acc, hour) => {
          const statForHour = statistics.screenHours.find((sd) => DateTime.fromISO(sd.time, { setZone: true }).hour === hour)
          if (!statForHour) return [...acc, 0]

          const sumForGroup = statForHour.peoples
            .filter(p => group.targetGroups.includes(p.targetGroup))
            .reduce((sum, stat) => sum + stat.count, 0)

          return [...acc, sumForGroup]
        }, []),
        label: group.label,
        backgroundColor: group.backgroundColor,
        hoverBackgroundColor: group.hoverBackgroundColor,
        borderColor: group.borderColor,
        pointBackgroundColor: group.pointBackgroundColor
      })),
      labels: statHours.map(h => `${h}:00`),
    };

    this.chart.update();
  }

  override updateChartDataWithTranslation() {
    this.chartDataWithTranslation = { ...this.getChartDataWithTranslations() }
    if(!this.chartDataWithTranslation?.datasets[0]?.data?.length && !this.chartDataWithTranslation?.datasets[1]?.data?.length) {
      this.noChartData = true;
    } else {
      this.noChartData = false;
    }
  }

  getChartDataWithTranslations(): ChartData<'line', number[], string | string[]> {
    if (this.chartData) {

      return {
        ...this.chartData,
        datasets: this.chartData.datasets.map((d) => {
          return ({
            ...d,
            label: this.translateConfigService.instant(d.label)
          })
        })

      }
    }

    return { datasets: [], labels: [] }
  }
}
