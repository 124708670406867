
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BookingList } from '@api/models/booking';
import { Category } from '@api/models/category';
import { Client } from '@api/models/client';
import { EngagementRequest } from '@api/models/engagement-request';
import { IdName } from '@api/models/idname';
import { PaginatorParams } from '@api/models/paginator';
import { ClientApi } from '@api/services/client-api';
import { MonthTranslations } from '@app/core/translations';
import { StatisticsFilterInputs } from '@app/models/statistics-filter-inputs';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { EnumService } from '@core/services/enum.service';
import { TranslateConfigService } from '@core/translate-config.service';
import { DateTime } from 'luxon';
import { distinctUntilKeyChanged, forkJoin, from, map, of } from 'rxjs';
import { AdminApi, Customer, Engagement, EngagementsAdminApi, EngagementTypeEnum, Placement, ReportsResponse, ScreenApi, StatisticsApi, TimeframeEnum } from 'src/_api';
import { ActiveReportEnum } from 'src/_api/models/active-report-enum';
import { StatisticsService } from '../statistics.service';

@Component({
  selector: 'flow-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() reportType: ActiveReportEnum;
  @Input() currentPage?: number;
  @Input() selectedIndex?: number;
  @Input() fetchedData?: any;
  @Input() permissions?: string[];
  @Output() fetchNextBookings = new EventEmitter();
  @Output() searchBookings = new EventEmitter();
  isAdmin: boolean;
  customerId: number;
  placements: Placement[] = [];
  engagements: Engagement[] = [];
  medias: IdName[];
  filteredEngagements: Engagement[];
  filteredMedias: IdName[];
  reports: ReportsResponse;
  isDownloadEnabled: boolean = false;
  activeReport: ActiveReportEnum = ActiveReportEnum.PeopleCounter;
  ActiveReportEnum = ActiveReportEnum;
  title: string = 'reports.titlePeopleCounter';
  info: string = 'reports.infoPeopleCounter';
  startDate: any;
  endDate: any;
  preselectedStartDate: any;
  preselectedEndDate: any;
  filtersData = {
    channelIds: [],
    screenIds: [],
    placement: [],
    bookingNumbers: [],
    customerId: []
  };
  preSelectedFilters = false;
  categories: Category[];
  pois: IdName[];
  filteredPOIs: IdName[];
  includeAdminDetailsPOI: boolean;
  includeSmsDetailsPOI: boolean;
  includeVehicleDataReach: boolean;
  includeAvgTimeInChannel: boolean;
  isFilterDataReady: boolean = false;
  filterInputs = new Map<string, StatisticsFilterInputs>();
  timeframeTypes = [];
  trackingTypes = [];
  engagementTypes = [];
  clients: Client[] = [];
  nonAdmincustomerIds: number[];
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'Date',
    sortDirection: 'asc'
  }


  constructor(
    private authService: AuthService,
    private adminApi: AdminApi,
    private screenApi: ScreenApi,
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private cdr: ChangeDetectorRef,
    private statisticsApi: StatisticsApi,
    private translateConfigService: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    private engagementsAdminApi: EngagementsAdminApi,
    public statisticsService: StatisticsService,
    private clientApi: ClientApi) {
      this.statisticsService.form = this.formBuilder.group({});
    }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.routeConfig.path == 'statistics/exposures-reports' || this.reportType === ActiveReportEnum.Exposures) {
      this.activeReport = ActiveReportEnum.Exposures;
      this.title = 'reports.titleExposures';
      this.info = 'reports.infoExposures';
    } else if (this.activatedRoute.snapshot.routeConfig.path === "statistics/reach-reports" || this.reportType === ActiveReportEnum.Reach) {
      this.title = 'reports.titleReachReports';
      this.info = 'reports.infoReach';
      this.activeReport = ActiveReportEnum.Reach;
    } else if (this.activatedRoute.snapshot.routeConfig.path === 'statistics/poi-reports' || this.reportType === ActiveReportEnum.POI) {
      this.title = 'reports.titlePOI';
      this.activeReport = ActiveReportEnum.POI;
      this.info = 'reports.infoPOI';
    }
    this.timeframeTypes = this.enumService.timeframeTypes;
    this.trackingTypes = this.enumService.trackingTypes;
    this.engagementTypes = this.enumService.engagementTypes;


    const user = this.authService.userData;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.initForm();
    this.activatedRoute
      .paramMap
      .pipe(map(() => window.history.state))
      .subscribe(state => {
        const preselectedEngagements: BookingList = state?.data?.engagement;
        if (preselectedEngagements) {
          this.preSelectedFilters = true;
          const uniqueScreens = preselectedEngagements?.placements.flatMap((placement) => placement?.screens);
          const uniqueChannels = preselectedEngagements?.channels;
          const engagementsArray = [{
            id: preselectedEngagements?.engagement?.id,
            name: preselectedEngagements?.engagement?.name,
            presentationId: preselectedEngagements?.engagement?.presentationId
          }];

          this.preselectedStartDate = preselectedEngagements?.engagementStartDate;
          this.preselectedEndDate = preselectedEngagements?.engagementEndDate;

          this.placements = this.getUniqueValues(preselectedEngagements.placements, 'id', 'name');
          let placements = [...[{ id: 'All', name: 'reports.form.all' }], ...this.placements];

          this.fillInputData('customerIds', [preselectedEngagements.customer]);
          this.fillInputData('channelIds', uniqueChannels);
          this.fillInputData('screenIds', uniqueScreens);
          this.fillInputData('placement', placements);
          this.fillInputData('bookingNumbers', engagementsArray);

          this.filtersData['customerId'] = [preselectedEngagements.customer.id];
          this.filtersData['channelIds'] = uniqueChannels.map(c => c.id);
          this.filtersData['screenIds'] = uniqueScreens.map(s => s.id);
          this.filtersData['placement'] = placements.map(p => p.id);
          this.filtersData['bookingNumbers'] = engagementsArray.map(e => e.id);
          this.getMediaFiles(this.filtersData['bookingNumbers']);
        } else {
          this.getData();
        }
      });
  }
  ngAfterViewInit(): void {
    if (this.preSelectedFilters) {
      this.statisticsService.form.get('customerIds').setValue(this.filtersData.customerId);
      this.statisticsService.form.get('channelIds').setValue(this.filtersData.channelIds);
      this.statisticsService.form.get('screenIds').setValue(this.filtersData.screenIds);
      this.statisticsService.form.get('placement').setValue('All');
      this.statisticsService.form.get('bookingNumbers').setValue(this.filtersData.bookingNumbers);
      this.statisticsService.form.get('timeframe').setValue(TimeframeEnum.Custom);
      this.statisticsService.form.get('start').setValue(this.preselectedStartDate);
      this.statisticsService.form.get('end').setValue(this.preselectedEndDate);
    }
  }
  ngOnDestroy(): void {
    this.statisticsService.form = this.formBuilder.group({});
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fetchedData && changes.fetchedData.previousValue && changes.fetchedData.currentValue) {
      this.fillInputData('bookingNumbers', changes.fetchedData.currentValue);
    }
  }
  getData() {
    forkJoin([
      this.isAdmin ? this.adminApi.getCustomers() : this.adminApi.getCustomerById(this.customerId),
      this.activeReport == ActiveReportEnum.Exposures ? this.clientApi.getClients(this.isAdmin ? undefined : [this.customerId]) : of(null)
    ])
      .subscribe(([response, clients]) => {
        let customers = (Array.isArray(response) ? response : [response]) as Customer[];
        this.clients = clients;
        this.isFilterDataReady = true;
        this.fillInputData('customerIds', customers);
        if (this.customerId)
          this.fillInputData('clientIds', clients);
        if (!this.isAdmin) {
          this.fillInputData('channelIds', customers[0].channels);
          this.nonAdmincustomerIds = customers.map(c => c.id);
          this.statisticsService.form.get('customerIds').setValue(this.nonAdmincustomerIds);
          this.statisticsService.form.get('customerIds').disable({ onlySelf: true });
          if (this.activeReport === ActiveReportEnum.POI)
            this.getCategories([this.customerId]);
        }
      });
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  getChannels(customerIds?: number[]) {
    if (this.preSelectedFilters) {
      this.statisticsService.form.get('channelIds').setValue(this.filtersData.channelIds);
    }
    let callApi = customerIds && customerIds.length > 0 ? this.adminApi.getChannels({ customerIds }) : of(null);
    callApi.subscribe((channelsResponse) => {
      this.fillInputData('channelIds', channelsResponse || []);
      if (this.preSelectedFilters) {
        this.statisticsService.form.get('bookingNumbers').setValue(this.filtersData.bookingNumbers)
      }
    });
  }
  getScreens(customerIds: number[], channelIds: number[], trackingTypes: number[]) {
    if (!this.preSelectedFilters) {
      if (channelIds.length > 0) {
        this.engagementsAdminApi.getScreens({ channelIds, customerIds: customerIds ? customerIds : this.nonAdmincustomerIds, isSensor: null, trackingTypes: trackingTypes }).subscribe(
          data => {
            this.fillInputData('screenIds', data || []);
            this.getEngagements();
          }
        )
      } else {
        this.fillInputData('screenIds', []);
      }
    }
    else {
      this.engagementsAdminApi.getScreens({ channelIds: this.filtersData.channelIds as number[], customerIds: customerIds ? customerIds : this.nonAdmincustomerIds, isSensor: null, trackingTypes: trackingTypes }).subscribe(
        data => {
          const screen = [data.find(x => x.id = this.filtersData.screenIds[0])];
          this.fillInputData('screenIds', screen);
          this.toggleSelectDeselect(true, 'screenIds', screen);
          this.toggleSelectDeselect(true, 'bookingNumbers', this.engagements);
        }
      )
    }
  }
  getPlacements() {
    this.placements = [];
    if (this.statisticsService.form.get('customerIds').value.length === 0 || this.statisticsService.form.get('channelIds').value.length === 0) return null;
    let params: any = {};
    if (this.statisticsService.form.get('customerIds').value && this.statisticsService.form.get('customerIds').value.length > 0)
      params.customerIds = this.statisticsService.form.get('customerIds').value;
    if (this.statisticsService.form.get('channelIds').value && this.statisticsService.form.get('channelIds').value.length > 0)
      params.channelIds = this.statisticsService.form.get('channelIds').value;
    if (this.statisticsService.form.get('screenIds').value && this.statisticsService.form.get('screenIds').value.length > 0)
      params.screenIds = this.statisticsService.form.get('screenIds').value;
    forkJoin([
      this.screenApi.getUniquePlacements(params)
    ])
      .subscribe(([placements]) => {
        this.placements = placements;

        if (this.activeReport === ActiveReportEnum.Exposures) this.getEngagements();
      });
  }
  getEngagements() {
    this.engagements = [];
    this.filteredEngagements = [];
    let isScreensIdsArrayNull = this.statisticsService.form.get('screenIds').value == null || this.statisticsService.form.get('screenIds')?.value?.length == 0;
    let isBookingTypesArrayNull = false;
    if (this.statisticsService.form.get('bookingTypes'))
      isBookingTypesArrayNull = this.statisticsService.form.get('bookingTypes').value == null || this.statisticsService.form.get('bookingTypes')?.value?.length == 0;
    let isTimeFrameNull = this.statisticsService.form.get('timeframe').value == null;
    let isStartEndDateNull = this.checkTimeFrame() ? (this.statisticsService.form.get('start').value == null || this.statisticsService.form.get('end').value == null)
      : (!this.startDate || !this.endDate);
    if (isTimeFrameNull || isStartEndDateNull || isScreensIdsArrayNull || isBookingTypesArrayNull || this.placements.length == 0) return;
    let body: EngagementRequest = {
      customerIds: this.statisticsService.form.get('customerIds').value || [],
      startDate: this.statisticsService.form.get('start').value,
      endDate: this.statisticsService.form.get('end').value,
      channelIds: [],
      screenIds: this.statisticsService.form.get('screenIds').value || [],
      clientIds: this.statisticsService.form.get('clientIds').value || [],
      name: "",
      types: this.statisticsService.form.get('bookingTypes').value || [],
      placements: this.placements.map(p => { return p.id })
    };
    if (this.getTimeFrame()) {
      body.startDate = this.getTimeFrame()[0];
      body.endDate = this.getTimeFrame()[1];
    }
    this.engagementsAdminApi.getStatisticsEngagements({ body })
      .subscribe((engagements) => {
        this.engagements = engagements;
        this.filteredEngagements = engagements;
        this.fillInputData('bookingNumbers', engagements);
        // @ts-ignore
        const engagementItems = engagements.items ? engagements.items : engagements;
        this.getMediaFiles(engagementItems.map(e => e.id));
      })
  }
  getMediaFiles(engagementIds: number[]) {
    if (engagementIds.length === 0) return;
    this.engagementsAdminApi.getEngagementsMedias({ engagementIds })
      .subscribe((medias) => {
        this.medias = medias;
        this.filteredMedias = medias;
        this.fillInputData('mediaFiles', medias);
        if (this.preSelectedFilters) {
          this.toggleSelectDeselect(true, 'mediaFiles', medias)
          this.onCreateReport();
        }
      })
  }
  getClients(customerIds: number[]) {
    this.clientApi.getClients(customerIds).subscribe((clients) => {
      this.clients = clients;
      this.fillInputData('clientIds', clients || []);
    });
  }
  getCategories(customerIds: number[]) {
    this.toggleSelectDeselect(false, 'poiCategoryName', []);
    if (customerIds.length == 0) return;
    this.statisticsApi.getStatisticsCategories({ customerIds }).subscribe((categories) => {
      this.categories = categories;
      this.fillInputData('poiCategoryName', categories);
      this.statisticsService.form.get('poiCategoryName')?.setValue(this.categories.map(c => c.id));
      this.getPOI(customerIds);
    });
  }
  getPOI(customerIds: number[]) {
    this.toggleSelectDeselect(false, 'poiName', []);
    if (customerIds.length == 0) return;
    this.statisticsApi.getStatisticsPersonsOfInterest({ customerIds }).subscribe((pois) => {
      this.pois = pois;
      this.filteredPOIs = [{ id: -1, name: 'reports.form.includeDeletedPOI' }].concat(this.pois);
      this.fillInputData('poiName', this.filteredPOIs);
      this.statisticsService.form.get('poiName')?.setValue(this.pois.map(c => c.id));
      this.onCreateReport();
    });
  }
  reportShown(data) {
    if (this.activeReport == ActiveReportEnum.Exposures) {
      this.toggleSelectDeselect(true, 'mediaFiles', this.medias);
      this.preSelectedFilters = false;
    }
  }
  checkTimeFrame() {
    return this.statisticsService.form.get('timeframe')?.value === TimeframeEnum.Custom
  }
  getTimeFrame() {
    return (this.statisticsService.form.get('timeframe').value != 5 && this[`get${TimeframeEnum[this.statisticsService.form.get('timeframe').value]}`]()) || null;
  }
  getCurrentWeek() {
    const { start, end } = this.getStartWeekAndEndWeekDate();

    const startOfCurrentWeek = start.toISODate();
    const endOfCurrentWeek = end.toISODate();
    return [startOfCurrentWeek, endOfCurrentWeek]
  }
  getPreviousWeek() {
    const { start, end } = this.getStartWeekAndEndWeekDate();

    const startOfPreviousWeek = start.minus({ weeks: 1 }).toISODate();
    const endOfPreviousWeek = end.minus({ weeks: 1 }).toISODate();
    return [startOfPreviousWeek, endOfPreviousWeek];
  }
  private getStartWeekAndEndWeekDate() {
    const today = DateTime.local();
    const start = today.startOf('week');
    const end = today.endOf('week');
    return { start, end };
  }
  getCurrentMonth() {
    const { start, end } = this.getStartMonthAndEndMonthDate();
    const startOfCurrentMonth = start.toISODate();
    const endOfCurrentMonth = end.toISODate();
    return [startOfCurrentMonth, endOfCurrentMonth]
  }
  getPreviousMonth() {
    const { start, end } = this.getStartMonthAndEndMonthDate();
    const startOfPreviousMonth = start.minus({ month: 1 }).toISODate();
    const endOfPreviousMonth = end.minus({ month: 1 }).endOf('month').toISODate();
    return [startOfPreviousMonth, endOfPreviousMonth]
  }
  private getStartMonthAndEndMonthDate() {
    const today = DateTime.local();
    const start = today.startOf('month');
    const end = today.endOf('month');
    return { start, end }
  }
  generateBody() {
    let body = {
      ...this.params,
      ...this.statisticsService.form.value,
      customerIds: this.statisticsService.form.get('customerIds').value || [this.customerId]
    }
    if (this.statisticsService.form.get('timeframe').value === TimeframeEnum.Custom) {
      body.startDate = new Date(this.statisticsService.form.get('start').value);
      body.endDate = new Date(this.statisticsService.form.get('end').value);
    }

    if (this.statisticsService.form.get('timeframe').value !== TimeframeEnum.Custom) {
      let selectedTimeFrame = `get${TimeframeEnum[this.statisticsService.form.get('timeframe').value]}`;
      const [startDate, endDate] = this[selectedTimeFrame]();
      body.startDate = startDate;
      body.endDate = endDate;
    }
    if (this.title === 'reports.titleReachReports')
      body.IncludeTrackingType = false;
    if (this.title === 'reports.titlePeopleCounter')
      body.IncludeTrackingType = true;
    if (this.statisticsService.form.get('placement').value === "All") {
      body.placements = this.placements.map((p) => p.id);
    } else {
      body.placements = [this.placements.find((p) => p.id === this.statisticsService.form.get('placement').value)?.id]
    }

    delete body.created;
    delete body.timeframe;
    delete body.placement;

    return body;
  }
  onDownloadReport() {
    if (!this.statisticsService.form.valid) return;

    const body = this.generateBody();
    const callFunction = this.statisticsApi[`download${ActiveReportEnum[this.activeReport]}Reports`](body);

    callFunction.subscribe((data: any) => {
      if (data) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const a = document.createElement('a');
        const today = DateTime.local();
        const formattedDate = today.toISODate();
        a.href = window.URL.createObjectURL(blob);
        let text
        if (this.activeReport === ActiveReportEnum.Exposures) text = "BizLab Flow Exposures Report";
        if (this.activeReport === ActiveReportEnum.PeopleCounter) text = "BizLab Flow People Counter Report";
        if (this.activeReport === ActiveReportEnum.Reach) text = "BizLab Flow Reach Report";
        if (this.activeReport === ActiveReportEnum.POI) text = "BizLab Flow POI Report";
        a.download = `${text} ${formattedDate}`;
        a.click();
      }
    })
  }
  onCreateReport() {
    if (!this.statisticsService.form.valid) return;

    const body = this.generateBody();
    const callFunction = this.statisticsApi[`get${ActiveReportEnum[this.activeReport]}Reports`](body);

    callFunction.subscribe((reports) => {
      this.reports = reports;
      this.includeVehicleDataReach = reports.includeVehicleData || false;
      this.includeAdminDetailsPOI = body.includePOIAdministrativeDetails;
      this.includeSmsDetailsPOI = body.includeSMSNotifications;
      if (this.isAdmin)
        this.includeAvgTimeInChannel = body.includeAverageTimeInChannel;
      this.isDownloadEnabled = true;
    })
  }
  loadMoreItems(event?: any) {
    this.fetchNextBookings.emit(event);
  }
  searchBooking(value: string) {
    this.searchBookings.emit(value);
  }
  getNewReport(event) {
    this.params = event;
    this.onCreateReport();
  }
  translateMonth(month: string) {
    if (month.length > 0)
      return `${this.translateConfigService.instant(MonthTranslations[month])}`
    else return '';
  }
  toggleSelectDeselect(selectAll: boolean, formControlName: string, array: any): void {
    if (this.activeReport === ActiveReportEnum.Exposures) {
      if (selectAll)
        this.statisticsService.form.get(formControlName).setValue(array.map((a) => a.id));
      else
        this.statisticsService.form.get(formControlName).setValue([]);
    }
  }
  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  // Statistics form START
  private initForm(): void {
    let filterArray: string[] = ['timeframe', 'start', 'end', 'customerIds'];

    switch (this.activeReport) {
      case ActiveReportEnum.Reach: {
        filterArray = [...filterArray, ...['channelIds', 'screenIds', 'placement', 'includeDemographicalBreakdown', 'includeHourlyBreakdown', 'includeVehicleData']];
      } break;
      case ActiveReportEnum.Exposures: {
        filterArray = [...filterArray, ...['channelIds', 'tracking', 'screenIds', 'placement', 'bookingTypes', 'clientIds', 'bookingNumbers', 'mediaFiles', 'includePrimaryOrSecoundaryExposureBreakdown', 'includeShareOfVoice', 'includeExtendedStatistics', 'includeReach', 'includeDemographicalBreakdown']];
      } break;
      case ActiveReportEnum.PeopleCounter: {
        filterArray = [...filterArray, ...['channelIds', 'tracking', 'screenIds', 'placement', 'includeDemographicalBreakdown', 'includeHourlyBreakdown']];
        if (this.isAdmin) {
          filterArray.push('includeAverageTimeInChannel');
        }
      } break;
      case ActiveReportEnum.POI: {
        filterArray = [...filterArray, ...['poiCategoryName', 'poiName', 'channelIds', 'tracking', 'screenIds', 'placement', 'includePOIAdministrativeDetails', 'includeSMSNotifications']];
      } break;
    }

    filterArray.forEach(item => {
      this.filterInputs.set(item, this[item]());
    });
    this.fillInputData('timeframe', this.timeframeTypes);
    this.fillInputData('tracking', this.trackingTypes);
    this.fillInputData('bookingTypes', this.engagementTypes);
    this.fillInputData('placement', [{ id: 'All', name: 'reports.form.all' }])
  }
  fillInputData(formControlName: string, data: any) {
    let item = this.filterInputs.get(formControlName);
    if (item) {
      const responseData = data?.items ? data?.items : data;
      const transformedArray: any[] = (responseData || []).map((item) => {
        const { id, name, presentationId } = item;
        return {
          id: formControlName == 'bookingTypes' ? EngagementTypeEnum[id] : id,
          name: presentationId ? `<div><strong>${presentationId}</strong>: ${name}</div>` :
            (name.split('-')[1] && formControlName == 'timeframe' ?
              `${name.split('-')[0]}-${this.translateMonth(name.split('-')[1])}`
              : name)
            || ''
        };
      });
      item.data = transformedArray;
      item.filteredData = transformedArray;
    }
  }
  onFilterChanged = (values: { filter: number[] | string[] | number | string, type: string, form: FormGroup, allFilters: any }): void => {
    this.isDownloadEnabled = false;
    switch (values.type) {
      case 'customerIds': {
        this.statisticsService.form.get('channelIds').setValue([]);
        this.getChannels(values.allFilters.customerIds);
        if (this.activeReport === ActiveReportEnum.Exposures) this.getClients(values.allFilters.customerIds);
        if (this.activeReport === ActiveReportEnum.POI) this.getCategories(values.allFilters.customerIds);
      } break;
      case 'channelIds': {
        this.statisticsService.form.get('screenIds').setValue([]);
        this.getScreens(values.allFilters.customerIds, values.allFilters.channelIds, values.allFilters.tracking);
        this.getPlacements();
      } break;
      case 'screenIds': {
        this.getPlacements();
      } break;
      case 'poiCategoryName': this.getPOI(this.statisticsService.form.get('customerIds').value); break;
      case 'timeframe': this.changeTimeframe(values.filter as number); break;
      case 'tracking': this.getScreens(values.allFilters.customerIds, values.allFilters.channelIds, values.allFilters.tracking); break;
      case 'end': if (this.activeReport === ActiveReportEnum.Exposures) this.getEngagements(); break;
      case 'start': if (this.activeReport === ActiveReportEnum.Exposures) this.getEngagements(); break;
      case 'bookingNumbers': if (this.activeReport === ActiveReportEnum.Exposures) this.getMediaFiles(values.allFilters.bookingNumbers); break;
    }
  }
  changeTimeframe(value: number) {
    if (value === TimeframeEnum.Custom) {
      this.statisticsService.form.get('start').setValidators(Validators.required);
      this.statisticsService.form.get('end').setValidators(Validators.required);
      this.startDate = undefined;
      this.endDate = undefined;
    }
    if (value !== TimeframeEnum.Custom) {
      this.statisticsService.form.get('start').removeValidators([Validators.required]);
      this.statisticsService.form.get('end').removeValidators([Validators.required]);
      this.statisticsService.form.get('start').setValue(null);
      this.statisticsService.form.get('end').setValue(null);
      const [startDate, endDate] = this[`get${TimeframeEnum[value]}`]();
      this.startDate = startDate;
      this.endDate = endDate;
    }
    if (this.activeReport === ActiveReportEnum.Exposures) this.getEngagements();
  }
  timeframe() {
    return { label: 'reports.form.selectTimeframe', data: [], type: 'dropdown', formControlName: 'timeframe', isRequired: true, isChecked: null, hasParams: true };
  }
  start() {
    return { label: 'reports.form.datePickerFrom', data: [], type: 'datepicker', formControlName: 'start', isRequired: false, isChecked: null, placeholder: 'reports.form.datePickerFrom', picker: 'pickerStart' };
  }
  end() {
    return { label: 'reports.form.datePickerTo', data: [], type: 'datepicker', formControlName: 'end', isRequired: false, isChecked: null, placeholder: 'reports.form.datePickerTo', picker: 'pickerEnd' };
  }
  customerIds() {
    return { label: 'reports.form.selectCustomers', data: [], type: 'dropdown', formControlName: 'customerIds', isMultipleDropdown: true, isRequired: true, isChecked: null, hasSelectAll: true };
  }
  channelIds() {
    return { label: 'reports.form.selectChannels', data: [], type: 'dropdown', formControlName: 'channelIds', isMultipleDropdown: true, isRequired: true, isChecked: null, hasSelectAll: true };
  }
  screenIds() {
    return { label: this.activeReport === ActiveReportEnum.PeopleCounter ? 'reports.form.selectScreensOrSensors' : 'reports.form.selectScreens', data: [], type: 'dropdown', formControlName: 'screenIds', isMultipleDropdown: true, isRequired: true, isChecked: null, hasSelectAll: true };
  }
  placement() {
    return { label: 'reports.form.placement', data: [], type: 'dropdown', formControlName: 'placement', isRequired: true, isChecked: null, isFilled: true, isTranslatable: true };
  }
  tracking() {
    return { label: 'reports.form.tracking', data: [], type: 'dropdown', formControlName: 'tracking', isRequired: false, isChecked: null, isMultipleDropdown: true, isFilled: true, isTranslatable: true }
  }
  bookingTypes() {
    return { label: 'reports.form.bookingType', data: [], type: 'dropdown', formControlName: 'bookingTypes', isRequired: true, isChecked: null, isMultipleDropdown: true, hasSelectAll: true, isFilled: true, isTranslatable: true };
  }
  clientIds() {
    return { label: 'reports.form.selectClients', data: [], type: 'dropdown', formControlName: 'clientIds', isRequired: false, isChecked: null, isMultipleDropdown: true, hasSelectAll: true };
  }
  bookingNumbers() {
    return { label: 'reports.form.bookingNumberName', data: [], type: 'dropdown', formControlName: 'bookingNumbers', isRequired: true, isChecked: null, isMultipleDropdown: true, hasSelectAll: true, search: true };
  }
  mediaFiles() {
    return { label: 'reports.form.mediaFile', data: [], type: 'dropdown', formControlName: 'mediaFiles', isRequired: true, isChecked: null, isMultipleDropdown: true, hasSelectAll: true, search: true };
  }
  poiCategoryName() {
    return { label: 'reports.form.poiCategoryName', data: [], type: 'dropdown', formControlName: 'poiCategoryName', isRequired: true, isMultipleDropdown: true, isChecked: null, hasSelectAll: true };
  }
  poiName() {
    return { label: 'reports.form.poiName', data: [], type: 'dropdown', formControlName: 'poiName', isRequired: true, isMultipleDropdown: true, isChecked: null, hasSelectAll: true, isTranslatable: true };
  }
  includeDemographicalBreakdown() {
    return { label: 'reports.form.demographicalBreakdown', data: [], type: 'checkbox', formControlName: 'includeDemographicalBreakdown', isRequired: false, isChecked: false };
  }
  includeHourlyBreakdown() {
    return { label: 'reports.form.hourlyBreakdown', data: [], type: 'checkbox', formControlName: 'includeHourlyBreakdown', isRequired: false, isChecked: false };
  }
  includeVehicleData() {
    return { label: 'reports.form.includeVehicleData', data: [], type: 'checkbox', formControlName: 'includeVehicleData', isRequired: false, isChecked: false };
  }
  includePrimaryOrSecoundaryExposureBreakdown() {
    return { label: 'reports.form.exposureBreakdown', data: [], type: 'checkbox', formControlName: 'includePrimaryOrSecoundaryExposureBreakdown', isRequired: false, isChecked: true };
  }
  includeShareOfVoice() {
    return { label: 'reports.form.shareOfVoice', data: [], type: 'checkbox', formControlName: 'includeShareOfVoice', isRequired: false, isChecked: true };
  }
  includeExtendedStatistics() {
    return { label: 'reports.form.extendedStatistics', data: [], type: 'checkbox', formControlName: 'includeExtendedStatistics', isRequired: false, isChecked: true };
  }
  includeReach() {
    return { label: 'reports.form.reachPerPerson', data: [], type: 'checkbox', formControlName: 'includeReach', isRequired: false, isChecked: false };
  }
  includePOIAdministrativeDetails() {
    return { label: 'reports.form.includePOIAdministrativeDetails', data: [], type: 'checkbox', formControlName: 'includePOIAdministrativeDetails', isRequired: false, isChecked: false };
  }
  includeSMSNotifications() {
    return { label: 'reports.form.includeSMSNotifications', data: [], type: 'checkbox', formControlName: 'includeSMSNotifications', isRequired: false, isChecked: false };
  }
  includeAverageTimeInChannel() {
    return { label: 'reports.form.includeAvgTimeInChannel', data: [], type: 'checkbox', formControlName: 'includeAverageTimeInChannel', isRequired: false, isChecked: false };
  }
  // Statistics form END

  private getUniqueValues(array, id, name) {
    const uniqueArray: IdName[] = [];
    const observableScreens$ = from(array?.map(a => { return { id: a[id], name: a[name] } }) as IdName[]);
    observableScreens$
      .pipe(distinctUntilKeyChanged('id'))
      .subscribe((result) => uniqueArray.push(result));
    return uniqueArray;
  }
}
