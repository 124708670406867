/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';

import {PersonOfInterest, PersonOfInterestResponse} from '../models/person-of-interest';
import {Category} from "../models/category";
import {PersonOfInterestCreate} from "../../app/admin/person-of-interest/create/person-of-interest-create.component";
import {PaginationResult} from "@api/models/paginator";


@Injectable({
  providedIn: 'root',
})
export class PersonOfInteresetApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  getPersonOfInterests(params){
    let body={}
    if(params) {
      body = {
        params: {
          'pageNumber': params.pageNumber || 0,
          'pageSize': params.pageSize || 10,
          'sortBy': params.sortBy || 'name',
          'sortDirection': params.sortDirection || 'asc',
          'customerIds': params.customerIds || [],
          'categories': params.categories || [],
          'text': params.search || '',
          'tags': params.tags || [],
          'dateFrom': params.dateFrom || '',
          'dateTo': params.dateTo || ''
        }
      }
    }
    return  this.http.get<PersonOfInterestResponse>(`${this.rootUrl}/personOfInterests/withpagination`, body)
  }


  uploadPoiImage(params, image){
    const imageToUpload = new FormData()
    imageToUpload.append(`file`, image)

    let body = {}
    if(params) {
      body = {
        params: {
          'customerId': params.customerId || '',
          'name': params.name || ''
        }
      }
    }
  return  this.http.post(`${this.rootUrl}/personOfInterests/upload`, imageToUpload, body)
  }

  createOrUpdatePersonOfInterest(poiId, poi) {
    return this.http.put(`${this.rootUrl}/personOfInterests/${poiId}`, poi )
  }

  deletePoi(poiIds){
    return this.http.delete(`${this.rootUrl}/personOfInterests`, {body: poiIds})
  }

  getCategories(params){
    let body={}
    if(params) {
      body = {
        params: {
          'pageNumber': params.pageNumber || 0,
          'pageSize': params.pageSize || 10,
          'sortBy': params.sortBy || 'name',
          'sortDirection': params.sortDirection || 'asc',
          'customerIds': params.customerIds || [],
        }
      }
    }

    return  this.http.get<PaginationResult<Category>>(`${this.rootUrl}/category`, body)
  }

  saveCategory(category :Category){
    return this.http.post(`${this.rootUrl}/category/${category.customerId}`, category)
  }

  updateCategory(category){
    return this.http.put(`${this.rootUrl}/category/${category.id}/${category.customerId}`, category)
  }

  deleteCategory(category: Category) {
    return this.http.delete(`${this.rootUrl}/category/${category.id}/${category.customerId}`)
  }


  getContactPerson(id){
    return  this.http.get(`${this.rootUrl}/contactPerson?customerId=${id}`)
  }

  getPersonOfInterest(id){
    return  this.http.get<PersonOfInterestCreate>(`${this.rootUrl}/personOfInterests/${id}`)
  }

  getCategoryDetails(categoryId:number, customerId:number){
    return this.http.get<Category>(`${this.rootUrl}/category/details/${categoryId}/${customerId}`)
  }
}

