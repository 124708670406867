import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeframeEnum} from "@api/models/timeframe-enum";
import {FormGroup, Validators} from "@angular/forms";
import {PeopleCountFormGroup} from "@admin/statistics/people-count/people-count-models";
import {Channel} from "@api/models/channels";
import {IdName} from "@api/models/idname";
import {Customer} from "@api/models/customer";
import {MonthTranslations} from "@models/month-translation";
import {TranslateConfigService} from "@core/translate-config.service";
import {takeUntil} from "rxjs";
import {DateTime} from "luxon";
import {unsubscribeMixin} from "@core/unsubscribe";

@Component({
  selector: 'flow-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent extends unsubscribeMixin() implements OnInit {
  @Input() form: FormGroup<PeopleCountFormGroup>;
  @Input() filteredChannels: Channel[];
  @Input() devices: IdName[];
  @Input() timeframeTypes: IdName[]
  @Input() isAdmin!: boolean;
  @Input() customers!: Customer[];
  @Output() onSubmit = new EventEmitter();

  protected readonly TimeframeEnum = TimeframeEnum;

  constructor(
    private translateConfigService: TranslateConfigService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.form.get('timeframe').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((timeframe)=> {

        if (timeframe !== TimeframeEnum.Custom) {
          this.getStartAndEndDate(timeframe)
          this.form.get('start').removeValidators([Validators.required]);
          this.form.get('end').removeValidators([Validators.required]);
        }
        if(timeframe === TimeframeEnum.PreviousWeek || timeframe === TimeframeEnum.CurrentWeek) {
          this.form.get('rangeCompare').reset()
        }
        if(timeframe === TimeframeEnum.Custom){
          this.form.get('start').setValidators([Validators.required]);
          this.form.get('end').setValidators([Validators.required]);
        }
      });

  }

  translateMonth(month: string) {
    if (month?.length > 0)
      return `${this.translateConfigService.instant(MonthTranslations[month])}`
    else return '';
  }

  clearDatePickerTo() {
    this.form.get('end').setValue(null);
  }

  clearDatePickerFrom() {
    this.form.get('start').setValue(null);
  }

  clearChannels(form: FormGroup) {
    form.get('channels').setValue([]);
    this.clearScreens(form);
  }

  clearScreens(form: FormGroup<PeopleCountFormGroup>) {
    form.get('screens').setValue([]);
  }

  selectAllChannels(form: FormGroup) {
    form.get('channels').setValue(this.filteredChannels);
  }

  selectAllScreens(form: FormGroup<PeopleCountFormGroup>) {
    const allScreens = this.devices as IdName[]
    form.get('screens').setValue(allScreens)
  }

  getStartAndEndDate(timeframe: number) {
    switch (timeframe) {
      case TimeframeEnum.Today:
        return this.getToday();
      case TimeframeEnum.CurrentWeek:
        return this.getCurrentWeek();
      case TimeframeEnum.PreviousWeek:
        return this.getPreviousWeek();
      case TimeframeEnum.CurrentMonth:
        return this.getCurrentMonth();
      case TimeframeEnum.PreviousMonth:
        return this.getPreviousMonth();
      default:
        return [];
    }
  }

  private getToday() {
    const today = DateTime.local();

    this.form.patchValue({
      start: today,
      end: today,
    });
  }

  private getCurrentWeek() {
    const[start, end] = this.getWeekDates(0);
    this.form.get('start').setValue(start);
    this.form.get('end').setValue(end);
  }

  private getPreviousWeek() {
    const[start, end] = this.getWeekDates(-1);
    this.form.get('start').setValue(start);
    this.form.get('end').setValue(end);
  }

  private getWeekDates(offsetWeeks: number) {
    const { start, end } = this.getStartWeekAndEndWeekDate(offsetWeeks);
    return [start, end];
  }

  private getStartWeekAndEndWeekDate(offsetWeeks: number = 0) {
    const today = DateTime.local();
    const start = today.startOf('week').plus({ weeks: offsetWeeks });
    const end = today.endOf('week').plus({ weeks: offsetWeeks });
    return { start, end };
  }

  private getCurrentMonth() {
    const[start, end] = this.getMonthDates(0);
    this.form.get('start').setValue(start);
    this.form.get('end').setValue(end);
  }

  private getPreviousMonth() {
    const[start, end] = this.getMonthDates(-1);
    this.form.get('start').setValue(start);
    this.form.get('end').setValue(end);
  }

  private getMonthDates(offsetMonths: number) {
    const { start, end } = this.getStartMonthAndEndMonthDate(offsetMonths);
    return [start, end];
  }

  private getStartMonthAndEndMonthDate(offsetMonths: number = 0) {
    const today = DateTime.local();
    const start = today.startOf('month').plus({ months: offsetMonths });
    const end = today.endOf('month').plus({ months: offsetMonths });
    return { start, end };
  }


  submit() {
    this.onSubmit.emit();
  }
}
