<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ (customerId ? "editCustomer.title" : "createCustomer.title") | translate }}</h1>
</div>
  <flow-stepper [steps]="steps"
                [(activeStep)]="selectedIndex"
                (activeStepChange)="selectedTabChange($event)"
                [isEdit]="customerId == null">
    <div header class="h-10 justify-end items-start inline-flex gap-2">
      <button class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer border-neutral-100" [routerLink]="['/customers']">
        <app-icon iconName="x-square"
                  stroke="#202020"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <div class="text-xs font-semibold capitalize leading-none text-black">
          {{'global.cancel' | translate}}
        </div>
      </button>
      <button [disabled]="(loading$.save | async) || invoiceForm.invalid || customerForm.invalid"
              class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer"
              [ngClass]="invoiceForm.invalid || customerForm.invalid ? 'border-neutral-100' : 'border-brand-chathams-blue'"
              (click)="onSaveClick()">
        <app-icon iconName="save"
                  [stroke]="invoiceForm.invalid || customerForm.invalid ? '#EBEBE4' : '#1f5792'"
                  fill="#fff"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <span class="text-xs font-semibold capitalize leading-none"
              [ngClass]="invoiceForm.invalid || customerForm.invalid ? 'text-[#EBEBE4]' : 'text-brand-chathams-blue'">
          {{(customerId ? 'global.saveChanges' : 'global.add') | translate}}
        </span>
      </button>
    </div>
    <flow-tabs-item [error]="customerValidationErrors.length > 0">
      <form class="w-full" [formGroup]="customerForm">
        <div class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50" role="alert" *ngIf="customerValidationErrors?.length > 0">
          <div class="flex flex-row items-center">
            <span class="font-medium">{{'createCustomer.validationErrors.requirementsMet' | translate}}</span>
          </div>
          <ul class="mt-1.5 list-disc list-inside" *ngFor="let validation of customerValidationErrors">
            <li>{{validation}}</li>
          </ul>
        </div>

        <div class="flex input-override gap-10">
          <div class="w-[362px] flex flex-col gap-4">
            <div class="w-[362px] flex flex-row mb-5">
              <app-icon fill="#BFD6E4"
                        iconName="annotation-info"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598FAE"></app-icon>
              <h3 class="m-0 ml-3 text-text-primary font-semibold">{{'createCustomer.permissions.subTitles.generalInfo' | translate}}</h3>
            </div>

            <div class="w-[362px] flex flex-col">
              <mat-label>{{ "createCustomer.form.companyName" | translate }} *</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="name" />
              </mat-form-field>
            </div>

            <div class="w-[362px] flex flex-col">
              <mat-label>{{ "createCustomer.form.orgNumber" | translate }}</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="organizationId" />
              </mat-form-field>
            </div>

            <div class="w-[362px] flex flex-col">
              <mat-label>{{ "createCustomer.form.customerType" | translate}} *</mat-label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="type">
                  <mat-option *ngFor="let type of enumService.customerTypes" [value]="type.id">
                    {{ type.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="w-[362px] flex flex-col">
              <mat-label>{{ "createCustomer.form.numbersOfWeekForFiltering" | translate }}</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="number"
                  formControlName="numbersOfWeekForFiltering"
                  min="1"
                />
              </mat-form-field>
            </div>

            <div class="w-[362px] flex justify-between">
              <div class="w-[173px] flex flex-col">
                <mat-label>{{ "createCustomer.form.presentationTime" | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    formControlName="presentationTime"
                    min="1"
                    max="100"
                  />
                </mat-form-field>
              </div>
              <div class="w-[173px] flex flex-col">
                <mat-label>{{ "createCustomer.form.storageLimitSize" | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    formControlName="storageLimitSize"
                    min="1"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="w-[362px] flex justify-between">
              <div class="w-[173px] flex flex-col">
                <mat-label>{{ "createCustomer.form.poiLimit" | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    formControlName="personOfInterestLimit"
                    min="1"
                  />
                </mat-form-field>
              </div>
              <div class="w-[173px] flex flex-col">
                <mat-label>{{ "createCustomer.form.contactPersonLimit" | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    formControlName="contactPersonLimit"
                    min="1"
                  />
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="customerId" class="flex flex-row items-end">
              <div class="w-full justify-start items-end gap-2 inline-flex">
                <div class="flex flex-col flex-1">
                  <mat-label>{{ "createCustomer.form.contact" | translate }}</mat-label>
                  <mat-form-field appearance="outline" class="multi-select-field flex-1">
                    <mat-select formControlName="contactPersons"  multiple>
                      <mat-select-trigger>
                        <div *ngFor="let person of selectedContactPersons">
                          {{person.name}} - {{ person.phoneNumber }}
                        </div>
                      </mat-select-trigger>
                      <mat-option *ngFor="let person of customerPersons; let i = index" [value]="person">
                        <div class="flex">
                          <span class="flex-1">{{ person.name }} - {{ person.phoneNumber }}</span>
                          <button
                            class="btn-edit-customer-person"
                            mat-icon-button
                            (click)="onCreateCustomerPerson(person, i, $event)"
                            color="accent">
                            <mat-icon>edit</mat-icon>
                          </button>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <a (click)="onCreateCustomerPerson()" class="mb-1 p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            iconName="plus"
                            size="md"
                            stroke="#202020">
                  </app-icon>
                </a>
              </div>
            </div>
            <!-- <div class="w-[362px] h-fit p-4 bg-neutral-50 flex-col justify-start items-start gap-4 inline-flex">
              <div class="self-stretch h-[71px] flex-col justify-start items-start gap-2 flex">
                <mat-label class="text-gray-900 text-sm font-normal leading-[14px]">{{'createCustomer.form.contactPerson.name' | translate}} *</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <input matInput
                         type="text"
                         formControlName="name">
                </mat-form-field>
              </div>
              <div class="self-stretch h-[71px] flex-col justify-start items-start gap-2 flex">
                <mat-label class="text-gray-900 text-sm font-normal leading-[14px]">{{'createCustomer.form.contactPerson.email' | translate}} *</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <input matInput
                         type="text"
                         formControlName="email">
                </mat-form-field>
              </div>
              <div class="self-stretch justify-start items-end gap-4 inline-flex">
                <div class="w-full flex flex-col">
                  <mat-label class="text-gray-900 text-sm font-normal leading-[14px]">{{'createCustomer.form.contactPerson.phone' | translate}} *</mat-label>
                  <mat-form-field class="w-full bg-white rounded-md">
                    <input matInput
                           type="text"
                           formControlName="phone">
                  </mat-form-field>
                </div>
                <div (click)="onCreateCustomerPerson()" class="w-fit h-fit p-2 bg-surface-surface-brand rounded justify-center items-center gap-2 inline-flex">
                  <app-icon stroke="#fafafa" width="24px" height="24px" [iconName]="selectedMedia ? 'checked' : 'plus'"></app-icon>
                </div>
              </div>
            </div>
            <div class="w-full h-fit flex flex-col gap-4">
              <div *ngFor="let contactPerson of customerPersons; let i = index"
                   class="w-[365px] justify-between items-center inline-flex p-1 border-b border-gray-200"
                   [ngClass]="contactPerson.id != selectedContact?.id ? 'bg-gray-50' : 'bg-chathams-blue-400'">
                <div class="flex gap-2 cursor-pointer">
                  <div (click)="onCreateCustomerPerson(contactPerson)"
                        class="p-2 bg-gray-50 rounded justify-start items-start gap-2 flex">
                    <app-icon [ngStyle]="{cursor:'pointer'}"
                              iconName="edit-pencil"
                              size="md"
                              stroke="#202020">
                    </app-icon>
                  </div>
                  <div (click)="onCreateCustomerPerson(contactPerson, i)"
                        class="p-2 bg-gray-50 rounded justify-start items-start gap-2 flex">
                    <app-icon [ngStyle]="{cursor:'pointer'}"
                              iconName="x"
                              size="md"
                              stroke="#202020">
                    </app-icon>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="w-full flex justify-between">
            <div class="flex flex-col text-xs">
              <p class="border-l-4 border-chathams-blue-600 pl-1"> {{'createCustomer.permissions.notes.title' | translate}}</p>
              <p>{{'createCustomer.permissions.notes.generalInfo' | translate}}</p>
            </div>
            <div class="flex flex-row items-start justify-end">
              <div>
                <button [disabled]="loading$.save | async"
                        mat-flat-button
                        class="btn-save"
                        color="primary"
                        (click)="onNextTab(1)">
                        {{ "global.next" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </flow-tabs-item>
    <flow-tabs-item [error]="invoiceValidationErrors.length > 0">
      <form class="w-full" [formGroup]="invoiceForm">
        <div class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50" role="alert" *ngIf="invoiceValidationErrors?.length > 0">
          <div class="flex flex-row items-center">
            <span class="font-medium">{{'createCustomer.validationErrors.requirementsMet' | translate}}</span>
          </div>
          <ul class="mt-1.5 list-disc list-inside" *ngFor="let validation of invoiceValidationErrors">
            <li>{{validation}}</li>
          </ul>
        </div>

        <div class="grid grid-cols-3 gap-x-10 input-override">
          <div class="grid grid-cols-1 gap-4 col-span-1">

            <div class="flex flex-row mb-5">
              <app-icon fill="#BFD6E4"
                        iconName="invoice"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598FAE"></app-icon>
              <h3 class="m-0 ml-3 text-text-primary font-semibold">{{'createCustomer.permissions.subTitles.invoice' | translate}}</h3>
            </div>

            <div class="flex flex-col">
              <mat-label>{{ "createCustomer.form.address" | translate }}</mat-label>
              <mat-form-field appearance="outline" class="w-full">
                <input matInput type="text" formControlName="address" />
              </mat-form-field>
            </div>

            <div class="grid lg:grid-cols-2 gap-4">
              <div class="flex flex-col">
                <mat-label>{{ "createCustomer.form.postalCode" | translate }}</mat-label>
                <mat-form-field appearance="outline" appearance="outline">
                  <input matInput type="text" formControlName="postalCode" />
                </mat-form-field>
              </div>

              <div class="flex flex-col">
                <mat-label>{{ "createCustomer.form.county" | translate }}</mat-label>
                <mat-form-field appearance="outline" appearance="outline">
                  <input matInput type="text" formControlName="postalCity" />
                </mat-form-field>
              </div>
            </div>

            <div class="flex flex-col">
              <mat-label>{{ "createCustomer.form.country" | translate }}</mat-label>
              <mat-form-field appearance="outline" appearance="outline">
                <mat-select formControlName="countryExternalId">
                  <mat-option *ngFor="let country of countries" [value]="country.id">
                    {{ country.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex flex-col">
              <mat-label>{{ "createCustomer.form.deliveryType" | translate }}</mat-label>
              <mat-form-field appearance="outline" appearance="outline">
                <mat-select formControlName="invoiceDeliveryType">
                  <mat-option *ngFor="let type of enumService.invoiceDeliveryTypes" [value]="type.id">
                    {{ type.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex flex-col" *ngIf="invoiceForm.value?.invoiceDeliveryType === InvoiceDeliveryTypeEnum.Email">
              <mat-label>{{ "createCustomer.form.ePostAddress" | translate }} *</mat-label>
              <mat-form-field appearance="outline">
                <input matInput type="text" formControlName="invoiceDeliveryEmail" />
              </mat-form-field>
            </div>

            <div class="flex flex-col">
              <mat-label>{{ "createCustomer.form.reference" | translate }}</mat-label>
              <mat-form-field appearance="outline" class="w-full mt-1">
                <input matInput type="text" formControlName="referenceName" />
              </mat-form-field>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-4 col-span-2">
          <div class="row row-col text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{'createCustomer.permissions.notes.title' | translate}}</p>
            <p>{{'createCustomer.permissions.notes.invoiceInfo' | translate}}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button
                type="button"
                mat-stroked-button
                class="btn-cancel mr-3"
                (click)="onNextTab(0)">
                {{ "global.back" | translate }}
              </button>
              <button
                [disabled]="loading$.save | async"
                mat-flat-button
                class="btn-save"
                color="primary"
                (click)="onNextTab(2)">
                {{ "global.next" | translate }}
            </button>
            </div>
          </div>
        </div>
        </div>
      </form>
    </flow-tabs-item>
    <flow-tabs-item>
      <div class="w-full flex flex-col gap-8">
        <div class="grid grid-cols-3 gap-x-10">
          <div class="flex flex-row flex-1 mb-5">
            <app-icon fill="#BFD6E4"
                      iconName="lock"
                      size="md"
                      class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                      stroke="#598FAE"></app-icon>
            <h3 class="m-0 ml-3 text-text-primary font-semibold">{{'createCustomer.permissions.subTitles.permissions' | translate}}</h3>
          </div>
          <div class="grid grid-cols-2 gap-4 col-span-2">
            <div class="row row-col text-xs">
              <p class="border-l-4 border-chathams-blue-600 pl-1"> {{'createCustomer.permissions.notes.title' | translate}}</p>
              <p>{{'createCustomer.permissions.notes.allocatePermissions' | translate}}</p>
            </div>
            <div class="flex flex-row justify-end">
              <div>
                <button
                  type="button"
                  mat-stroked-button
                  class="btn-cancel mr-3"
                  (click)="onNextTab(1)">
                  {{ "global.back" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="permissionsForm" *ngIf="permissionsForm">
          <table class="min-w-full divide-y divide-gray-200" formArrayName="permissions">
            <thead>
              <tr>
                  <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {{'createCustomer.permissions.actions.headers.actions' | translate}}
                  </th>

                  <th scope="col" class="px-12 py-3.5 text-sm font-semibold text-center rtl:text-right text-gray-500 dark:text-gray-400">
                    {{'createCustomer.permissions.actions.headers.view' | translate}}
                  </th>

                  <th scope="col" class="px-4 py-3.5 text-sm font-semibold text-center rtl:text-right text-gray-500 dark:text-gray-400">
                    {{'createCustomer.permissions.actions.headers.update' | translate}}
                  </th>

                  <th scope="col" class="px-4 py-3.5 text-sm font-semibold text-center rtl:text-right text-gray-500 dark:text-gray-400">
                    {{'createCustomer.permissions.actions.headers.export' | translate}}
                  </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    *ngFor="let header of permissionsArray.controls; let headerIndex = index"
                    [formGroupName]="headerIndex">

              <tr class="bg-gray-200">
                <td colspan="5"><span class="flex px-3 py-2 font-semibold">
                  <app-icon stroke="#595959" fill="#fff" iconName="{{header.get('icon').value}}" size="md"></app-icon> {{ 'createCustomer.permissions.headers.' + header.get('header').value | translate}}
                </span></td>
              </tr>

              <ng-container class="flex flex-col" formArrayName="actions">
                <tr *ngFor="let action of getActionsArray(headerIndex).controls; let actionIndex = index" [formGroupName]="actionIndex">
                  <td class="p-3">
                    <div class="flex flex-row items-center">
                      <app-icon *ngIf="action.get('icon').value" class="mr-3"
                                stroke="#595959"
                                fill="#fff"
                                iconName="{{action.get('icon').value}}"
                                size="md"></app-icon>
                      <h4 class="m-0 p-0 leading-4">
                        {{ 'createCustomer.permissions.actions.body.' + action.get('title').value | translate}}
                      </h4>
                    </div>
                  </td>
                  <ng-container class="flex flex-row items-center" formArrayName="permissions">
                    <td class="w-2/12 px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap text-center"
                        *ngFor="let permission of getPermissionsArray(headerIndex, actionIndex).controls; let permissionIndex = index"
                        [formGroupName]="permissionIndex">
                      <mat-checkbox *ngIf="permission.value.hiddenValue !== null" color="primary" formControlName="value"></mat-checkbox>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>
    </flow-tabs-item>
  </flow-stepper>
