import { generateEnumTranslations } from "@app/shared/enumTranslate/generateEnumTranslations";

export enum LastUsedMonthValue {
  OneMonth = 1,
  TwoMonths = 2,
  ThreeMonths = 3,
  FourMonths = 4,
  FiveMonths = 5,
  SixMonths = 6,
}

export const LastUsedMonthValuesTranslations = generateEnumTranslations(LastUsedMonthValue, 'cleanUpMedia.lastUsedSelectionValues');
