<mat-dialog-title class="flex justify-between">
  <span *ngIf="data.type !== 3"
        class="text-[#fc4b47] text-sm font-semibold">{{ 'deleteModal.title' | translate }}</span>
  <span *ngIf="data.type === 3"
        class="text-[#fc4b47] text-sm font-semibold">{{ 'deleteModal.titleFolder' | translate }}</span>
  <app-icon mat-button
            mat-dialog-close
            iconName="x"
            stroke="#fc4b47"
            size="md"
            [ngStyle]="{'cursor': 'pointer'}"></app-icon>
</mat-dialog-title>
<div mat-dialog-content
     class="py-2">
  <div>
    <div *ngIf="data.type !== 3">{{ 'deleteModal.text' | translate }}</div>
    <div *ngIf="data.type === 3">{{ 'deleteModal.textFolder' | translate }}</div>
  </div>
  <mat-checkbox *ngIf="data.logic"
                [(ngModel)]="checked"
                class="example-margin mt-2">
    <span *ngIf="data.type !== 3">{{ 'deleteModal.yes' | translate }}</span>
    <span *ngIf="data.type === 3">{{ 'deleteModal.confirmationFolder' | translate }}</span>
  </mat-checkbox>
</div>
<div *ngIf="data.logic"
     mat-dialog-actions>
  <button mat-button
          mat-dialog-close
          type="button">{{'global.cancel' | translate}}</button>
  <button mat-raised-button
          type="button"
          color="warn"
          [disabled]="!checked"
          (click)="onConfirm()">{{'global.delete' | translate}}</button>
</div>