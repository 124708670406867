import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiConfiguration, BaseService, BookEngagement } from '@api/index';
import { BookingDetailsResponse } from '@app/admin/engagement/book/book-models';
import { BookingsResponse } from '../models/booking/bookings-response';
import { BookingsRequest } from '../models/booking/bookings-request';
import { UpdateAdvertisement } from '../models/booking/update-advertisment';
import { Advertisement, BookingPageExposureDetails, MarketingAutomation } from '@app/admin/engagement/bookings/model/bookings.model';

@Injectable({
  providedIn: 'root',
})
export class BookingApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly GetBookingPath = 'bookings';

  getBookingDetails(engagementId: number): Observable<BookingDetailsResponse> {
    return this.http.get<BookingDetailsResponse>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/details/${engagementId}`);
  }

  getBookingSegmentAdvertisments(engagementId: number) {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${engagementId}/advertisements`);
  }

  getBookingSegmentMarketingAutomation(engagementId: number) {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${engagementId}/marketing-automation`);
  }

  getBookingsPlaylistAdvertisments(engagementId: number) {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/playlist/${engagementId}/advertisements`);
  }

  getBookingWeekly(bookingsRequest: BookingsRequest): Observable<BookingsResponse> {
    return this.http.get<BookingsResponse>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/weekly`, {
      params: bookingsRequest as any,
    });
  }

  getBookingSummary(bookingsRequest: BookingsRequest): Observable<BookingsResponse> {
    return this.http.get<BookingsResponse>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/summary`, {
      params: bookingsRequest as any,
    });
  }

  createNewBooking(booking: BookEngagement): Observable<number> {
    return this.http.post<number>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/book`, booking);
  }

  copyBooking(engagementId: number, booking: BookEngagement): Observable<number> {
    return this.http.post<number>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/copy`, booking);
  }

  getSegmentDetails(segmentId: number): Observable<any> {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${segmentId}`);
  }

  getPlaylistDetails(playlistId: number): Observable<any> {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/playlist/${playlistId}`);
  }

  updateGeneralInfo(engagementId: number, booking: BookEngagement): Observable<null> {
    return this.http.put<null>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/general-info/${engagementId}`, {
      name: booking.name,
      bookingSlots: booking.bookingSlots,
    });
  }

  updateMediaSegment(engagementId: number, advertisements: UpdateAdvertisement): Observable<any> {
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${engagementId}/advertisements`, advertisements);
  }

  updateMediaPlaylist(engagementId: number, playlsitMediaFiles: UpdateAdvertisement): Observable<any> {
    return this.http.put(
      `${this.rootUrl}/${BookingApiService.GetBookingPath}/playlist/${engagementId}/playlist-media-files`,
      playlsitMediaFiles
    );
  }

  updateMarketingAutomation(booking: any, engagementId: number): Observable<any> {
    if (!booking.marketingAutomation.hasAdvancedSettings) {
      booking.marketingAutomation.advancedSettings = null;
    }
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${engagementId}/marketing-automation`, booking);
  }

  deleteBooking(engagementId: number): Observable<any> {
    return this.http.delete(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}`);
  }

  getExposureDetails(bookingId: number): Observable<BookingPageExposureDetails> {
    return this.http.get<BookingPageExposureDetails>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${bookingId}/exposure-details`);
  }

  getPlaylistById(id: number): Observable<Advertisement> {
    return this.http.get<Advertisement>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/playlist/${id}/advertisements`);
  }

  getSegmetById(id: number): Observable<MarketingAutomation> {
    return this.http.get<MarketingAutomation>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${id}/marketing-automation`);
  }
}
