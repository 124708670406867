import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomBackendErrorHandlingEnum } from '@api/models/be-errors-enum';
import { BackendErrorsTranslate } from './be-errors-translations';
import { SystemMessageService } from './services/system-message.service';

@Injectable({
  providedIn: 'root'
})
export class BackendErrorsService {
  constructor(
    private translateService: TranslateService, 
    private systemMessageService: SystemMessageService
  ) {}

  isBackendError(errorType: string): boolean {
    return Object.values(CustomBackendErrorHandlingEnum).some((type) => type.toLowerCase() === errorType.toLowerCase());
  }

  handleErrors(error: any, params?: Object) {
    const errorType = error?.error?.errorType;
    if (errorType && this.isBackendError(errorType)) {
      return
    } 

    this.systemMessageService.error(this.translateService.instant(BackendErrorsTranslate[errorType], params))
  }
}
