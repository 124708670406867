<div *ngIf="noChartData; else chartTemplate"
     class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
  <canvas baseChart
          height="50"></canvas>
</div>

<ng-template #chartTemplate>
  <div class="flex gap-14">
    <div class="custom-legend flex flex-col gap-[1px]">
      <div *ngFor="let legend of legends"
           (click)="toggleDataset(legend.datasetIndex)"
           [ngClass]="{'line-through': legend.hidden}"
           [ngStyle]="{'border-left': '5px solid ' + legend.fillStyle}"
           class="w-full h-8 px-[16px] py-[6px]  border-b border-b-gray-200 inline-flex gap-2 cursor-pointer">
        <span class="h-5 flex text-black text-xs font-normal leading-none">{{legend.text}}</span>
      </div>
    </div>
    <div class="h-[60px]">
      <canvas [data]="data"
              baseChart
              type='bar'
              [options]="chartOptions"
              [plugins]="chartPlugins"
              height="60px">
      </canvas>
    </div>
  </div>
</ng-template>