import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminApi } from 'src/_api';
import { ConfigurationSettings } from 'src/_api/models/configuration-settings';
import {BehaviorSubject, takeUntil} from "rxjs";
import {unsubscribeMixin} from "@core/unsubscribe";
import { indicateLoading } from "@core/indicate-loading";

@Component({
  selector: 'flow-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends unsubscribeMixin() implements OnInit {
  form: FormGroup<any>;
  isEditSelected: boolean = false;
  settings: ConfigurationSettings;
  selectedIndex: number = 0;

  steps = [
    {title: 'settings.sms', subtitle: 'settings.settings'},
    {title: 'settings.weatherData', subtitle: 'settings.settings'},
    {title: 'settings.marketingAutomation', subtitle: 'settings.settings'}
  ];

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
  }

  protected readonly indicateLoading = indicateLoading;

  get numberOfMinutes(): FormControl {
    return this.form.get('numberOfMinutes') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private adminApi: AdminApi) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.getData();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      cost: [0.00, Validators.required],
      currency: ['', Validators.required],
      weatherApiConfigurationFrequency: [15, Validators.required],
      maSettingsHighLevelX: [10, Validators.required],
      maSettingsHighLevelY: [2, Validators.required],
      maSettingsHighLevelZ: [2, Validators.required],
      maSettingsMediumLevelX: [20, Validators.required],
      maSettingsMediumLevelY: [2, Validators.required],
      maSettingsMediumLevelZ: [5, Validators.required],
      maSettingsLowLevelX: [40, Validators.required],
      maSettingsLowLevelY: [2, Validators.required],
      maSettingsLowLevelZ: [10, Validators.required],
    });
    if (!this.isEditSelected) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  getData() {
    this.adminApi.getSettings()
      .pipe(takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init)).subscribe((result: ConfigurationSettings) => {
      if (result){
        this.settings = result;
        this.setFormValues();
      }
    });
  }

  onEditClick(){
    this.isEditSelected = true;
    this.form.enable();
  }

  onSaveClick() {
    if(this.form.valid){
      this.isEditSelected = false;
      this.form.disable();
      this.adminApi.updateSettings(this.form.value)
        .pipe(takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save)).subscribe();
    }
  }

  onCancelClick() {
    this.isEditSelected = false;
    this.setFormValues();
    this.form.disable();
  }

  selectedTabChange(index) {
    this.selectedIndex = index;
  }

  private setFormValues(){
    this.form.get('cost').setValue(this.settings.cost ? this.settings.cost : this.settings.cost.toFixed(2));
    this.form.get('currency').setValue(this.settings.currency);
    this.form.get('weatherApiConfigurationFrequency').setValue(this.settings.weatherApiConfigurationFrequency || 15);
    this.form.get('maSettingsHighLevelX').setValue(this.settings?.maSettingsHighLevelX || 10);
    this.form.get('maSettingsHighLevelY').setValue(this.settings?.maSettingsHighLevelY || 2);
    this.form.get('maSettingsHighLevelZ').setValue(this.settings?.maSettingsHighLevelZ || 2);
    this.form.get('maSettingsMediumLevelX').setValue(this.settings?.maSettingsMediumLevelX || 20);
    this.form.get('maSettingsMediumLevelY').setValue(this.settings?.maSettingsMediumLevelY || 2);
    this.form.get('maSettingsMediumLevelZ').setValue(this.settings?.maSettingsMediumLevelZ || 5);
    this.form.get('maSettingsLowLevelX').setValue(this.settings?.maSettingsLowLevelX || 40);
    this.form.get('maSettingsLowLevelY').setValue(this.settings?.maSettingsLowLevelY || 2);
    this.form.get('maSettingsLowLevelZ').setValue(this.settings?.maSettingsLowLevelZ || 10);
  }

  onNextTab(number: number) {
     this.selectedIndex = number
  }
}
