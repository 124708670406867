<svg
  [ngClass]="className"
  [ngStyle]="{
    width: width,
    height: height,
    fill: fill,
    stroke: stroke
  }"
  [class]="size ? size : ''"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <use [attr.class]="className" [attr.xlink:href]="link" [attr.href]="link"></use>
</svg>
