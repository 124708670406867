<div class="table-container h-[50%]">
  <table mat-table
         #table
         [dataSource]="data.dataSource"
         class="table-hover"
         matSort
         [matSortActive]="data.matSortOptions.sortActive"
         [matSortDirection]="data.matSortOptions.sortDirection"
         [matSortDisableClear]="true">

    <ng-container *ngFor="let item of data.dataModels" [matColumnDef]="item.columnName">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> {{ item.header | translate}} </th>
      <td mat-cell
          *matCellDef="let data">
          <div *ngIf="!data.isPlacementString || item.columnName != 'placementString'">{{data[item.columnName]}}</div>
          <div *ngIf="data.isPlacementString && item.columnName == 'placementString'"><app-icon [matTooltip]="data[item.columnName]"
            matTooltipPosition="above"
            [ngStyle]="{cursor:'pointer'}"
            size="md"
            iconName="location"></app-icon></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="actions-col"></th>
      <td mat-cell
          *matCellDef="let element; let index = index;"
          [style.width]="'1px'">
        <div class="button-group">
          <ng-container *ngFor="let item of data.actionButtons">
            <div *ngIf="element[item.condition]" class="button-group-el">
              <app-icon [matTooltip]="item.tooltip | translate"
                        [matTooltipPosition]="item.tooltipPosition"
                        (click)="onClickAction(item.id, element, index)"
                        [ngStyle]="item.style"
                        [size]="item.size"
                        [iconName]="item.iconName"></app-icon>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row
        *matHeaderRowDef="data.displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let element; columns: data.displayedColumns;"
        (dblclick)="onClickAction(ActionButtonEnum.Edit, element)"
        [ngClass]="{'missingConfig': element.missingMedia && data.dataModels[0].header == 'manage.table.id' }"></tr>
  </table>
</div>