// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'dev',
  production: false,
  apiBaseUrl: 'https://bizlab-flow-dev.azurewebsites.net',//dev
  // apiBaseUrl: 'https://localhost:7101', //local
  wikiUrl: 'https://bizlab-wiki-dev.azurewebsites.net/login/2b5445f3-3add-4003-92d0-233cf41101d8/callback'
  // apiBaseUrl: 'https://bizlab-flow-test.azurewebsites.net' //test
  // apiBaseUrl: 'https://bizlab-flow.azurewebsites.net' //prod
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

