export enum ScreenOrientationEnum {
  Sensor = 0,
  Portrait = 1,
  Landscape = 2,

}

export const ScreenOrientationTranslations = {
  [ScreenOrientationEnum.Landscape]: 'global.screenOrientation.landscape',
  [ScreenOrientationEnum.Portrait]: 'global.screenOrientation.portrait'
};
