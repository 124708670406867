import { WeekDayEnum } from "@api/models/week-day-enum";

export const WeekdayTranslations = {
    [WeekDayEnum.Monday]: 'global.days.monday',
    [WeekDayEnum.Tuesday]: 'global.days.tuesday',
    [WeekDayEnum.Wednesday]: 'global.days.wednesday',
    [WeekDayEnum.Thursday]: 'global.days.thursday',
    [WeekDayEnum.Friday]: 'global.days.friday',
    [WeekDayEnum.Saturday]: 'global.days.saturday',
    [WeekDayEnum.Sunday]: 'global.days.sunday',
  };