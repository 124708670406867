<flow-helper-card
  *ngIf="!consentGiven && !isModal"
  consentKey="media-consent"
  description="media.consentDescription"
  title="media.title"></flow-helper-card>

<div class="flex flex-col h-full w-full">
  <div *ngIf="!isModal" class="media-header-container">
    <div class="justify-start items-center flex">
      <h1 class="media-title mr-10">{{ "media.title" | translate }}</h1>
      <div class="w-fit h-10 mr-2">
        <flow-dropdown-button (onSelectionChange)="onSelectionChange($event)" [options]="mediaLinks">
          {{ "global.add" | translate }}
        </flow-dropdown-button>
      </div>
      <div *ngIf="!isAdmin">
        <button
          class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer border-brand-chathams-blue"
          *flowHasPermission="['UpdateMediaCms']; disabled true;"
          [routerLink]="['clean-up']">
          <app-icon fill="#fff" iconName="trash" size="md" className="icon-trash-blue"></app-icon>
          <span class="text-xs font-semibold capitalize leading-none text-brand-chathams-blue">
            {{'cleanUpMedia.title' | translate}}
          </span>
        </button>
      </div>
    </div>
    <div class="media-right-header-content">
      <button class="media-filters-button" (click)="openFilterModal()">
        <app-icon stroke="#202020" [ngStyle]="{ cursor: 'pointer' }" size="md" iconName="filter"></app-icon>
        <span class="text-xs font-semibold">{{ "media.filters.title" | translate }}</span>
        <span *ngIf="filtersNumber > 0" class="filters-count">{{filtersNumber}}</span>
      </button>
      <div class="media-grid">
        <app-icon
          (click)="changeView('grid')"
          [stroke]="viewMode === 'grid' ? '#1F5792' : '#202020'"
          [ngStyle]="{ cursor: 'pointer' }"
          size="md"
          iconName="grid-view"></app-icon>
        <app-icon
          (click)="changeView('table')"
          [stroke]="viewMode === 'table' ? '#1F5792' : '#202020'"
          [ngStyle]="{ cursor: 'pointer' }"
          size="md"
          iconName="row-view"></app-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!isModal" class="media-library-info">
    <div class="justify-start flex-col items-start inline-flex">
      <div class="w-full text-gray-900  text-base font-semibold leading-tight">
        {{ "media.library" | translate }}
      </div>
      <div class="w-full text-gray-900 text-xs font-normal leading-none flex items-center gap-1">
        <app-icon *ngIf="currentFolder" stroke="#f8d775" fill="#f8d775" size="md" iconName="folder"></app-icon>
        <span *ngIf="currentFolder" class="font-semibold">{{ currentFolder.label }}</span>
        {{ currentFolder ? ("media.folderDescription" | translate : { medias: totalMediaFiles }) : ("media.libraryDescription" | translate : { folders: totalFolders, medias: totalMediaFiles }) }}
      </div>
    </div>
    <flow-cloud-storage
      *ngIf="sizeOfMediaAndFolders"
      [sizeOfMediaAndFolders]="sizeOfMediaAndFolders"
      [storageLimitSize]="storageLimitSize"
      [showSizeOfFolder]="isInFolder"
      [sizeOfFolder]="currentFolderSize"></flow-cloud-storage>
  </div>
  <flow-media-filter-section 
    flowStickyHeader
    *ngIf="!isModal" [currentUser]="currentUser"
    (onFilterMedias)="searchMedias($event)"
    (onClearAllFilters)="clearAllFilters()"></flow-media-filter-section>
  <form *ngIf="isModal" [formGroup]="form" class="flex flex-col bg-[#fafafa] my-1 py-2 px-1 gap-1">
    <div class="flex justify-between pb-1">
      <span class="text-xs font-semibold">{{'media.filters.title' | translate}}</span>
      <app-icon
        (click)="openFilter = !openFilter"
        [ngStyle]="{ cursor: 'pointer' }"
        size="md"
        stroke="#202020"
        iconName="chevron-down"></app-icon>
    </div>
    <div class="grid grid-cols-3 gap-2" *ngIf="openFilter">
      <div class="w-full">
        <mat-label>{{ "media.filters.text" | translate }}</mat-label>
        <mat-form-field class="w-full">
          <input matInput type="text" formControlName="text" />
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-label>{{ "media.filters.types" | translate }}</mat-label>
        <mat-form-field class="w-full">
          <mat-select appearance="outline" formControlName="filterTypes" multiple>
            <mat-option *ngFor="let type of types" [value]="type.id">
              {{ type.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-label>{{ "media.filters.clients" | translate }}</mat-label>
        <mat-form-field class="w-full">
          <mat-select formControlName="clientIds" multiple>
            <mat-option *ngFor="let client of clients$ | async" [value]="client.id">
              {{ client.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-label>{{ "media.filters.tags" | translate }}</mat-label>
        <mat-form-field class="w-full">
          <mat-select formControlName="tags" multiple>
            <mat-option *ngFor="let tag of tags$ | async" [value]="tag.id">
              {{ tag.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-full">
        <mat-label>{{ "poi.form.datePickerLabel" | translate }}</mat-label>
        <mat-form-field appearance="outline" class="w-full mr-3">
          <mat-date-range-input [rangePicker]="picker" formGroupName="created">
            <input [placeholder]="'poi.form.datePickerFrom' | translate" formControlName="start" matStartDate />
            <input [placeholder]="'poi.form.datePickerTo' | translate" formControlName="end" matEndDate />
          </mat-date-range-input>
          <app-icon
            (click)="clearDatePicker()"
            *ngIf="form.get('created').get('start').value"
            [ngStyle]="{
              cursor: 'pointer',
              position: 'absolute',
              right: '-6px',
              top: '6.5px'
            }"
            iconName="close"
            size="xl"></app-icon>
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="media-table-container flex-1">
    <div class="flex" [ngClass]="currentFolder || isModal || (!isAdmin && mediaIds.length > 0) ? 'justify-between' : 'self-end'">
      <div class="flex items-end gap-2">
        <flow-breadcrumb [isModal]="isModal" (goBackBreadcrumb)="goBackBreadcrumb()"></flow-breadcrumb>
        <ng-container *ngIf="!isModal && !isAdmin && mediaIds.length > 0">
          <div
            *flowHasPermission="['UpdateMediaCms']; disabled true;"
            class="bulk-button cursor-pointer border-brand-red"
            (click)="onDeleteClick(null, true)">
            <span class="bulk-button-text text-brand-red">{{'media.deleteMedias' | translate}}</span>
          </div>
          <div *flowHasPermission="['UpdateMediaCms']; disabled true;" class="bulk-button" (click)="openMoveMediaModal(null)">
            <span class="bulk-button-text">{{'media.moveMedias' | translate}}</span>
          </div>
        </ng-container>
      </div>
      <div class="flex items-baseline">
        <mat-checkbox
          *ngIf="!isModal && viewMode === 'table'"
          color="primary"
          [checked]="showExtendedDetails"
          (change)="extendedDetailsToggle()">
          {{ 'media.showExtendedInformation' | translate }}
        </mat-checkbox>
        <mat-paginator
          showFirstLastButtons
          (page)="handlePageEvent($event)"
          [disabled]="!isReady"
          [pageSize]="params.pageSize"
          [pageIndex]="params.pageNumber"
          [pageSizeOptions]="pageSizeOptions"
          [length]="totalCount"></mat-paginator>
      </div>
    </div>
    <mat-table
      matSort
      [dataSource]="dataSource"
      [ngClass]="{ 'flex-row gap-5 flex-wrap': viewMode === 'grid' }"
      [matSortDisableClear]="true"
      (matSortChange)="handleSortEvent($event)">
      <ng-container matColumnDef="grid">
        <mat-cell *matCellDef="let row" class=" w-[180px] h-[120px] flex flex-col gap-1 bg-gray-50 rounded p-0">
          <div *ngIf="row.type == MediaTypeEnum.Folder" (click)="folderClick(row)" class="grid-folder-container pb-0 rounded ">
            <app-icon size="lg" stroke="#f8d775" fill="#f8d775" iconName="folder"></app-icon>
            <span class="grid-folder-name-text">{{row.name}}</span>
            <div class="grid-folder-files-text">{{'media.mediaCount' | translate: {mediaCount: row.mediaCount} }}</div>
          </div>
          <div *ngIf="row.type != MediaTypeEnum.Folder" class="grid-folder-container pb-0 bg-gray-50">
            <div class="flex self-center">
              <flow-media-preview
                class="flex self-center max-h-[70px] "
                [media]="row"
                [type]="row?.type"
                [fixedSize]="false"></flow-media-preview>
            </div>
          </div>
          <div class="w-full flex justify-between items-center pl-2">
            <div class="flex truncate hover:text-clip text-xs">
              {{row.type === MediaTypeEnum.Folder ? ('media.table.createdGrid' | translate: {created: row.created | date: 'yyyy-MM-dd' : 'medium'}) : row.name}}
            </div>
            <button mat-icon-button class="flex justify-end" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="row.type === MediaTypeEnum.Folder ? folderClick(row) : openViewMedia(row, $event)">
                <span>{{ (row.type === MediaTypeEnum.Folder ? 'media.table.openFolder' : 'media.table.preview') | translate }}</span>
              </button>
              <button mat-menu-item (click)="getDetailsData(row)">
                <span>{{ "media.table.details" | translate }}</span>
              </button>
              <ng-container *ngIf="row.type != MediaTypeEnum.Folder">
                <button *flowHasPermission="['UpdateMediaCms']; disabled true;" mat-menu-item (click)="openMoveMediaModal(row)">
                  <span>{{ 'media.table.move' | translate }}</span>
                </button>
              </ng-container>
              <button *flowHasPermission="['UpdateMediaCms']; disabled true;" mat-menu-item (click)="redirectToEditPage(row)">
                <span>{{ 'media.table.edit' | translate }}</span>
              </button>
              <button *flowHasPermission="['UpdateMediaCms']; disabled true;" mat-menu-item (click)="onDeleteClick(row)">
                <span class="text-red-500">{{ 'media.table.delete' | translate }}</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="(!isAdmin && !isModal) || isModal && viewMode === 'table'" matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef class="min-w-12 max-w-12">
          <mat-checkbox
            *ngIf="!isModal || multipleSelect"
            id="select-media-checkbox-all"
            [checked]="isAllSelected"
            color="primary"
            (change)="selectMedias($event,null)"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let media" class="min-w-12 max-w-12">
          <ng-container *ngIf="media.type != 3">
            <mat-checkbox
              *ngIf="multipleSelect"
              [id]="'select-media-checkbox'+media.id"
              color="primary"
              [checked]="media.isSelected"
              (change)="selectMedias($event, media)"></mat-checkbox>
            <mat-radio-button
              *ngIf="!multipleSelect"
              [id]="'select-media-checkbox'+media.id"
              color="primary"
              [value]="media.id"
              (change)="selectMedias($event, media)"></mat-radio-button>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container *ngFor="let column of columnsConfig" [matColumnDef]="column.key">
        <mat-header-cell
          *matHeaderCellDef
          [ngClass]="{'min-w-24 max-w-24': viewMode == 'table'}"
          class="ml-[1.125rem] text-gray-900 text-xs font-semibold">
          <span *ngIf="column.key !== 'thumbnail' && column.key !== 'tags'" mat-sort-header>
            {{ ('media.table.'+column.label) | translate }}
          </span>
          <span *ngIf="column.key === 'thumbnail' || column.key === 'tags'">
            {{ ('media.table.'+column.label) | translate }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let index = index" [ngClass]="{'min-w-24 max-w-24': viewMode == 'table'}" class="media-table-cell">
          <div
            *ngIf="column.key == 'thumbnail'"
            class="file-placeholder"
            (click)="isModal ? redirectToEditPage(row) : openViewMedia(row, $event)"
            [ngClass]="index % 2 == 0 ? 'bg-gray-50' : 'bg-white'">
            <div *ngIf="row.type === MediaTypeEnum.Folder" class="folder-placeholder" (click)="folderClick(row)">
              <app-icon size="lg" stroke="#f8d775" fill="#f8d775" iconName="folder"></app-icon>
            </div>

            <div *ngIf="row[column.text] && row.type !== MediaTypeEnum.Folder">
              <flow-media-preview [media]="row" [type]="row?.type"></flow-media-preview>
            </div>

            <div class="thumbnail-placeholder" *ngIf="row.type === MediaTypeEnum.Image && !row[column.text]">
              <mat-icon>visibility</mat-icon>
            </div>
          </div>

          <ng-container *ngIf="column.key == 'tags' && row.tags.length > 0">
            <div class="flex flex-wrap gap-2">
              <ng-container *ngFor="let tag of row.tags">
                <span class="flex p-1 bg-chathams-blue-400 rounded text-center text-gray-900 text-xs font-normal leading-tight">
                  {{tag.name}}
                </span>
              </ng-container>
            </div>
          </ng-container>

          <span *ngIf="column.key != 'tags' && column.key != 'thumbnail' && column.key != 'folder'">
            {{ column.text == 'created' || column.text == 'lastUsed' ?
            row[column.text] : row[column.text] }}
          </span>
          <span *ngIf="column.key == 'folder' && row.type != MediaTypeEnum.Folder">{{ row[column.text] }}</span>

          <span *ngIf="!isModal && row.createdOrApprovedBy && column.key == 'createdOrApprovedBy'" class="ml-1">
            <app-icon
              *ngIf="row.mediaUploadStatus === mediaUploadStatusEnum.Approved"
              size="sm"
              stroke="#202020"
              iconName="approved-by"></app-icon>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="!isModal" matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="min-w-16 max-w-24 justify-items-end"></mat-header-cell>
        <mat-cell *matCellDef="let row" class="justify-items-end justify-end">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngIf="row.type != MediaTypeEnum.Folder">
              <button mat-menu-item *flowHasPermission="['UpdateMediaCms']; disabled true;" (click)="openMoveMediaModal(row)">
                <span>{{ 'media.table.move' | translate }}</span>
              </button>
            </ng-container>
            <button mat-menu-item *flowHasPermission="['UpdateMediaCms']; disabled true;" (click)="redirectToEditPage(row)">
              <span>{{ 'media.table.edit' | translate }}</span>
            </button>
            <button mat-menu-item *flowHasPermission="['UpdateMediaCms']; disabled true;" (click)="onDeleteClick(row)">
              <span class="text-red-500">{{ 'media.table.delete' | translate }}</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row
        [ngClass]="{'hidden': viewMode === 'grid'}"
        *matHeaderRowDef="displayedColumns;sticky: true"
        class="bg-gray-200 w-min min-w-full"></mat-header-row>
      <mat-row
        (dblclick)="redirectToEditPage(row)"
        *matRowDef="let row; columns: viewMode === 'grid' ? ['grid'] : displayedColumns; let odd = odd"
        [ngClass]="viewMode === 'grid' ? 'w-[180px] h-[120px] border-0' : odd ? 'min-h-[60px] w-min min-w-full bg-white' : 'min-h-[60px] w-min min-w-full bg-gray-50'"></mat-row>

      <div class="no-data" *matNoDataRow>
        {{ "manage.table.noData" | translate }}
      </div>
    </mat-table>
  </div>
</div>
