// This enum is used for custom error implementation specific to a component. 
// If the error type is not in this list it will be handeled with a generic toaster message.
// This is handled in BackendErrorsService
export enum CustomBackendErrorHandlingEnum {
  mediasInUse = "MEDIAS_IN_USE",
  mediaInUse = "MEDIA_IN_USE",
  ScreenInUse = "SCREEN_IN_USE"
}

export enum BackendErrorsEnum {
  ScreenUsed = "SCREEN_ALREADY_USED",
  ScreenInUse = "SCREEN_IN_USE",
  ScreenNotFound = "SCREEN_NOT_FOUND",
  ScreenBooked = "SCREEN_ALREADY_BOOKED",
  ChannelUsedAtSegment = "CHANNEL_ALREADY_USED_AT_SEGMENT",
  ChannelUsedAtScreen = "CHANNEL_ALREADY_USED_AT_SCREEN",
  MediaUsedAtAd = "MEDIA_USED_IN_ADVERTISEMENT",
  MediaUsedAtDm = "MEDIA_USED_AS_DEFAULT_MEDIA",
  mediasInUse = "MEDIAS_IN_USE",
  mediaInUse = "MEDIA_IN_USE",
  mediaNameCannotBeEmpty = "MEDIA_NAME_CANNOT_BE_EMPTY",
  mediaCannotRemoveClient = "MEDIA_CLIENT_CANNOT_BE_UNASSIGNED",
  mediaFileTooLarge = "MEDIA_FILE_TOO_LARGE",
  mediaNotFound = "MEDIA_NOT_FOUND",
  MediaNameAlreadyExists = "MEDIA_NAME_ALREADY_EXISTS",
  EngagementNameUsed = "ENGAGEMENT_NAME_ALREADY_USED",
  PlacementNameUsed = "PLACEMENT_NAME_ALREADY_USED",
  FolderNameUsed = "FOLDER_NAME_ALREADY_USED",
  TransactionFailed = "TRANSACTION_FAILED_NOTHING_IS_SAVED_IN_THE_DATABASE",
  SegmentNotFound = "SEGMENT_NOT_FOUND",
  ChannelNotFound = "CHANNEL_NOT_FOUND",
  CustomerNotFound = "CUSTOMER_NOT_FOUND",
  UserNotFound = "USER_NOT_FOUND",
  EngagementNotFound = "ENGAGEMENT_NOT_FOUND",
  PlacementNotFound = "PLACEMENT_NOT_FOUND",
  FolderNotFound = "FOLDER_NOT_FOUND",
  TagNotFound = "TAG_NOT_FOUND",
  CustomerStorageSizeLimitReached = "CUSTOMER_STORAGE_SIZE_LIMIT_REACHED",
  AdvertisementNotFound = "ADVERTISEMENT_NOT_FOUND",
  ServerError = "SERVER_ERROR",
  EmptyFolderName = "EMPTY_FOLDER_NAME",
  TagNameAlreadyUsed = "TAG_NAME_ALREADY_USED",
  ActivationRuleMissing = "ACTIVATION_RULE_MISSING",
  MAAlreadyExists = "MARKETING_AUTOMATION_ALREADY_EXISTS_FOR_THIS_SEGMENT",
  MAInvalidRules = "MARKETING_AUTOMATION_INVALID_RULES",
  MAAlreadyDefined = "MARKETING_AUTOMATION_ALREADY_DEFINED",
  MANotFound = "MARKETIN_AUTOMATION_NOT_FOUND",
  UserAlreadyRegistered = "USER_ALREADY_REGISTERED",
  ContactPersonEmailExists = "CONTACT_PERSON_EMAIL_ALREADY_EXITS",
  ContactPersonPhoneExists = "CONTACT_PERSON_PHONE_NUMBER_ALREADY_EXITS",
  ContactPersonNotFound = "CONTACT_PERSON_NOT_FOUND",
  ContactPersonLimitReach = "CONTACT_PERSON_LIMIT_REACHED",
  ContactPersonNegativeNumber = "CONTACT_PERSON_NEGATIVE_NUMBER",
  ContactPersonInvalidPhone = "CONTACT_PERSON_INVALID_PHONE_NUMBER",
  ContactPersonListEmpty = "CONTACT_PERSON_LIST_EMPTY",
  ContactPersonLimitDefaultValue = "CONTACT_PERSON_LIMIT_DEFAULT_VALUE",
  StorageLimitSizeDefaultValue = "STORAGE_LIMIT_SIZE_DEFAULT_VALUE",
  StorageLimitNegativeNumber = "STORAGE_LIMIT_NEGATIVE_NUMBER",
  CategoryNameCannotBeEmpty = "CATEGORY_NAME_CANNOT_BE_EMPTY",
  CategoryNameIsNotUnique = "CATEGORY_NAME_IS_NOT_UNIQUE",
  CategoryNotFound = "CATEGORY_NOT_FOUND",
  CategoryInUse = "CATEGORY_IN_USE",
  CategoryWithoutNotifications = "CATEGORY_WITHOUT_NOTIFICATIONS",
  CategoryMinutesException = "CATEGORY_MINUTES_EXCEPTION",
  CategorySMSException = "CATEGORY_SMS_EXCEPTION",
  POILimitReached = "POI_LIMIT_REACHED",
  POINotFound = "POI_NOT_FOUND",
  POIWithoutCategory = "POI_WITHOUT_CATEGORY",
  POINegativeNumberAtCustomer = "POI_NEGATIVE_NUMBER_AT_CUSTOMER",
  PoiNameAlreadyExists = 'POI_NAME_ALREADY_EXIST',
  AINotFound = "AI_NOT_FOUND",
  PlaylistNotFound = "PLAYLIST_NOT_FOUND",
  PlaylistMediaNotFound = "PLAYLIST_MEDIA_FILE_NOT_FOUND",
  ChildPlaylistNotFound = "CHILD_PLAYLIST_NOT_FOUND",
  ClientNameExists = "CLIENT_NAME_ALREADY_EXIST",
  ClientNotFound = "CLIENT_NOT_FOUND",
  ClientVATNumberExists = "VAT_NMBER_ALREADY_EXIST",
  InvalidCredentials = "INVALID_CREDENTIALS",
  InvalidPowerDownBeforePowerUpSchedule = "SCREEN_POWER_DONW_BEFORE_POWER_UP_IS_NOT_VALID",
  ContactPersonSmsNotSelected = "CONTACT_PERSON_SMS_NOT_SELECTED",
  ScreenSmsEndTimeBeforeStartTime = "SCREEN_SMS_END_TIME_BEFORE_START_TIME",
  ScreenAtLeasOneSmsAlertRequired = "SCREEN_AT_LEAST_ONE_SMS_ALERT_REQUIRED",
  ScreenSmsStartAndEndAreTheSame = "SCREEN_SMS_START_AND_END_ARE_THE_SAME"
}
