<div class="container-login">
  <div class="login-wrapper">
    <div class="login-card">
      <div class="logo">
        <img src="../../../../assets/svg/logo.svg"
             alt="Logo">
      </div>
      <div class="header">
        <h3 class="welcome">{{'global.welcome' | translate}}</h3>
        <p>{{ 'global.selectCustomerMessage' | translate }}</p>
      </div>
      <form [formGroup]="form" (ngSubmit)="selectCustomer()">

          <mat-label>{{'global.customer' | translate}}</mat-label>

          <mat-form-field appearance="outline" class="w-full">
            
            <mat-select placeholder="{{'global.customer' | translate}}" floatlLabel="never" formControlName="customerId">              
              <mat-option *ngFor="let customer of customers"
                          [value]="customer.id">
                {{ customer.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        
        <button class="btn-primary-l w-full">Proceed</button>

      </form>
      <a class="btn-base-l w-full"
         [routerLink]="['/login']">Back
      </a>

    </div>
    <div>
      <p>© BizLab Analytics 2024</p>
    </div>
  </div>
  <div class="image-wrapper">
  </div>
</div>