<div class="tabs-group">
  <mat-tab-group class="mat-tabs-override" [selectedIndex]="selectedIndex" [animationDuration]="animationDuration" [disableRipple]="disableRipple" (selectedTabChange)="selectedTab($event)">
    <mat-tab *ngFor="let tab of tabsList" [disabled]="tab?.disabled" >
        <ng-template mat-tab-label class="py-3">
          <div class="flex flex-col text-left" [ngClass]="{'opacity-50': tab?.disabled}">
            <span class="text-text-brand" translate  [ngClass]="{'error': tab?.error}">{{ tab.title }}</span>
            <span class="text-text-brand font-semibold" translate  [ngClass]="{'error': tab?.error}">{{ tab.subTitle }}</span>
          </div>
        </ng-template>
        <ng-container [ngTemplateOutlet]="tab.content"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>