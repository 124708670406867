<div class="flex items-center gap-10">
    <div>
        <div *ngFor="let step of steps; let i = index"
             (click)="setActiveStep(i, $event)"
             [ngClass]="i | bookStep: activeStep: isEdit : form?.value"
             class="px-4 pt-2 pb-1 rounded-tl rounded-tr border-b-4 flex-col justify-center items-start inline-flex cursor-pointer">
            <div class="header-stepper-book">
                <div class="step-title font-normal">{{step.title | translate}}</div>
            </div>
            <div class="header-stepper-book">
                <div class="step-title font-semibold">{{step.subtitle | translate}}</div>
            </div>
        </div>
    </div>
    <div class="h-10 justify-end items-start inline-flex gap-2">
        <ng-content select="[header]"></ng-content>
    </div>
</div>
<div class="h-[92%] mt-8">
    <ng-container *ngFor="let step of stepList; let i = index">
        <div *ngIf="i === activeStep" class="w-full h-full justify-between inline-flex">
            <ng-container [ngTemplateOutlet]="step?.content"></ng-container>
        </div>
    </ng-container>
</div>
