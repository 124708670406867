import { AfterViewChecked, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'flow-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownInputComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DropdownInputComponent),
    multi: true
  }]
})
export class DropdownInputComponent implements ControlValueAccessor, Validator, AfterViewChecked, OnChanges {
  dropdownOpen: boolean = false;
  isMenuWidthSet: boolean = false;
  selectedItems: any[] = [];
  selectedNames: string[] = [];
  filteredOptions: any[] = [];
  selectAll: boolean = false;
  setValueState: any = null;
  disabled: boolean = false;
  control!: AbstractControl | null;
  Validators: Validators;

  @Input() options: any[] = [];
  @Input() multiSelect: boolean = false;
  @Input() enableSearch: boolean = false;
  @Input() label: string = 'Label';
  @Input() placeholder: string = 'Placeholder';

  @Output() onSelectionChange = new EventEmitter<any[]>();

  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  @ViewChild('dropdownToggle') dropdownToggle!: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private renderer: Renderer2){}

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.options && !_.isEqual(changes.options.currentValue, changes.options.previousValue)) {
        this.clearState();
        this.filteredOptions = this.options || [];
        if (this.setValueState)
          this.updateSelectedState();
      }
  }
  ngAfterViewChecked(): void {
    if (this.dropdownOpen && !this.isMenuWidthSet)
      this.setDropdownWidth();
  }
  clearState() {
    this.selectedItems = [];
    this.selectedNames = [];
    this.selectAll = false;
  }
  setDropdownWidth() {
    const parentWidth = this.dropdownContainer.nativeElement.offsetWidth;
    this.renderer.setStyle(this.dropdownMenu.nativeElement, 'width', `${parentWidth}px`);
    this.isMenuWidthSet = true;
  }
  toggleDropdown() {
    if (this.disabled) return;
    if (this.options.length > 0) {
      this.dropdownOpen = !this.dropdownOpen;
      if (!this.dropdownOpen)
        this.isMenuWidthSet = false;
    }
  }
  filterOptions(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredOptions = this.options.filter(option =>
      option.toLowerCase().includes(query)
    );
  }
  selectOption(option: any, event?: any) {
    if (this.disabled) return;
    if (this.multiSelect) {
      option.selected = !option.selected;
      this.updateSelections();
    } else {
      this.selectedItems = [option.id];
      this.selectedNames = [option.name];
      this.dropdownOpen = false;
      this.isMenuWidthSet = false;
    }
    this.onChange(this.selectedItems);
    this.onSelectionChange.emit(this.selectedItems);
  }
  updateSelectedState() {
    this.filteredOptions.forEach(option => option.selected = this.setValueState.filter(val => _.isEqual(val, option.id)).length > 0 || false);
    this.updateSelections();
    this.setValueState = null;
  }
  updateSelections() {
    this.selectedItems = this.filteredOptions.filter(option => option.selected).map(option => option.id);
    this.selectedNames = this.filteredOptions.filter(option => option.selected).map(option => option.name);
    this.selectAll = this.filteredOptions.length > 0 && this.selectedItems.length === this.filteredOptions.length;
  }
  toggleSelectAll() {
    if (this.disabled) return;
    this.selectAll = !this.selectAll;
    this.filteredOptions.forEach(option => option.selected = this.selectAll);
    this.updateSelections();
    this.onChange(this.selectedItems);
    this.onSelectionChange.emit(this.selectedItems);
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target) && this.dropdownToggle && !this.dropdownToggle.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
      this.isMenuWidthSet = false;
      this.onTouched();
    }
  }
  writeValue(value: any): void {
    if (value && ((value && !Array.isArray(value)) || value.length > 0)) {
      let valueArray = value;
      if (!Array.isArray(value))
        valueArray = [value];
      this.setValueState = valueArray;
      //if there is not options provided update them in ngChanges lifecycle
      if (this.filteredOptions.length > 0) {
        this.updateSelectedState();
      }
    }
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnValidatorChange?(fn: () => void): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    return null;
  }
  get requiredValidator() {
    return Validators.required;
  }
}
