import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemMessageService } from '../../services/system-message.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'flow-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loadingRegister = false;
  navigating = false;
  email: string;
  password: string;
  passwordStrength = 0;
  hide = true;
  iconName = "eye-show";
  code = ''
  constructor(
    public authService: AuthService,
    private router: Router,
    private systemMessageService: SystemMessageService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.code = this.route.snapshot.queryParams['code'];
    if (!this.code) {
      this.router.navigate(['/login']);
    }
    if (this.code) {
      this.authService.email(this.code).subscribe({
        next: (resp: string) => {
          this.email = resp
        }
      })
    }
  }

  calculatePasswordStrength(password: string) {

    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=]/.test(password);

    let strength = 0;

    if (password.length >= minLength) {
      strength += 20;
    }

    if (hasUppercase) {
      strength += 20;
    }

    if (hasLowercase) {
      strength += 20;
    }

    if (hasDigit) {
      strength += 20;
    }

    if (hasSpecialCharacter) {
      strength += 20;
    }

    if (strength === 100) {
      this.loadingRegister = true;
    }

    this.passwordStrength = strength;
  }

  onRegister(password: string) {

    if (!this.loadingRegister) return;

    this.authService.register(this.email, password, this.code).subscribe({
      next: () => {
        this.loadingRegister = false;
        this.systemMessageService.success(`Verification link sent`);
        this.router.navigate(['/login']);
      },
      error: () => {
        this.loadingRegister = false;
      }
    })
  }

  onToggle() {
    this.hide = !this.hide;
    this.iconName = this.hide ? 'eye-show' : 'eye-hide'
  }

}
