import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { AdminApi, Customer, Tag } from 'src/_api';
import { FolderApi } from 'src/_api/services/folder-api';
import { AppService } from 'src/app/app.service';
import { AuthService, UserRoleEnum } from 'src/app/core/auth/auth.service';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { FolderFile } from 'src/_api/models/folder-file';
import { TagsApi } from '@api/services/tags-api.service';
import { MediaApi } from '@api/services/media-api';

@Component({
  selector: 'flow-folder-create-modal',
  templateUrl: './folder-create-modal.component.html',
  styleUrls: ['./folder-create-modal.component.scss']
})
export class FolderCreateModalComponent implements OnInit {
  form: FormGroup;
  customers: Customer[];
  tags: Tag[];
  isAdmin: boolean;
  customerId: number;
  folderId: number;
  folder: FolderFile;

  constructor(
    private adminApi: AdminApi,
    private tagsApi: TagsApi,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService,
    private translateConfigService: TranslateConfigService,
    private authService: AuthService,
    private folderApi: FolderApi,
    private mediaApi: MediaApi,
    public dialogRef: MatDialogRef<FolderCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    this.appService.hasToolbarFormActions = true;
    this.folderId = data.folderId;
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.getData();
  }


  private getData() {
    this.initForm();
    forkJoin([
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin ? this.adminApi.getCustomerById(this.data.customerId) : of(null),
      this.folderId ? this.mediaApi.getMediasAndFolders({ folderId: this.folderId, types: [3] }) : of(null)
    ])
      .subscribe(([customers, customer, files]) => {
        this.customers = customers || [customer];
        this.folder = files?.folders[0]
        if (this.data.customerId || this.customerId) {
          this.form.get('customerId').setValue(this.data.customerId || this.customerId)
        }
      });
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }

    const body = {
      ...this.form.value,
      customerId: this.form.value?.customerId || this.customerId,
      tags: this.form.value?.tags?.map((t) => {
        if (t.id) return { id: t.id };
        return { name: t.name }
      })
    };

    if (this.folderId) {
      body.id = this.folderId
    }

    if (!this.folderId) {
      this.folderApi.createFolder({ body }).subscribe(() => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.folderCreate"))
        this.dialogRef.close(true)
      })
    } else {
      this.folderApi.updateFolder({ body }).subscribe(() => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.folderEdit"))
        this.dialogRef.close(true)
      })
    }
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  getTagsByCustomerId(customerId: number) {
    this.tagsApi.getTags({ customerIds: [customerId] }).subscribe((tags) => {
      this.tags = tags;
      if (this.folderId) {
        this.form.get('name').setValue(this.folder.name);
        this.form.get('tags').setValue(this.folder.tags)
      }
    })
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      customerId: [null, Validators.required],
      tags: [],
    });
    this.form.get('customerId').valueChanges.subscribe((customerId) => this.getTagsByCustomerId(customerId))
  }


}

interface ModalData {
  folderId?: number;
  customerId?: number;
}

