<mat-form-field class="w-full">
       <mat-label>{{'peopleCount.month' | translate}}</mat-label>
       <input matInput
              [max]="maxDate"
              [min]="minDate"
              [formControl]="formValue"
              [matDatepicker]="picker">
       <mat-datepicker-toggle matSuffix
                              [for]="picker">
       </mat-datepicker-toggle>
       <mat-datepicker #picker
                       startView="year"
                       [calendarHeaderComponent]="headerComponent"
                       (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
</mat-form-field>