import { TopGroupEnum } from "@admin/target-group/top-group-enum";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MetricBoxLanes } from "@api/models/people-counter-statistics-response";
import { DateTime } from "luxon";

@Component({
  selector: 'flow-people-count-kpis',
  templateUrl: './people-count-kpis.component.html',
  styleUrls: ['./people-count-kpis.component.scss']
})
export class PeopleCountKpisComponent implements OnInit, OnChanges {

  @Input() peopleCount: MetricBoxLanes;
  @Input() isAdmin!: boolean;
  @Input() startDate!: string;
  @Input() endDate!: string;
  weeklyIsPositive: boolean
  dailyIsPositive: boolean
  weekStartDate = DateTime.now().startOf('week').toISODate()
  weekEndDate = DateTime.now().endOf('week').toISODate()
  days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  lastWeekDay: string = this.days[new Date().getDay()];
  protected readonly TopGroupEnum = TopGroupEnum;

  constructor() { }

  ngOnInit(): void {
    this.calcTrend();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.peopleCount) {
     this.calcTrend();
    }
  }

  calcTrend() {
    this.weeklyIsPositive = (this.peopleCount?.weekly?.totalCurrentWeek - this.peopleCount?.weekly?.totalLastWeek) > 0
    this.dailyIsPositive = (this.peopleCount?.daily?.totalToday - this.peopleCount?.daily?.totalLastWeekCurrentDay) > 0
  }
}
