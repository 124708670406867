import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'flow-tabs-item',
  templateUrl: './tabs-item.component.html',
  styleUrls: ['./tabs-item.component.scss']
})
export class TabsItemComponent {
    @Input() title = '';
    @Input() subTitle = '';
    @Input() error = false;
    @Input() disabled = false;
    @ViewChild('content') content!: TemplateRef<string>;
}