import { FormControl } from "@angular/forms";
import { Tag } from "@api/index";

export interface MediaFilesFormGroup {
  id: FormControl<string>;
  name: FormControl;
  client: FormControl<number>;
  tags: FormControl<Tag[]>;
  status: FormControl<UploadStatus>;
  selectedFile: FormControl;
  uploadProgress: FormControl<number>;
}
export interface MediaFiles extends SelectedFile {
  id: string;
  name: string;
  customerId: number;
  clientId: number;
  tags: Tag[];
}

export interface MediaFormGroup {
  customerId: FormControl<number>;
  medias: MediaFiles[];
}

export interface MediaFile {
  filePreview: string
  fileSize: number
  format: MediaFormat
  index: null
  name: string
  selectedFile: SelectedFile;
}

export enum MediaFormat {
  Video = "video",
  Image = "image"
}

export interface SelectedFile {
  index: string;
  selectedFile: File;
  fileSize: number;
  filePreview?: string;
  format?: MediaFormat;
  videoDuration?: number;
  name?: string
}

export enum UploadStatus {
  success = 'success',
  error = 'error'
}