import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import { ContactPerson } from "../models/contact-person";
import { PaginationResult } from "@api/models/paginator";

@Injectable({
    providedIn: 'root',
  })
  export class ContactPersonApi extends BaseService {
    constructor(
      config: ApiConfiguration,
      http: HttpClient
    ) {
      super(config, http);
    }

    static readonly GetContactPersonPath = '/contactPerson';

    getContactPersons(params): Observable<ContactPerson[]> {
      return this.http.get<Array<ContactPerson>>(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}`, {params});
    }
    
    getContactPersonList(params?): Observable<PaginationResult<ContactPerson>> {
      return this.http.get<PaginationResult<ContactPerson>>(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}/list`, params ? { params } : {});
    }

    createContactPerson(contactPerson: ContactPerson): Observable<any> {
      return this.http.post(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}`, contactPerson);
    }

    editContactPersons(contactPerson: ContactPerson): Observable<any> {
      return this.http.put(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}`, contactPerson);
    }

    deleteContactPersons(customerId: number, contactPersonId: number): Observable<any> {
      return this.http.delete(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}/${customerId}/${contactPersonId}`);
    }

    getContactPersonDetails(contactPersonId: number): Observable<any> {
      return this.http.get<Array<ContactPerson>>(`${this.rootUrl}${ContactPersonApi.GetContactPersonPath}/details/${contactPersonId}`);
    }
}
