<div>
  <div class="flex justify-start items-center">
    <mat-form-field class="mt-4">
      <mat-label>{{'exposures.groupExposuresOn' | translate}}</mat-label>
      <mat-select [formControl]="groupByFC">
        <mat-option *ngFor="let gd of groupData"
                    [value]="gd.id">
          {{gd.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex justify-center items-center">
    <h2 class="chart-period m-0 text-center">
      {{label}}
    </h2>
  </div>
</div>