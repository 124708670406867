<div class="popover rounded-md bg-white border border-gray-200 shadow-[0px_30px_15px_rgba(0,_0,_0,_0.15)]"
     [ngClass]="{'show': isOpen}"
     [ngStyle]="{top: top + 'px', left: left + 'px'}">
  <div class="flex flex-row p-4 pr-3 justify-between items-center bg-white border-b border-gray-100">
    <p class="p-0 m-0 text-brand text-sm font-semibold leading-[18px]">{{options?.data?.title}}</p>
    <button class="btn-round-primary">
      <app-icon fill="#202020"
                iconName="close"
                size="md"
                (click)="closePopover()">
      </app-icon>
    </button>
  </div>
  <div class="p-4">
    <ng-template #popoverContent></ng-template>
  </div>
</div>