import { Chart } from 'chart.js';

export const customPlugin = {
  id: 'customPlugin',
  afterDatasetDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales['x'];
    const data = chart.data


    if (!chart.options.customPlugin ) {
      return;
    }


    data.datasets[0].images.forEach((data, index) => {
      const label = new Image();
      label.src = data
      const width = 24

      const x = xAxis.getPixelForValue(index)

      ctx.drawImage(label, x-10, xAxis.top+5, width, width)

    })

  }
};

Chart.register(customPlugin);
