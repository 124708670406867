import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DateTime } from "luxon";
import { BaseChartDirective } from 'ng2-charts';
import { PeopleCounterAgeDistributionData } from 'src/_api/models/people-counter-age-distribution-data copy';
import {AgeDistribution, AgeRangeDistributionResponse} from "@admin/statistics/people-count/people-count.component";
import {TranslateConfigService} from "@core/translate-config.service";
@Component({
  selector: 'flow-age-distribution-graph',
  templateUrl: './age-distribution-graph.component.html',
  styleUrls: ['./age-distribution-graph.component.scss']
})
export class AgeDistributionGraphComponent implements OnChanges {
  @Input('channels') channels: any[];
  @Input('customerId') customerId: any;
  @Input() responseData: PeopleCounterAgeDistributionData;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  chartPlugins = [DataLabelsPlugin];
  chartData: ChartData<'bar', number[], string | string[]> = null;
  chartOptions: ChartConfiguration['options'] = {};
  currentWeekStartDate = DateTime.now().startOf('week').toISODate()
  currentWeekEndDate = DateTime.now().endOf('week').toISODate()
  hasData = false;
  @Input() ageDistributionData!: AgeRangeDistributionResponse;
  @Input() ageDistributionCompareData!: AgeRangeDistributionResponse;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() compareStartDate!: string;
  @Input() compareEndDate!: string;
  @Input() compare!: boolean;
  weekNumber: number;
  weekNumberCompare: number;


  constructor(private translateService: TranslateService,
              protected translateConfigService: TranslateConfigService) {
    this.setChartOptions();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
      this.updateChartData();
      this.weekNumber = DateTime.fromISO(this.startDate).weekNumber;
      this.weekNumberCompare = DateTime.fromISO(this.compareStartDate).weekNumber;
  }
  updateChartData(): void {
    this.hasData = !!this.ageDistributionData?.ageRangeDistribution?.length;
    const menColor = 'rgba(31, 87, 146, 1)';
    const womenColor = 'rgba(250, 98, 0, 1)';
    const menCompareColor = 'rgba(162, 195, 214, 0.8)';
    const womenCompareColor = 'rgba(239, 194, 169, 0.8)';
    const menBorderColor = 'rgba(33, 150, 243, 0)';
    const womenBorderColor = 'rgba(244, 67, 54, 0)';

    if(!this.compare) {
      const totalMenArray = []
      const totalWomanArray = []

      this.ageDistributionData?.ageRangeDistribution?.forEach((ageSpan, index) => {
        totalMenArray.push(ageSpan?.totalMen)
        totalWomanArray.push(ageSpan?.totalWomen)
      });
      const dataSetMen = {
        label: this.translateService.instant('peopleCount.men'),
        data: totalMenArray,
        fill: 'origin',
        backgroundColor: menColor,
        hoverBackgroundColor: menColor,
        borderColor: menBorderColor,
        pointBackgroundColor: menBorderColor
      };
      const dataSetWoman = {
        label: this.translateService.instant('peopleCount.women'),
        data: totalWomanArray,
        fill: 'origin',
        backgroundColor: womenColor,
        hoverBackgroundColor: womenColor,
        borderColor: womenBorderColor,
        pointBackgroundColor: womenBorderColor
      };
      this.chartData = {
        labels: this.ageDistributionData?.ageRangeDistribution?.map(a => this.translateService.instant('peopleCount.targetGroups.' + (a.ageRange.charAt(0).toLowerCase() + a.ageRange.replace(' ', '').slice(1)))),
        datasets: [dataSetMen, dataSetWoman],
      };
    } else {
      const totalMenArray = []
      const totalWomanArray = []
      const totalMenArrayToCompare = []
      const totalWomanArrayToCompare = []
      const weekNumber = DateTime.fromISO(this.startDate).weekNumber;
      const weekNumberCompare = DateTime.fromISO(this.compareStartDate).weekNumber;

      this.ageDistributionData?.ageRangeDistribution?.forEach((ageSpan, index) => {
        totalMenArray.push(ageSpan?.totalMen)
        totalWomanArray.push(ageSpan?.totalWomen)
      });

      this.ageDistributionCompareData?.ageRangeDistribution?.forEach((ageSpan, index) => {
        totalMenArrayToCompare.push(ageSpan?.totalMen)
        totalWomanArrayToCompare.push(ageSpan?.totalWomen)
      });

      const dataSetMen = {
        label: `${this.translateService.instant('peopleCount.men')}, ${this.translateConfigService.instant('peopleCount.week')} ${weekNumber}`,
        data: totalMenArray,
        fill: 'origin',
        backgroundColor: menColor,
        hoverBackgroundColor: menColor,
        borderColor: menBorderColor,
        pointBackgroundColor: menBorderColor
      };
      const dataSetWoman = {
        label: `${this.translateService.instant('peopleCount.women')}, ${this.translateConfigService.instant('peopleCount.week')} ${weekNumber}`,
        data: totalWomanArray,
        fill: 'origin',
        backgroundColor: womenColor,
        hoverBackgroundColor: womenColor,
        borderColor: womenBorderColor,
        pointBackgroundColor: womenBorderColor
      };
      const dataSetMenToCompare = {
        label: `${this.translateService.instant('peopleCount.men')}, ${this.translateConfigService.instant('peopleCount.week')} ${weekNumberCompare}`,
        data: totalMenArrayToCompare,
        fill: 'origin',
        backgroundColor: menCompareColor,
        hoverBackgroundColor: menCompareColor,
        borderColor: menBorderColor,
        pointBackgroundColor: menBorderColor
      };
      const dataSetWomanToCompare = {
        label:`${this.translateService.instant('peopleCount.women')}, ${this.translateConfigService.instant('peopleCount.week')} ${weekNumberCompare}`,
        data: totalWomanArrayToCompare,
        fill: 'origin',
        backgroundColor: womenCompareColor,
        hoverBackgroundColor: womenCompareColor,
        borderColor: womenBorderColor,
        pointBackgroundColor: womenBorderColor
      }
      this.chartData = {
        labels: this.ageDistributionData?.ageRangeDistribution?.map(a => this.translateService.instant('peopleCount.targetGroups.' + (a.ageRange.charAt(0).toLowerCase() + a.ageRange.replace(' ', '').slice(1)))),
        datasets: [dataSetMen, dataSetWoman, dataSetMenToCompare, dataSetWomanToCompare],
      };

    }

    this.chart?.update();
  }
  private setChartOptions() {
    this.chartOptions = {
      scales: {
        x: {
          type: 'category', // This line is important for X-axis to recognize labels
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        },
        y: {
          grace: '5%',
          title: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, values) => {
              const total1 = this.ageDistributionData?.total ?? 0;
              const total2 = this.ageDistributionCompareData?.total ?? 0;
              let displayValue = Math.round((value as number / (total1 + total2)) * 100);
              displayValue = Math.min(displayValue, 100);

              return total1 > 0 ? `${displayValue} %` : '';
            }
          }
        },
      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 3,
            boxHeight: 30,
            textAlign: 'left',
            padding: 5,
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    };
  }
}
