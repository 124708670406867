import { DateTime } from 'luxon';

export interface EngagementSlot {
  startDate?: string;
  endDate?: string;
  date?: string;
  isBooked?: BOOK_SLOT_STATUS;
  isSelected?: boolean;
  isMainBooking?: boolean;
  engagementId?: number;
  engagementSlotId?: number;
  weekStartDate?: string;
  weekEndDate?: string;
}

export enum BOOK_SLOT_STATUS {
  IS_BOOKED_EXTERNALLY = 0,
  IS_BOOKED_BY_USER = 1,
  IS_SELECTED = 2,
}
