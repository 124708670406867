import { MonthEnum } from "@api/models/months-enum";
import { WeekDayEnum } from "@api/models/week-day-enum";
import { TrackingEnum } from "@api/models/tracking.enum";
import { TranslationsEnum } from "@api/models/translations-enum";

export const Translations = {
  [TranslationsEnum.English]: 'en',
  [TranslationsEnum.Swedish]: 'se',
  [TranslationsEnum.German]: 'de',
  [TranslationsEnum.Finnish]: 'fi',
  [TranslationsEnum.Italian]: 'it',
};


export const LanguageLongTranslate = {
  [TranslationsEnum.English]: 'English',
  [TranslationsEnum.Swedish]: 'Svenska',
  [TranslationsEnum.German]: 'Deutsch',
  [TranslationsEnum.Finnish]: 'Suomi',
  [TranslationsEnum.Italian]: 'Italiano'
};

export const TrackingTypeTranslations = {
  [TrackingEnum.Inbound]: 'reports.form.trackingValues.inbound',
  [TrackingEnum.Outbound]: 'reports.form.trackingValues.outbound',
  [TrackingEnum.NotApplicable]: 'reports.form.trackingValues.notApplicable'
};


export const WeekdayTranslations = {
  [WeekDayEnum.Monday]: 'global.days.monday',
  [WeekDayEnum.Tuesday]: 'global.days.tuesday',
  [WeekDayEnum.Wednesday]: 'global.days.wednesday',
  [WeekDayEnum.Thursday]: 'global.days.thursday',
  [WeekDayEnum.Friday]: 'global.days.friday',
  [WeekDayEnum.Saturday]: 'global.days.saturday',
  [WeekDayEnum.Sunday]: 'global.days.sunday',
};

export const MonthTranslations = {
  [MonthEnum.January]: 'global.months.january',
  [MonthEnum.February]: 'global.months.february',
  [MonthEnum.March]: 'global.months.march',
  [MonthEnum.April]: 'global.months.april',
  [MonthEnum.May]: 'global.months.may',
  [MonthEnum.June]: 'global.months.june',
  [MonthEnum.July]: 'global.months.july',
  [MonthEnum.August]: 'global.months.august',
  [MonthEnum.September]: 'global.months.september',
  [MonthEnum.October]: 'global.months.october',
  [MonthEnum.November]: 'global.months.november',
  [MonthEnum.December]: 'global.months.december',
};
