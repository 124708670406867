import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription, interval, takeUntil } from 'rxjs';
import { EngagementMediaFile, Screen, StatisticsEngagementMediaFilesResponse, StatisticsEngagementMediaFilesScreenDay, StatisticsApi, TargetGroupEnum, StatisticsCurrentMonth, EngagementTypeEnum } from 'src/_api';
import { STATISTICS_EXPOSURES_POLLING_INTERVAL } from 'src/app/constants';

import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { TargetGroupEnumTranslations } from '../../target-group/target-group-enum-translations';
import { TargetGroupHelper } from '../../target-group/target-group.helper';
import { ChartWeekday, StatisticsService } from '../statistics.service';
import { EnumService } from 'src/app/core/services/enum.service';
import { StatisticsFilterValues } from 'src/app/models/statistics-filter-values';

const mapEmptyToNull = value => value || null

@Component({
  template: ''
})
export abstract class AbstractViewsComponent extends unsubscribeMixin() implements OnInit {
  @Input() filter: StatisticsFilterValues;
  @Input() selected: EngagementMediaFile;
  @Input() screens: Screen[]
  @Input() currentMonthStatistics?: StatisticsCurrentMonth;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public chartOptions: ChartConfiguration['options'] = {
    scales: {
      y: { grace: '5%' }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public chartData: ChartData<'bar'> = null;
  public chartPlugins = [DataLabelsPlugin];
  public weekDays = [];
  public groupData = [];

  statsRequest: Subscription;
  statistics: StatisticsEngagementMediaFilesResponse;
  hideDefaultAdFC = new FormControl(true);
  GroupByData = GroupByData;
  groupByFC = new FormControl(GroupByData.Total);

  constructor(
    protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected targetGroupHelper: TargetGroupHelper,
    protected translateConfigService: TranslateConfigService,
    protected enumService: EnumService
  ) {
    super();
    this.weekDays = enumService.weekDays;
  }


  ngOnInit(): void {
    this.groupByFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onGroupByChange);
    interval(STATISTICS_EXPOSURES_POLLING_INTERVAL)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateData();
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected?.currentValue) {
      this.updateData();
    }
    if (changes.currentMonthStatistics?.currentValue && this.isTimespanInCurrentMonth()) {
      this.updateData()
    }
  }
  private onGroupByChange = (value: GroupByData): void => {
    this.chartData = this.mapChartData(this.statistics)
    this.chart.update();
  };
  onPeriodChange() {
    this.updateData()
  }

  protected updateData(): void {
    const { startTime, endTime } = this.getStartAndEndTime()
    if (!startTime || !endTime || !this.selected) return

    if (this.isTimespanInCurrentMonth()) {
      // Statistics is in current month, no need to fetch new data
      if (this.currentMonthStatistics?.engagementMediaFileId === this.selected.id) {
        this.statistics = this.currentMonthStatistics.statistics
        this.chartData = this.mapChartData(this.currentMonthStatistics.statistics)
        this.chart.update();
        this.updateChartDataWithTranslation();
      }
      return
    }
    this.statsRequest?.unsubscribe();
    let apiCall: string;
    let id: string;

    if (this.selected.engagementType === EngagementTypeEnum.Segment) {
      apiCall = "getAdvertisementStatistics";
      id = "advertisementId";
    }
    if (this.selected.engagementType === EngagementTypeEnum.Playlist) {
      apiCall = "getPlaylistMediaSettingsStatistics";
      id = "playlistMediaFileId";
    }
    this.statsRequest = this.statisticsApi[apiCall]({
      [id]: this.selected.id,
      body: {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
      }
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stats) => {
        if (this.statistics?.perScreenDay && isEqual(stats.perScreenDay, this.statistics.perScreenDay)) {
          return;
        }
        this.statistics = stats
        this.chartData = this.mapChartData(stats)
        this.chart.update();
        this.updateChartDataWithTranslation();
      });
  }

  //this method is used to be overridden in the component ts
  protected updateChartDataWithTranslation() {

  }

  protected isTimespanInCurrentMonth() {
    const { startTime, endTime } = this.getStartAndEndTime()
    if (!startTime || !endTime || !this.selected) return false

    const now = DateTime.local().startOf('day');
    const startMonth = now.startOf('month')
    const endMonth = now.endOf('month')

    return startTime.startOf('day') >= startMonth && endTime.startOf('day') <= endMonth
  }

  protected mapChartData(stats: StatisticsEngagementMediaFilesResponse): ChartData<'bar'> {
    const { perScreenDay, targetGroups } = stats
    const { startTime, endTime } = this.getStartAndEndTime()
    const days = this.statisticsService.buildPeriod(startTime, endTime, this.getColumnType());

    let datasets = []
    switch (this.groupByFC.value) {
      case GroupByData.TargetGroup:
        datasets = this.mapByTargetGroups(days, perScreenDay)

        break;
      case GroupByData.Screen:
        datasets = this.mapByScreen(days, perScreenDay)
        break;
      case GroupByData.Total:
      default:
        datasets = this.mapByTotal(days, perScreenDay, targetGroups)
        break;
    }
    const labels: string[] = days.map(day => day.label)
    return {
      labels,
      datasets
    };
  }

  protected mapByTotal(days: ChartWeekday[], perDay: StatisticsEngagementMediaFilesScreenDay[], targetGroups: TargetGroupEnum[]) {
    if (this.selected.engagementType === EngagementTypeEnum.Playlist) {
      const total = days.reduce((acc, day) => {
        const counts = perDay.filter(d => d.date === day.utcString);
        const mapDaysCounts = counts.map((day) => day?.counts);
        const multipleCounts = mapDaysCounts.map((c) => {
          return c.reduce((sum, curr) => sum + curr.count ?? 0, 0);
        })
        const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
        acc.push(add)
        return acc
      }, [])

      return [
        {
          label: 'exposures.title',
          data: total.map(mapEmptyToNull)
        }
      ]
    }
    const primaryViews2 = days.reduce((acc, day) => {
      const counts = perDay.filter(d => d.date === day.utcString);
      const mapDaysCounts = counts.map((day) => day?.counts);
      const multipleCounts = mapDaysCounts.map((c) => {
        return c.filter((i) => targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0);
      })
      const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
      acc.push(add)
      return acc
    }, [])

    const secondaryViews2 = days.reduce((acc, day) => {
      const counts = perDay.filter(d => d.date === day.utcString);
      const mapDaysCounts = counts.map((day) => day?.counts);
      const multipleCounts = mapDaysCounts.map((c) => {
        return c.filter((i) => !targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0);
      })
      const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
      acc.push(add)
      return acc
    }, [])

    return [
      {
        label: 'exposures.primaryExposures',
        data: primaryViews2.map(mapEmptyToNull)
      },
      {
        label: 'exposures.secondaryExposures',
        data: secondaryViews2.map(mapEmptyToNull)
      }
    ]
  }
  private mapByScreen(days: ChartWeekday[], perDay: StatisticsEngagementMediaFilesScreenDay[]) {
    const screens = perDay.reduce((acc, curr) => acc.includes(curr.screenId) ? acc : [...acc, curr.screenId], [])

    return screens.map(screenId => ({
      label: this.screens.find((s) => s.id === screenId)?.humanUniqueIdentifier,
      data: days.map(day => {
        const counts = perDay.filter((i) => i.screenId === screenId).find(d => d.date === day.utcString)?.counts ?? [];
        return counts.reduce((sum, curr) => sum + curr.count ?? 0, 0)
      }).map(mapEmptyToNull)
    }))
  }
  private mapByTargetGroups(days: ChartWeekday[], perDay: StatisticsEngagementMediaFilesScreenDay[]) {
    const targetGroups = perDay.reduce((acc, curr) => {
      curr.counts?.forEach(count => {
        if (!acc.includes(count.targetGroup) && count.targetGroup) {
          acc.push(count.targetGroup)
        }
      })
      return acc
    }, [])

    return targetGroups.map(targetGroupId => ({
      label: TargetGroupEnumTranslations[targetGroupId],
      data: days.map(day => {
        const counts = perDay.filter(d => d.date === day.utcString);
        const mapDaysCounts = counts.map((day) => day?.counts);
        const multipleCounts = mapDaysCounts.map((c) => {
          const countsForTargetGroup = c.find((count) => count.targetGroup === targetGroupId)
          return countsForTargetGroup?.count ?? 0
        })
        const add = multipleCounts.reduce((sum, curr) => sum + curr, 0);
        return add;
      }).map(mapEmptyToNull),
      ...this.targetGroupHelper.getChartColors(targetGroupId)

    }))
  }

  abstract getStartAndEndTime(): { startTime: DateTime, endTime: DateTime }
  abstract getColumnType(): 'date' | 'day'

}

export enum GroupByData {
  Total,
  Screen,
  TargetGroup
}


export const GroupDataTranslations = {
  [GroupByData.Total]: 'exposures.total',
  [GroupByData.Screen]: 'exposures.screen',
  [GroupByData.TargetGroup]: 'exposures.targetGroup',
}
