import { Component, Input, TemplateRef, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'flow-tabs-item',
  templateUrl: './tabs-item.component.html',
  styleUrls: ['./tabs-item.component.scss'],
})
export class TabsItemComponent implements OnChanges {
  @Input() title = '';
  @Input() subTitle = '';
  @Input() error = false;
  @Input() disabled = false;
  @ViewChild('content') content!: TemplateRef<string>;

  @Output() disabledChange = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabledChange.emit(this.disabled);
    }
  }
}
