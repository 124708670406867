import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StatisticsApi, VehicleType } from '@api/index';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'flow-vehicles-daily',
  templateUrl: './vehicles-daily.component.html',
  styleUrls: ['./vehicles-daily.component.scss']
})
export class VehiclesDailyComponent implements OnInit, OnChanges {
  @Input('channels') channels: any[];
  @Input('customerId') customerId: any;
  @Input('screenIds') screenIds: any;
  @Input() end!: DateTime;
  @Input() start!: DateTime;
  form: FormGroup;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public weekDays = [];
  public chartData: ChartData<'line', number[], string | string[]> = null;
  selectedDate: string = DateTime.now().toISODate();
  noChartData: boolean = false;
  formInitialized = false;
  carColor = 'rgb(14, 51, 72)';
  vanColor = 'rgb(232, 170, 134)';
  busColor = 'rgb(112, 185, 187)';
  truckColor = 'rgb(252, 75, 71)';
  otsColor = 'rgb(213, 187, 166)';

  public chartOptions: ChartConfiguration['options'] = {
    elements: {
      line: { tension: 0.5 }
    },
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 1,
          boxHeight: 30,
          textAlign: 'left',
          padding: 5
        }
      },
      datalabels: { display: false },
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        ticks: {
          padding: 10,
        }
      },
      x: {
        grid: {
          tickColor: 'white',
          color: '#F5F5F5',
          lineWidth: 2
        },
        ticks: {
          padding: 20
        },
      }
    }
  };


  constructor(private statisticApi: StatisticsApi, private formBuilder: FormBuilder, protected translateService: TranslateService) {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      date: [this.getRelevantDate(this.start, this.end)],
    })

    this.form.get('date').valueChanges.subscribe((value) => {
      if(this.formInitialized){
        this.statisticApi.getVehiclesDaily({ date: this.form.get('date').value, customerId: this.customerId, channelIds: this.channels.map(channel => channel.id), screenIds: this.screenIds.map(s => s.id) }).subscribe(data => {
          this.selectedDate = value.toFormat('yyyy-MM-dd');
          this.updateChartData(data);
        });
      }
    })
  }

  ngOnInit(): void {
    this.statisticApi.getVehiclesDaily({ date: this.form.get('date').value, customerId: this.customerId, channelIds: this.channels.map(channel => channel.id), screenIds: this.screenIds.map(s => s.id) }).subscribe(data => {
      this.updateChartData(data);
      this.formInitialized = true;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form.get('date').setValue(this.getRelevantDate(this.start, this.end))
  }

  private getRelevantDate(startDate, endDate) {
    const today = DateTime.local().startOf('day');
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).startOf('day');


    if (today >= start && today <= end) {
      return today;
    } else {
      return end;
    }
  }

  updateChartData(data) {
    if(data.screenHours.length) {
      this.noChartData = false;

      const hoursWithData = data.screenHours.map((item) => Number(DateTime.fromISO(item.time, { setZone: true }).hour));
      const startHour = Math.min(...hoursWithData);
      const endHour = Math.max(...hoursWithData);

      const statHours = [];
      for (let i = startHour; i <= endHour; i++) statHours.push(i);

      const vehicleCountsByHour: { [hour: number]: { [key in VehicleType]: number } } = {};
      const otsByHour: { [hour: number]: number } = {};

      statHours.forEach(hour => {
        vehicleCountsByHour[hour] = {
          [VehicleType.Car]: 0,
          [VehicleType.Van]: 0,
          [VehicleType.Bus]: 0,
          [VehicleType.Truck]: 0,
        };
        otsByHour[hour] = 0;
      });

      data.screenHours.forEach(item => {
        const hour = Number(DateTime.fromISO(item.time, { setZone: true }).hour);
        item.vehicles.forEach(vehicle => {
          vehicleCountsByHour[hour][vehicle.vehicleType] = vehicle.count;
        });
        otsByHour[hour] = item.OTS;
      });

      this.chartData = {
        labels: statHours.map(h => `${h}:00`),
        datasets: [
          {
            backgroundColor: this.carColor,
            borderColor: this.carColor,
            data: statHours.map(hour => vehicleCountsByHour[hour][VehicleType.Car]),
            fill: false,
            hoverBackgroundColor: this.carColor,
            label: this.translateService.instant('reach.vehicles.cars'),
            pointBackgroundColor: this.carColor,
            pointRadius: 0
          },
          {
            backgroundColor: this.vanColor,
            borderColor: this.vanColor,
            data: statHours.map(hour => vehicleCountsByHour[hour][VehicleType.Van]),
            fill: false,
            hoverBackgroundColor: this.vanColor,
            label: this.translateService.instant('reach.vehicles.vans'),
            pointBackgroundColor: this.vanColor,
            pointRadius: 0
          },
          {
            backgroundColor: this.busColor,
            borderColor: this.busColor,
            data: statHours.map(hour => vehicleCountsByHour[hour][VehicleType.Bus]),
            fill: false,
            hoverBackgroundColor: this.busColor,
            label: this.translateService.instant('reach.vehicles.buses'),
            pointBackgroundColor: this.busColor,
            pointRadius: 0,
          },
          {
            backgroundColor: this.truckColor,
            borderColor: this.truckColor,
            data: statHours.map(hour => vehicleCountsByHour[hour][VehicleType.Truck]),
            fill: false,
            hoverBackgroundColor: this.truckColor,
            label: this.translateService.instant('reach.vehicles.trucks'),
            pointBackgroundColor: this.truckColor,
            pointRadius: 0,
          },
          {
            backgroundColor: this.otsColor,
            borderColor: this.otsColor,
            data: statHours.map(hour => otsByHour[hour]),
            fill: false,
            hoverBackgroundColor: this.otsColor,
            label: this.translateService.instant('reach.vehicles.ots'),
            pointBackgroundColor: this.otsColor,
            pointRadius: 0,
          },
        ]
      }
      this.chart?.update();
    } else {
      this.noChartData = true;
    }
  }



}
