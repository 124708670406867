import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolderFile } from '@api/index';
import { FolderApi } from '@api/services/folder-api';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';

@Component({
  selector: 'flow-move-file-to-folder-modal',
  templateUrl: './move-file-to-folder-modal.component.html',
  styleUrls: ['./move-file-to-folder-modal.component.scss']
})
export class MoveFileToFolderModalComponent implements OnInit {
  folders: FolderFile[];
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialogRef: MatDialogRef<MoveFileToFolderModalComponent>,
    private formBuilder: FormBuilder,
    private folderApi: FolderApi,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.setFoldersList();
  }

  setFoldersList () {
    const root = {
      id: null,
      name: "Home",
      size: 0,
      media: null,
      customer: null,
      type: 3
    };
    
    this.folders = (this.data?.folderId)
      ? [...this.data.folders]
      : [root, ...this.data.folders].filter((f) => f.id !== this.data.folderId) 
  }

  moveFile() {
    const folder = this.form.get("folder").value;
    const mediaIds = this.data.mediaId ? [this.data.mediaId] : this.data.mediaIds;
    this.folderApi.moveMediasToFolder({ mediaIds: mediaIds, folderId: folder.id }).subscribe(() => {
      if (this.data.mediaId)
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.fileMoved", folder.name));
      else
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.filesMoved", folder.name));
      this.dialogRef.close(true)
    })
  }


  initForm() {
    this.form = this.formBuilder.group({
      folder: [null, Validators.required],
    });
  }
}

interface ModalData {
  folders: FolderFile[] | null;
  mediaId: number;
  mediaIds: number[];
  folderId: number | null;
}
