import { Component, Input } from '@angular/core';

@Component({
  selector: 'flow-pill-box',
  templateUrl: './pill-box.component.html',
  styleUrls: ['./pill-box.component.scss']
})
export class PillBoxComponent {
  @Input() color: string = '';
  @Input() label: string = '';
  @Input() tooltip?: string;
}
