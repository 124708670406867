import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {GenderDistribution} from "@admin/statistics/people-count/people-count.component";
import {DateTime} from "luxon";

@Component({
  selector: 'flow-gender-graph',
  templateUrl: './gender-graph.component.html',
  styleUrls: ['./gender-graph.component.scss']
})
export class GenderGraphComponent implements OnChanges {
  @Input() genderDistributionData: GenderDistribution;
  @Input() genderDistributionCompareData: GenderDistribution;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() compareStartDate!: string;
  @Input() compareEndDate!: string;
  @Input() compare!: boolean;
  weekNumber: number;
  weekNumberCompare: number;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.weekNumber = DateTime.fromISO(this.startDate).weekNumber;
    this.weekNumberCompare = DateTime.fromISO(this.compareStartDate).weekNumber;
    }

}
