import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { ScreenGroup } from "@api/models/screen-group";

@Injectable({
  providedIn: 'root',
})
export class ScreenGroupApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }


  static readonly GetScreenGroupsPath = '/screenGroups';

  getScreenGroups(params) {
    return this.http.get<any>(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}`, {params});
  }

  getScreenGroupDetails(screenGroupId: number) {
    return this.http.get<ScreenGroup>(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}/${screenGroupId}`)
  }

  deleteScreenGroup(screen) {
    return this.http.delete(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}/?customerId=${screen.customerId}&screenGroupId=${screen.id}`)
  }

  updateScreenGroup(body) {
    return this.http.put(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}/?customerId=${body.customerId}&screenGroupId=${body.id}`, body)
  }

  saveScreenGroup(body) {
    return this.http.post(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}/?customerId=${body.customerId}`, body)
  }

  activateScreenGroup(screenGroupId: number, isActive) {
    return this.http.put(`${this.rootUrl}${ScreenGroupApi.GetScreenGroupsPath}/${screenGroupId}/status`, isActive);
  }
}
