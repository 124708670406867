
<div class="relative aspect-video max-w-5xl bg-gray-800 flex items-center justify-center overflow-hidden">
  <a  mat-dialog-title class="mat-dialog-close-button absolute top-0 right-0 z-10"
     mat-icon-button
     (click)="close()"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
</a>
  <div
    class="relative w-full h-full min-w-[1024px]">
    <div
      class="w-full h-full flex-shrink-0 flex items-center justify-center transition-opacity duration-200 ease-in-out"
      *ngFor="let item of mediaItems; let i = index"
      [ngClass]="{ 
        'opacity-100 visible absolute top-0 left-0': i === currentSlide, 
        'opacity-0 invisible pointer-events-none': i !== currentSlide 
      }">
        <flow-media-preview-slide
          [mediaItems]="item"
          (videoEnded)="handleVideoEnded()"
        ></flow-media-preview-slide>
    </div>
  </div>

  <div class="absolute bottom-0 w-full flex items-center justify-center space-x-4 p-4">
    <button
      class="text-white bg-black/70 rounded-md w-10 h-8 flex items-center justify-center"
      (click)="prevSlide()"
    >
    <app-icon size="md"
              stroke="#fff"
              iconName="arrow-left"></app-icon>
    </button>

    <div
      class="bg-black/70 text-white text-sm h-8 min-w-24 rounded-md flex justify-center items-center gap-2">
      <span class="font-bold">{{ currentSlide + 1 }}/{{ mediaItems.length }}</span>
      
    </div>
    <button
      class="text-white bg-black/70 rounded-md w-10 h-8 flex items-center justify-center"
      (click)="nextSlide()"
    >
    <app-icon size="md"
    stroke="#fff"
    iconName="arrow-right"></app-icon>
    </button>
  </div>
  <span
        *ngIf="currentSlideHasTriggers"
        class="absolute bottom-4 right-4 text-xs bg-brand-bright-orange text-white px-2 py-1 rounded-md font-bold">
        {{ 'media.triggerApplied' | translate }}
  </span>
</div>
