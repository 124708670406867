import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DateTime } from "luxon";
import { BaseChartDirective } from 'ng2-charts';
import {VisitorsPerHour} from "@admin/statistics/people-count/people-count.component";
import {TranslateConfigService} from "@core/translate-config.service";
@Component({
  selector: 'flow-visitors-per-hour-graph',
  templateUrl: './visitors-per-hour-graph.component.html',
  styleUrls: ['./visitors-per-hour-graph.component.scss']
})
export class VisitorsPerHourGraphComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() visitorsChartData: VisitorsPerHour[];
  @Input() visitorsChartCompareData!: VisitorsPerHour[];
  @Input() chartDataFromResponse: {
    currentWeek: {
      hour: number
      totalMen: number
      totalWomen: number
    }[],
    lastWeek: {
      hour: number
      totalMen: number
      totalWomen: number
    }[]
  };
  showStatisticsPreviousWeek = false;
  hasData:boolean
  public chartPlugins = [DataLabelsPlugin];
  public chartData: ChartData<'line', number[], string | string[]> = null;
  public chartOptions: ChartConfiguration['options'] = {};
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() compareStartDate!: string;
  @Input() compareEndDate!: string;
  @Input() compare!: boolean;
  weekNumber: number;
  weekNumberCompare: number;




  constructor(protected translateService: TranslateService,
              protected translateConfigService: TranslateConfigService) {
    this.setChartOptions();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
        this.updateChartData();
        this.weekNumber = DateTime.fromISO(this.startDate).weekNumber;
        this.weekNumberCompare = DateTime.fromISO(this.compareStartDate).weekNumber;

  }

  ngOnInit() {
  }

  updateChartData() {

    this.hasData = !!this.visitorsChartData;
    if (!this.compare) {
      const totalMenArray = [];
      const totalWomanArray = [];
      const hours = [];
      this.visitorsChartData?.forEach((item, index) => {
        hours.push(item.hour.toString().padStart(2, '0') + ':00');
        totalMenArray[index] = item?.totalMen;
        totalWomanArray[index] = item?.totalWomen;
      });
      const dataSetMen = {
        pointRadius: 0,
        label: this.translateService.instant('peopleCount.manCurrentWeek'),
        data: totalMenArray,
        fill: false,
        backgroundColor: 'rgba(31, 87, 146, 0.8)',
        borderColor: 'rgba(31, 87, 146, 0.8)',
      };
      const dataSetWoman = {
        pointRadius: 0,
        label: this.translateService.instant('peopleCount.womanCurrentWeek'),
        data: totalWomanArray,
        fill: false,
        backgroundColor: 'rgba(250, 98, 0, 0.8)',
        borderColor: 'rgba(250, 98, 0, 0.8)',
      };
      this.chartData = {
        labels: hours,
        datasets: [dataSetMen, dataSetWoman],
      };
    }
    else {
      const totalMenArray = [];
      const totalWomanArray = [];
      const totalMenArrayToCompare = [];
      const totalWomanArrayToCompare = [];
      const weekNumber = DateTime.fromISO(this.startDate).weekNumber;
      const weekNumberCompare = DateTime.fromISO(this.compareStartDate).weekNumber;
      const hours = [];
      this.visitorsChartData?.forEach((item, index) => {
        hours.push(item.hour.toString().padStart(2, '0') + ':00');
        totalMenArray[index] = item?.totalMen;
        totalWomanArray[index] = item?.totalWomen;
      });

      this.visitorsChartCompareData?.forEach((item, index) => {
        totalMenArrayToCompare[index] = item?.totalMen;
        totalWomanArrayToCompare[index] = item?.totalWomen;
      });
      const dataSetMen = {
        pointRadius: 0,
        label:`${this.translateConfigService.instant('peopleCount.manCurrentWeek')} ${this.translateConfigService.instant('peopleCount.week')} ${weekNumber}`,
        data: totalMenArray,
        fill: false,
        backgroundColor: 'rgba(31, 87, 146, 0.8)',
        borderColor: 'rgba(31, 87, 146, 0.8)',
      };
      const dataSetWoman = {
        pointRadius: 0,
        label: `${this.translateService.instant('peopleCount.womanCurrentWeek')} ${this.translateConfigService.instant('peopleCount.week')} ${weekNumber}`,
        data: totalWomanArray,
        fill: false,
        backgroundColor: 'rgba(250, 98, 0, 0.8)',
        borderColor: 'rgba(250, 98, 0, 0.8)',
      };
      const dataSetMenToCompare = {
        pointRadius: 0,
        label: `${this.translateConfigService.instant('peopleCount.manCurrentWeek')} ${this.translateConfigService.instant('peopleCount.week')} ${weekNumberCompare}`,
        data: totalMenArrayToCompare,
        fill: false,
        backgroundColor: 'rgba(162, 195, 214, 0.8)',
        borderColor: 'rgba(162, 195, 214, 0.8)',
      };
      const dataSetWomanToCompare = {
        pointRadius: 0,
        label: `${this.translateService.instant('peopleCount.womanCurrentWeek')} ${this.translateConfigService.instant('peopleCount.week')} ${weekNumberCompare}`,
        data: totalWomanArrayToCompare,
        fill: false,
        backgroundColor:'rgba(239, 194, 169, 0.8)',
        borderColor: 'rgba(239, 194, 169, 0.8)',
      };
      this.chartData = {
        labels: hours,
        datasets: [dataSetMen, dataSetWoman, dataSetMenToCompare, dataSetWomanToCompare],
      };
    }
    this.chart?.update();
  }
  updateCheckbox() {
    this.showStatisticsPreviousWeek = !this.showStatisticsPreviousWeek;
    this.updateChartData();
  }
  private setChartOptions() {
    this.chartOptions = {
      elements: {
        line: { tension: 0.5 },
      },
      scales: {
        x: {
          type: 'category', // This line is important for X-axis to recognize labels
          title: {
            display: false,
            text: this.translateService.instant('peopleCount.hourlyBreakdown'),
          },
          grid: {
            display: true,
            tickColor: 'white',
          }
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
            text: this.translateService.instant('peopleCount.visitors'),
          },
        },
      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 3,
            boxHeight: 30,
            textAlign: 'left',
            padding: 5
          }
        },
        datalabels: { display: false },
      }
    }
  }
}


