<div class="flex-col inline-flex justify-center items-center gap-1 p-2 xl:p-0 bg-neutral-100 rounded xl:bg-white lg:items-start">
  <ng-container *ngFor="let sidnevParent of sidnevMenu">
    <ng-container *ngIf="!navMini && sidnevParent?.label">
      <div
        *flowHasPermission="sidnevParent?.permissions; operation 'OR'"
        class="inline-flex my-2 text-zinc-600 text-xs font-medium font-['Inter'] uppercase leading-none">
        {{sidnevParent?.label | translate | uppercase}}
      </div>
    </ng-container>

    <ng-container *ngFor="let sidenavChild of sidnevParent.children" #ref>
      <div class="w-full">
        <flow-sidenav-item
          *flowHasPermission="sidenavChild.permissions; operation 'OR'"
          [label]="sidenavChild?.label"
          [url]="sidenavChild.url"
          [iconName]="sidenavChild.iconName"
          [permissions]="sidenavChild.permissions"
          [navMini]="navMini"></flow-sidenav-item>
      </div>
    </ng-container>
  </ng-container>
</div>
