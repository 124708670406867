import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {

  defaultMessageTime = 8000;
  dangerMessageTime = 8000;
  currentMessage: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private snackBar: MatSnackBar) { }

  success(message: string): void {
    this.openMessage('success', message);
  }

  error(message: string): void {
    this.openMessage('danger', message);
  }

  info(message: string): void {
    this.openMessage('info', message);
  }

  clear(): void {
    this.currentMessage?.dismiss();
  }

  private openMessage = (type: SystemMessageType, msg: string): void => {
    this.currentMessage = this.snackBar.open(msg, "X", {
      panelClass: `${type}-snack`,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: type === 'danger' ? this.dangerMessageTime : this.defaultMessageTime,
    });
  };

}

export type SystemMessageType = 'success' | 'danger' | 'info';
