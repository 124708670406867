<div class="flex items-center justify-between visitors">
  <div>
    <h2 class="reach-section-title">{{ "peopleCount.genderDistributionTitle" | translate }}</h2>
  </div>
</div>

<div class="flex flex-col gap-4">
  <div class="flex gap-8">
    <flow-metrics-box-gender title="men"
                             [totalValue]="genderDistributionData?.percentageOfMen"
                             [startDate]="startDate"
                             [endDate]="endDate"
                             [weekNumber]="weekNumber"
                             [compare]="compare"
    ></flow-metrics-box-gender>
    <flow-metrics-box-gender title="women"
                             [totalValue]="genderDistributionData?.percentageOfWomen"
                             [startDate]="startDate"
                             [endDate]="endDate"
                             [weekNumber]="weekNumber"
                             [compare]="compare">

    </flow-metrics-box-gender>
  </div>

  <div class="flex gap-8" *ngIf="compare">
    <flow-metrics-box-gender title="men"
                             [totalValue]="genderDistributionCompareData?.percentageOfMen"
                             [startDate]="compareStartDate"
                             [endDate]="compareEndDate"
                             [weekNumberCompare]="weekNumberCompare"
                             [compare]="compare"
    ></flow-metrics-box-gender>
    <flow-metrics-box-gender title="women"
                             [totalValue]="genderDistributionCompareData?.percentageOfWomen"
                             [startDate]="compareStartDate"
                             [endDate]="compareEndDate"
                             [weekNumberCompare]="weekNumberCompare"
                             [compare]="compare">
    </flow-metrics-box-gender>
  </div>
</div>
