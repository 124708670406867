<div class="flex flex-row items-center">
  <nav class="mr-5" mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      *ngFor="let tab of tabsList; let index = index"
      mat-tab-link
      class="flex flex-col items-start bg-gray-50 h-12 min-w-16 px-4 opacity-100"
      routerLinkActive
      [disabled]="disabledTabs[index]"
      [active]="selectedIndex === index"
      (click)="selectedTab(index)">
      <div class="flex items-center w-full">
        <div class="flex flex-col text-left" [ngClass]="{ 'opacity-50': tab?.disabled }">
          <span class="leading-4 text-xs text-gray-800">{{ tab.subTitle | translate }}</span>
          <span class="leading-none text-sm text-gray-900 font-semibold">{{ tab.title | translate }}</span>
        </div>
      </div>
    </a>
  </nav>  
  <ng-content select="[tabsActions]"></ng-content>
</div>
<mat-tab-nav-panel #tabPanel>
  <ng-container *ngFor="let tab of tabsList; let i = index">
    <div [hidden]="selectedIndex !== i">
      <ng-container [ngTemplateOutlet]="tab.content"></ng-container>
    </div>
  </ng-container>
</mat-tab-nav-panel>
