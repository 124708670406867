<div class="flex flex-shrink-0 metrics-box-total-container">

  <div class="flex items-start total-container  w-1/3">
    <div class="relative">
    <div class="man">
      <img  class="h-[100px]" src="{{title==='men' ? 'assets/profile-male.png' : 'assets/profile-female.png'}}"/>
    </div>
    <div class="title absolute top-[10px] left-[6px]" [ngClass]="title ==='men' ? 'text-chathams-blue-600' : 'text-[#FA6200]' ">{{'peopleCount.'+title | translate }}</div>
    </div>
  </div>

  <div class="p-2 flex gap-4">
    <div class="flex flex-col items-end justify-between">
      <div class="period">
        <div *ngIf="compare"  class="text-right">{{ 'peopleCount.week' | translate }} {{weekNumberCompare ? weekNumberCompare : weekNumber}}</div>
        <div>{{ startDate  | date: 'MM-dd'}} - {{ endDate  | date: 'MM-dd'}} </div>
      </div>
      <div class="total-value " [ngClass]="title ==='men' ? 'text-chathams-blue-600' : 'text-[#FA6200]' ">{{ totalValue }}%</div>
    </div>
  </div>

</div>
