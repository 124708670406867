import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TabsItemComponent } from '../tabs/tabs-item/tabs-item.component';

@Component({
  selector: 'flow-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit {
  @Input() activeStep: number = 0;
  @Input() form: FormGroup;
  @Input() isEdit: boolean = false;
  @Input() steps: StepHeader[] = [];
  @Output() activeStepChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChildren(TabsItemComponent, {descendants: true}) stepList!: QueryList<TabsItemComponent>;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  setActiveStep(index: number, event: Event) {
    let element = event.currentTarget as HTMLElement;
    if (!element.classList.contains('disabled')) {
      this.activeStep = index;
      this.activeStepChange.emit(this.activeStep);
    }
  }
}
export interface StepHeader {
  title: string;
  subtitle?: string;
}
