<div class="page-header">
  <h1 class="page-title">{{ (isEdit ? 'client.editClient' : 'createClient.title') | translate}}</h1>
</div>

<div class="container-book">
  <div #itemsBook
       class="items-book">
    <div *ngFor="let step of steps; let i = index"
         (click)="setActiveStep(i, $event)"
         [ngClass]="i | bookStep: activeStep : isEdit"
         class="stepper-book">
      <div class="header-stepper-book">
        <div class="header-title-stepper-book">{{'createClient.client' | translate}}</div>
      </div>
      <div class="header-stepper-book">
        <div class="header-subtitle-stepper-book">{{step | translate}}</div>
      </div>
    </div>
  </div>


  <ng-container [ngSwitch]="activeStep">
    <div *ngSwitchCase="0"
         class="generail-information-main-wrapper">
      <div>
        <form [formGroup]="form">
          <div class="client-main-wrapper">
            <div class="client-general-information-wrapper">
              <div class="general-information">
                <app-icon fill="#BFD6E4"
                          iconName="annotation-info"
                          size="md"
                          class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                          stroke="#598FAE"></app-icon>
                <h3 class="m-0 ml-3 text-text-primary font-semibold">{{ 'createScreen.generalInfo' | translate }}</h3>
              </div>

              <div class="input-dimensions"
                   *ngIf="isAdmin">
                <mat-label>{{'client.form.customer' | translate}}*</mat-label>
                <mat-form-field class="w-full">
                  <mat-select formControlName="customerId"
                              placeholder="{{ 'createClient.placeholder.chooseCustomer' | translate }}">
                    <mat-option *ngFor="let customer of customers"
                                (onSelectionChange)="changeCustomer(customer.id, $event)"
                                [value]="customer.id">
                      {{customer.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="input-dimensions">
                <mat-label>{{'client.form.clientName' | translate}} *</mat-label>
                <mat-form-field class="w-full">
                  <input matInput
                         placeholder="{{ 'createClient.placeholder.clientName' | translate }}"
                         type="text"
                         formControlName="name">
                </mat-form-field>
              </div>

              <div *ngIf="!isEdit"
                   class="client-checkbox-wrapper">
                <mat-checkbox color="primary"
                              formControlName="shouldCreateFolder"
                              class="client-checkbox">
                </mat-checkbox>
                <div class="client-text">
                  {{ 'createClient.createMediaFolder' | translate }}
                  <span class="client-text">{{ 'createClient.client' | translate }}</span>
                </div>

              </div>

              <div class="input-dimensions">
                <mat-label>{{'client.form.vat' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <input formControlName="vatNumber"
                         placeholder="{{ 'createClient.placeholder.vatNumber' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>

              <div class="input-dimensions">
                <mat-label>{{'client.form.typeOfAdvertising' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <mat-select formControlName="advertisingType"
                              placeholder="{{ 'createClient.placeholder.typeOfAdvertising' | translate }}">
                    <mat-option *ngFor="let type of adTypes"
                                [value]="type.id">
                      {{type.name | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="input-dimensions">
                <mat-label>{{'client.form.additionalInformation' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <textarea formControlName="additionalInformation"
                            matInput></textarea>
                </mat-form-field>
              </div>

            </div>
          </div>
        </form>
      </div>

      <div class="general-information-text-wrapper">
        <div class="note-wrapper">
          <div class="note">
            {{ 'createClient.note' | translate }}
          </div>
          <div class="note-main-text">
            {{ 'createClient.generalInformationNoteText' | translate }}
          </div>
        </div>

        <div class="generail-information-buttons">
          <div class="skip-book-button"
               (click)="onCancel()">
            <div class="skip-book-button-text">{{'global.cancel' | translate}}</div>
          </div>

          <div class="navigation-book-button bg-brand-chathams-blue"
               (click)="onContinueClick()">
            <div class="navigation-book-button-text">{{'global.continue' | translate}}</div>
          </div>
        </div>
      </div>

    </div>

    <div *ngSwitchCase="1">
      <div class="invoice-main-wrapper">

        <div class="invoice-form-wrapper">

          <form [formGroup]="form">

            <div class="general-information">
              <app-icon fill="#BFD6E4"
                        iconName="invoice"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598FAE"></app-icon>
              <h3 class="m-0 ml-3 text-text-primary font-semibold">{{ 'createClient.invoice' | translate }}</h3>
            </div>

            <div class="address-input input-wrapper">
              <mat-label>{{'client.form.address' | translate}}</mat-label>
              <mat-form-field class="w-full">
                <input formControlName="address"
                       placeholder="{{ 'createClient.placeholder.addAddress' | translate }}"
                       matInput
                       type="text">
              </mat-form-field>
            </div>

            <div class="postal-code-and-city-wrapper input-wrapper">
              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.postalCode' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <input formControlName="postalCode"
                         placeholder="{{ 'createClient.placeholder.addPostalCode' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>

              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.city' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <input formControlName="city"
                         placeholder="{{ 'createClient.placeholder.city' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>
            </div>


            <div class="input-dimensions">
              <ng-container *ngIf="_reload">
                <mat-label>{{'client.form.country' | translate}}*</mat-label>
                <mat-select-country formControlName="country"
                                    placeholder="{{ 'createClient.placeholder.country' | translate }}"
                                    [countries]="predefinedCountries"
                                    [required]="true">
                </mat-select-country>
              </ng-container>
            </div>

            <div class="input-dimensions">
              <mat-label>{{'client.form.emailAddress' | translate}}*</mat-label>
              <mat-form-field class="w-full">
                <input formControlName="emailAddress"
                       placeholder="{{ 'createClient.placeholder.addEmailAddress' | translate }}"
                       matInput
                       type="text">
                <mat-error *ngIf="form.get('emailAddress')?.invalid">
                  {{ 'global.invalidEmail' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-dimensions">
              <mat-label>{{'client.form.contactPerson' | translate}}</mat-label>
              <mat-form-field class="w-full">
                <input formControlName="contactPerson"
                       placeholder="{{ 'createClient.placeholder.addContactPerson' | translate }}"
                       matInput
                       type="text">
              </mat-form-field>
            </div>

            <div class="input-dimensions">
              <mat-label>{{'client.form.phoneNumber' | translate}}</mat-label>
              <mat-form-field class="w-full">
                <ngx-mat-intl-tel-input [enablePlaceholder]="true"
                                        inputPlaceholder="{{ 'createClient.placeholder.addPhoneNumber' | translate }}"
                                        [enableSearch]="true"
                                        [preferredCountries]="['se']"
                                        focused="true"
                                        formControlName="phoneNumber">
                </ngx-mat-intl-tel-input>
              </mat-form-field>
            </div>

            <div class="postal-code-and-city-wrapper input-wrapper">
              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.department' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <input formControlName="department"
                         placeholder="{{ 'createClient.placeholder.addDepartment' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>

              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.costCenter' | translate}}</mat-label>
                <mat-form-field class="w-full">
                  <input formControlName="costCenter"
                         placeholder="{{ 'createClient.placeholder.addCostCenter' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>
            </div>


            <div class="postal-code-and-city-wrapper input-wrapper">

              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.currency' | translate}}*</mat-label>
                <mat-form-field class="example-full-width">
                  <mat-select formControlName="currency">
                    <mat-option placeholder="{{ 'createClient.placeholder.addCurrency' | translate }}"
                                *ngFor="let currency of currencies"
                                [value]="currency">
                      <div class="currency-option">
                        <app-icon [ngStyle]="{'margin-right': '10px'}"
                                  size="md"
                                  iconName="{{currency}}"></app-icon>
                        <span>{{currency}}</span>
                      </div>
                    </mat-option>
                  </mat-select>
                  <div matSuffix>
                    <app-icon size="md"
                              iconName="{{form.get('currency').value}}">
                    </app-icon>
                  </div>
                </mat-form-field>
              </div>

              <div class="postal-code-and-city-input">
                <mat-label>{{'client.form.paymentTerms' | translate}}</mat-label>
                <mat-form-field class="w-full">

                  <input formControlName="paymentTerms"
                         placeholder="{{ 'createClient.placeholder.paymentTerms' | translate }}"
                         matInput
                         type="text">
                </mat-form-field>
              </div>
            </div>

            <div class="framework-agreement-wrapper">
              <mat-label class="framework-agreement-text">{{'client.form.frameworkAgreement' | translate}}*</mat-label>
            </div>

            <div class="framework-agreement-radio-buttons-wrapper">
              <mat-radio-group formControlName="frameworkAgreementEnabled">
                <mat-radio-button style="margin-right: 1rem"
                                  class="framework-radio-button"
                                  color="primary"
                                  [value]="true">{{'global.yes' | translate}}</mat-radio-button>
                <mat-radio-button [value]="false"
                                  class="framework-radio-button"
                                  color="primary">{{'global.no' | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </form>
        </div>

        <div class="general-information-text-wrapper">
          <div class="note-wrapper">
            <div class="note">
              {{ 'createClient.note' | translate }}
            </div>
            <div class="note-main-text">
              {{ 'createClient.invoiceNoteText' | translate }}
            </div>
          </div>

          <div class="generail-information-buttons">
            <div class="skip-book-button"
                 (click)="onCancel()">
              <div class="skip-book-button-text">{{'global.cancel' | translate}}</div>
            </div>

            <div class="navigation-book-button bg-brand-chathams-blue"
                 [ngClass]="{'bg-disabled': !form.valid }"
                 [style.pointer-events]="form.valid ? 'auto' : 'none'"
                 (click)="saveClient()">
              <div class="navigation-book-button-text">{{(isEdit ? 'global.save' : 'global.add') | translate}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>