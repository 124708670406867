import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { SmsStatisticsResponse } from '@api/models/sms-statistics-response';

@Component({
  selector: 'flow-sms-charts',
  templateUrl: './sms-charts.component.html',
  styleUrls: ['./sms-charts.component.scss']
})
export class SmsChartsComponent implements OnInit, OnChanges {
  @Input() chartData: SmsStatisticsResponse;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  form: FormGroup;
  public weekDays = [];
  legends: any[] = [];
  noChartData: boolean = true;
  chartPlugins = [DataLabelsPlugin];
  data: ChartData<'bar', number[], string | string[]> = null;

  public chartOptions: ChartConfiguration['options'] = {
    elements: {
      line: { tension: 0.5 }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: { display: false }
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      },
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        },
      }
    },
    responsive: false,
    maintainAspectRatio: false
  };

  constructor(
    protected translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.updateChartData(this.chartData);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartData(changes['chartData'].currentValue);
  }

  updateChartData(data: SmsStatisticsResponse) {
    this.noChartData = false;
    const successColor = 'rgb(23, 117, 120)';
    const failColor = 'rgb(252, 75, 71)';

    const successfullSms = {
      label: this.translateService.instant('sms.successfulSendOuts'),
      data: [data.successfullSms],
      fill: 'origin',
      backgroundColor: successColor,
      hoverBackgroundColor: successColor,
      borderColor: successColor,
      pointBackgroundColor: successColor
    };
    const unsuccessfullSms = {
      label: this.translateService.instant('sms.unsuccessfulSendOuts'),
      data: [data.unsuccessfullSms],
      fill: 'origin',
      backgroundColor: failColor,
      hoverBackgroundColor: failColor,
      borderColor: failColor,
      pointBackgroundColor: failColor
    };

    this.data = {
      labels: [this.translateService.instant('sms.successfulSendOuts'), this.translateService.instant('sms.unsuccessfulSendOuts')],
      datasets: [successfullSms, unsuccessfullSms],
    };

    this.createCustomLegend();
    this.chart?.update();
  }

  createCustomLegend(): void {
    this.legends = this.data.datasets.map((dataset, index) => {
      return {
        text: dataset.label,
        fillStyle: dataset.borderColor,
        hidden: false,
        datasetIndex: index
      };
    });
  }
  toggleDataset(datasetIndex: number): void {
    const chart = this.chart.chart;
    const meta = chart.getDatasetMeta(datasetIndex);

    meta.hidden = !meta.hidden;
    this.legends[datasetIndex].hidden = meta.hidden;
    chart.update();
  }
}
