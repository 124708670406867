import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, switchMap, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { ContactPersonCreateModal } from '../person-create/contact-person-create.modal';
import { CountryCodes } from './country-codes';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { EnumService } from 'src/app/core/services/enum.service';
import { ContactPerson } from 'src/_api/models/contact-person';
import { Action, Customer, CustomerApi, CustomerTypeEnum, InvoiceDeliveryTypeEnum, SinglePermission, Permissions } from '@api/index';
import { CustomerPermissionApi } from '@api/services/customer-permission-api';
import { CustomEmailValidator } from '@app/core/services/media.service';
import { PERMISSIONS_CONFIG } from '../customer.constants';

interface Country {
  name: string;
  id: number;
}

interface CustomerFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  organizationId: FormControl<string>;
  type: FormControl<CustomerTypeEnum>;
  presentationTime: FormControl<number>;
  numbersOfWeekForFiltering: FormControl<number>;
  storageLimitSize: FormControl<number>;
  personOfInterestLimit: FormControl<number>;
  contactPersonLimit: FormControl<number>;
  contactPersons: FormControl<ContactPerson[]>;
}

interface InvoiceFormGroup {
  address: FormControl<string>;
  postalCode: FormControl<string>;
  postalCity: FormControl<string>;
  countryExternalId: FormControl<number>;
  invoiceDeliveryType: FormControl<InvoiceDeliveryTypeEnum>;
  invoiceDeliveryEmail: FormControl<string>;
  referenceName: FormControl<string>;
}

@Component({
  selector: 'flow-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss'],
})
export class CustomerCreateComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  customerId: number;
  customer: Customer;
  customerPersons: ContactPerson[] = [];
  customerForm: FormGroup<CustomerFormGroup>;
  invoiceForm: FormGroup<InvoiceFormGroup>;
  personsControl: FormControl<ContactPerson[]>;
  countries: Country[] = [];
  countryCodes: Country[] = [];
  removedContactPersons: ContactPerson[] = [];
  InvoiceDeliveryTypeEnum = InvoiceDeliveryTypeEnum;
  selectedContactPersons = [];
  selectedIndex = 0;
  selectedContact: ContactPerson;
  loading$ = {
    customer: new BehaviorSubject(false),
    save: new BehaviorSubject(false),
  };
  customerValidationErrors: string[] = [];
  invoiceValidationErrors: string[] = [];
  permissionsForm: FormGroup;
  steps = [
    { title: 'global.customer', subtitle: 'createCustomer.permissions.subTitles.generalInfo' },
    { title: 'global.customer', subtitle: 'createCustomer.permissions.subTitles.invoice' },
    { title: 'global.customer', subtitle: 'createCustomer.permissions.subTitles.permissions' },
  ];
  permissionsConfig = PERMISSIONS_CONFIG;

  constructor(
    private customerApi: CustomerApi,
    private customerPermissionApi: CustomerPermissionApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public dialog: MatDialog,
    private appService: AppService,
    private translateConfigService: TranslateConfigService,
    public enumService: EnumService
  ) {
    this.appService.hasToolbarFormActions = true;
    this.customerId = +this.activatedRoute.snapshot.params['id'] || null;
    this.countries = enumService.countries;
    this.countryCodes = enumService.countryCodes;
    this.initForm();
  }

  ngOnInit(): void {
    if (this.customerId) {
      this.fetchCustomerData();
      this.fetchCustomerPermissions();
    } else {
      this.initpermissionsForm(this.permissionsConfig);
    }
  }

  fetchCustomerData(): void {
    this.customerApi
      .getCustomerById(this.customerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(customer => {
        this.customer = customer as Customer;
        this.customerPersons = customer.contactPersons || [];
        this.initForm(this.customer);
      });
  }

  fetchCustomerPermissions(): void {
    this.customerPermissionApi
      .getPermissionsForCustomer(this.customerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((permissions: Permissions[]) => {
        this.initpermissionsForm(permissions);
      });
  }

  initpermissionsForm(permissions: Permissions[]) {
    this.permissionsForm = this.formBuilder.group({
      permissions: this.formBuilder.array(permissions.map(header => this.createHeaderGroup(header))),
    });
  }

  createHeaderGroup(header: Permissions): FormGroup {
    return this.formBuilder.group({
      header: [header.header],
      icon: [header.icon],
      actions: this.formBuilder.array(header.actions.map(action => this.createActionGroup(action))),
    });
  }

  createActionGroup(action: Action): FormGroup {
    return this.formBuilder.group({
      title: [action.title],
      icon: [action.icon],
      permissions: this.formBuilder.array(action.permissions.map(permission => this.createPermissionControl(permission))),
    });
  }

  createPermissionControl(permission: SinglePermission): FormGroup {
    return this.formBuilder.group({
      label: [permission.label],
      value: [permission.value],
      hiddenValue: [permission.hiddenValue],
    });
  }

  get permissionsArray() {
    return this.permissionsForm.get('permissions') as FormArray;
  }

  getActionsArray(headerIndex: number) {
    return this.permissionsArray.at(headerIndex).get('actions') as FormArray;
  }

  getPermissionsArray(headerIndex: number, actionIndex: number) {
    return this.getActionsArray(headerIndex).at(actionIndex).get('permissions') as FormArray;
  }

  onPermissionsSubmit() {
    const permissions = [];
    this.permissionsForm.value.permissions.forEach((item: Permissions) => {
      item.actions.forEach(action => {
        action.permissions.forEach(permission => {
          permission.value && permissions.push(permission.hiddenValue);
        });
      });
    });

    return permissions;
  }

  onNextTab(val: number) {
    if (this.selectedIndex == 1 && val == 2) {
      this.invoiceValidationErrors = this.setValidationMessages(this.invoiceForm);
      this.selectedIndex = val;
    } else if (this.selectedIndex == 0 && val == 1) {
      this.customerValidationErrors = this.setValidationMessages(this.customerForm);
      this.selectedIndex = val;
    } else this.selectedIndex = val;
  }

  selectedTabChange(index) {
    this.selectedIndex = index;
  }

  setValidationMessages(formGroup: FormGroup) {
    const validationErrors = [];
    Object.keys(formGroup.controls).forEach((controlKey: any) => {
      const control = formGroup.get(controlKey);

      if (control instanceof FormGroup) {
        this.setValidationMessages(control);
      } else {
        if (control && control.errors) {
          for (const [key, value] of Object.entries(control.errors)) {
            switch (key) {
              case 'required':
                const isRequired =
                  this.translateConfigService.instant(`createCustomer.validationErrors.${controlKey}`) +
                  ' ' +
                  this.translateConfigService.instant(`createCustomer.validationErrors.required`);
                validationErrors.push(isRequired);
                break;
              case 'min':
                const length = this.translateConfigService.instantWithParams('createCustomer.validationErrors.requiredLength', {
                  length: value.min,
                });
                const requiredLength = this.translateConfigService.instant(`createCustomer.validationErrors.${controlKey}`) + ' ' + length;
                validationErrors.push(requiredLength);
                break;
              case 'email':
                const validEmail =
                  this.translateConfigService.instant(`createCustomer.validationErrors.${controlKey}`) +
                  ' ' +
                  this.translateConfigService.instant(`createCustomer.validationErrors.validEmail`);
                validationErrors.push(this.translateConfigService.instant(validEmail));
                break;
            }
          }
        }
      }
    });

    return validationErrors;
  }

  onSaveClick(): void {
    if (this.invoiceForm.get('invoiceDeliveryType').value !== InvoiceDeliveryTypeEnum.Email) {
      this.invoiceForm.get('invoiceDeliveryEmail').setValue('');
      this.invoiceForm.get('invoiceDeliveryEmail').setErrors(null);
    }

    if (!this.invoiceForm.valid || !this.customerForm.valid) {
      this.invoiceValidationErrors = this.setValidationMessages(this.invoiceForm);
      this.customerValidationErrors = this.setValidationMessages(this.customerForm);
      this.systemMessageService.error(this.translateConfigService.instant('createCustomer.validationErrors.errors'));
      return;
    }

    if (this.removedContactPersons.length > 0) {
      this.customerForm.get('contactPersons').setValue(this.customerForm.get('contactPersons').value.concat(this.removedContactPersons));
    }
    let customerFormValues = this.customerForm.getRawValue();
    customerFormValues.contactPersons = this.personsControl.getRawValue();

    const body = {
      ...this.invoiceForm.getRawValue(),
      ...customerFormValues,
      countryExternalId: CountryCodes[this.invoiceForm.value.countryExternalId],
    };

    // pass the permissions array to the new permissions endpoint!
    const permissions = this.onPermissionsSubmit();

    this.customerApi
      .saveCustomer(body)
      .pipe(
        takeUntil(this.destroy$),
        indicateLoading(this.loading$.save),
        switchMap(customer => this.customerPermissionApi.assignPermissionsToCustomer(customer.id, { permissions }))
      )
      .subscribe(() => {
        this.systemMessageService.success(
          this.translateConfigService.instant('notifications.success.customerCreate', this.customerForm.value.name)
        );
        this.router.navigate(['/customers']);
      });
  }

  customerPersonLabel = (person: ContactPerson): string => person?.name + ' - ' + person?.phoneNumber;

  onCreateCustomerPerson(customerPerson?: ContactPerson, index?: number, event?: any): void {
    const dialogRef = this.dialog.open(ContactPersonCreateModal, {
      data: {
        customerPerson,
        selectedContactPerson: null,
        contactPersons: [],
        isAdmin: this.customerId == null,
        currentCustomerId: this.customerId,
        isCustomerPage: true,
      },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((person: ContactPerson) => {
      if (!person) {
        return;
      }
      if (customerPerson) {
        customerPerson.id = person.id;
        customerPerson.name = person.name;
        customerPerson.email = person.email;
        customerPerson.phoneNumber = person.phoneNumber;
        customerPerson.channelIds = person.channelIds;
        customerPerson.customer = person.customer;
      } else {
        person.customer = {
          id: this.customer ? this.customer?.id : person?.id,
          name: this.customer ? this.customer?.name : person?.name,
        };
        const newList = [...this.customerPersons];
        newList.push(person);
        this.customerPersons = newList;
        const selectedPersons = this.personsControl.value;
        selectedPersons.push(person);
        this.personsControl.setValue(selectedPersons);
      }
    });
  }

  findCountry(code: string) {
    return this.countryCodes.find(c => c.name === code).id;
  }
  private initForm(customer?: Customer): void {
    this.personsControl = this.formBuilder.control(customer?.contactPersons || []);

    this.customerForm = this.formBuilder.group({
      id: this.customerId,
      name: [customer?.name, Validators.required],
      organizationId: customer?.organizationId,
      type: [customer?.type, Validators.required],
      presentationTime: [customer?.presentationTime, [Validators.min(1)]],
      numbersOfWeekForFiltering: [customer?.numbersOfWeekForFiltering, [Validators.min(1)]],
      storageLimitSize: [customer?.storageLimitSize, [Validators.min(1)]],
      personOfInterestLimit: [customer?.personOfInterestLimit, [Validators.min(1)]],
      contactPersonLimit: [customer?.contactPersonLimit, [Validators.min(1)]],
      contactPersons: [customer?.contactPersons || []],
    });

    this.invoiceForm = this.formBuilder.group({
      address: customer?.address,
      postalCode: customer?.postalCode,
      postalCity: customer?.postalCity,
      countryExternalId: this.findCountry(customer?.countryExternalId || 'SE'), // Sverige förvalt
      invoiceDeliveryType: customer?.invoiceDeliveryType || (InvoiceDeliveryTypeEnum.Email as InvoiceDeliveryTypeEnum),
      referenceName: customer?.referenceName,
      invoiceDeliveryEmail: [customer?.invoiceDeliveryEmail, [CustomEmailValidator(), Validators.required]],
    });

    this.selectedContactPersons = this.customerForm.get('contactPersons').value;

    if (this.customerId && !customer) {
      this.customerForm.disable();
    } else {
      this.customerForm.enable();
    }
    this.invoiceForm
      .get('invoiceDeliveryType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const deliveryEmailControl = this.invoiceForm.get('invoiceDeliveryEmail');
        if (this.invoiceForm.get('invoiceDeliveryType').value !== InvoiceDeliveryTypeEnum.Email) {
          deliveryEmailControl.setValue(null);
          deliveryEmailControl.clearValidators();
          deliveryEmailControl.updateValueAndValidity();
        } else {
          deliveryEmailControl.setValidators([CustomEmailValidator(), Validators.required]);
        }
      });
  }
  onItemRemove(contactPerson: ContactPerson) {
    this.removedContactPersons.push(contactPerson);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
