<div class="page-header">
  <h1 class="page-title">{{ 'client.title' | translate}}</h1>
  <button *flowHasPermission="['UpdateClientsAdmin']; disabled true;"
           class="client-add-button"
           (click)="redirectToCreatePage()">
    <span class="client-add-button-text">{{ 'global.add' | translate }}</span>
  </button>
</div>

<mat-paginator showFirstLastButtons
               (page)="handlePageEvent($event)"
               [pageSize]="params.pageSize"
               [pageIndex]="params.pageNumber"
               [pageSizeOptions]="pageSizeOptions"
               [length]="totalCount">
</mat-paginator>

<table mat-table
       [dataSource]="dataSource"
       class="table-hover"
       (matSortChange)="handleSortEvent($event)"
       matSort
       [matSortDisableClear]="true"
       multiTemplateDataRows>

  <ng-container matColumnDef="id">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.id' | translate}} </th>
    <td mat-cell
        *matCellDef="let client"> {{client.id}} </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.customer' | translate}}</th>
    <td mat-cell
        *matCellDef="let client"> {{client.customer.name}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.clientName' | translate}}</th>
    <td mat-cell
        *matCellDef="let client"> {{client.name}} </td>
  </ng-container>

  <ng-container matColumnDef="vatNumber">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.vatNumber' | translate}} </th>
    <td mat-cell
        *matCellDef="let client"> {{client.vatNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="department">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.department' | translate}} </th>
    <td mat-cell
        *matCellDef="let client"> {{client.department | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="costCenter">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.costCenter' | translate}} </th>
    <td mat-cell
        *matCellDef="let client">
      {{client.costCenter}}
    </td>
  </ng-container>

  <ng-container matColumnDef="frameworkAgreementEnabled">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.frameworkAgreement' | translate}} </th>
    <td mat-cell
        *matCellDef="let client">
      {{(client.frameworkAgreementEnabled ? 'global.yes' : 'global.no') | translate}}
    </td>
  </ng-container>

  <ng-container matColumnDef="advertisingType">
    <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header> {{'client.table.typeOfAdvertising' | translate}} </th>
    <td mat-cell
        *matCellDef="let client">
      {{ AdvertisingTypeTranslations[client.advertisingType] | translate }}
    </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell
        *matHeaderCellDef
        class="justify-items-end"> </th>
    <td mat-cell
        *matCellDef="let client"
        class="justify-items-end">
      <button mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *flowHasPermission="['UpdateClientsAdmin']; disabled true;"
                mat-menu-item
                (click)="redirectToEditPage(client.id)">
          <span>{{ 'manage.table.edit' | translate }}</span>
        </button>
        <button *flowHasPermission="['UpdateClientsAdmin']; disabled true;"
                mat-menu-item
                (click)="onDeleteClick($event, client)">
          <span class="text-red-500">{{ 'manage.table.delete' | translate }}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row
      class="bg-[#FAFAFA]"
      *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      *matRowDef="let client; columns: displayedColumns; let i = dataIndex; let odd = odd"
      [ngClass]="odd ? 'bg-[#FAFAFA]' : 'bg-[#FFFFFF]'"
      (dblclick)="redirectToEditPage(client.id)"></tr>
</table>