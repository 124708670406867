import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateTime } from 'luxon';
import { StatisticsFilterInputs } from "@app/models/statistics-filter-inputs";
import { Segment, Screen, StatisticsEngagementMediaFiles, StatisticsPerDay, TargetGroup } from "@api/index";
@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  form: FormGroup;

  groupDataBySegment(perDay: StatisticsPerDay[]): {
    segmentId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('segmentId', perDay);
  }

  groupDataByScreen(perDay: StatisticsPerDay[]): {
    screenId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('screenId', perDay);
  }

  groupDataByTargetGroup(perDay: StatisticsPerDay[]): {
    targetGroupId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('targetGroupId', perDay);
  }

  groupDataBy(property: string, perDay: StatisticsPerDay[]) {
    const groupedByProperty: any[] = [];
    perDay.forEach(pd => {
      pd.adSlots.forEach(slot => {
        const group = groupedByProperty.find(g => g[property] === slot[property]);
        if (!group) {
          groupedByProperty.push({
            [property]: slot[property],
            perDay: [{
              date: pd.date,
              count: 1
            }]
          });
        } else {
          const perDay = group.perDay.find(day => day.date === pd.date);
          if (!perDay) {
            group.perDay.push({
              date: pd.date,
              count: 1
            });
          } else {
            perDay.count++;
          }
        }
      });
    });
    return groupedByProperty;
  }

  buildPeriod(start: DateTime, end: DateTime, columnType: 'day' | 'date' = 'day'): ChartWeekday[] {
    let current = start;
    const days: ChartWeekday[] = [];
    while (current < end) {
      days.push({
        dateTime: current,
        utcString: current.toISO(),
        label: columnType === 'day' ? current.weekdayShort : current.day.toString()
      });
      current = current.plus({ day: 1 });
    }
    return days;
  }

  initForm(initData: Map<string, StatisticsFilterInputs>, filterVisibleInputs: (extractionWords:string[], search:string, input: StatisticsFilterInputs, form: any) => void): void {
    if (Object.values(initData)) {
      initData.forEach((content) => {
        let formControl:any = new FormControl(content.isChecked);
        if (content.formGroup){
          formControl = new FormGroup({});
          content.formGroup.forEach(f => {
            formControl.addControl(f.formControlName, new FormControl());
            if (f.isRequired)
              formControl.get(f.formControlName).setValidators(Validators.required);
          });
        }
        this.form.addControl(content.formControlName, formControl);
        if (content.isRequired)
          this.form.get(content.formControlName).setValidators(Validators.required);
        if (content.data.length > 0 && content.isFilled)
          this.form.get(content.formControlName).setValue(content.isMultipleDropdown ? content.data.map(d => { return d.id }) : content.data[0].id);
        if (content.search) {
          this.form.addControl("searchTerm"+content.formControlName, new FormControl(''));
          this.form.get("searchTerm"+content.formControlName).valueChanges.subscribe(() => filterVisibleInputs(content.extractionWords, "searchTerm"+content.formControlName, initData.get(content.formControlName), this.form));
        }
        if (content.value) {
          this.form.get(content.formControlName).setValue(
            Array.isArray(content.value) ?
              content.value.map(d => { return d.id }) : content.value);
        }
      });
    }
  }
}

export interface StatisticsInitData {
  segments?: Segment[];
  screens: Screen[];
  targetGroups?: TargetGroup[];
  engagementMediaFiles?: StatisticsEngagementMediaFiles[]
}

export interface ChartWeekday {
  dateTime: DateTime;
  utcString: string;
  label: string;
}