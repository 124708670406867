<div class="register-wrapper">
  <mat-card class="register-card"
            *ngIf="!navigating">
    <div>
      <div class="logo">
        <img src="../../../../assets/svg/logo.svg"
             alt="Logo">
      </div>
      <div class="header">
        <h1>Registration</h1>
        <p>Please enter a secure password in order to complete the registration.</p>
      </div>
      <form #f="ngForm"
            (ngSubmit)="onRegister( f.value.password)">
        <mat-card-content>
          <mat-form-field appearance="outline"
                          class="w-full">
            <mat-label>Email</mat-label>
            <input disabled
                   matInput
                   type="email"
                   name="email"
                   id="email"
                   [(ngModel)]="email">
          </mat-form-field>
          <mat-form-field appearance="outline"
                          class="w-full">
            <mat-label>Password</mat-label>
            <input matInput
                   [type]="hide ? 'password' : 'text'"
                   [(ngModel)]="password"
                   name="password"
                   required
                   minlength="8"
                   pattern="^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$"
                   (input)="calculatePasswordStrength(password)"
                   placeholder="Password">
            <div [ngStyle]="{top: iconName === 'eye-show' ? '44%' : '42%'}"
                 class="eye-icon">
              <app-icon (click)="onToggle()"
                        [matTooltip]="iconName === 'eye-show' ? 'Show password' : 'Hide password'"
                        matTooltipPosition="above"
                        [ngStyle]="{cursor:'pointer'}"
                        size="md"
                        [iconName]="iconName"
                        fill="none"
                        stroke="#787c83"></app-icon>
            </div>
          </mat-form-field>
          <mat-progress-bar mode="determinate"
                            [value]="passwordStrength"></mat-progress-bar>
          <div *ngIf="f.form.controls.password?.touched"
               class="
               error-container">
            <div class="errors"
                 *ngIf="f.form.controls.password.hasError('required')">
              Password is required.
            </div>
            <div class="errors"
                 *ngIf="f.form.controls.password.hasError('minlength')">
              Password must be at least 8 characters long.
            </div>
            <div class="errors"
                 *ngIf="f.form.controls.password.hasError('pattern')">
              Password must contain at least one digit, one uppercase letter, one
              lowercase letter, and one special character.
            </div>
          </div>
        </mat-card-content>
        <button mat-flat-button
                color="primary"
                class="btn-block w-full">Register</button>
      </form>
    </div>
  </mat-card>
</div>