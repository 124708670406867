import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, forkJoin, of } from 'rxjs';

import { TranslateConfigService } from '@core/translate-config.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { BookingRouterState } from '@shared/enums/booking-router-state.enum';
import { BookingApiService } from '@api/services/booking-api.service';
import { BookService } from '@app/admin/engagement/book/services/book.service';
import { EngagementTypeEnum } from '@api/models/engagement-type-enum';
import { BookCopyBookingActions, BookCreateActions, BookEditActions, BookMediaActions, BookNewBookingActions } from './book.actions';

@Injectable()
export class BookEffects {
  constructor(
    private actions$: Actions,
    private bookService: BookService,
    private bookingApiService: BookingApiService,
    private systemMessageService: SystemMessageService,
    private activatedRoute: ActivatedRoute,
    private translateConfigService: TranslateConfigService,
    private router: Router
  ) {}

  editBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookEditActions.editBooking),
      switchMap(({ engagementId, bookState }) =>
        this.bookingApiService.getBookingDetails(engagementId).pipe(
          map(data => BookEditActions.editBookingSuccess({ data, bookState })),
          catchError(error => of(BookEditActions.editBookingFailure({ error })))
        )
      )
    )
  );

  createBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookCreateActions.createBooking),
      switchMap(({ engagementId, isCopy }) =>
        this.activatedRoute.paramMap.pipe(
          map(() => window.history.state),
          map(() => {
            const state = window.history.state;

            return {
              data: state?.data,
              bookState: state?.bookState || BookingRouterState.Create,
              isCopy,
            };
          }),
          switchMap(({ data, bookState, isCopy }) => {
            const state = { bookState, engagementId, isCopy };

            if (bookState === BookingRouterState.CreateCopy) {
              const engagementId = data?.engagement?.id;
              const engagementType = data?.engagementType;
              const api = engagementType === EngagementTypeEnum.Segment ? 'getSegmentDetails' : 'getPlaylistDetails';

              return forkJoin([
                this.bookingApiService[api](engagementId),
                of({
                  bookState,
                  engagementId: engagementId || data?.engagement?.id,
                  isCopy,
                }),
              ]);
            }
            return of([data, state]);
          })
        )
      ),
      map(([data, state]) => {
        if (state?.bookState === BookingRouterState.CreateCopy || state?.bookState === BookingRouterState.CreateHome) {
          const details = data?.segmentDetails || data;

          return {
            details,
            bookState: state?.bookState,
            isCopy: state?.isCopy,
            engagementId: state?.engagementId,
          };
        }

        // bookState === BookingRouterState.Create
        return {
          bookState: state?.bookState,
          isCopy: state?.isCopy,
          engagementId: state?.engagementId,
        };
      }),
      map(data =>
        BookCreateActions.createBookingSuccess({ data, bookState: data?.bookState, isCopy: data?.isCopy, engagementId: data?.engagementId })
      ),
      catchError(error => of(BookCreateActions.createBookingFailure({ error })))
    )
  );

  book$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookNewBookingActions.newBooking),
      switchMap(({ engagementType, generalInfoForm, filtersForm }) => {
        const formatedBooking = this.bookService.formatBookingRequest({
          engagementType,
          ...generalInfoForm,
          ...filtersForm,
        });

        return forkJoin([this.bookingApiService.createNewBooking(formatedBooking), of(engagementType)]);
      }),
      map(([engagementId, engagementType]) => {
        const message = this.translateConfigService.instantWithParams(
          engagementType == EngagementTypeEnum.Playlist ? 'notifications.success.playlistBook' : 'notifications.success.segmentBook',
          { text: name }
        );

        this.systemMessageService.success(message);

        return {
          bookState: null,
          engagementId,
          engagementType,
          continueCreateMode: true,
        };
      }),
      map(data => {
        return BookNewBookingActions.newBookingSuccess({
          data,
          bookState: data?.bookState,
          engagementId: data?.engagementId,
          engagementType: data?.engagementType,
          continueCreateMode: true,
        });
      }),
      catchError(error => {
        return of(BookNewBookingActions.newBookingFailure({ error }));
      })
    )
  );

  getMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookMediaActions.loadBookingMedia),
      switchMap(({ engagementId, engagementType }) => {
        const api = engagementType === EngagementTypeEnum.Segment ? 'getBookingSegmentAdvertisments' : 'getBookingsPlaylistAdvertisments';
        return this.bookingApiService[api](engagementId);
      }),
      map((data: any) => data),
      map((data: any) => BookMediaActions.loadBookingMediaSuccess({ data })),
      catchError(error => of(BookMediaActions.loadBookingMediaFailure({ error })))
    )
  );

  copy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookCopyBookingActions.copyBooking),
      switchMap(({ engagementId, engagementType, generalInfoForm, filtersForm }) => {
        const formatedBooking = this.bookService.formatBookingRequest({
          engagementType,
          ...generalInfoForm,
          ...filtersForm,
        });

        return forkJoin([
          this.bookingApiService.copyBooking(engagementId, formatedBooking),
          of({
            engagementId,
            engagementType,
            name: generalInfoForm.name,
          }),
        ]);
      }),
      map(([engagementId, data]) => {
        this.router.navigate([`/${EngagementTypeEnum[data?.engagementType].toLowerCase()}s/${engagementId}/media`]);
        this.systemMessageService.success(this.translateConfigService.instant('notifications.success.bookingCopy', data?.name));
        return BookCopyBookingActions.copyBookingSuccess({ data: engagementId });
      }),
      catchError(error => {
        return of(BookCopyBookingActions.copyBookingFailure({ error }));
      })
    )
  );
}
