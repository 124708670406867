export enum Permissions {
  All = "All",
  ViewBookingCms = "ViewBookingCms",
  UpdateBookingCms = "UpdateBookingCms",
  ViewMediaCms = "ViewMediaCms",
  UpdateMediaCms = "UpdateMediaCms",
  ViewMediaTransfer = "ViewMediaTransfer",
  UpdateMediaTransfer = "UpdateMediaTransfer",
  ViewReachAnalytics = "ViewReachAnalytics",
  ViewReachReportsAnalytics = "ViewReachReportsAnalytics",
  ExportReachReportsAnalytics = "ExportReachReportsAnalytics",
  ViewExposuresAnalytics = "ViewExposuresAnalytics",
  ViewExposuresReportsAnalytics = "ViewExposuresReportsAnalytics",
  ExportExposuresReportsAnalytics = "ExportExposuresReportsAnalytics",
  ViewPeopleCounterInsights = "ViewPeopleCounterInsights",
  ViewPeopleCounterReportsInsights = "ViewPeopleCounterReportsInsights",
  ExportPeopleCounterReportsInsights = "ExportPeopleCounterReportsInsights",
  ViewPoiInsights = "ViewPoiInsights",
  UpdatePoiInsights = "UpdatePoiInsights",
  ViewPoiCategoriesInsights = "ViewPoiCategoriesInsights",
  UpdatePoiCategoriesInsights = "UpdatePoiCategoriesInsights",
  ViewPoiReportsInsights = "ViewPoiReportsInsights",
  ExportPoiReportsInsights = "ExportPoiReportsInsights",
  ViewSmsAdmin = "ViewSmsAdmin",
  ViewChannelAdmin = "ViewChannelAdmin",
  UpdateChannelAdmin = "UpdateChannelAdmin",
  ViewScreensAdmin = "ViewScreensAdmin",
  UpdateScreensAdmin = "UpdateScreensAdmin",
  ViewClientsAdmin = "ViewClientsAdmin",
  UpdateClientsAdmin = "UpdateClientsAdmin",
  ViewContactPersonAdmin = "ViewContactPersonAdmin",
  UpdateContactPersonAdmin = "UpdateContactPersonAdmin",
  ViewUserPermissionsAdmin = "ViewUserPermissionsAdmin",
  UpdateUserPermissionsAdmin = "UpdateUserPermissionsAdmin",
  ViewUsersAdmin = "ViewUsersAdmin",
  UpdateUsersAdmin = "UpdateUsersAdmin"
}