<div class="flex flex-col">
        <week-picker class="flex-1 sm:mr-1 "
                     [range]="range"
                     (weekSelected)="onPeriodChange()">
        </week-picker>
</div>

<views-chart-header [groupByFC]="groupByFC"
                    [hideDefaultAdFC]="hideDefaultAdFC"
                    [label]="'v. ' + range.value?.start?.weekNumber">
</views-chart-header>
<canvas baseChart
        [data]="chartDataWithTranslation"
        [type]="'bar'"
        [options]="chartOptions"
        [plugins]="chartPlugins">
</canvas>