import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Breadcrumb } from 'src/app/models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: BehaviorSubject<Breadcrumb[]>  = new BehaviorSubject<any>({label: 'home', id: -1});
  breadcrumbs$ = this.breadcrumbs.asObservable();
  private navigationClickSubject = new Subject<number>();
  navigationClick$ = this.navigationClickSubject.asObservable();

  constructor() {
    const breadcrumbs = this.getBreadcrumbs();
    this.breadcrumbs.next(breadcrumbs);
  }

  getBreadcrumbs(): Breadcrumb[] {
    const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
    let breadcrumbs = [];
    if (storedBreadcrumbs && storedBreadcrumbs.length > 0) {
      breadcrumbs = JSON.parse(storedBreadcrumbs);
    } else {
      breadcrumbs = [{ id: -1, label: 'home' }];
      localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
    }
    return breadcrumbs;
  }
  addBreadcrumb(label: string, id: number): void {
    let breadcrumbs = this.getBreadcrumbs();
    if (breadcrumbs.filter(b => b.id === id).length === 0){
      breadcrumbs.push({label, id});
      // Save breadcrumb data in localStorage
      localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
      this.breadcrumbs.next(breadcrumbs);
    }
  }

  removeBreadcrumb(id: number): void {
    let breadcrumbs = this.getBreadcrumbs();
    if (breadcrumbs.filter(b => b.id === id)){
      breadcrumbs = breadcrumbs.slice(breadcrumbs.findIndex(b => b.id == id), breadcrumbs.length-1);
      // Save breadcrumb data in localStorage
      localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
      this.breadcrumbs.next(breadcrumbs);
      this.navigationClickSubject.next(id);
    }
  }
  clearBreadcrumbs(): void {
    localStorage.removeItem('breadcrumbs');
    this.breadcrumbs.next([]);
  }
}