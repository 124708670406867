export const PERMISSIONS_CONFIG = [
  {
    header: 'cms',
    icon: 'pen',
    actions: [
      {
        title: 'booking',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewBookingCms' },
          { label: 'edit', value: true, hiddenValue: 'UpdateBookingCms' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'media',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewMediaCms' },
          { label: 'edit', value: true, hiddenValue: 'UpdateMediaCms' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'mediaTransfer',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewMediaTransfer' },
          { label: 'edit', value: true, hiddenValue: 'UpdateMediaTransfer' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
    ],
  },
  {
    header: 'analytics',
    icon: 'exposures-reports',
    actions: [
      {
        title: 'reach',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewReachAnalytics' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'reachReports',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewReachReportsAnalytics' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: true, hiddenValue: 'ExportReachReportsAnalytics' },
        ],
      },
      {
        title: 'exposures',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewExposuresAnalytics' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'exposuresReports',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewExposuresReportsAnalytics' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: true, hiddenValue: 'ExportExposuresReportsAnalytics' },
        ],
      },
    ],
  },
  {
    header: 'visitorInsights',
    icon: 'people-counter',
    actions: [
      {
        title: 'peopleCounter',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPeopleCounterInsights' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'peopleCounterReports',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPeopleCounterReportsInsights' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: true, hiddenValue: 'ExportPeopleCounterReportsInsights' },
        ],
      },
      {
        title: 'poi',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPoiInsights' },
          { label: 'edit', value: true, hiddenValue: 'UpdatePoiInsights' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'poiCategories',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPoiCategoriesInsights' },
          { label: 'edit', value: true, hiddenValue: 'UpdatePoiCategoriesInsights' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'poiReports',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPoiReportsInsights' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: true, hiddenValue: 'ExportPoiReportsInsights' },
        ],
      },
    ],
  },
  {
    header: 'admin',
    icon: 'shield',
    actions: [
      {
        title: 'sms',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewSmsAdmin' },
          { label: 'edit', value: null, hiddenValue: null },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'channels',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewChannelAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateChannelAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'poi',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewPoiInsights' },
          { label: 'edit', value: true, hiddenValue: 'UpdatePoiInsights' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'screens',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewScreensAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateScreensAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'clients',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewClientsAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateClientsAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'contactPersons',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewContactPersonAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateContactPersonAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'users',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewUsersAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateUsersAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
      {
        title: 'usersPermissions',
        icon: '',
        permissions: [
          { label: 'view', value: true, hiddenValue: 'ViewUserPermissionsAdmin' },
          { label: 'edit', value: true, hiddenValue: 'UpdateUserPermissionsAdmin' },
          { label: 'export', value: null, hiddenValue: null },
        ],
      },
    ],
  },
];
