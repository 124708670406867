import { environment } from "@environments/environment";

export const STORAGE_KEY_USER = environment.env + '_polar.user';
export const STORAGE_KEY_AUTH = environment.env + '_polar.auth';
export const STORAGE_KEY_LOCATION = environment.env + '_polar.location';

export const STATISTICS_START_DATE = '2022-10-01';
export const STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL = 15000;
export const STATISTICS_EXPOSURES_POLLING_INTERVAL = 60000;
export const LANGUAGE_KEY = 'language';
export const BREADCRUMBS_KEY = 'breadcrumbs';

export const AVAILABLE_PERMISSIONS = [
  "ViewBookingCms",
  "UpdateBookingCms",
  "ViewMediaCms",
  "UpdateMediaCms",
  "ViewMediaTransfer",
  "UpdateMediaTransfer",
  "ViewReachAnalytics",
  "ViewReachReportsAnalytics",
  "ExportReachReportsAnalytics",
  "ViewExposuresAnalytics",
  "ViewExposuresReportsAnalytics",
  "ExportExposuresReportsAnalytics",
  "ViewPeopleCounterInsights",
  "ViewPeopleCounterReportsInsights",
  "ExportPeopleCounterReportsInsights",
  "ViewPoiInsights",
  "UpdatePoiInsights",
  "ViewPoiCategoriesInsights",
  "UpdatePoiCategoriesInsights",
  "ViewPoiReportsInsights",
  "ExportPoiReportsInsights",
  "ViewSmsAdmin",
  "ViewChannelAdmin",
  "UpdateChannelAdmin",
  "ViewScreensAdmin",
  "UpdateScreensAdmin",
  "ViewClientsAdmin",
  "UpdateClientsAdmin",
  "ViewContactPersonAdmin",
  "UpdateContactPersonAdmin",
  "ViewUserPermissionsAdmin",
  "UpdateUserPermissionsAdmin",
  "ViewUsersAdmin",
  "UpdateUsersAdmin"
];
