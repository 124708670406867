import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberFormat' })
@Injectable({ providedIn: 'root' })
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value === null || value === undefined || value === '') return '';

    if (typeof value === 'number' || !isNaN(parseFloat(value))) {
      const numberValue = typeof value === 'number' ? value : parseFloat(value);
      return Math.round(numberValue)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    return value.toString();
  }
}
