<div class="h-8 p-2 bg-brand-orange">
  <div class="text-neutral-800 text-xs font-normal font-['Inter'] leading-none">{{ 'poi.createPoiGdpr' | translate }}
  </div>
</div>

<div class="pt-6 mb-8">
  <div class="text-2xl font-[600]">{{ (editMode ? 'poi.editPoi' : 'poi.createPoi') | translate }}</div>
</div>

<div class="w-full form-container flex gap-10">
  <div class="w-[362px]">
    <form [formGroup]="form" class="w-[362px] flex flex-col gap-4">
      <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
        <div class="justify-start items-center gap-2 flex">
          <app-icon iconName="annotation-info"
                    size="md"
                    class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                    stroke="#598fae"></app-icon>
        </div>
        <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}
        </div>
      </div>
      <div>
        <label *ngIf="isAdmin && !editMode" class="mt-4">{{ 'poi.form.customer' | translate }} *</label>
        <mat-form-field *ngIf="isAdmin && !editMode" appearance="outline" class="w-full">
          <mat-select formControlName="customerId"
                      placeholder="{{ 'poi.form.customer' | translate }}">
            <mat-option *ngFor="let customer of customers"
                        [value]="customer.id">
              {{ customer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="form.get('customerId').value" class="w-full">
        <div>
          <label>{{ 'poi.form.name' | translate }} *</label>
          <mat-form-field class="w-full" appearance="outline">
            <input formControlName="name"
                   placeholder="{{ 'poi.form.name' | translate }}"
                   matInput
                   type="text">
          </mat-form-field>
        </div>

        <div>
          <label>{{ 'poi.form.categories' | translate }} *</label>
          <mat-form-field  appearance="outline" class="w-full">
            <mat-select
              formControlName="categoryId"
              placeholder="{{ 'poi.form.categories' | translate }}">
              <mat-option *ngFor="let category of categories"
                          [value]="category.id">
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <label>{{ 'createMedia.tags' | translate }}</label>
          <mat-form-field appearance="outline" class="w-full" >
            <flow-multi-select-autocomplete [displayFn]="displayTag"
                                            [newOptionFn]="newTag"
                                            [options]="tags"
                                            [placeholder]="'createMedia.tags' | translate "
                                            formControlName="tags">
            </flow-multi-select-autocomplete>
          </mat-form-field>
        </div>

        <div class="relative flex items-center justify-center w-full">
          <flow-drag-to-upload *ngIf="!selectedFile && !editMode" (selectedFile)="selectFile($event)"  class="w-full"></flow-drag-to-upload>
          <button (click)="clearFile()"
                  *ngIf="selectedFile"
                  class="absolute top-6 right-2 bg-white"
                  color="warn"
                  mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
          <div class="preview mt-4">
            <img *ngIf="selectedFile?.filePreview"
                [src]="selectedFile?.filePreview">
            <img *ngIf="!selectedFile?.filePreview && personOfInterest?.media?.imageUrl"
                [src]="personOfInterest.media.imageUrl">
          </div>
        </div>

      </ng-container>

      <div class="flex mt-4 ">
        <mat-checkbox formControlName="confirmation"></mat-checkbox>
        <p class="ml-3">{{ 'poi.createPoiWarningCheckbox' | translate }}</p>
      </div>
    </form>
  </div>
  <div class="w-full flex justify-between">
    <div class="w-[346px] flex flex-col p-4 gap-2">
      <div class="h-4 px-2 border-l-4 border-slate-500 items-start gap-2 inline-flex">
        <div class="text-zinc-600 text-xs font-normal font-['Inter'] leading-[18px]">{{'global.note' | translate}}</div>
      </div>
      <div class="w-full text-xs">{{'poi.note' | translate}}</div>
    </div>
    <div class="flex items-start justify-end">
      <button (click)="onCancel()"
              class="btn-cancel mr-2"
              mat-stroked-button
              type="button">
        {{ 'global.cancel' | translate }}
      </button>
      <button (click)="onSaveClick()"
              [disabled]="!form.get('confirmation').value || form.invalid"
              class="btn-save"
              color="primary"
              mat-flat-button>
        {{ (editMode ? 'global.save' : 'poi.add' ) | translate }}
      </button>
    </div>
  </div>
</div>



