import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { combineLatest, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Chart, ChartData, registerables } from 'chart.js';
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import { DateTime } from "luxon";

import { StatisticsApi } from "@api/index";
import { IdName } from "@api/models/idname";
import { customPlugin } from './labelIcon';


@Component({
  selector: 'flow-weather-chart',
  templateUrl: './weather-chart.component.html'
})
export class WeatherChartComponent implements OnInit, OnChanges {
  @Input() screens!: IdName[];
  @Input() startDate!: string;
  @Input() endDate!: string;

  dateSub = new Subject<DateTime>();
  dateSub$ = this.dateSub.asObservable().pipe(distinctUntilChanged());

  screenSub = new Subject<number[] | any>();
  screenSub$ = this.screenSub.asObservable().pipe(distinctUntilChanged());

  destroy$ = new Subject<void>();

  hasData: boolean;
  width: any
  gradient: any
  height: any
  form: FormGroup;
  lineChartData: ChartData<'line', number[] | string | string[] | any>[] | any
  public lineChartLabels: string[]
  public chartPlugins = [DataLabelsPlugin];
  public lineChartOptions = {
    elements: {
      line: { tension: 0.5 },
    },
    scales: {
      x: {
        offset: false,
        type: 'category',
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          padding: 20,
          align: 'center',
          autoSkip: false
        },
      },
      y: {
        autoSkip: false,
        offset: false,
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          callback: ((context, index) => {
            return context + '°C'
          })
        }

      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    customPlugin: {
      enabled: true,
      images: [
        '../../../../assets/logo.png',
      ]
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'top',
        padding: -10,
        formatter: ((value, context) => {
          return context.chart.data.datasets[0].data[context.dataIndex] + '°'
        }),
        color: '#000000',
        font: {
          size: 12,
        },
      }
    }
  };
  public lineChartLegend = false;

  constructor(
    private formBuilder: FormBuilder,
    private statisticApi: StatisticsApi
  ) {
    Chart.register(...registerables, customPlugin);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startDate'] || changes['endDate']) {
      if (this.startDate && this.endDate) {
        this.dateSub.next(this.getRelevantDate(this.startDate, this.endDate));
      }
    }

    if (this.screens) {
      const screenIds = this.screens ? this.screens[0]?.id : []; // Select the first screen if there is array of screens
      this.screenSub.next(screenIds);
    }
  }

  ngOnInit(): void {
    this.initForm(this.screens)

    combineLatest([this.dateSub$, this.screenSub$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([date, screen]) => {
        date ? this.form.get('date').setValue(date) : null;
        screen ? this.form.get('screenId').setValue(screen) : null;
        this.getWeatherData();
      })

    if (this.startDate && this.endDate) {
      this.dateSub.next(this.getRelevantDate(this.startDate, this.endDate))
    }

    if (this.screens) {
      const screenIds = this.screens ? this.screens[0]?.id : [];
      this.screenSub.next(screenIds)
    }
  }

  getWeatherData() {
    this.statisticApi.getWeather(this.form.getRawValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.updateChart(data)
      })
  }

  initForm(screens?) {
    this.form = this.formBuilder.group({
      date: [null],
      screenId: [screens ? screens[0]?.id : []]
    })
  }

  onDateChange(event) {
    this.dateSub.next(DateTime.fromISO(event.value));
  }

  onScreenChange(event) {
    this.screenSub.next(event.value);
  }

  private getRelevantDate(startDate, endDate) {
    const today = DateTime.local().startOf('day');
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).startOf('day');

    if (today >= start && today <= end) {
      return today;
    } else {
      return end;
    }
  }

  updateChart(data) {
    this.hasData = data.length > 0
    const dataHours = data.map(h => h.hour)
    const temperature = data.map(t => Math.round(t.temperature));
    const hours = Array.from({ length: 24 }, (_, i) => i.toString() + ':00');
    const images = data.map(i => `../../../../assets/${i.weather}.png`)

    const dataSet =
    {
      type: 'line',
      fill: 'origin',
      labels: hours,
      pointRadius: 0,
      hitRadius: 0,
      data: temperature,
      borderColor: 'transparent',
      images: images,
      backgroundColor: (context) => {
        const chart = context.chart
        const { ctx, chartArea, scales } = chart

        if (!chartArea) {
          return null;
        }
        return this.getGradinet(ctx, chartArea, scales)

      }
    }

    this.lineChartData = {
      labels: dataHours.length > 0 ? dataHours : hours,
      datasets: [dataSet]
    }
  }

  public getGradinet(ctx, chartArea, scales) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    if (this.gradient === null || this.width !== chartWidth || this.height !== chartHeight) {

      const pointZero = scales.y.getPixelForValue(0)
      const pointZeroHeight = pointZero - chartArea.top
      let pointZeroPercentage = pointZeroHeight / chartHeight

      this.width = chartWidth
      this.height = chartHeight
      this.gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartHeight + chartArea.top);


      // Clamp pointZeroPercentage between 0 and 1
      pointZeroPercentage = Math.min(Math.max(pointZeroPercentage, 0), 1);

      // Add color stops to handle negative and positive ranges
      if (pointZeroPercentage === 0) {
        // All positive values
        this.gradient.addColorStop(1, '#1F5792');
        this.gradient.addColorStop(0, 'white');
      } else if (pointZeroPercentage === 1) {
        // All negative values
        this.gradient.addColorStop(1, 'white');
        this.gradient.addColorStop(0, '#FA6200');
      } else {
        // Mixture of positive and negative values
        this.gradient.addColorStop(0, '#FA6200');
        this.gradient.addColorStop(pointZeroPercentage, 'white');
        this.gradient.addColorStop(pointZeroPercentage + ((1 - pointZeroPercentage) / 2), '#1F5792');
        this.gradient.addColorStop(1, '#1F5792');
      }

      return this.gradient;
    }

    return this.gradient;
  }
}