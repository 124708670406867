import { Component, Input} from '@angular/core';

@Component({
  selector: 'flow-metrics-box-gender',
  templateUrl: './metrics-box-gender.component.html',
  styleUrls: ['./metrics-box-gender.component.scss']
})
export class MetricsBoxGenderComponent {

  @Input() title: string;
  @Input() totalValue: number;
  @Input() startDate!: string;
  @Input() endDate!: string;
  @Input() weekNumber!: number;
  @Input() weekNumberCompare!: number;
  @Input() compare!: boolean;

}
