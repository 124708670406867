<h1 mat-dialog-title>
  {{ 'manage.validityModal.title' | translate }}
  <a class="mat-dialog-close-button" mat-icon-button (click)="onCancel()" tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a>
</h1>
<div mat-dialog-content class="py-2">
  <p>{{ 'manage.validityModal.subtitle' | translate }}</p>

  <div class="flex items-center gap-2" *ngIf="data.formInvalid">
    <app-icon fill="#ef4444" iconName="info" size="md"></app-icon>
    <p class="text-red-500 m-0">{{ 'manage.validityModal.resolveErrors' | translate }}</p>
  </div>
</div>
<div mat-dialog-actions class="flex flex-row gap-4">
  <flow-button theme="secondary" variation="outline" (click)="onDiscard()">{{ 'global.discard' | translate }}</flow-button>
  <flow-button theme="primary" cdkFocusInitial [disabled]="disable" (click)="onSave()">{{ 'global.save' | translate }}</flow-button>
</div>
