import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { data } from 'autoprefixer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/_api';
import { AuthService, UserRoleEnum } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  previousUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkForUser().pipe(
      map(
        (user: User) => {
          const set2 = user ? new Set(user.permissions) : null;
          if (!route.data || !route.data['authorize']) {
            this.previousUrl = state.url;
            return true;
          }
          if (!user) {
            this.router.navigateByUrl('/login');
            return false;
          }
          if (!Object.values(UserRoleEnum).includes(user.roleId)) {
            return false;
          }
          if (set2 && route.data.permissions && !route.data.permissions.map(item => set2.has(item)).filter(item => item).length) {
            return false;
          }
          this.previousUrl = state.url;
          return true;
        }
      )
    );
  }
}
