<div class="flex items-center justify-between">
        <div>
                <h2 class="reach-section-title">{{ "reach.reachOverall" | translate }}</h2>
        </div>
        <div>


        </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between action-bar-wrapper py-4">
        <div>
                <div *ngIf="!compareChart" class="flex">
<!--                        <span class="w-[125px]">{{ 'peopleCount.thisWeek' | translate }}</span>-->
                        <span>{{ startDate }} - {{ endDate }}</span>
                </div>
                <div *ngIf="compareChart">
                        <span class="pr-8">{{ "global.compareWeeks" | translate }}</span>
                        <span class="pr-5">{{ firstWeek }}</span>
                        <span>{{ secondWeek }}</span>
                </div>
        </div>
</div>
<mat-divider></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate" class="flex justify-center flex-col items-center mt-5">
        <img src="../../../../../assets/no-chart-data.png" />
        <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
        <canvas baseChart height="50"></canvas>
</div>
<ng-template #chartTemplate>
        <canvas
                baseChart
                [data]="chartDataWithTranslation"
                [type]="'line'"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                height="80vh">
        </canvas>
</ng-template>
