import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

type ButtonType = 'primary' | 'secondary' | 'tetriary' | 'success' | 'danger' | 'light';
type ButtonStyle = 'fill' | 'outline';

@Component({
  selector: 'flow-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  template: `
  <button 
    *ngIf="typeClasses"
    [ngClass]="[
      'h-9 rounded font-medium transition-colors duration-200',
      'disabled:opacity-60 disabled:cursor-not-allowed',
      typeClasses
    ]"
    [disabled]="disabled"
    [type]="type"
    mat-button>
    <div class="flex items-center gap-2">
      <ng-content></ng-content>
    </div>
  </button>
`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() theme: ButtonType = 'primary';
  @Input() variation: ButtonStyle = 'fill';
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  typeClasses: string = '';

  ngOnInit() {
    this.setStyle();
  }

  setStyle() {
    const base = 'min-w-0 text-sm font-semibold capitalize leading-none';

    const styles = {
      primary: {
        fill: 'bg-brand-chathams-blue text-white hover:bg-brand-chathams-blue/85',
        outline: 'ring ring-1 ring-brand-chathams-blue text-brand-chathams-blue hover:bg-brand-chathams-blue/10',
      },
      secondary: {
        fill: 'bg-neutral-200 text-black hover:bg-neutral-100',
        outline: 'ring ring-1 ring-neutral-200 text-black hover:bg-neutral-100',
      },
      tetriary: {
        fill: 'bg-brand-orange text-white hover:bg-brand-orange/75',
        outline: 'ring ring-1 ring-brand-orange text-brand-orange hover:bg-brand-orange/10',
      },
      success: {
        fill: 'bg-green-600 text-white hover:bg-green-700',
        outline: 'ring ring-1 ring-green-600 text-green-600 hover:bg-green-50',
      },
      danger: {
        fill: 'bg-brand-red text-white hover:bg-brand-red/85',
        outline: 'ring ring-1 ring-brand-red text-brand-red hover:bg-brand-red/10',
      },
      light: {
        fill: 'text-black hover:bg-neutral-100',
      },
    };

    this.typeClasses = `${styles[this.theme][this.variation]} ${base} px-${this.theme === 'light' ? '2' : '3'} focus:ring-${
      this.theme
    }-500`;
  }
}
