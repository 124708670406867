import { animate, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MediaRequest } from '@api/models/medias/media-request';
import { PaginatorParams } from '@api/models/paginator';
import { EnumService } from '@app/core/services/enum.service';
import { environment } from '@environments/environment';
import { DateTime } from 'luxon';
import {
  catchError,
  map,
  Observable,
  Subscription,
  takeUntil,
  throwError,
} from 'rxjs';
import {
  AdminApi,
  Customer,
  MediaFile,
  MediaTypeEnum,
  StandardMedia,
  StatisticsApi,
  Tag,
} from 'src/_api';
import { Client } from '@api/models/client';
import { FolderFile } from '@api/models/folder-file';
import { FolderApi } from '@api/services/folder-api';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { BreadcrumbService } from '@app/core/services/breadcrumb.service';
import { MediaModalService } from '@app/core/services/media-modal.service';
import { Media } from '@app/core/services/media.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { Breadcrumb } from '@app/models/breadcrumb';
import { StatisticsFilterInputs } from '@app/models/statistics-filter-inputs';
import { TableData } from '@app/models/table-data';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { FilesizePipe } from '@app/shared/filesize-pipe/filesize.pipe';
import { MoveFileToFolderModalComponent } from '@app/shared/move-file-to-folder-modal/move-file-to-folder-modal.component';
import { BookingsFilterService } from '@app/admin/engagement/bookings/bookings-filter.service';
import { MediaDetailsComponent } from '@app/admin/media/media-details/media-details.component';
import { MediaFiltersComponent } from '@app/admin/media/media-filters/media-filters.component';
import { MediaApi } from '@api/services/media-api';
import { PermissionsService } from '@app/core/services/permissions.service';
import { FolderDeletion } from '@api/models/folder-deletion';
import { MediaInUseModel } from '@api/models/media-in-use-model';
import { BackendErrorsTranslate } from '@app/core/be-errors-translations';
import { ContentData, CustomSnackbarComponent } from '@app/shared/custom-snackbar/custom-snackbar.component';
import { BackendErrorsEnum } from '@api/models/be-errors-enum';

const enterTransition = transition(':enter', [
  style({
    opacity: 0
  }),
  animate('100ms ease-in', style({
    opacity: 1,
  }))
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate('100ms ease-out', style({
    opacity: 0
  }))
])

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);
@Component({
  selector: 'flow-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [FilesizePipe],
  animations: [fadeIn, fadeOut],
})
export class MediaComponent extends unsubscribeMixin() implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  storageLimitSize: number = 0;
  sizeOfMediaAndFolders: number;
  viewMode: string = 'table';
  medias: MediaListItem[] = [];
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc',
  };
  selectedFolder: number;
  mediaLinks: any[] = [
    { id: 1, name: this.translateConfigService.instant('sidebar.media'), permissions: ['UpdateMediaCms'] },
    { id: 2, name: this.translateConfigService.instant('global.folder'), permissions: ['UpdateMediaCms'] }
  ]
  paginatedData = [];
  displayedColumns: string[] = ['id', 'thumbnail', 'name', 'type', 'tags', 'filename', 'customer', 'client', 'layout', 'filesize', 'duration', 'impressions', 'lastUsed', 'created'];
  columnsConfig = [
    { key: 'id', label: 'id', text: 'id' },
    { key: 'thumbnail', label: 'file', text: 'imageUrl' },
    { key: 'name', label: 'name', text: 'name' },
    { key: 'type', label: 'type', text: 'typeText' },
    { key: 'tags', label: 'tags', text: 'tagsName' },
    { key: 'filename', label: 'filename', text: 'filename' },
    { key: 'customer', label: 'customer', text: 'customerName' },
    { key: 'client', label: 'client', text: 'clientName' },
    { key: 'layout', label: 'layout', text: 'layout' },
    { key: 'filesize', label: 'filesize', text: 'filesizeFormat' },
    { key: 'duration', label: 'duration', text: 'duration' },
    { key: 'impressions', label: 'impressions', text: 'impressions' },
    { key: 'lastUsed', label: 'lastUsed', text: 'lastUsed' },
    { key: 'created', label: 'created', text: 'created' }
  ];
  MediaTypeEnum = MediaTypeEnum;
  dataSource: MatTableDataSource<MediaListItem>;
  foldersTopRowData: Array<MediaListItem>;
  panelOpenState = true;
  filterValues: MediaFilterValues;
  clients: Client[];
  tags: Tag[];
  types: Tag[];
  customers: Customer[];
  isAdmin: boolean;
  customerId: number;
  isReady: boolean = false;
  sizeOfMediaLibrary: number;
  currentFolderSize: number = 0;
  currentFolder: Breadcrumb;
  folders: MediaListItem[] = [];
  isInFolder: boolean = false;
  sortObject: any = { active: 'name', direction: 'asc' };
  mediaIds: number[] = [];
  pageSize: number = 20;
  pageNumber: number = 1;
  hideShowMore: boolean = false;
  folderlist = [];
  medialist = [];
  hoveredMedia: any;
  selectedMedias = [];
  isDragging = false;
  hideTotalSizebars = false;
  filterInputs = new Map<string, StatisticsFilterInputs>();
  isFilterDataReady: boolean = false;
  data: TableData = {
    actionButtons: [],
    dataModels: [],
    dataSource: new MatTableDataSource<MediaListItem>([]),
    displayedColumns: [],
  };
  clients$: Observable<{ id: number; name: string }[]>;
  tags$: Observable<{ id: number; name: string }[]>;
  openFilter: boolean = true;
  consentGiven: boolean = false;
  consentKey = 'media-consent';
  filtersNumber: number = 0;
  apiBaseUrl: string = environment.apiBaseUrl;
  
  private subscription: Subscription;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() isModal: boolean;
  @Input() multipleSelect: boolean = true;
  @Input() customerIdModal: number;
  @Output() mediaSelected = new EventEmitter<Media | Media[]>();
  @Output() cancel = new EventEmitter();

  constructor(
    public bookingsFilterService: BookingsFilterService,
    private filesizePipe: FilesizePipe,
    private folderApi: FolderApi,
    private authService: AuthService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private mediaModalService: MediaModalService,
    private mediaApi: MediaApi,
    public platform: Platform,
    private statisticsApi: StatisticsApi,
    private enumService: EnumService,
    private permissionsService: PermissionsService,
    private translateService: TranslateService
  ) {
    super();
  }

  get isAllSelected(): boolean {
    const validItems = this.dataSource.data.filter(item => item.type !== MediaTypeEnum.Folder);
    return validItems.length > 0 && validItems.every(item => item.isSelected);
  }

  ngOnInit(): void {
    this.checkConsent();
    this.isInFolder = this.breadcrumbService.getBreadcrumbs().length > 1;
    const mediaView = localStorage.getItem('mediaView');
    if (mediaView) this.viewMode = JSON.parse(mediaView);
    const user = this.authService.userData;
    this.isAdmin = user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    this.customerId = this.isAdmin ? null : this.customerIdModal || user?.currentCustomerId || user?.customerId;

    if (this.isModal) {
      const columnsToRemove = ['id', 'filename', 'filesize', 'impressions', 'lastUsed'];

      if (this.isAdmin) {
        columnsToRemove.push('customer');
      }

      this.displayedColumns = this.displayedColumns.filter(column => !columnsToRemove.includes(column));
      this.columnsConfig = this.columnsConfig.filter(column => !columnsToRemove.includes(column.key));
      this.types = this.enumService.mediaTypes;
      const newItem = { key: 'folder', label: 'folder', text: 'folderName' };
      const index = this.columnsConfig.findIndex((item) => item.key === 'name');

      if (index !== -1) {
        this.displayedColumns.splice(index + 1, 0, 'folder');
        this.columnsConfig.splice(index + 1, 0, newItem);
      }

      if (!this.isAdmin && !this.isModal || this.isModal) {
        this.displayedColumns.unshift('checkbox');
      }

      this.initForm();

    } else {
      this.addAdditionalColumns();
    }

    this.dataSource = new MatTableDataSource();
    this.subscription = this.breadcrumbService.navigationClick$.subscribe(
      (folderId) => {
        this.pageNumber = 1;
        this.medias = [];
        this.folders = [];
        this.getMedias(this.getParams());
      }
    );
    if(this.isModal){
      this.onCustomerSelect();
    }
    this.getMedias(this.getParams());
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  override ngOnDestroy(): void {
    localStorage.removeItem('mediaView');
    this.breadcrumbService.clearBreadcrumbs();
    this.ngUnsubscribe.next();
    this.subscription.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      text: [''],
      filterTypes: [],
      customerIds: this.isAdmin ? null : [this.customerIdModal],
      clientIds: [],
      tags: [],
      created: this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null)
      }),
      isShowFolder: [true]
    });
    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((values) => {
        this.filterValues = values;
        this.getMedias(this.getParams());
      });
  }

  clearDatePicker() {
    this.form.get('created').get('start').setValue(null);
    this.form.get('created').get('end').setValue(null);
  }
  selectMedias(event: MatCheckboxChange | MatRadioChange, media: MediaListItem) {
    const checked = event instanceof MatCheckboxChange ? event.checked : event instanceof MatRadioChange ? event.value : false;

    if (this.multipleSelect) {
      if (!media) {
        this.selectAll(checked);
      } else {
        this.selectCheckbox(media, checked);
      }
    } else {
      this.selectRadio(media);
    }

    this.mediaSelected.emit(this.multipleSelect ? this.selectedMedias : this.selectedMedias[0]);
  }

  selectAll(checked: boolean) {
    const itemsWithNoFolders = this.dataSource.data.filter(item => item.type !== MediaTypeEnum.Folder);

    itemsWithNoFolders.forEach(item => {
      item.isSelected = checked;
      if (checked) {
        if (!this.selectedMedias.find((m: MediaListItem) => m.id === item.id)) {
          this.mediaIds.push(item.id);
          this.selectedMedias.push(item);
        }
      } else {
        this.mediaIds = this.mediaIds.filter(id => !itemsWithNoFolders.find((item: MediaListItem) => item.id === id));
        this.selectedMedias = this.selectedMedias.filter((m: MediaListItem) => !itemsWithNoFolders.find((item: MediaListItem) => item.id === m.id));
      }
    });
  }

  selectCheckbox(media: MediaListItem, checked: boolean) {
    media.isSelected = checked;

    if (checked) {
      if (!this.selectedMedias.find((m: MediaListItem) => m.id === media.id)) {
        this.selectedMedias.push(media);
        this.mediaIds.push(media.id);
      }
    } else {
      this.selectedMedias = this.selectedMedias.filter((m: MediaListItem) => m.id !== media.id);
      this.mediaIds = this.mediaIds.filter(id => id !== media.id);
    }
  }

  selectRadio(media: MediaListItem) {
    this.selectedMedias = [media];
    this.mediaIds = [media.id];
  }

  isSortable(columnKey: string) {
    return columnKey !== 'thumbnail';
  }

  changeView(view) {
    if (view === "grid") {
      this.displayedColumns.shift();
    }
    else if (!this.isAdmin && !this.isModal || this.isModal) {
      this.displayedColumns.unshift('checkbox');
    }
    localStorage.setItem('mediaView', JSON.stringify(view));
    this.mediaIds = [];
    this.viewMode = view || 'table';
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(MediaFiltersComponent, {
      width: '500px',
      panelClass: ['max-h-[33.5rem]']
    });

    dialogRef.afterClosed().subscribe((filters: any) => {
      this.filterValues = filters;
      this.clearPaginationParams();
      if(filters){
        this.getMedias(this.getParams());
      }
    });
  }

  openDetailsModal(row) {
    const dialogRef = this.dialog.open(MediaDetailsComponent, {
      width: '500px',
      panelClass: ['max-h-[33.5rem]'],
      data: row
    });

    dialogRef.afterClosed().subscribe((filters: any) => { });
  }

  onSelectionChange(option) {
    switch (option.id) {
      case 1: this.router.navigate(['/medias/create']); break;
      case 2: this.router.navigate(['/folders/create']); break;
    }
  }

  folderClick(media: MediaListItem) {
    this.hideShowMore = true;
    this.medias = [];
    this.folders = [];
    if (media.type === 3) {
      this.selectedFolder = media.folder.id;
      this.breadcrumbService.addBreadcrumb(media.name, media.id);
      this.isInFolder = this.breadcrumbService.getBreadcrumbs().length > 1;
      this.params = { ...this.params, pageNumber: 0, pageSize: 10 };
      this.getMedias(this.getParams());
    }
  }

  clearPaginationParams() {
    this.params = {
      pageNumber: 0,
      pageSize: 10,
      sortBy: 'name',
      sortDirection: 'asc'
    };
  }

  handleSortEvent(sort: Sort) {
    this.params = {
      ...this.params,
      pageNumber: 0,
      sortBy: sort?.active,
      sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc'
    };
    this.getMedias(this.getParams());
  }

  handlePageEvent(e: PageEvent) {
    if (this.isReady) {
      this.params = {
        ...this.params,
        pageNumber: e.pageIndex,
        pageSize: e.pageSize,
      };
      this.getMedias(this.getParams());
    }
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (standardMedia.type !== MediaTypeEnum.Folder)
      this.mediaModalService.openViewMediaModal(standardMedia);
  }

  addAdditionalColumns() {
    this.displayedColumns.push('actions');
    if (!this.isAdmin && !this.isModal || this.isModal) {
      this.displayedColumns.unshift('checkbox');
    }
  }

  onCustomerSelect() {
    this.clients$ = this.statisticsApi.getClients([this.customerIdModal]);
    this.tags$ = this.statisticsApi.getTags([this.customerIdModal]);
  }

  openMoveMediaModal(media: Media) {
    const mediaCustomerID = media ? media?.customer?.id : this.dataSource.data[0].customer.id;

    this.folderApi.getFoldersById(mediaCustomerID)
      .pipe(
        map((folders: FolderFile[]) => {
          if (folders && folders.length > 0) {
            return folders;
          } else {
            throw "notifications.error.noFoldersForCustomer";
          }
        }),
        catchError(err => throwError(() => err))
      )
      .subscribe({
        next: (folders: FolderFile[]) => {
          const dialogRef = this.dialog.open(MoveFileToFolderModalComponent, {
            width: '1000px',
            data: {
              mediaId: media ? media.id : null,
              mediaIds: media ? null : this.mediaIds,
              folderId: this.selectedFolder,
              folders: folders
            },
          });

          dialogRef.afterClosed().subscribe((data?: boolean) => {
            if (data) {
              this.medias = [];
              this.mediaIds = [];
              this.getMedias(this.getParams());
            }
          });
        },
        error: (error) => {
          this.systemMessageService.error(this.translateConfigService.instant(error));
        }
      })

  }

  private getMedias(params?: MediaRequest): void {
    this.medias = [];
    this.isInFolder = this.breadcrumbService.getBreadcrumbs().length > 1;
    this.currentFolder = null;
    if (this.isInFolder) {
      this.currentFolder = this.breadcrumbService.getBreadcrumbs()[this.breadcrumbService.getBreadcrumbs().length - 1];
      params.folderId = this.currentFolder.id;
    } else {
      delete params.folderId;
    }
    this.isReady = false;
    this.mediaApi.getMediasAndFolders(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mediasAndFoldersResponse) => {

        this.medias = this.convertMedias(mediasAndFoldersResponse.mediaFiles);
        this.folders = this.medias.filter(x => x.type === MediaTypeEnum.Folder);
        const shouldShowFolders = params.isShowFolder && !this.isInFolder;
        const mergedData = !shouldShowFolders ? this.medias.filter(x => x.type !== MediaTypeEnum.Folder) : this.medias;

        this.currentFolderSize = mediasAndFoldersResponse.sizeOfFolder;
        this.sizeOfMediaAndFolders = mediasAndFoldersResponse.sizeOfMediaLibrary;
        this.sizeOfMediaLibrary = mediasAndFoldersResponse.sizeOfMediaLibrary;
        this.storageLimitSize = mediasAndFoldersResponse.storageLimitSize;

        this.dataSource = new MatTableDataSource(mergedData);
        this.params = {
          ...this.params,
          pageNumber: mediasAndFoldersResponse?.paginationResult?.currentPage,
        };
        this.totalCount = mediasAndFoldersResponse?.paginationResult?.totalCount;
        this.isReady = true;
        // this.scrollToTop();
      });
  }

  private getParams() {
    let mediaRequest: MediaRequest = this.params;
    this.filtersNumber = 0;
    if (this.filterValues) {
      if (this.filterValues?.created?.start) {
        mediaRequest.dateFrom = this.filterValues.created.start.toString();
        this.filtersNumber++;
      }
      if (this.filterValues?.created?.end) {
        mediaRequest.dateTo = this.filterValues.created.end.toString();
        this.filtersNumber++;
      }
      if (this.filterValues.filteredStartDate) {
        mediaRequest.dateFrom = this.filterValues.filteredStartDate.toString();
        this.filtersNumber++;
      }
      if (this.filterValues.filteredEndDate) {
        mediaRequest.dateTo = this.filterValues.filteredEndDate.toString();
        this.filtersNumber++;
      }
      if (this.filterValues.customerIds) {
        mediaRequest.customerIds = this.filterValues.customerIds;
        this.filtersNumber++;
      }
      if (this.filterValues.filterTypes) {
        mediaRequest.filterTypes = this.filterValues.filterTypes;
        this.filtersNumber++;
      }
      if (this.filterValues.clientIds) {
        mediaRequest.clientIds = this.filterValues.clientIds;
        this.filtersNumber++;
      }
      if (this.filterValues.tags) {
        mediaRequest.tags = this.filterValues.tags;
        this.filtersNumber++;
      }
      if (this.filterValues.text) {
        mediaRequest.text = this.filterValues.text;
        this.filtersNumber++;
      }
      if (!this.filterValues.isShowFolder) this.filtersNumber++;
      mediaRequest.isShowFolder = this.filterValues.isShowFolder;
    } else {
      mediaRequest = {
        ...mediaRequest,
        isShowFolder: true
      }
    }
    if (this.customerId) {
      mediaRequest.customerIds = [this.customerId];
      mediaRequest.selectedCustomerId = this.customerId;
    }
    return mediaRequest;
  }

  onDeleteClick(media: Media, isBulk: boolean = false): void {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent, {
      width: isBulk ? '500px' : media.type === 3 ? '600px' : '500px',
      data: {
        remove: () => isBulk ? this.deleteBulkMedias(dialogRef) : this.deleteMedia(media.id, media.type, dialogRef),
        type: isBulk ? 1 : media.type,
        logic: true
      }
    })
  }
  deleteMedia(id: number, type: number, dialogRef: MatDialogRef<ConfirmationModalComponent>) {
    const method = type === 3 ? 'deleteFolder' : 'removeMedias';
    const service = type === 3 ? 'folderApi' : 'mediaApi';
    this[service][method](type === 3 ? { id } : { mediaIds: [id] })
      .pipe(
        catchError((error) => {
          dialogRef.close();
          return throwError(() => error);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          if (type === 3) {
            this.systemMessageService.success(this.translateConfigService.instant("notifications.success.folderDelete"));
          } else {
            this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaDeleted"));
          }
          dialogRef.close()
          this.getMedias(this.getParams());
        },
        error: (error: FolderDeletion) => {
          if (error?.errorType && (error?.errorType === BackendErrorsEnum.mediasInUse || error?.errorType === BackendErrorsEnum.mediaInUse)) {
            this.calculateMediaDeletionErrors(error);
          }
        },
        finally: () => dialogRef.close()
      });
  }

  calculateMediaDeletionErrors(result: FolderDeletion) {
    const medias = result.mediasInUse;
    let message: ContentData = {
      title: '',
      content: '',
      preText: '',
      listContent: '',
    };

    if (medias && Array.isArray(medias)) {
      medias.forEach((media) => this.buildMedia(media, message, result.errorType));
    } else {
      this.buildMedia(result.mediaInUse, message, result.errorType);
    }

    this.dialog.open(CustomSnackbarComponent, {
      width: '600px',
      data: {
        msg: message,
      },
    });
  }

  buildMedia(media: MediaInUseModel, message: ContentData, errorType: string) {
    let mediaName = media.mediaName;
    message.title = 'media.cannotDelete';
    message.content = this.translateService.instant(BackendErrorsTranslate[errorType]);
    message.preText = this.translateService.instant('media.usedMediasList');
    media.mediaUsedAtEngagements.filter((engagement: any) => {
      let type =
        engagement.engagementType === 1
          ? 'editSegment.segmentName'
          : 'editPlaylist.playlistName';
      message.listContent +=
        '<li><strong>' +
        mediaName +
        ':</strong> ' +
        this.translateService.instant(type) +
        ' <strong>' +
        engagement.engagementName +
        '</strong></li>';
    });
    media.mediaUsedAsADefaultMediaAtScreens.filter((screen: any) => {
      message.listContent +=
        '<li><strong>' +
        mediaName +
        ':</strong> ' +
        this.translateService.instant('media.defaultMediaForScreen') +
        ' <strong>' +
        screen +
        '</strong></li>';
    });
    media.mediaUsedAsADefaultMediaAtSegments.filter((segment: any) => {
      message.listContent +=
        '<li><strong>' +
        mediaName +
        ':</strong> ' +
        this.translateService.instant('media.defaultMediaForSegment') +
        ' <strong>' +
        segment +
        '</strong></li>';
    });
  }

  onCancelClick(): void {
    this.cancel.emit();
  }

  resolutionLabel = (media: Media): string => {
    return media && media.width + ' x ' + media.height;
  }

  isFilterEmpty = (): boolean => {
    return (
      !this.filterValues?.text &&
      !this.filterValues?.filterTypes?.length &&
      !this.filterValues?.tags?.length &&
      !this.filterValues?.filteredStartDate &&
      !this.filterValues?.filteredEndDate &&
      !this.filterValues?.lastUsed?.start &&
      !this.filterValues?.lastUsed?.end &&
      (this.isAdmin ? !this.filterValues?.customerIds?.length : true) &&
      !this.filterValues?.clientIds?.length
    );
  };

  isButtonShown() {
    return !this.isModal && !this.isAdmin && this.mediaIds.length > 0;
  }

  deleteBulkMedias(dialogRef: MatDialogRef<ConfirmationModalComponent>) {
    if (this.mediaIds.length > 0) {
      this.mediaApi.removeMedias({ mediaIds: this.mediaIds })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            dialogRef.close();
            this.systemMessageService.success(
              this.translateConfigService.instant('notifications.success.mediasDeleted')
            );
            this.mediaIds = [];
            this.getMedias(this.getParams());
          },
          error: (error) => {
            if (error?.errorType && (error?.errorType === BackendErrorsEnum.mediasInUse || error?.errorType === BackendErrorsEnum.mediaInUse)) {
              this.calculateMediaDeletionErrors(error);
            }
          }
        });
    }
  }
  

  drop(media: any) {
    if (this.hoveredMedia.type === 3) {
      let mediasIds = [];
      if (this.mediaIds.length) mediasIds = this.mediaIds;
      else mediasIds.push(media.id);
      this.folderApi.moveMediasToFolder({
        mediaIds: mediasIds,
        folderId: this.hoveredMedia.id,
      })
        .subscribe(() => {
          this.selectedMedias = [];
          this.mediaIds = [];
          this.systemMessageService.success(this.translateConfigService.instant('notifications.success.fileMoved', this.hoveredMedia.name));
          this.getMedias(this.getParams());
        });
    }
    this.isDragging = false;
  }

  enter(media: any) {
    this.hoveredMedia = media;
    if (this.isDragging) media.openFolder = true;
  }

  leave(media) {
    this.hoveredMedia = null;
    media.openFolder = false;
  }

  onDragStarted() {
    this.isDragging = true;
  }

  onDragReleased() {
    this.isDragging = false;
  }

  redirectToEditPage(media: MediaListItem) {
    this.mediaIds = [];
    if (this.permissionsService.hasPermissions(['UpdateMediaCms']) || this.isModal) {
      if (this.isModal) {
        if (media.type === 3) {
          this.folderClick(media);
        } 
      } else {
        if (media.type === 3) {
          this.router.navigate(['/folders', media.id])
        } else {
          this.router.navigate(['/medias', media.id])
        }
      }
    }
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }

  private convertMedias(mediaFiles: MediaFile[]) {
    return mediaFiles.map((m) => {
      const listItem = { ...m } as MediaListItem;
      listItem.imageUrl = listItem.imageUrl;
      listItem.layout = m.width && m.height ? m.width + 'x' + m.height : '';
      listItem.typeText = MediaTypeEnum[m.type];
      listItem.customerName = m.customer?.name || '';
      listItem.clientName = m.client?.name || '';
      listItem.filesizeFormat = this.filesizePipe.transform(m.size);
      listItem.duration = m.duration;
      listItem.tagsName = m.tags.map((t) => t.name).join(', ') || '';
      listItem.folderName = m.folder?.name;
      listItem.mediaCount = m.mediaCount || 0;
      return listItem;
    }) as MediaListItem[];
  }
}

interface MediaFilterValues {
  text?: string;
  filterTypes?: number[];
  tags?: number[];
  customerIds?: number[];
  clientIds?: number[];
  isShowFolder?: boolean;
  filteredStartDate?: DateTime;
  filteredEndDate?: DateTime;
  created? : {
    start?: DateTime;
    end?: DateTime;
  };
  lastUsed?: {
    start?: DateTime;
    end?: DateTime;
  };
}

export interface MediaListItem extends Media {
  isSelected?: boolean;
  openFolder?: boolean;
  impressions?: number;
  typeText?: string;
  customerName?: string;
  clientName?: string;
  filesizeFormat?: string;
  layout?: string;
  tagsName?: string;
  folderName?: string;
}