import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'flow-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  checked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.data.remove()
  }

}

interface ModalData {
  remove?: Function;
  type: number;
  logic: boolean;
}
