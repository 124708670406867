import {
  Component, EventEmitter, Injectable, Input, Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import { DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { STATISTICS_START_DATE } from 'src/app/constants';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
@Injectable()
export class WeekRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) { }

  selectionFinished(date: D | null): DateRange<D> {
    return this._createWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createWeekRange(activeDate);
  }

  private _createWeekRange(date: D | null): DateRange<D> {
    // @ts-ignore weekday does exist, but how to let typescript know?
    if (!date || date?.weekday !== 1) {
      const dateObjAsAny: any = date
      const d = dateObjAsAny?.weekday
      if (d) {
        const start = this._dateAdapter.addCalendarDays(date, -d + 1);
        const end = this._dateAdapter.addCalendarDays(start, 6);
        return new DateRange<D>(start, end);
      }
      else
        return new DateRange<D>(null, null);
    }
    const end = this._dateAdapter.addCalendarDays(date, 6);
    return new DateRange<D>(date, end);
  }
}

@Component({
  selector: 'week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekRangeSelectionStrategy,
    },
  ],
})
export class WeekPickerComponent extends unsubscribeMixin() {
  @Input() range: any
  @Input() label = ''
  @Input() disabled = false
  @Input() showClearBtn = false;
  @Input() disableWeekStartDate: DateTime;

  @Output() weekSelected = new EventEmitter();

  @Input() maxDate = DateTime.local().endOf('week')
  @Input() minDate = STATISTICS_START_DATE
  startDate: Date | null = null;

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if (dateRangeStart && dateRangeEnd) {
      this.weekSelected.emit()
    }
  }

  clearDateRange(event) {
    event.stopPropagation()
    this.range.patchValue({
      start: null,
      end: null
    });

  }

  getWeekDates(date: DateTime): DateTime[] {
    const startOfWeek = date.startOf('week');
    return Array.from({ length: 7 }, (_, i) => startOfWeek.plus({ days: i }));
  }

  dateFilter = (date: DateTime | null): boolean => {
    if (!this.disableWeekStartDate) return true;

    const weekDates = this.getWeekDates(this.disableWeekStartDate);
    const disableDate = weekDates ? !weekDates.some(disabledDate => {
      const val = disabledDate.hasSame(date, 'day');
      return val;
    }) : true;

    return disableDate
  };
  protected readonly event = event;
}
