import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TriggerData } from 'src/app/models/trigger-data';


@Component({
  selector: 'flow-trigger-media-model',
  templateUrl: './trigger-media-model.component.html',
  styleUrls: ['./trigger-media-model.component.scss']
})
export class TriggerMediaModal implements OnInit {
  form: FormGroup;
  skyConditionIcons = ['Thunderstorm', 'Drizzle', 'Rain', 'Snow', 'Atmosphere', 'Clear', 'Clouds'];
  newSelectionValues = { min: null, max: null };
  isChanged = true;

  constructor(public dialogRef: MatDialogRef<TriggerMediaModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    if (this.data.triggers && this.data.triggers.temperature && Object.keys(this.data.triggers.temperature).length > 0) {
      this.newSelectionValues = this.data.triggers.temperature;
    }
    this.form = this.formBuilder.group({
      selectedIcons: this.formBuilder.array(this.data.triggers?.weatherConditions || []),
      temperature: [this.data.triggers?.temperature || {}]
    });
  }
  get selectedIcons(): FormArray {
    return this.form.get('selectedIcons') as FormArray;
  }
  toggleIcon(icon: string) {
    const index = this.selectedIcons.value.indexOf(icon);
    if (index === -1) {
      this.selectedIcons.push(this.formBuilder.control(icon));
    } else {
      this.selectedIcons.removeAt(index);
    }
  }
  onClose() {
    this.dialogRef.close();
  }
  onConfirm() {
    this.dialogRef.close(this.form.value);
  }
  onTemperatureValuesChange(values:any){
      this.form.get('temperature').setValue(values);
  }
  clearTriggers(){
    this.selectedIcons.clear();
    this.form.get('temperature').setValue(null);
    this.newSelectionValues = { min: null, max: null };
    this.isChanged = false;
    setTimeout(() => {
      this.isChanged = true;
    })
  }
}
interface ModalData {
  triggers?: TriggerData;
}