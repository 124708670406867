import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { TranslateConfigService } from 'src/app/core/translate-config.service';

@Component({
  selector: 'flow-password-reset-confirm-modal',
  templateUrl: './password-reset-confirm-modal.component.html',
  styleUrls: ['./password-reset-confirm-modal.component.scss']
})
export class PasswordResetConfirmModalComponent implements OnInit {

  checked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordResetConfirmModalComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    private systemMessageService: SystemMessageService,
    private router: Router,
    public translateService: TranslateConfigService
  ) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.authService.resetPassword().subscribe({
      next: () => {
        this.systemMessageService.success(this.translateService.instant("notifications.success.checkYourEmail"));
        this.dialog.closeAll()
        setTimeout(() => {
          this.authService.logout();
          this.router.navigateByUrl("/login");
        }, 1000)

      }
    })
  }

}
