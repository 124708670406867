<div class="flex items-center justify-between visitors">
  <div>
    <h2 class="reach-section-title"> {{ "peopleCount.weatherPerDay" | translate }}</h2>
  </div>
  <div class="menu">
    <button [matMenuTriggerFor]="menu" type="button" aria-label="Example icon-button with a menu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="flex flex-col">
        <form [formGroup]="form" class="p-2">
          <mat-form-field appearance="outline"
                          class="w-full">
            <mat-label>{{'peopleCount.date' | translate}}</mat-label>
            <input matInput
                   formControlName="date"
                   [matDatepicker]="picker"
                   (dateChange)="onDateChange($event)"
            >
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-full" (click)="$event.stopPropagation()">
            <mat-label>{{'global.screen' | translate}}</mat-label>
            <mat-select formControlName="screenId" (selectionChange)="onScreenChange($event)">
              <mat-option *ngFor="let screen of screens" [value]="screen.id">
                {{screen.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

      </div>
    </mat-menu>
  </div>
</div>

<mat-divider></mat-divider>
<div class="flex py-4">
  <span class="pr-1">{{ form.get('date').value | date: 'YYYY-MM-dd' }}</span>
</div>
<mat-divider></mat-divider>

<div *ngIf="!hasData" class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
  <canvas baseChart height="50"></canvas>
</div>

<div *ngIf="hasData">
  <canvas baseChart
          [data]="lineChartData"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [plugins]="chartPlugins"
          height="90vh">
  </canvas>
</div>
