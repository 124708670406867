import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { IconComponent } from './icon/icon.component';
import { PopoverComponent } from './popover/popover.component';
import { PopoverDirective } from './popover/popover.directive';
import { StickyHeaderDirective } from './directives/sticky-header.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TabsComponent } from './tabs/tabs.component';
import { TabsItemComponent } from './tabs/tabs-item/tabs-item.component';
import { TabsRouteComponent } from './tabs-route/tabs-route.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { PermissionsTableComponent } from './permissions-table/permissions-table.component';
import { HelperCardComponent } from '@app/admin/helper-card/helper-card.component';
import { StepperComponent } from './stepper/stepper.component';
import { BookStepPipe } from './pipes/book-step.pipe';
import { DragToUploadComponent } from './drag-to-upload/drag-to-upload.component';
import { FilesizePipe } from "@shared/filesize-pipe/filesize.pipe";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SpinnerComponent } from './spinner/spinner.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { HeaderComponent } from './header/header.component';
import { CloudStorageComponent } from './cloud-storage/cloud-storage.component';
import { FormInputComponent } from './form/form-input/form-input.component';
import { PillBoxComponent } from './pill-box/pill-box.component';
import { MediaPreviewCarouselComponent } from './media-preview-carousel/media-preview-carousel.component';
import { MediaPreviewSlideComponent } from './media-preview-carousel/media-preview-slide/media-preview-slide.component';
import { DashboardFilterComponent } from '@app/admin/statistics/component/dashboard-filter/dashboard-filter.component';
import { MetricsBoxDetailsComponent } from '@app/admin/statistics/component/metrics-box-details/metrics-box-details.component';
import { StatisticsFilterComponent } from '@app/admin/statistics/component/statistics-filter/statistics-filter.component';
import { WeekPickerComponent } from './date/week-picker/week-picker.component';
import { YearAndMonthPickerComponent } from './date/year-and-month-picker/year-and-month-picker.component';
import { YearAndMonthPickerHeader } from './date/year-and-month-picker/year-and-month-picker-header.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { ButtonComponent } from './button/button/button.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { KpiRadioGroupComponent } from './form/kpi-radio-group/kpi-radio-group.component';
import { MultiSelectAutocompleteComponent } from './multi-select-autocomplete/multi-select-autocomplete.component';

const STANDALONE_COMPONENTS = [ButtonComponent];


const MATERIAL = [
  MatButtonModule,
  MatButtonToggleModule,
  MatSidenavModule,
  MatIconModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatChipsModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTabsModule,
  MatTreeModule,
  MatDatepickerModule,
  MatLuxonDateModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatMenuModule,
  MatRadioModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  ClipboardModule
];

const IMPORTS = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  NgChartsModule,
  NgxMatIntlTelInputComponent,
  DragDropModule,
  ...MATERIAL,
  ...STANDALONE_COMPONENTS,
]

const DECLARATIONS = [
  PopoverDirective,
  HasPermissionDirective,
  StickyHeaderDirective,
  IconComponent,
  PopoverComponent,
  TabsComponent,
  TabsItemComponent,
  TabsRouteComponent,
  PermissionsTableComponent,
  HelperCardComponent,
  StepperComponent,
  BookStepPipe,
  DragToUploadComponent,
  FilesizePipe,
  SpinnerComponent,
  ButtonDropdownComponent,
  MediaPreviewComponent,
  HeaderComponent,
  CloudStorageComponent,
  FormInputComponent,
  MediaPreviewCarouselComponent,
  MediaPreviewSlideComponent,
  PillBoxComponent,
  DashboardFilterComponent,
  MetricsBoxDetailsComponent,
  StatisticsFilterComponent,
  WeekPickerComponent,
  YearAndMonthPickerComponent,
  YearAndMonthPickerHeader,
  DatetimePickerComponent,
  NumberFormatPipe,
  KpiRadioGroupComponent,
  MultiSelectAutocompleteComponent
]

const PROVIDERS = [
  DatePipe
]

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  exports: [...IMPORTS, ...DECLARATIONS],
  providers: [...PROVIDERS]
})
export class SharedModule { }
