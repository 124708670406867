import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TableData } from 'src/app/models/table-data';
import { ActionButtonEnum } from '../action-button.enum';

@Component({
  selector: 'flow-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: ElementRef;
  @Input() data: TableData;
  @Output() onClick = new EventEmitter<any>();
  ActionButtonEnum = ActionButtonEnum;
  tableHeight: number;

  ngAfterViewInit(): void {
    //this.calculateTableHeight();
  }
  onClickAction(id, element, index?: number){
    if (id == ActionButtonEnum.NotClicable) return;
    this.onClick.emit({id, element, index});
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event: any): void {
  //   this.calculateTableHeight();
  // }
  private calculateTableHeight(): void {
    const viewportHeight = window.innerHeight;
    const offsetTop = document.querySelector('.table-container')?.getBoundingClientRect().top || 0;
    this.tableHeight = viewportHeight - offsetTop - 5; // Adjust 20 to your desired bottom margin
  }
}
