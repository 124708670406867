import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaTypeEnum } from '@api/index';
import { Media } from '@core/services/media.service';
import { environment } from '@environments/environment';

interface ModalData {
  title?: string;
  media?: Media;
}

@Component({
  selector: 'flow-view-media-modal',
  templateUrl: './view-media.modal.html',
  styleUrls: ['./view-media.modal.scss']
})
export class ViewMediaModal {
  MediaTypeEnum = MediaTypeEnum;
  apiBaseUrl = environment.apiBaseUrl;

  constructor(
    public dialogRef: MatDialogRef<ViewMediaModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  onClose(): void {
    this.dialogRef.close();
  }
}