import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { StatisticsFilterInputs } from 'src/app/models/statistics-filter-inputs';
import { StatisticsService } from '../../statistics.service';

@Component({
  selector: 'flow-statistics-filter',
  templateUrl: './statistics-filter.component.html',
  styleUrls: ['./statistics-filter.component.scss']
})
export class StatisticsFilterComponent extends unsubscribeMixin() implements OnInit, AfterViewChecked {
  showAnimation = false;
  @Input() initData = new Map<string, StatisticsFilterInputs>();
  @Input() isChecked: boolean;
  @Input() isInFolder: boolean;
  @Input() showFolder: boolean;
  @Input() downloadReportDisableBtn: boolean;
  @Input() hideSection?: boolean;
  @Input() currentPage?: number;
  @Input() selectedIndex?: number;
  @Input() permissions?: string[] = [];
  @Output() showHideFolderClick = new EventEmitter<MatCheckboxChange>();
  @Output() filterChange = new EventEmitter<{ filter: number[] | string[] | number | string, type: string, form: FormGroup, allFilters: any }>();
  @Output() onCreateReport = new EventEmitter();
  @Output() onDownloadReport = new EventEmitter();
  @Output() fetchNextBookings = new EventEmitter();
  @Output() searchBookings = new EventEmitter();
  firstCheckboxEncountered = false;
  @ViewChild('checkboxElement') firstCheckboxElement: ElementRef;
  @ViewChild('pickerCalendar') pickerCalendar: MatDateRangePicker<any>;
  @ViewChild('pickerStart') pickerStart: MatDatepicker<any>;
  @ViewChild('pickerEnd') pickerEnd: MatDatepicker<any>;
  debounceTimeout: any;

constructor(public statisticsService: StatisticsService) {
    super();

    this.filterVisibleInputs = this.filterVisibleInputs.bind(this);
  }

  ngOnInit(): void {
    const fd = this.permissions;
    this.statisticsService.initForm(this.initData, this.filterVisibleInputs);
    if (this.statisticsService.form.get('search')) {
      this.statisticsService.form.get('search').valueChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe(() => {
        this.onFilterChange(null, 'search');
      })
    }
    this.onFilterChange(null, "all");
  }
  ngAfterViewChecked(): void {
    var firstCheckbox = document.querySelector('.checkbox');

    if (firstCheckbox) {
      var newElement = document.createElement('div');

      // Set attributes, classes, content, etc., for the new element
      newElement.className = 'new-row';
      newElement.style.width = '100%';

      // Insert the new element before the first checkbox element
      firstCheckbox.parentNode.insertBefore(newElement, firstCheckbox);
    }
  }

  selectAllItems(form: FormGroup, formControlName: string) {
    form.get(formControlName).setValue(this.initData.get(formControlName).data.map((l) => l.id));
    this.onFilterChange(null, formControlName);
  }

  clearAllItems(form: FormGroup, formControlName: string) {
    form.get(formControlName).setValue([]);
    this.onFilterChange(null, formControlName);
  }

  onFilterChange(event: MatSelectChange, formControlName: string) {
    let statsForm = this.statisticsService.form;
    let allFilters = statsForm.value;
    this.filterChange.emit({ filter: formControlName == 'all' ? formControlName : statsForm.get(formControlName).value, type: formControlName, form: statsForm, allFilters });

  }
  filterVisibleInputs(extractionWords: string[], searchableInput: string, oldArray: StatisticsFilterInputs, form: any) {
    if(searchableInput === "searchTermbookingNumber" || searchableInput === "searchTermbookingNumbers") {
       if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.searchBookings.emit(form.get(searchableInput).value);
      }, 1000);
    } else {
      let searchValue = form.get(searchableInput).value;
      if (searchValue && searchValue != "") {
        let newData = oldArray.data.filter(e => {
          const parser = new DOMParser();
          const parsedHtml = parser.parseFromString(e.name, 'text/html');
          const extracted = parsedHtml.body.textContent || '';
          let searchableString = extractionWords && extractionWords.length > 0 ? extracted.replace(new RegExp('\\b' + extractionWords.join('|') + '\\b', 'ig'), '').trim() : e.name;
          return searchableString.toLowerCase().includes(form.get(searchableInput).value.toLowerCase())
        });
        oldArray.filteredData = newData;
      } else {
        oldArray.filteredData = oldArray.data;
      }
    }
  }

  loadMoreItems() {
    this.fetchNextBookings.emit();
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }
  updateMediaFileField(value : number) {
    this.statisticsService.form.get('mediaFile').setValue(value);
    this.onFilterChange(null, 'mediaFile');
  }
  triggerMediaAnimation() {
    this.showAnimation = true;
  }
  getPanelClass(input: StatisticsFilterInputs): string {
    return (input.formControlName==='mediaFile'|| input.formControlName==='bookingNumber')?'lg-panel-class':''
  }
  preventDefault(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
  _showHideFolderClick(event: MatCheckboxChange){
    this.showHideFolderClick.emit(event);
  }
  updateCheckboxFlag(): void {
    this.firstCheckboxEncountered = true;
  }
  
  returnZero() {
    return 0;
  }

  getPicker(picker: string) {
    return this[picker] as MatDateRangePicker<any> | MatDatepicker<any>;
  }
  createReport() {
    if (!this.statisticsService.form.valid) return;
    this.onCreateReport.emit();
  }

  downloadReport() {
    this.onDownloadReport.emit();
  }
}

