/* tslint:disable */
/* eslint-disable */
export enum TargetGroupEnum {
  NotSet = 0,
  WomenUnder18 = 1,
  Women18to24 = 2,
  Women25to34 = 3,
  Women35to44 = 4,
  Women45to54 = 5,
  Women55to64 = 6,
  WomenAbove65 = 7,
  MenUnder18 = 8,
  Men18to24 = 9,
  Men25to34 = 10,
  Men35to44 = 11,
  Men45to54 = 12,
  Men55to64 = 13,
  MenAbove65 = 14,
  Man = 15,
  Woman = 16,
  OpportunityToSee = 17,
  OpportunityToSeeCompare = 18
}
