import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'flow-kpi-radio-group',
  templateUrl: './kpi-radio-group.component.html',
  styleUrls: ['./kpi-radio-group.component.scss'],
})
export class KpiRadioGroupComponent implements ControlValueAccessor {
  @Input() deviceOptions: { value: any; label: string; description: string }[] = [];
  @Input() disabled: boolean = false;
  selectedValue: any = null;

  constructor(@Optional() @Self() protected control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.selectedValue = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelect(value: any) {
    if (!this.disabled) {
      this.selectedValue = value;
      this.onChange(value);
      this.onTouched();
    }
  }
}
