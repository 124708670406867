import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {ActivatedRoute} from "@angular/router";
import {SelectedFile} from "@models/media-files";
import {SystemMessageService} from "@core/services/system-message.service";
import {TranslateConfigService} from "@core/translate-config.service";
import {MediaApi} from "@api/services/media-api";
import {MediaTypeEnum} from "@api/models/media-type-enum";
import {unsubscribeMixin} from "@core/unsubscribe";
import {takeUntil} from "rxjs";

@Component({
  selector: 'flow-media-upload-external',
  templateUrl: './media-upload-external.component.html',
  styleUrls: ['./media-upload-external.component.scss']
})
export class MediaUploadExternalComponent extends unsubscribeMixin() implements OnInit {
  token: string;
  settings : TokenSettings
  selectedBody: SelectedFile;
  isDragging = false;
  filesToUpload: SelectedFile[] = [];
  uploadProgress = 0
  uploading = false
  invalidToken = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private route: ActivatedRoute,
              private systemMessageService: SystemMessageService,
              private translateConfigService: TranslateConfigService,
              private mediaApiService: MediaApi) {
    super()
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('id');
    this.settings = this.decodeToken(this.token);
  }

  private decodeToken(token: string): TokenSettings {
    try {
      return jwt_decode(token);
    } catch (error) {
      this.invalidToken=true
      return null;
    }
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  upload() {
    const body = {
      token: this.token as string,
      files: this.filesToUpload
    }
    this.mediaApiService.uploadMediaForExternalUsers(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
      next: (progress: number) => {
        this.uploading = true;
        this.uploadProgress = progress;
      },
      error: (error) => {
        console.error('File upload error:', error);
      }
    });
  }

  selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    const selectedFiles = input.files;

    if (!selectedFiles) {
      console.warn('No file selected');
      return;
    }

    if(selectedFiles.length > +this.settings.limitForNumberOfLinks) {
      this.systemMessageService.error(`The max limit for files is ${+this.settings.limitForNumberOfLinks}`)
      return;
    }

    Array.from(selectedFiles).forEach((file) => {
      this.readyFile(file);
    });
  }

  async readyFile(selectedFile) {
    const file = {
      index: null,
      selectedFile,
      fileSize: selectedFile.size,
      filePreview: null,
      name: selectedFile.name,
      format: this.getFileFormat(selectedFile),
    };

    if (!this.isValidFileType(file?.selectedFile?.type)) {
      this.systemMessageService.error(this.translateConfigService.instant("createMedia.error.supports"))
      return;
    }

    let sizeInKB = +this.settings.fileSize * 1000000
    if(selectedFile.size > sizeInKB) {
      this.systemMessageService.error(`The file with name ${selectedFile.name} is to big. Max file size is ${+this.settings.fileSize}MB `)
      return;
    }


    if (file?.format === 'video') {
      try {
        const duration = await this.getVideoDuration(selectedFile)
        file.selectedFile.videoDuration = duration.toFixed(2);
      } catch (error) {
        file.selectedFile.videoDuration = 0;
      }
    }

    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      file.filePreview = e.target?.result as string;

      this.selectedBody = file;
      this.filesToUpload.push(file);
    };

    reader.onerror = () => {
      console.error('File reading failed');
    };

    reader.readAsDataURL(selectedFile);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      Array.from(droppedFiles).forEach((file) => {
        this.readyFile(file);
      });
    }
  }

  isValidFileType(fileType: string): boolean {
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    return allowedTypes.includes(fileType);
  }

  getFileFormat(file: File): 'image' | 'video' {
    if (file.type.startsWith('image/')) {
      return 'image';
    }

    return 'video';
  }

  getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = window.URL.createObjectURL(file);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
    });
  }

  removeFile(i: number) {
    this.filesToUpload.splice(i, 1);
  }

  protected readonly MediaTypeEnum = MediaTypeEnum;
}

export interface TokenSettings {
  customerId: string;
  clientId: string;
  lifespanOfLink: string;
  limitForNumberOfLinks: string;
  fileSize: string;
  exp: number;
}
