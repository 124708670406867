<ng-container>
  <button type="button"
          color="primary"
          mat-flat-button
          [matMenuTriggerFor]="menu">
    <span class="flex flex-row items-center">{{label | translate}}
      <mat-icon>arrow_drop_down</mat-icon></span>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of menuItems">
      <a (click)="item.action()"
         class="flex w-auto"
         mat-menu-item>{{item.label | translate}}</a>
    </ng-container>
  </mat-menu>
</ng-container>