<div class="pt-6 mb-8">
  <div class="text-2xl font-[600]">{{ (!channelId ? 'createChannel.title' : 'editChannel.title') | translate }}</div>
</div>

<div class="flex gap-10">
  <div class="flex flex-col gap-4 w-1/3">
    <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
      <div class="justify-start items-center gap-2 flex">
        <app-icon iconName="annotation-info"
                  size="md"
                  class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                  stroke="#598fae"></app-icon>
      </div>
      <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}
      </div>
    </div>
    <form [formGroup]="form" class="form-container">
      <div class="flex flex-col mb-4">
        <mat-label>{{'createChannel.form.name' | translate}}</mat-label>
          <mat-form-field class="w-full">
            <input matInput
                   type="text"
                   formControlName="name">
          </mat-form-field>
      </div>
      <div class="flex flex-col">
        <mat-label>{{'createChannel.form.customer' | translate}}</mat-label>
          <mat-form-field class="w-full">
            <mat-select formControlName="customerId">
              <mat-option *ngFor="let customer of customers"
                          [value]="customer.id">
                {{customer.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </form>
  </div>

  <div class="text-xs w-2/3">
    <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
    <p>{{ 'channels.note' | translate }}</p>
  </div>

  <div class="w-fit flex items-start justify-end gap-2">
    <button [routerLink]="['/channels']"
            class="btn-cancel"
            mat-stroked-button
            type="button">
      {{ 'global.cancel' | translate }}
    </button>
    <button
      (click)="onSaveClick()"
      [disabled]="form.invalid"
      class="btn-save"
      color="primary"
      mat-flat-button
      type="button">
      {{ (channelId ? 'global.save' : 'global.add' ) | translate }}
    </button>
  </div>
</div>
