<div class="page-header">
  <h1 class="page-title">
    {{ "peopleCount.title" | translate }}
  </h1>
</div>
<br/>

<flow-helper-card *ngIf="!consentGiven"
                  consentKey="peopleCount-consent"
                  description="peopleCount.info"
                  title="peopleCount.title">
</flow-helper-card>

<!--filters-->
<mat-tab-group animationDuration="0ms" class="mat-tabs-override" [(selectedIndex)]="selectedIndex">
  <mat-tab [disabled]="disablePeopleCounterInsights" [label]="'sidebar.overview' | translate">
    <br />

    <flow-dashboard-filter [form]="form"
                           [filteredChannels]="filteredChannels"
                           [screens]="screens"
                           [customers]="customers"
                           [timeframeTypes]="timeframeTypes"
                           [isAdmin]="isAdmin"
                           (onSubmit)="submitForm()">
    </flow-dashboard-filter>

    <div *ngIf="!isChannels;else channelsSelected" class="no-data-selected-container flex flex-col justify-center items-center">
      <img src="../../../../assets/no-data.png" />
      <p>{{ "global.noDataSelected" | translate }}</p>
      <p class="no-data-subtitle">{{ "peopleCount.noData" | translate }}</p>
    </div>
    <ng-template #channelsSelected>
        <ng-container *ngIf="showKpiBox">
          <flow-people-count-kpis [isAdmin]="isAdmin"
                                  [peopleCount]="peopleCounterStatisticsResponse?.metricBoxLanes"
                                  [startDate]="startDate"
                                  [endDate]="endDate">
          </flow-people-count-kpis>
        </ng-container>
        <div class="flex flex-col gap-4">
          <flow-visitors-per-hour-graph [visitorsChartData]="visitorsData"
                                        [visitorsChartCompareData]="visitorsCompareData"
                                        [startDate]="startDate"
                                        [endDate]="endDate"
                                        [compareStartDate]="compareStartDate"
                                        [compareEndDate]="compareEndDate"
                                        [compare]="compareMode"
          >
          </flow-visitors-per-hour-graph>

          <flow-age-distribution-graph [ageDistributionData]="ageDistributionData"
                                       [ageDistributionCompareData]="ageDistributionCompareData"
                                       [startDate]="startDate"
                                       [endDate]="endDate"
                                       [compareStartDate]="compareStartDate"
                                       [compareEndDate]="compareEndDate"
                                       [compare]="compareMode">
          </flow-age-distribution-graph>

          <flow-gender-graph [genderDistributionData]="genderDistributionData"
                             [genderDistributionCompareData]="genderDistributionCompareData"
                             [startDate]="startDate"
                             [endDate]="endDate"
                             [compareStartDate]="compareStartDate"
                             [compareEndDate]="compareEndDate"
                             [compare]="compareMode">
          </flow-gender-graph>

          <flow-weather-chart [screens]="selectedScreens"
                              [startDate]="startDate"
                              [endDate]="endDate"
          ></flow-weather-chart>

            <flow-average-time-in-channel
              [averageTimeReportPerHour]="peopleCounterStatisticsResponse?.averageTimeReportPerHour"
              [form]="form"
              [startDate]="startDate"
              [endDate]="endDate">
            </flow-average-time-in-channel>

          <flow-mood-chart [screens]="selectedScreens"
                           [startDate]="startDate"
                           [endDate]="endDate">
          </flow-mood-chart>

        </div>
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="disablePeopleCounterReportsInsights" [label]="'sidebar.reports' | translate">
    <br/>
    <flow-reports *ngIf="selectedIndex === 1"
                  [reportType]=ActiveReportEnum.PeopleCounter
                  [permissions]="['ExportPeopleCounterReportsInsights']"></flow-reports>
  </mat-tab>
</mat-tab-group>



