export enum MediaType {
    Image = 1,
    Video = 2,
    Folder = 3
  }

  export const MediaTypesTranslations = {
    [MediaType.Image]: 'global.image',
    [MediaType.Video]: 'global.video',
    [MediaType.Folder]: 'global.folder'
  };