import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TotalNumberOfExposuresResponse } from '@api/models/exposures/TotalNumberOfExposures.model';
import { STATISTICS_START_DATE } from '@app/constants';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { StatisticsApi } from 'src/_api';
import { HourlySeparatedTarget } from 'src/_api/models/people-counter-statistics-response';
import { EnumService } from 'src/app/core/services/enum.service';
import { StatisticsFilterExposures } from 'src/app/models/statistics-filter-exposures';
import { StatisticsService } from '../../statistics.service';

@Component({
  selector: 'flow-distribution-of-exposures-over-hours',
  templateUrl: './distribution-of-exposures-over-hours.component.html',
  styleUrls: ['./distribution-of-exposures-over-hours.component.scss'],
})
export class DistributionOfExposuresOverHoursComponent implements OnChanges {
  @Input() filter: StatisticsFilterExposures;
  @Input() distributonOfDailyHoursReport: Array<HourlySeparatedTarget>;
  @Input() totalNumberOfExposures: TotalNumberOfExposuresResponse;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public chartPlugins = [DataLabelsPlugin];
  public weekDays = [];
  public chartData: ChartData<'line', number[], string | string[]> = null;
  public range = new FormGroup({
    start: new FormControl<DateTime | null>(DateTime.local().startOf('week')),
    end: new FormControl<DateTime | null>(DateTime.local().endOf('week')),
  });
  public rangeCompare = new FormGroup({
    start: new FormControl<DateTime | null>(null),
    end: new FormControl<DateTime | null>(null),
  });

  public disableWeekToCompare = false;
  date = new FormControl(DateTime.local());
  maxDate = DateTime.local();
  minDate = STATISTICS_START_DATE;
  gradient: any;
  width: any;
  height: any;
  noChartData: boolean = false;

  public chartOptions: ChartConfiguration['options'] = {}

  constructor(
    protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected translateService: TranslateService,
    protected enumService: EnumService,
    private translateConfigService: TranslateConfigService
  ) {
    this.weekDays = enumService.weekDays;
    this.setChartOptions();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.distributonOfDailyHoursReport)
      this.updateChartData();
  }

  updateChartData() {
    this.noChartData = this.distributonOfDailyHoursReport?.length ? false : true;
    const dataHours = this.distributonOfDailyHoursReport.map(h => h.hour.toString() + ':00');
    const hours = Array.from({ length: 24 }, (_, i) => i.toString() + ':00');

    const totalMenArray = Array(dataHours.length || 24).fill(0);
    const totalWomanArray = Array(dataHours.length || 24).fill(0);

    this.distributonOfDailyHoursReport?.forEach((hour, index) => {
      totalMenArray[index] = hour?.totalMen;
      totalWomanArray[index] = hour?.totalWomen;
    });

    const dataSetMen = {
      pointRadius: 0,
      label: this.translateConfigService.instant('peopleCount.men'),
      data: totalMenArray,
      fill: 'origin',
      backgroundColor(context) {
        const { ctx, chartArea } = context.chart;
        if (chartArea) {
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(1, 'rgba(31, 87, 146, 1)');
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.9)');
          return gradient;
        }
      },
      borderColor: 'rgba(31, 87, 146, 0)',
    };
    const dataSetWoman = {
      pointRadius: 0,
      label: this.translateConfigService.instant('peopleCount.women'),
      data: totalWomanArray,
      fill: 'origin',
      backgroundColor(context) {
        const { ctx, chartArea } = context.chart;
        if (chartArea) {
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(1, 'rgba(250, 98, 0, 1)');
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.9)');
          return gradient;
        }
      },
      borderColor: 'rgba(250, 98, 0, 0)',
    };
    this.chartData = {
      labels: dataHours.length > 0 ? dataHours : hours,
      datasets: [dataSetMen, dataSetWoman],
    };

    this.chart?.update();
  }

  onWeekSelected() {
    this.updateChartData();
  }
  onWeekCompareSelected() {
    this.updateChartData();
  }
  private setChartOptions(){
    this.chartOptions = {
      elements: {
        line: { tension: 0.5 },
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: false,
          },
        },
        y: {
          title: {
            display: false,
          },
        },
      },
      plugins: {
        legend: { 
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 3,
            boxHeight: 30,
            textAlign: 'left',
            padding: 5
          }
        },
        datalabels: { display: false },
      },
    };
  }

}

