<div class="container-breadcrumb">
  <div *ngIf="isModal" class="h-3 justify-start items-center gap-1.5 inline-flex pl-[0.25rem] pb-[0.75rem]">
    <div *ngFor="let breadcrumb of breadcrumbs; let index = index" class="flex items-center gap-1.5">
      <div (click)="index == 0 && breadcrumbs.length > 1 ? goToFolder(-1) : null" class="capitalize text-gray-800" [ngClass]="index == 0 && breadcrumbs.length > 1 ? 'cursor-pointer' : ''">{{breadcrumb.label}}</div>
      <div *ngIf="index < breadcrumbs.length-1" class="breadcrumb-light bg-brand-red"></div>
    </div>
  </div>
  <div *ngIf="!isModal && breadcrumbs.length > 1" (click)="goToFolder(-1)" class="bulk-button cursor-pointer">
    <app-icon size="md"
              stroke="#202020"
              iconName="arrow-left"
              [ngStyle]="{'cursor': 'pointer'}"></app-icon>
    <span class="bulk-button-text">{{'global.back' | translate}}</span>
  </div>
</div>
