import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@api/models/customer';
import { FolderFile } from '@api/models/folder-file';
import { Tag } from '@api/models/tag';
import { AdminApi } from '@api/services/admin-api';
import { FolderApi } from '@api/services/folder-api';
import { MediaApi } from '@api/services/media-api';
import { TagsApi } from '@api/services/tags-api.service';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { forkJoin, of, takeUntil } from 'rxjs';

@Component({
  selector: 'flow-folder-create',
  templateUrl: './folder-create.component.html',
  styleUrls: ['./folder-create.component.scss']
})
export class FolderCreateComponent extends unsubscribeMixin() implements OnInit {
  form: FormGroup;
  folderId: number = null;
  isReady: boolean = false;
  tags: Tag[];
  customers: Customer[];
  folder: FolderFile;
  customerId: number;
  isAdmin: boolean = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminApi: AdminApi,
    private tagsApi: TagsApi,
    private formBuilder: FormBuilder,
    private folderApi: FolderApi,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private authService: AuthService) {
    super();
  }
  ngOnInit(): void {
    this.folderId = +this.activatedRoute.snapshot.params['id'] || null;
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.getData();
  }
  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }
  newTag(name: string): Tag {
    return { id: null, name };
  }
  getData() {
    forkJoin([
      !this.customerId ? this.adminApi.getCustomers() : this.adminApi.getCustomerById(this.customerId),
      this.customerId ? this.tagsApi.getTags({ customerIds: [this.customerId] }) : of(null),
      this.folderId ? this.folderApi.getFolderById(this.folderId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([customers, tags, folder]) => {
        this.customers = (this.customerId ? [customers] : customers) as Customer[];
        this.folder = folder;
        this.tags = tags || [];
        if (this.folderId) {
          this.tagsApi.getTags({ customerIds: [ this.folder.customer.id] })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((tagsResponse) => {
              this.tags = tagsResponse || [];
              this.initForm();
              this.isReady = true;
            })
        } else {
          this.initForm();
          this.isReady = true;
        }
      })
  }
  getDataByCustomer() {
    const customerId = this.form.get('customerId').value;
    this.tagsApi.getTags({ customerIds: [customerId] })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tags) => {
        this.tags = tags || [];
      })
  }
  onCancelClick() {
    this.router.navigate(['/medias']);
  }
  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    const formValues = this.form.getRawValue();
    const body = {
      ...formValues,
      customerId: formValues?.customerId || this.customerId,
      tags: formValues?.tags?.map((t) => {
        if (t.id) return { id: t.id };
        return { name: t.name }
      })
    };

    if (this.folderId) {
      body.id = this.folderId
    }

    if (!this.folderId) {
      this.folderApi.createFolder({ body }).subscribe(() => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.folderCreate"));
        this.router.navigate(['/medias']);
      })
    } else {
      this.folderApi.updateFolder({ body }).subscribe(() => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.folderEdit"));
        this.router.navigate(['/medias']);
      })
    }
  }
  private initForm(): void {
    this.form = this.formBuilder.group({
      customerId: [this.customerId || this.folder?.customer?.id || null, Validators.required],
      name: [this.folder?.name || '', Validators.required],
      tags: this.formBuilder.control(this.folder?.tags)
    });
    if (this.folderId) {
      this.form.get('customerId').disable();
    }
  }
}
