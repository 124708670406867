<flow-helper-card
  *ngIf="!consentGiven"
  consentKey="sms-consent"
  description="sms.info"
  title="sms.smsTitle">
</flow-helper-card>

<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{'sms.smsTitle' | translate}}</h1>
</div>

<div class="flex justify-between items-center w-full rounded-md bg-gray-50 p-4 pt-4 mb-5">
  <form [formGroup]="form" class="form-container input-override">
    <div class="inputs">
      <div class="mr-3">
        <mat-label>{{ "peopleCount.customer" | translate }}</mat-label>
        <mat-form-field *ngIf="isAdmin" appearance="outline" class="input collapsed-input inline">
          <mat-select formControlName="customer"  placeholder="{{'peopleCount.customer' | translate}}">
            <mat-option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mr-3">  
        <mat-label>{{'reports.form.selectTimeframe' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="input collapsed-input inline">
          <mat-select formControlName="timeframe" placeholder="{{'reports.form.selectTimeframe' | translate}}">
            <mat-option *ngFor="let types of timeframeTypes" [value]="types.id">
              {{types.name.split('-')[0] | translate: {month: translateMonth(types.name.split('-')[1])} }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mr-3" *ngIf="checkTimeFrame()">  
        <mat-label>{{'reports.form.datePickerFrom' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="input collapsed-input inline">
          <input [matDatepicker]="picker1"
                [max]="form.get('end').value?.toString()"
                [placeholder]="'reports.form.datePickerFrom' | translate"
                formControlName="start"
                matInput/>

          <app-icon (click)="clearDatePickerFrom()"
                    *ngIf="form.get('start').value"
                    [ngStyle]="{cursor:'pointer', position: 'absolute', right:'-9px', top:'7px'}"
                    iconName="close"
                    size="xl"></app-icon>
          <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="mr-3 items-center datepicker-styles" *ngIf="checkTimeFrame()">  
        <mat-label>{{'overview.form.datePickerTo' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="input collapsed-input inline items-center">
          <input [matDatepicker]="picker2"
                [min]="form.get('start').value?.toString()"
                [placeholder]="'overview.form.datePickerTo' | translate"
                formControlName="end"
                matInput/>

          <app-icon (click)="clearDatePickerTo()"
                    *ngIf="form.get('end').value"
                    [ngStyle]="{cursor:'pointer', position: 'absolute', right:'-9px', top:'7px'}"
                    iconName="close"
                    size="xl"></app-icon>
          <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
      
<div class="flex gap-6" *ngIf="chartData">
  <div class="w-fit flex flex-col p-4 gap-6 rounded border border-gray-200" >
    <span class="text-brand-bright-orange text-xs font-semibold capitalize leading-none">{{'sms.notifications' | translate}}</span>
    <div class="flex gap-14">
      <div class="flex flex-col gap-2">
        <span class="text-xs font-normal leading-none">{{'sms.totalSms' | translate}}</span>
        <span class="text-2xl font-semibold leading-7">{{chartData?.totalNoSms}}</span>
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-xs font-normal leading-none">{{'sms.topRecipient' | translate}}</span>
        <span class="text-2xl font-semibold leading-7">
          {{!chartData?.topRecipient ? '-' : (chartData?.topRecipient + ' - ' + chartData.noSmsForTopRecipinet)}} 
        </span>
      </div>
      <div class="flex flex-col gap-2">
        <span class="text-xs font-normal leading-none">{{'sms.estimatedCostOfSmsNotifications' | translate}}</span>
        <span class="text-2xl font-semibold leading-7">{{chartData?.costOfSms}}</span>
      </div>
    </div>
  </div>

  <div class="w-fit flex flex-col p-4 gap-6 rounded border border-gray-200" >
    <span class="text-brand-bright-orange text-xs font-semibold capitalize leading-none">{{'sms.smsDeliveryStatus' | translate}}</span>
    <div class="flex gap-14">
        <flow-sms-charts [chartData]="chartData"></flow-sms-charts>
    </div>
  </div>
</div>
  
<div *ngIf="dataSource && dataSource.data.length > 0" class="mt-10">
  <table [dataSource]="dataSource"
          class="table-hover"
          mat-table
          matSort
          matSortActive="name"
          matSortDirection="asc"
          [matSortDisableClear]="true">

    <ng-container matColumnDef="name">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"sms.table.name" | translate}} </th>
      <td *matCellDef="let sms"
          mat-cell> {{sms?.contactPersonName}} </td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"sms.table.emailAddress" | translate}} </th>
      <td *matCellDef="let sms"
          mat-cell> {{sms?.contactPersonEmail}} </td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"sms.table.phoneNumber" | translate}} </th>
      <td *matCellDef="let sms"
          mat-cell> {{ sms?.contactPersonPhoneNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="smsNotifications">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"sms.table.smsNotifications" | translate}} </th>
      <td *matCellDef="let sms"
          mat-cell>
        {{sms?.smsNotifications}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"sms.table.status" | translate}} </th>
      <td *matCellDef="let sms"
          mat-cell style="padding-left: 15px;">
        <div *ngIf="sms?.status.toLowerCase() === 'failed'"
              [matTooltip]="'sms.table.statusInfo' | translate: {latestDeliveryFailure: sms?.undeliveredSmsDate | date:'YYYY-MM-dd HH:mm:ss' }"
              class="traffic-light bg-red-600"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="mediaName">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{"home.table.defaultMedia" | translate}} </th>
      <td *matCellDef="let screen"
          mat-cell>
        {{screen?.mediaName}}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <!-- Condition to be updated -->
    <tr *matRowDef="let sms; columns: displayedColumns;"
        mat-row></tr>
  </table>
</div>
    
<div *ngIf="!chartData" class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
</div>


