<aside class="container-nav">
  <section class="profile-section">
    <div class="user-info">
      <div class="avatar">
        <div class="initials">{{initials}}</div>
      </div>
      <div class="names">
        <p class="username">{{selectedCustomer?.name}}</p>
        <p class="email">{{ currentUser?.email }}</p>
      </div>
    </div>
    <hr class="divider" />
    <h2 class="customer-title">{{'sidebar.activeCustomer' | translate}}</h2>
    <div class="customer-dropdown">
    <mat-form-field appearance="outline" class="w-full" >
        <mat-select [(ngModel)]="selectedCustomer.id"
                    (ngModelChange)="onCustomerChange($event)">
          <mat-option *ngFor="let customer of customers"
                      [value]="customer.id">
            {{customer.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <nav class="nav-menu">
      <a *flowHasPermission="['ViewSmsAdmin']; disabled true; adminNav true;"
          routerLink="/sms"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.sms' | translate}}</a>
      <a *flowHasPermission="['ViewChannelAdmin']; disabled true; adminNav true;"
          routerLink="/channels"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.channels' | translate}}</a>
      <a *flowHasPermission="['ViewScreensAdmin']; disabled true; adminNav true;"
          routerLink="/screens"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.devices' | translate}}</a>
      <a *ngIf="checkIfAdminCustomerOrUser()"
          routerLink="/customers"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.customers' | translate}}</a>
      <a *flowHasPermission="['ViewClientsAdmin']; disabled true; adminNav true;"
          mat-list-item
          routerLink="/clients"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.clients' | translate}}</a>
      <a *flowHasPermission="['ViewContactPersonAdmin']; disabled true; adminNav true;"
          mat-list-item
          routerLink="/contact-persons"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.contactPersons' | translate}}</a>
      <a *flowHasPermission="['ViewUserPermissionsAdmin']; disabled true; adminNav true;"
          mat-list-item
          routerLink="/permissions"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.permissions' | translate}}</a>
      <a *flowHasPermission="['ViewUsersAdmin']; disabled true; adminNav true;"
          mat-list-item
          routerLink="/users"
          routerLinkActive="active" (click)="closeNav()" class="nav-item">{{'sidebar.users' | translate}}</a>
      <div class="language">
        <a (click)="openLanguageModal()" class="language-text cursor-pointer">{{'sidebar.language' | translate}}
          <app-icon [ngStyle]="{cursor:'pointer'}"
                    size="md"
                    [iconName]="currentLanguage"></app-icon>
        </a>
      </div>
      <a *ngIf="checkIfAdminCustomerOrUser()"
          routerLink="/settings"
          routerLinkActive="active" class="nav-item">{{'sidebar.settings' | translate}}</a>
    </nav>
    <section class="settings">
      <hr class="divider-bottom" />
      <a type="button" class="logout-button" tabindex="0" (click)="logout()">{{'cogwheel.logOut' | translate}}</a>
    </section>
  </section>
</aside>
