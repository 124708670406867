import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'flow-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements AfterViewChecked {
  dropdownOpen: boolean = false;
  disabled: boolean = false;
  isMenuWidthSet: boolean = false;

  @Input() options: any[] = [];

  @Output() onSelectionChange = new EventEmitter<any[]>();

  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  @ViewChild('dropdownToggle') dropdownToggle!: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;


  constructor(private renderer: Renderer2){}

  ngAfterViewChecked(): void {
    if (this.dropdownOpen && !this.isMenuWidthSet)
      this.setDropdownWidth();
  }

  setDropdownWidth() {
    const parentWidth = this.dropdownContainer.nativeElement.offsetWidth;
    this.renderer.setStyle(this.dropdownMenu.nativeElement, 'min-width', `${parentWidth}px`);
    this.isMenuWidthSet = true;
  }
  toggleDropdown() {
    if (this.disabled) return;
    if (this.options.length > 0) {
      this.dropdownOpen = !this.dropdownOpen;
      if (!this.dropdownOpen)
        this.isMenuWidthSet = false;
    }
  }
  selectOption(option: any) {
    this.onSelectionChange.emit(option);
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target) && this.dropdownToggle && !this.dropdownToggle.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
      this.isMenuWidthSet = false;
    }
  }
}
