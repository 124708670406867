import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { BaseService } from '@api/base-service';
import { Tag } from '@api/models/tag';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class TagsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly GetTagsPath = '/tags';

  getTags(params?: { customerIds?: number[] }): Observable<Array<Tag>> {
    const filteredParams = !!params?.customerIds ? params : {};

    return this.http.get<Array<Tag>>(`${this.rootUrl}${TagsApi.GetTagsPath}`, {
      params: filteredParams,
    });
  }
}
