import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageChange'
})
export class PercentageChangePipe implements PipeTransform {
  transform(currentMonthData: number, previousMonthData: number): string {
    if (previousMonthData === 0) {
      return currentMonthData === 0 ? '0%' : '100%'; // Avoid division by zero
    }
    const change = currentMonthData - previousMonthData;
    const percentageChange = (change / previousMonthData) * 100;
    return `${percentageChange.toFixed(2)}%`;
  }
}
