import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { forkJoin, interval, Observable, of, Subscription, catchError, takeUntil, takeWhile } from 'rxjs';
import { PaginationResult, PaginatorParams } from '@api/models/paginator';
import { PageEvent } from '@angular/material/paginator';
import { BrokenScreensResponse, OfflineScreens, BookingsMetricsModel, ScreenAliveStatusEnum, UnusedScreen } from './start.models';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { BookingRouterState } from '@app/shared/enums/booking-router-state.enum';

import { TrackingEnum } from '@api/models/tracking.enum';
import { TableData } from '@app/models/table-data';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { CustomerApi, ScreenApi } from '@api/index';
import { BookingApi } from '@api/services/bookings-api';
import { DatePipe } from '@angular/common';
import { ScreenServicesStateData } from '../screen/screen.model';

@Component({
  selector: 'flow-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent extends unsubscribeMixin() implements OnInit, OnDestroy {
  isReady: boolean = false;
  subscription: Subscription;
  alive = true;
  customerId: number;
  intervalTime = 60000;
  firstName: string = '';
  currentTime: DateTime = DateTime.local();
  isAdmin: boolean;
  displayedColumnsOffline: string[] = ['status', 'name', 'type', 'customer', 'channel', 'placement', 'tracking', 'lastOnline'];
  displayedColumns: string[] = ['id', 'week', 'name', 'channel', 'placement', 'screenLayout', 'mediaName', 'actions'];
  dataSource: MatTableDataSource<UnusedScreen>;
  dataSourceOffline: MatTableDataSource<OfflineScreens>;
  numberOfScreens: number | null = null;
  numbersOfWeekForFiltering: number;
  TrackingEnum: TrackingEnum;
  ScreenAliveStatusEnum: ScreenAliveStatusEnum;
  sortObjectOffline: any = { active: 'name', direction: 'asc' };
  sortObject: any = { active: 'week', direction: 'asc' };
  data: TableData = { actionButtons: [], dataModels: [], dataSource: new MatTableDataSource<UnusedScreen>([]), displayedColumns: [] };
  dataOffline: TableData = {
    actionButtons: [],
    dataModels: [],
    dataSource: new MatTableDataSource<OfflineScreens>([]),
    displayedColumns: [],
  };
  screensWithIssues: BrokenScreensResponse = {
    screensAndSensorsCount: 0,
    onlineScreensAndSensorsCount: 0,
    offlineScreensAndSensorsCount: 0,
    screensWithIssues: [],
  };
  unusedScreens: UnusedScreen[];
  bookingsMetrics: BookingsMetricsModel = {
    bookingsCountForThisPeriod: 0,
    segmentCountForThisPeriod: 0,
    playlistCountForThisPeriod: 0,
    bookingsCountForPreviousPeriod: 0,
    segmentCountForPreviousPeriod: 0,
    playlistCountForPreviousPeriod: 0,
  };
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'week',
    sortDirection: 'asc',
  };
  brokenScreensParams = {
    sortBy: 'name',
    sortDirection: 'asc',
  };
  @ViewChild('sortBroken') sortBroken: MatSort;
  @ViewChild('sortUnused') sortUnused: MatSort;

  constructor(
    public translateConfigService: TranslateConfigService,
    private authService: AuthService,
    private customerApi: CustomerApi,
    private screenApi: ScreenApi,
    private bookingsApi: BookingApi,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.firstName = user?.fullName?.split(' ')[0];
    this.currentTime = DateTime.local();
    this.getData();
    this.subscription = interval(this.intervalTime)
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.screenApi
          .getBrokenScreens(
            this.customerId ? { customerId: this.customerId, ...this.brokenScreensParams } : { ...this.brokenScreensParams }
          )
          .subscribe((screens: BrokenScreensResponse) => {
            this.getBrokenScreens(screens);
          });
      });
  }
  override ngOnDestroy() {
    this.alive = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  getData() {
    const user = this.authService.userData;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.isAdmin = user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    if (this.isAdmin) {
      const index = this.displayedColumns.indexOf('channel');
      this.displayedColumns.splice(index, 0, 'customer');
    }
    this.isReady = false;

    forkJoin([
      this.screenApi.getUnusedScreens(this.getParams()).pipe(catchError(error => this.handleApiError(error))),
      this.screenApi
        .getBrokenScreens(this.customerId ? { customerId: this.customerId, ...this.brokenScreensParams } : { ...this.brokenScreensParams })
        .pipe(catchError(error => this.handleApiError(error))),
      this.bookingsApi.getBookingsMetrics(this.customerId).pipe(catchError(error => this.handleApiError(error))),
      !this.isAdmin ? this.customerApi.getCustomerById(this.customerId) : of(null),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([unusedScreens, brokenScreens, bookingsMetrics, customer]) => {
        this.numbersOfWeekForFiltering = customer?.numbersOfWeekForFiltering || 4;
        if (unusedScreens) {
          this.getUnusedScreens(unusedScreens);
        }
        if (brokenScreens) {
          this.getBrokenScreens(brokenScreens);
        }
        if (bookingsMetrics) {
          this.bookingsMetrics = bookingsMetrics;
        }
      });
  }
  private getBrokenScreens(brokenScreens?: BrokenScreensResponse) {
    // Set data source for offline table
    this.screensWithIssues = brokenScreens;
    this.dataSourceOffline = new MatTableDataSource(brokenScreens.screensWithIssues);
    this.dataSourceOffline.sort = this.sortBroken;
    this.dataOffline.dataSource = this.dataSourceOffline;
    this.dataOffline.displayedColumns = this.displayedColumnsOffline;
    this.computeScreenStatuses(brokenScreens.screensWithIssues);
    this.formatDate(brokenScreens.screensWithIssues.map(screen=> screen.lastAliveDate));
  }
  private getUnusedScreens(unusedScreens?: PaginationResult<UnusedScreen>) {
    this.numberOfScreens = unusedScreens?.items.length;

    // Set data source for the table
    this.unusedScreens = unusedScreens.items;
    this.dataSource = new MatTableDataSource(this.unusedScreens);
    this.params = { ...this.params, pageNumber: unusedScreens?.currentPage };
    this.totalCount = unusedScreens?.totalCount;
    this.data.dataSource = this.dataSource;
    this.data.displayedColumns = this.displayedColumns;
    this.isReady = true;
  }
  private getParams() {
    let response: any = this.params;
    if (this.customerId) response.customerId = this.customerId;
    return response;
  }

  private handleApiError(error: any): Observable<null> {
    return of(null);
  }

  onEngagementBookClick(route: string, screen: UnusedScreen) {
    // send the date here
    const data = {
      customer: screen?.customer,
      channels: [screen?.channel],
      placements: [screen?.placement],
      startDate: screen?.startDate,
    };

    this.router.navigate([route], {
      state: {
        data,
        bookState: BookingRouterState.CreateHome,
      },
    });
  }

  getTimeOfDay(): string {
    const hour = this.currentTime.hour;
    if (hour >= 0 && hour < 12) {
      return this.translateConfigService.instant('home.morning');
    } else if (hour >= 12 && hour < 18) {
      return this.translateConfigService.instant('home.afternoon');
    } else {
      return this.translateConfigService.instant('home.evening');
    }
  }

  getTimeIconName(): string {
    const hour = this.currentTime.hour;
    if (hour >= 0 && hour < 12) {
      return 'morning';
    } else if (hour >= 12 && hour < 18) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  }

  checkNumber(numOfScreens: number) {
    return isNaN(numOfScreens);
  }

  redirectToEditPage(customerId: number): void {
    this.router.navigate(['/screens', customerId]);
  }

  formatDate(dates: Date[]) {
    dates.forEach(date => {
      return date ? this.datePipe.transform(date, 'YYYY-MM-dd HH:mm:ss') : '';
    });    
  }

  sortData(sort: any, dataSource: any): void {
    sort = { active: sort.active, direction: sort.direction };
    const data: Array<any> = dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      dataSource.data = data;
      return;
    }
    dataSource = new MatTableDataSource<any>(dataSource.data);
  }
  handleSortBrokenEvent(sort: Sort) {
    this.isReady = false;
    this.brokenScreensParams = { sortBy: sort?.active, sortDirection: sort.direction };
    this.screenApi
      .getBrokenScreens(this.customerId ? { customerId: this.customerId, ...this.brokenScreensParams } : { ...this.brokenScreensParams })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(brokenScreens => {
        this.getBrokenScreens(brokenScreens);
      });
  }
  handleSortEvent(sort: Sort) {
    this.isReady = false;
    this.params = {
      pageNumber: 0,
      pageSize: 10,
      sortBy: sort?.active,
      sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc',
    };
    this.screenApi
      .getUnusedScreens(this.getParams())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(unusedScreens => {
        this.getUnusedScreens(unusedScreens);
      });
  }
  handlePageEvent(e: PageEvent) {
    this.isReady = false;
    this.params = { ...this.params, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.screenApi
      .getUnusedScreens(this.getParams())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(unusedScreens => {
        this.getUnusedScreens(unusedScreens);
      });
  }

  computeScreenStatuses(screens: OfflineScreens[]): void {
    this.dataSourceOffline.data = screens.map(screen => {
      const status = this.serviceStatus(screen);
      return { ...screen, status } as OfflineScreens;
    });
  }

  serviceStatus(screen: OfflineScreens): { color: string; label: string; tooltip?: string } {
    if (screen.screenAliveStatus === ScreenAliveStatusEnum.NotAlive) {
      return { color: 'bg-brand-red text-white', label: 'global.offline' };
    }

    if (screen.screenServicesStateData && this.areAnyServicesDown(screen.screenServicesStateData)) {
      return { color: 'bg-yellow-500 text-black', label: 'global.warning' };
    }

    return { color: '', label: '' };
  }

  private areAnyServicesDown(screenServicesStateData: ScreenServicesStateData): boolean {
    return [
      screenServicesStateData?.advertiserServiceIsAlive,
      screenServicesStateData?.aiServiceIsAlive,
      screenServicesStateData?.redisIsAlive,
    ].includes(false);
  }
}