<div class="w-full pt-4 px-4 pb-0 bg-gray-50 rounded-l-[4px] rounded-tr-none rounded-br-none flex justify-between items-center">
  <form  [formGroup]="form" class="flex w-fit input-override flex-wrap">
    <div class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ 'reports.form.selectTimeframe' | translate }}</mat-label>
      <mat-form-field class="input">
        <mat-select formControlName="timeframe">
          <mat-option *ngFor="let types of timeframeTypes"
                      [value]="types.id">
            {{ types.name.split('-')[0] | translate: {month: translateMonth(types.name.split('-')[1])} }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="form.get('timeframe').value == TimeframeEnum.Custom" class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ 'reports.form.datePickerFrom' | translate }}</mat-label>
      <mat-form-field>
        <input [matDatepicker]="picker1"
               [max]="form.get('end').value?.toString()"
               [placeholder]="'reports.form.datePickerFrom' | translate"
               formControlName="start"
               matInput/>

        <app-icon (click)="clearDatePickerFrom()"
                  *ngIf="form.get('start').value"
                  [ngStyle]="{cursor:'pointer',position: 'absolute',right:'-5px', top:'6px'}"
                  iconName="close"
                  size="xl"></app-icon>
        <mat-datepicker-toggle [for]="picker1"
                               matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngIf="form.get('timeframe').value == TimeframeEnum.Custom" class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ 'overview.form.datePickerTo' | translate }}</mat-label>
      <mat-form-field>
        <input [matDatepicker]="picker2"
               [min]="form.get('start').value?.toString()"
               [placeholder]="'overview.form.datePickerTo' | translate"
               formControlName="end"
               matInput/>

        <app-icon (click)="clearDatePickerTo()"
                  *ngIf="form.get('end').value"
                  [ngStyle]="{cursor:'pointer',position: 'absolute',right:'-5px', top:'6px'}"
                  iconName="close"
                  size="xl"></app-icon>
        <mat-datepicker-toggle [for]="picker2"
                               matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngIf="form.get('timeframe').value == TimeframeEnum.CurrentWeek || form.get('timeframe').value == TimeframeEnum.PreviousWeek"
      class="mr-5 mb-4 flex flex-col">
      <week-picker [disableWeekStartDate]="form.get('start').value"
                   [disabled]="!form.get('start').value"
                   [label]="'peopleCount.weekToCompare' | translate"
                   [range]="form.get('rangeCompare')"
                   class="flex-1"
      >
      </week-picker>
    </div>

    <div *ngIf="isAdmin" class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ "peopleCount.customer" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="customer-dropdown">
        <mat-select [placeholder]="'contactPerson.modal.form.selectCustomer' | translate"
                    formControlName="customer">
          <mat-option *ngFor="let customer of customers"
                      [value]="customer">
            {{ customer.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ "peopleCount.channel" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-field-readonly channels-dropdown">
        <mat-select
          [placeholder]="'contactPerson.modal.form.selectChannels' | translate"
          class="w-full"
          formControlName="channels"
          multiple>
          <div (click)="selectAllChannels(form)"
               class="select-deselect">
            <app-icon [ngStyle]="{ cursor: 'pointer' }"
                      iconName="check"
                      size="md"></app-icon>
            <span class="ml-1">{{ "global.selectAll" | translate }}</span>
          </div>
          <mat-divider></mat-divider>
          <div (click)="clearChannels(form)"
               *ngIf="form.get('channels').value?.length"
               class="select-deselect">
            <app-icon [ngStyle]="{ cursor: 'pointer' }"
                      iconName="close"
                      size="md"></app-icon>
            <span class="ml-1">{{ "global.deselectAll" | translate }}</span>
          </div>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let channel of filteredChannels"
                      [value]="channel">
            {{ channel.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mr-5 mb-4 flex flex-col">
      <mat-label>{{ "screens.screens1" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-field-readonly channels-dropdown">
        <mat-select [placeholder]="'reports.form.selectScreens' | translate"
                    class="w-full"
                    formControlName="screens"
                    multiple>
          <div (click)="selectAllScreens(form)"
               class="select-deselect">
            <app-icon [ngStyle]="{ cursor: 'pointer' }"
                      iconName="check"
                      size="md"></app-icon>
            <span class="ml-1">{{ "global.selectAll" | translate }}</span>
          </div>
          <mat-divider></mat-divider>
          <div (click)="clearScreens(form)"
               *ngIf="form.get('screens').value?.length"
               class="select-deselect">
            <app-icon [ngStyle]="{ cursor: 'pointer' }"
                      iconName="close"
                      size="md"></app-icon>
            <span class="ml-1">{{ "global.deselectAll" | translate }}</span>
          </div>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let screen of screens"
                      [value]="screen">
            {{ screen.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>

  <button (click)="submit()"
          [ngClass]="form.invalid ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'"
          class="h-10 p-2 bg-brand-chathams-blue rounded justify-start items-center gap-2 inline-flex">
    <app-icon [stroke]="'#fff'"
              fill="none"
              iconName="file-check-02"
              size="md">
    </app-icon>
    <div class="text-gray-50 text-xs font-medium leading-[18px]">{{ "global.create" | translate }}</div>
  </button>

</div>
