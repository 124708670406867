import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MediaItemDetails } from '../media-preview.model';
import { MediaTypeEnum } from '@api/index';

@Component({
  selector: 'flow-media-preview-slide',
  templateUrl: './media-preview-slide.component.html',
  styleUrls: ['./media-preview-slide.component.scss']
})
export class MediaPreviewSlideComponent {
  @Input() mediaItems: MediaItemDetails;
  @Input() duration: string;
  @Output() videoEnded = new EventEmitter<void>();
  @ViewChild('videoPlayer') videoPlayer?: ElementRef<HTMLVideoElement>;
  mediaTypeEnum = MediaTypeEnum;

  playMedia(): void {
    if (this.mediaItems.type === MediaTypeEnum.Video && this.videoPlayer) {
      const video = this.videoPlayer.nativeElement;
  
      if (video.paused) {
        video.currentTime = 0;
        video.play();
      }
    }
  }

  resetMedia(): void {
    if (this.mediaItems.type === MediaTypeEnum.Video && this.videoPlayer) {
      this.videoPlayer.nativeElement.currentTime = 0; 
      this.videoPlayer.nativeElement.pause();
    }
  }

  onVideoEnded(): void {
    this.videoEnded.emit();
  }
}
