/* tslint:disable */
/* eslint-disable */
export enum OrientationEnum {
  Portrait = 1,
  Landscape = 2
}

export const OrientationEnumTranslations = {
  [OrientationEnum.Portrait]: 'createScreen.form.portrait',
  [OrientationEnum.Landscape]: 'createScreen.form.landscape'
};