<a
  class="flex p-2 justify-start items-center gap-2 cursor-pointer hover:xl:rounded hover:xl:bg-neutral-100 xl:border-l-4 border-white xl:-ml-1 transition-colors duration-100"
  routerLink="{{url}}"
  routerLinkActive="bg-sky-800 rounded-[1.25rem] w-10 xl:-ml-1 xl:w-[13.5rem] xl:bg-neutral-100 xl:rounded xl:border-l-4 xl:border-brand-chathams-blue"
  [routerLinkActiveOptions]="{ exact: false }">
  <div class="w-6 h-6 justify-center items-center flex">
    <app-icon
      class="cursor-pointer"
      fill="none"
      size="md"
      [stroke]="isActive(url) && navMini ? '#FAFAFA' : '#202020'"
      iconName="{{iconName}}"></app-icon>
  </div>
  <div *ngIf="!navMini" class="text-neutral-800 text-sm font-normal font-['Inter'] capitalize leading-4">
    {{label | translate}}
  </div>
</a>





