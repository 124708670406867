<h2 class="flex flex-col items-start flex-shrink-0 reach-section-title">{{ sectionTitle | translate }} {{ period }}</h2>
<div class="flex flex-col metrics-box-details-container">
    <div class="flex w-full justify-between items-start">
        <div class="slot-wrapper">
            <ng-content></ng-content>
        </div>
        <div class="period-section flex flex-col items-end">
            <span>{{ period }}</span>
        </div>
    </div>
    <div class="details-container flex items-start">
        <div *ngFor="let item of metricsData; let index=index;" [ngClass]="{ 'total-border': index === 0 }">
            <div class="detail-label">{{ item[detailItemName] | translate }}</div>
            <div class="detail-value">{{ item[detailItemValue] }}</div>
        </div>
    </div>
</div>
