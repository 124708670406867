import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'flow-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CustomSnackbarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialog: MatDialog
    ) {}

  ngOnInit(): void {
  }

}
interface ModalData {
  msg: ContentData;
}
export interface ContentData {
  listContent: string;
  title: string;
  preText: string;
  content: string;
}