/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdToDisplayRequest } from '../models/ad-to-display-request';
import { AdToDisplayRequest2 } from '../models/ad-to-display-request-2';
import { AddScreenActivityRequest } from '../models/add-screen-activity-request';
import { AddScreenActivityRequest2 } from '../models/add-screen-activity-request-2';
import { AdvertisementResponse } from '../models/advertisement-response';
import { AdvertisementResponse2 } from '../models/advertisement-response-2';
import { AlertRequest } from '../models/alert-request';
import { DownloadModelRequest } from '../models/download-model-request';
import { DownloadServiceRequest } from '../models/download-service-request';
import { Stream } from '../models/stream';
import { ScreenGroup } from "@api/models/screen-group";
import { Placement, Screen } from '..';
import { UnusedScreensResponse } from '@api/models/unused-screens-response';
import { BrokenScreensResponse } from '@api/models/broken-screens-response';

@Injectable({
  providedIn: 'root',
})
export class ScreenApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }


  static readonly GetScreensPath = '/screens';


  getScreens(params?) {
    return this.http.get<any>(`${this.rootUrl}${ScreenApi.GetScreensPath}`, params ? { params } : {})
  }

  getScreenById(screenId, params) {
    return this.http.get<Screen>(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}`, { params });
  }

  editScreen(screen): Observable<any> {
    return this.http.put(`${this.rootUrl}${ScreenApi.GetScreensPath}`, screen);
  }

  saveScreen(screen): Observable<any> {
    return this.http.post(`${this.rootUrl}${ScreenApi.GetScreensPath}`, screen);
  }

  removeScreen(screenId) {
    return this.http.delete(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}`)
  }

  getUnusedScreens(params): Observable<any> {
    return this.http.get<UnusedScreensResponse>(`${this.rootUrl}${ScreenApi.GetScreensPath}/unused`, { params });
  }

  getBrokenScreens(params): Observable<any> {
    return this.http.get<BrokenScreensResponse>(`${this.rootUrl}${ScreenApi.GetScreensPath}/broken`, { params });
  }

  getUniquePlacements(params?): Observable<any> {
    return this.http.get<Array<Placement>>(`${this.rootUrl}${ScreenApi.GetScreensPath}/placements`, { params });
  }

  getBookPlacements(params?): Observable<any> {
    return this.http.get<Array<Placement>>(`${this.rootUrl}${ScreenApi.GetScreensPath}/book/placements`, { params });
  }

  versions(): Observable<any> {
    return this.http.get(`${this.rootUrl}${ScreenApi.GetScreensPath}/versions`);
  }

  activateScreen(screenId: number, isActive) {
    return this.http.put(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}/status`, isActive)
  }
}
