/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { FolderFile } from '../models/folder-file';
import { CreateFolderRequest } from '../models/create-folder-request';
import { UpdateFolderRequest } from '../models/update-folder-request';
import { FolderDeletion } from '../models/folder-deletion';
@Injectable({
  providedIn: 'root',
})
export class FolderApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetFolderPath = '/folder';

  getFolders$Response(params?: {
  }): Observable<StrictHttpResponse<Array<FolderFile>>> {

    const rb = new RequestBuilder(this.rootUrl, FolderApi.GetFolderPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FolderFile>>;
      })
    );
  }

  getFolders(params?: {
  }): Observable<Array<FolderFile>> {

    return this.getFolders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FolderFile>>) => r.body as Array<FolderFile>)
    );
  }

  createFolder$Response(params?: { body?: CreateFolderRequest }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FolderApi.GetFolderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  createFolder(params?: { body?: CreateFolderRequest }): Observable<void> {
    return this.createFolder$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  updateFolder$Response(params?: { body?: UpdateFolderRequest }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FolderApi.GetFolderPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  updateFolder(params?: { body?: UpdateFolderRequest }): Observable<void> {
    return this.updateFolder$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  static readonly MoveMediasToFolderPath = '/folder/moveMedias';

  moveMediasToFolder$Response(params?: { folderId: number | null, mediaIds: number[] }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FolderApi.MoveMediasToFolderPath, 'put');
    if (params) {
      rb.query('folderId', params.folderId);
      rb.body(params.mediaIds, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  moveMediasToFolder(params?: { folderId: number | null, mediaIds: number[] }): Observable<void> {
    return this.moveMediasToFolder$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  static readonly DeleteFolderPath = '/folder/{folderId}';

  deleteFolder$Response(params?: { id: number }): Observable<StrictHttpResponse<FolderDeletion>> {
    const rb = new RequestBuilder(this.rootUrl, FolderApi.DeleteFolderPath, 'delete');
    if (params) {
      rb.path('folderId', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FolderDeletion>;
      })
    );
  }

  deleteFolder(params?: { id: number }): Observable<FolderDeletion> {
    return this.deleteFolder$Response(params).pipe(
      map((r: StrictHttpResponse<FolderDeletion>) => r.body as FolderDeletion)
    );
  }

  getFoldersById(customerId: number): Observable<FolderFile[]> {
    return this.http.get<FolderFile[]>(`${this.rootUrl}/folder`, {params: {customerId}})
  }

  getFolderById(folderId: number): Observable<FolderFile> {
    return this.http.get<FolderFile>(`${this.rootUrl}/folder/${folderId}`)
  }
}
