<div class="page-header">
  <h1 class="page-title">{{'exposures.title' | translate}}</h1>
</div>
<flow-helper-card *ngIf="!consentGiven"
                title="exposures.title"
                description="exposures.info"
                consentKey="exposures-consent">
</flow-helper-card>

<mat-tab-group animationDuration="0ms" class="mat-tabs-override" [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab [disabled]="disableExposuresAnalytics" [label]="'sidebar.overview' | translate">
      <br />
      <div class="filter-wrapper flex justify-between">
        <div>
          <flow-statistics-filter #filtersComponent
                                  *ngIf="isFilterDataReady"
                                  [initData]="filterInputs.visibleInputs"
                                  (filterChange)="onFilterChange($event)"
                                  (fetchNextBookings)="fetchNextBookings()"
                                  [currentPage]="currentPage"
                                  (searchBookings)="searchBookings($event)"
                                  [selectedIndex]="selectedIndex"
                                  [hideSection]="true">
          </flow-statistics-filter>
          <flow-statistics-filter #filtersComponent
                      *ngIf="isFilterDataReady"
                      [initData]="filterInputs.hiddenInputs"
                      (filterChange)="onFilterChange($event)"
                      [hideSection]="true">
          </flow-statistics-filter>
        </div>
        <button *ngIf="isFilterDataReady"
                (click)="submitForm()"
                class="h-10 p-2 mt-8 bg-brand-chathams-blue rounded justify-start items-center gap-2 inline-flex"
                [ngClass]="statisticsService.form.invalid ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'">
            <app-icon fill="none"
                  [stroke]="'#fff'"
                  iconName="file-check-02"
                  size="md">
            </app-icon>
            <div class="text-gray-50 text-xs font-medium leading-[18px]">{{ "global.create" | translate }}</div>
        </button>
      </div>
      <div *ngIf="!isMediaReady;" class="no-data-selected-container flex flex-col justify-center items-center">
          <img src="../../../../assets/no-data.png" />
          <p>{{ "global.noDataSelected" | translate }}</p>
          <p class="no-data-subtitle">{{ "exposures.noData" | translate }}</p>
      </div>

      <div *ngIf="isMediaReady" class="mt-5 mb-5 text-[16px]">
        <div class="mb-5 p-1 bg-[#E8AA86]" *ngIf="isBookingStillRunning">{{'exposures.warningToolTip' | translate}}</div>
        <span class="font-bold">{{'exposures.subtitle' | translate:{mediaName: mediaSubtitleData.mediaName} }}</span>
        <span>{{'exposures.subtitleAsUsedInBooking' | translate }}</span>
        <span class="font-bold">{{'exposures.subtitlePeriod' | translate:{engagementName: mediaSubtitleData.engagementName,
          engagementType: EngagementTypeTranslations[mediaSubtitleData.engagementType] | translate} }}</span>
        <span>{{'exposures.subtitleForPeriod' | translate }}</span>
        <span class="text-[#1F5792] underline cursor-pointer" mat-icon-button [matMenuTriggerFor]="aboveMenu" aria-label="Example icon-button with a menu">{{'exposures.subtitleDates' | translate:{ start: mediaSubtitleData.start, end: mediaSubtitleData.end} }}</span>
        <mat-menu #aboveMenu="matMenu" yPosition="below" xPosition="before">
          <div class="p-2 text-center">{{'exposures.periodDates' | translate }}</div>
          <mat-divider></mat-divider>
          <ul *ngFor="let week of exposedWeeks"><li class="p-1 text-center">{{ week }}</li></ul>
        </mat-menu>
        <mat-divider></mat-divider>
      </div>

      <div id="metrics-section" class="mt-3" *ngIf="isMediaReady">
        <flow-metrics-box-details [metricsData]="summaryPeriods"
                                periodTitle="{{ 'manage.filters.period' | translate }}"
                                [period]="currentWeek"
                                detailItemName="title"
                                detailItemValue="data"
                                sectionTitle="exposures.kpisTitle">
          <div class="title">{{ 'exposures.kpis' | translate }}</div>
        </flow-metrics-box-details>
      </div>

      <flow-view-chart-total-exposures [filter]="filterExposures"
                                        [totalNumberOfExposures]="totalNumberOfExposures"
                                        *ngIf="isMediaReady">
      </flow-view-chart-total-exposures>

      <br>
      <br>

      <flow-distribution-of-exposures-over-hours [filter]="filterExposures"
                                                [totalNumberOfExposures]="totalNumberOfExposures"
                                                [distributonOfDailyHoursReport]="distributonOfDailyHoursReport"
                                                *ngIf="isMediaReady">
      </flow-distribution-of-exposures-over-hours>

      <br>
      <br>

      <flow-average-attention-time [filter]="filterExposures"
                                  [totalNumberOfExposures]="totalNumberOfExposures"
                                  [hourlySeperatedAttentionTime]="hourlySeperatedAttentionTime"
                                  *ngIf="isMediaReady">
      </flow-average-attention-time>

      <br>
      <br>

    </mat-tab>
    <mat-tab [disabled]="disableExposuresReportsAnalytics" [label]="'sidebar.reports' | translate">
      <br />
      <ng-container *ngIf="selectedIndex === 1">
        <flow-reports [reportType]=ActiveReportEnum.Exposures
                      [fetchedData]="responseData"
                      (fetchNextBookings)="fetchNextBookings()"
                      [currentPage]="currentPage"
                      (searchBookings)="searchBookings($event)"
                      [selectedIndex]="selectedIndex"
                      [permissions]="['ExportExposuresReportsAnalytics']"></flow-reports>
      </ng-container>
    </mat-tab>
</mat-tab-group>
