import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import {TranslateConfigService} from '@app/core/translate-config.service';
import {TranslateService} from '@ngx-translate/core';
import {ChartConfiguration, ChartData} from 'chart.js';
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import {DateTime} from 'luxon';
import {BaseChartDirective} from 'ng2-charts';
import {IdName} from "@api/models/idname";

@Component({
  selector: 'flow-vehicles-weekly',
  templateUrl: './vehicles-weekly.component.html',
  styleUrls: ['./vehicles-weekly.component.scss']
})
export class VehiclesWeeklyComponent implements OnChanges {
  @Input('channels') channels: any[];
  @Input('customerId') customerId: any;
  @Input() screenIds!: IdName[];
  @Input() compareMode!: boolean;
  @Input() start!: DateTime;
  @Input() end!: DateTime;
  @Input() rangeCompare!: any;
  @Input() vehiclesCompareData!: any[];
  @Input() vehiclesData!: any[];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public chartData: ChartData<'bar', number[], string | string[]> = null;
  public chartPlugins = [DataLabelsPlugin];
  date = new FormControl(DateTime.local());
  compareChart: boolean = false;
  startDate: string;
  endDate: string;
  noChartData: boolean = false;
  numberOfOriginalWeek: number;
  numberOfComparedWeek: number;
  firstWeek: string;
  secondWeek: string;

  public chartOptions: ChartConfiguration['options'] = {
    indexAxis: 'y',
    scales: {
      x: {
        grace: '5%',
        grid: {
          display: true,
          tickColor: 'white',
        },
      },
      y: {
        grid: {
          display: true,
          tickColor: 'white'
        },
      }
    },
    plugins: {
      legend: {
        position: 'left',
        align: 'start',
        labels: {
          boxWidth: 3,
          boxHeight: 30,
          textAlign: 'left',
          padding: 4
        },
      },

      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end'
      }
    }
  };

  constructor(protected translateService: TranslateService, private translateConfigService: TranslateConfigService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartData(this.vehiclesData, this.vehiclesCompareData);
  }

  formatVehiclesData(data) {
    return data.filter(x => x.label !== this.translateConfigService.instant('global.total')).map(x => x.value);
  }

  updateChartData(vehiclesData, vehiclesCompareData?) {
    this.startDate = this.start.toFormat('yyyy-MM-dd');
    this.endDate = this.end.toFormat('yyyy-MM-dd');

    this.numberOfOriginalWeek = this.start.weekNumber;
    this.numberOfComparedWeek = this.rangeCompare?.start?.weekNumber;
    this.firstWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek}: ${this?.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this?.end.toFormat('yyyy-MM-dd')}`;
    this.secondWeek = `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfComparedWeek}: ${this.rangeCompare?.start?.startOf('week').toFormat('yyyy-MM-dd')} - ${this.rangeCompare?.end?.toFormat('yyyy-MM-dd')}`;


    if (vehiclesData.length > 0) {
      this.noChartData = false;
      if (!this.compareMode) {
        const dataSetWeek = {
          label: `${this.translateConfigService.instant('reach.vehicles.vehicles')}`,
          data: this.formatVehiclesData(vehiclesData),
          backgroundColor: '#598FAE',
        }
        this.chartData = {
          labels: ['cars', 'vans', 'buses', 'trucks', 'ots'].map(label => this.translateConfigService.instant(`reach.vehicles.${label}`)),
          datasets: [dataSetWeek]
        }
        this.chart?.update();
      } else {
        this.chartData = {
          labels: ['cars', 'vans', 'buses', 'trucks', 'ots'].map(label => this.translateConfigService.instant(`reach.vehicles.${label}`)),
          datasets: [
            {
              label: `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfOriginalWeek} ${this.translateConfigService.instant('reach.vehicles.vehicles')}`,
              data: this.formatVehiclesData(vehiclesData),
              backgroundColor: '#598FAE'
            },
            {
              label: `${this.translateConfigService.instant('peopleCount.week')} ${this.numberOfComparedWeek} ${this.translateConfigService.instant('reach.vehicles.vehicles')}`,
              data: this.formatVehiclesData(vehiclesCompareData),
              backgroundColor: '#EFC2A9'
            }
          ]
        }
        this.chart?.update();
      }

    } else {
      this.noChartData = true;
    }
  }
}
