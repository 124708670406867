import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BookingOptions } from '@api/models/booking';


@Component({
  selector: 'flow-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
  @ViewChild('popoverContent', { read: ViewContainerRef, static: true }) popoverContent: ViewContainerRef;
  @Input() contentComponent: Type<any>;
  options: BookingOptions;
  contentComponentRef: ComponentRef<any>;
  targetElement: HTMLElement;
  isOpen = false;
  top = 0;
  left = 0;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setPosition(this.targetElement);
  }

  openPopover(targetElement: HTMLElement, options: BookingOptions) {
    this.options = options;

    if (this.contentComponent) {
      this.popoverContent.clear();
      this.createComponent(this.contentComponent, options);
      
      this.isOpen = true;
      this.cdr.detectChanges();
      this.setPosition(targetElement);
    }
  }

  createComponent(contentComponent: Type<any>, options: Object) {
    this.closePopover();
    this.contentComponentRef = this.popoverContent.createComponent(contentComponent);

    if (this.contentComponentRef.instance) {
      this.contentComponentRef.instance.contentComponent = contentComponent;
      this.contentComponentRef.instance.options = options;
    }
  }

  private setPosition(targetElement: HTMLElement) {
    this.targetElement = targetElement;

    const rect = this.targetElement?.getBoundingClientRect();
    const popover = this.el.nativeElement.querySelector('.popover');
    const popoverRect = popover?.getBoundingClientRect();

    if (!rect || !popoverRect) return;

    const spaceAbove = rect.top;
    const spaceBelow = window.innerHeight - rect.top;
    const spaceLeft = rect.left;
    const spaceRight = window.innerWidth - rect.right;
    const heightWithoutRect = window.innerHeight - rect.height;

    // vertical position
    if (spaceBelow >= popoverRect.height) {
      this.top = rect.top;
    }

    if (spaceBelow <= popoverRect.height) {
      this.top = 20;
    }

    if (spaceAbove >= popoverRect.height) {
      if (heightWithoutRect >= spaceAbove) {
        this.top = rect.top + rect.height - popoverRect.height;
      } else {
        this.top = rect.top - popoverRect.height;
      }
    }

    // horizontal position
    if (spaceRight >= popoverRect.width) {
      this.left = rect.right;
    } else if (spaceLeft >= popoverRect.width) {
      this.left = rect.left - popoverRect.width;
    }
  }

  closePopover() {
    this.isOpen = false;
    if (this.contentComponentRef) {
      this.contentComponentRef.destroy();
    }
  }
}
