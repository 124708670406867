
<div class="nav-bar" *ngIf="isLoggedIn" >
  <img src="../../../../assets/svg/logo.svg"
       class="cursor-pointer"
       (click)="redirectToHomePage()"
       alt="Logo">
  <div class="nav-buttons">
    <!-- We will leave this commented out for now until we implement logic for it! -->
    <!-- <button class="btn-round-primary">
      <app-icon
        fill="rgba(0,0,0,0)"
        iconName="question"
        size="md">
    </app-icon></button> -->
    <button class="btn-round font-normal"
            (click)="showSettings($event)">{{initials}}</button>
  </div>
</div>

<div flowClickOutside class="settings z-30" *ngIf="showNavSettings" (clickOutside)="showNavSettings=false">
  <flow-nav-settings [currentUser]="currentUser" (closeNavSettings)="closeSettings($event)"></flow-nav-settings>
</div>

<div class="drawer-container">
  <!-- Drawer -->
  <div *ngIf="!appService.navHidden" class="drawer-side bg-whitesmoke-100 z-30">
    <flow-sidenav [navMini]="appService.navClosed"></flow-sidenav>
  </div>

  <!-- Backdrop -->
  <div *ngIf="!appService.navClosed && appService.modeOverQuery.matches"
       class="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"
       (click)="appService.navClosed = true"></div>

  <!-- Main Content -->
  <div class="flex flex-col flex-1 w-[79%]"
       [ngClass]="{'pr-[2rem] mt-[5.5rem] mb-[2.5rem] overflow-auto':isLoggedIn}">
    <div [ngClass]="{ 'has-toolbar-form-actions': appService.hasToolbarFormActions, 'content-container': !appService.navHidden }">
      <router-outlet></router-outlet>
      <div #top></div>
      <div *ngIf="showNavigateToTopBtn" class="fixed bottom-10 right-10">
        <button
          (click)="scrollToTop()"
          class="p-3 bg-secondary-500 text-white rounded-full shadow-lg focus:outline-none">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
