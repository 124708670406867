<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{'customer.title' | translate}}</h1>

  <div class="flex-1 ">
    <a mat-flat-button
       color="primary"
       [routerLink]="['/customers/create']">
      {{'global.add' | translate}}
    </a>
  </div>
</div>

<mat-paginator 
  (page)="handlePageEvent($event)" 
  [pageSize]="params.pageSize" 
  [pageIndex]="params.pageNumber" 
  [pageSizeOptions]="pageSizeOptions" 
  [length]="totalCount">
</mat-paginator>
<table mat-table [dataSource]="dataSource" class="table-hover" matSort matSortActive="name" matSortDirection="asc" (matSortChange)="handleSortEvent($event)">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.id' | translate}} </th>
    <td mat-cell *matCellDef="let customer"> {{customer.id}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.name' | translate}}</th>
    <td mat-cell *matCellDef="let customer"> {{customer.name}} </td>
  </ng-container>

  <ng-container matColumnDef="organizationId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.orgNumber' | translate}}</th>
    <td mat-cell *matCellDef="let customer"> {{customer.organizationId}}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'customer.table.customerType' | translate}}</th>
    <td mat-cell *matCellDef="let customer"> {{CustomerTypeTranslations[customer.type] | translate}}</td>
  </ng-container>

  <ng-container matColumnDef="person">
    <th mat-header-cell *matHeaderCellDef> {{'customer.table.contact' | translate}}</th>
    <td mat-cell *matCellDef="let customer">
      <span *ngIf="customer.contactPersons.length > 1">{{customer?.contactPersons[0]?.name}} <a class="text-blue-600 underline cursor-pointer" (click)="openCustomerDialog(customer?.contactPersons);">more</a></span>
      <span *ngIf="customer.contactPersons.length === 1">{{customer?.contactPersons[0]?.name}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="presentationTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.presentationTime' | translate}}</th>
    <td mat-cell *matCellDef="let customer">{{customer.presentationTime}}</td>
  </ng-container>

  <ng-container matColumnDef="numbersOfWeekForFiltering">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.numbersOfWeekForFiltering' | translate}}</th>
    <td mat-cell *matCellDef="let customer"> {{customer.numbersOfWeekForFiltering}}</td>
  </ng-container>

  <ng-container matColumnDef="storageLimitSize">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.storageLimitSize' | translate}}</th>
    <td mat-cell *matCellDef="let customer">{{customer.storageLimitSize}}</td>
  </ng-container>

  <ng-container matColumnDef="poiLimit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.poiLimit' | translate}}</th>
    <td mat-cell *matCellDef="let customer">{{customer.personOfInterestLimit}}</td>
  </ng-container>

  <ng-container matColumnDef="contactPersonLimit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'customer.table.contactPersonLimit' | translate}}</th>
    <td mat-cell *matCellDef="let customer">{{customer.contactPersonLimit}}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="actions-col"></th>
    <td mat-cell *matCellDef="let customer">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="['/customers', customer.id]">       
            <span>{{ 'global.edit' | translate }}</span>
          </a>
          <a mat-menu-item (click)="onDeleteClick($event, customer)">
            <span class="text-red-500">{{ 'global.delete' | translate }}</span>
          </a>
        </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let customer; columns: displayedColumns;" (dblclick)="redirectToEditPage(customer.id)"></tr>
</table>
