import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'flow-delete-screne-group-modal',
  templateUrl: './delete-screne-group-modal.component.html',
  styleUrls: ['./delete-screne-group-modal.component.scss']
})
export class DeleteScreneGroupModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
