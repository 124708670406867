<h2 class="flex flex-col items-start flex-shrink-0 reach-section-title">{{ "peopleCount.kpiTitle" | translate }} {{startDate}} - {{endDate}}?</h2>
<div class="flex flex-col metrics-box-details-container">
  <div class="flex w-full justify-between items-start">
    <div class="slot-wrapper">
      {{ "peopleCount.kpis" | translate }}
    </div>
    <div class="period-section flex flex-col items-end">
      <span>{{ weekStartDate | date: 'MM-dd' }} - {{ weekEndDate | date: 'MM-dd' }}</span>
    </div>
  </div>
  <div class="grid grid-cols-[2fr,0.5fr,2fr] gap-10">
    <div>
      <div class="font-semibold text-[#FA6200] text-xs mb-4">{{ 'peopleCount.total' | translate }}</div>


      <div *ngIf="isAdmin" class="mb-6">
        <div>{{ 'peopleCount.currently' | translate }} {{ 'peopleCount.inChannel' | translate }}</div>
        <div
          class="font-semibold text-lg flex items-baseline gap-1">{{ peopleCount?.daily?.currentPeopleInChannel || 0 }}
          <div>
            <app-icon
              fill="none"
              iconName="webcam"
              size="sm">
            </app-icon>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col w-1/2 bg-[#FAFAFA] p-[6px]">
          <div class="mb-[18px]">{{ 'peopleCount.today' | translate }}</div>
          <div class="font-semibold text-lg">{{ peopleCount?.daily?.totalToday }}</div>
          <div class="flex justify-between">
            <div class="flex-col">
              <div [ngClass]="dailyIsPositive ? 'text-[#177578]' : 'text-[#FC4B47]'" class="flex gap-1">
                <div [ngClass]="dailyIsPositive ? 'text-[#177578]' : 'text-[#FC4B47]' " class="flex gap-1">
                <span>
                  <app-icon
                    [iconName]="dailyIsPositive ? 'arrow-up' : 'arrow-down'"
                    [stroke]="dailyIsPositive ? '#177578' : '#FC4B47'"
                    size="sm">
                  </app-icon>
                </span>
                  <span>{{ (peopleCount?.daily?.totalToday - peopleCount?.daily?.totalLastWeekCurrentDay) }}</span>
                </div>

              </div>
              <div>{{ 'peopleCount.vsLastWeek' | translate }}</div>
            </div>
            <div>
              <app-icon
                [iconName]="dailyIsPositive ? 'chart-up' : 'chart-down'"
                height="50px"
                width="90px">
              </app-icon>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-1/2 p-[6px]">
          <div class="mb-[18px]">{{ 'peopleCount.lastWeek' | translate }}</div>
          <div class="font-semibold text-lg">{{ peopleCount?.weekly?.totalCurrentWeek }}</div>
          <div class="flex justify-between">
            <div class="flex-col">
              <div [ngClass]="weeklyIsPositive ? 'text-[#177578]' : 'text-[#FC4B47]' " class="flex gap-1">
                <span>
                  <app-icon
                    [iconName]="weeklyIsPositive ? 'arrow-up' : 'arrow-down'"
                    [stroke]="weeklyIsPositive ? '#177578' : '#FC4B47'"
                    size="sm">
                  </app-icon>
                </span>
                <span>{{ (peopleCount?.weekly?.totalCurrentWeek - peopleCount?.weekly?.totalLastWeek) }}</span>
              </div>
              <div>{{ 'peopleCount.vsLastWeek' | translate }}</div>
            </div>
            <div>
              <app-icon
                [iconName]="weeklyIsPositive ? 'chart-up' : 'chart-down'"
                height="50px"
                width="90px">
              </app-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        class="font-semibold text-[#FA6200] text-xs mb-4">{{ 'peopleCount.genderDistribution' | translate }}
      </div>
      <div class="mb-2 bg-[#FAFAFA] min-h-[86px] p-[6px]">
        <div class="pb-[6px]">{{ 'peopleCount.today' | translate }}</div>
        <div class="flex gap-6">
          <div>
            <div>{{ 'peopleCount.men' | translate }}</div>
            <div class="flex items-center">
              <div class="font-semibold text-lg">{{ peopleCount?.daily?.genderDistribution?.Male }}</div>
              <app-icon
                iconName="male-icon"
                size="md">
              </app-icon>
            </div>
          </div>
          <div>
            <div>{{ 'peopleCount.women' | translate }}</div>
            <div class="flex items-center">
              <div class="font-semibold text-lg">{{ peopleCount?.daily?.genderDistribution?.Female }}</div>
              <app-icon
                iconName="female-icon"
                size="md">
              </app-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="p-[6px] min-h-[86px]">
        <div class="mb-2">{{ 'peopleCount.thisWeek' | translate }}</div>
        <div class="flex gap-6">
          <div>
            <div>{{ 'peopleCount.men' | translate }}</div>
            <div class="flex items-center">
              <div class="font-semibold text-lg">{{ peopleCount?.weekly?.genderDistribution?.Male }}</div>
              <app-icon
                iconName="male-icon"
                size="md">
              </app-icon>
            </div>
          </div>
          <div>
            <div>{{ 'peopleCount.women' | translate }}</div>
            <div class="flex items-center">
              <div class="font-semibold text-lg">{{ peopleCount?.weekly?.genderDistribution?.Female }}</div>
              <app-icon
                iconName="female-icon"
                size="md">
              </app-icon>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="w-[70%]">
      <div class="font-semibold text-[#FA6200] text-xs mb-4">{{ 'peopleCount.topGroup' | translate }}</div>

      <div class="flex">
        <div class="w-1/2 mr-8 p-[6px] bg-[#FAFAFA] min-h-[86px]">
          <div class="mb-2">{{ 'peopleCount.today' | translate }}</div>
          <div>{{ TopGroupEnum[peopleCount?.daily?.todayTopGroup?.gender] | translate }}</div>
          <div *ngIf="TopGroupEnum[peopleCount?.daily?.todayTopGroup?.gender]" class="flex items-center">
            <div class="font-semibold text-lg">{{ peopleCount.daily?.todayTopGroup?.ageSpan ?? '' }}</div>
            <app-icon
              [iconName]="TopGroupEnum[peopleCount.daily?.todayTopGroup?.gender] == TopGroupEnum['1']? 'male-icon' : 'female-icon'"
              size="md">
            </app-icon>
          </div>
        </div>
        <div class="w-1/2 p-[6px] mr-8">
          <div class="mb-2">{{ "peopleCount.lastWeekDay" | translate: {lastWeekDay: lastWeekDay} }}</div>
          <div>{{ TopGroupEnum[peopleCount?.daily?.lastWeekCurrentDayTopGroup?.gender] | translate }}</div>
          <div *ngIf="TopGroupEnum[peopleCount?.daily?.lastWeekCurrentDayTopGroup?.gender]" class="flex items-center">
            <div class="font-semibold text-lg">{{ peopleCount?.daily?.lastWeekCurrentDayTopGroup?.ageSpan ?? '' }}</div>
            <app-icon
              [iconName]=" TopGroupEnum[peopleCount?.daily?.lastWeekCurrentDayTopGroup?.gender] == TopGroupEnum['1'] ? 'male-icon' : 'female-icon'"
              size="md">
            </app-icon>
          </div>
        </div>
      </div>

      <div class="flex mt-2">
        <div class="w-1/2 pl-[6px] min-h-[86px]">
          <div class="mb-2">{{ 'peopleCount.thisWeek' | translate }}</div>
          <div>{{ TopGroupEnum[peopleCount?.weekly?.currentWeekTopGroup?.gender] | translate }}</div>
          <div *ngIf="TopGroupEnum[peopleCount?.weekly?.currentWeekTopGroup?.gender]" class="flex items-center">
            <div class="font-semibold text-lg">{{ peopleCount?.weekly?.currentWeekTopGroup?.ageSpan ?? '' }}</div>
            <app-icon
              [iconName]=" TopGroupEnum[peopleCount?.weekly?.currentWeekTopGroup?.gender] == TopGroupEnum['1'] ? 'male-icon' : 'female-icon'"
              size="md">
            </app-icon>
          </div>
        </div>
        <div class="w-1/2 pl-[6px]">
          <div class="mb-2">{{ 'peopleCount.lastWeek' | translate }}</div>
          <div>{{ TopGroupEnum[peopleCount?.weekly?.previousWeekTopGroup?.gender] | translate }}</div>
          <div *ngIf="TopGroupEnum[peopleCount?.weekly?.previousWeekTopGroup?.gender]" class="flex items-center">
            <div class="font-semibold text-lg">{{ peopleCount?.weekly?.previousWeekTopGroup?.ageSpan ?? '' }}</div>
            <app-icon
              [iconName]=" TopGroupEnum[peopleCount?.weekly?.previousWeekTopGroup?.gender] == TopGroupEnum['1'] ? 'male-icon' : 'female-icon'"
              size="md">
            </app-icon>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
