<div class="w-full space-y-3">
    <div class="flex flex-col">
        <mat-label>{{ 'cleanUpMedia.lastUsedSelectLabel' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [(ngModel)]="selectedLastUsedValue" (selectionChange)="onValueChange()" placeholder="{{'global.select' | translate}}">
            <mat-option *ngFor="let value of lastUsedMonthValues"
                        [value]="value.id">
                {{ value.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    <div class="flex items-center">
        <mat-checkbox color="primary"
                      id="include-nested-media"
                      [(ngModel)]="includeNestedMedia"
                      (change)="onValueChange()">
        </mat-checkbox>
        <span class="ml-2 mt-1">{{ 'cleanUpMedia.nestedFilesFilter' | translate }}</span>
    </div>
    <div class="flex items-center">
        <mat-checkbox color="primary"
                      id="include-never-used"
                      [(ngModel)]="includeNeverUsed"
                      (change)="onValueChange()">       
        </mat-checkbox>
        <span class="ml-2 mt-1">{{ 'cleanUpMedia.neverUsedFilesFilter' | translate }}</span>
    </div>   
</div>