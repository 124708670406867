<flow-statistics-filter #filtersComponent
                        [initData]="filterInputs"
                        (filterChange)="onFilterChanged($event)"
                        (onCreateReport)="onCreateReport()"
                        (onDownloadReport)="onDownloadReport()"
                        (fetchNextBookings)="loadMoreItems($event)"
                        (searchBookings)="searchBooking($event)"
                        [currentPage]="currentPage"
                        [selectedIndex]="selectedIndex"
                        [downloadReportDisableBtn]="!statisticsService.form.valid || !reports || reports.totalVisitors === 0 || reports.total === 0 || !isDownloadEnabled || reports?.personOfInterestStatisticsReports?.length === 0"
                        [permissions]="permissions">
</flow-statistics-filter>

<flow-reports-data *ngIf="reports"
                   [reports]="reports"
                   [activeReport]="activeReport"
                   [includeSmsDetailsPOI]="includeSmsDetailsPOI"
                   [includeAdminDetailsPOI]="includeAdminDetailsPOI"
                   [includeVehicleDataReach]="includeVehicleDataReach"
                   [includeAverageTimeInChannel]="includeAvgTimeInChannel"
                   [isAdmin]="isAdmin"
                   (reportShown)="reportShown($event)"
                   (getNewReport)="getNewReport($event)"
></flow-reports-data>
<div *ngIf="!reports"
      class="no-data-selected-container flex flex-col justify-center items-center">
    <img src="../../../../assets/no-data.png" />
    <p>{{ "global.noDataSelected" | translate }}</p>
    <p class="no-data-subtitle">{{ "reports.noData" | translate }}</p>
</div>
