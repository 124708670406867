import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { Engagement } from '../models/engagement';
import { EngagementRequest } from '../models/engagement-request';
import { IdName } from '../models/idname';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { DeviceOptions } from '@app/shared/models/deviceOptions';

@Injectable({
  providedIn: 'root',
})
export class EngagementsAdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly GetStatisticsEngagementsEngagementAdminPath = '/statistics/engagements';

  getStatisticsEngagements$Response(params?: { body?: EngagementRequest }): Observable<StrictHttpResponse<Array<Engagement>>> {
    const rb = new RequestBuilder(this.rootUrl, EngagementsAdminApi.GetStatisticsEngagementsEngagementAdminPath, 'get');
    if (params?.body) {
      rb.query('customerIds', params.body.customerIds);
      rb.query('startDate', params.body.startDate ? new Date(params.body?.startDate)?.toISOString() : null);
      rb.query('endDate', params.body.endDate ? new Date(params.body?.endDate)?.toISOString() : null);
      rb.query('placements', params.body.placements);
      rb.query('name', params.body.name);
      rb.query('types', params.body.types);
      rb.query('channelIds', params.body.channelIds);
      rb.query('clientIds', params.body.clientIds);
      rb.query('screenIds', params.body.screenIds);
      rb.query('pageNumber', params.body.pageNumber);
      rb.query('text', params.body.text);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Engagement>>;
        })
      );
  }

  getStatisticsEngagements(params?: { body?: EngagementRequest }): Observable<any> {
    return this.getStatisticsEngagements$Response(params).pipe(map((r: StrictHttpResponse<any>) => r.body as any));
  }

  getScreens$Response(params?: {
    channelIds?: number[];
    customerIds?: number[];
    deviceTypesForFiltering: number[];
  }): Observable<StrictHttpResponse<Array<DeviceOptions>>> {
    const rb = new RequestBuilder(this.rootUrl, '/statistics/devices', 'get');
    if (params) {
      rb.query('channelIds', params.channelIds);
      rb.query('customerIds', params.customerIds);
      rb.query('deviceTypesForFiltering', params.deviceTypesForFiltering);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DeviceOptions>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScreens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreens(params: {
    channelIds?: number[];
    customerIds?: number[];
    deviceTypesForFiltering: number[];
  }): Observable<Array<DeviceOptions>> {
    return this.getScreens$Response(params).pipe(map((r: StrictHttpResponse<Array<DeviceOptions>>) => r.body as Array<DeviceOptions>));
  }

  static readonly GetEngagementsMediasAdminPath = '/statistics/engagementsMedias';

  getEngagementsMedias$Response(params?: { engagementIds: number[]; clientIds?: number[] }): Observable<StrictHttpResponse<Array<IdName>>> {
    const rb = new RequestBuilder(this.rootUrl, EngagementsAdminApi.GetEngagementsMediasAdminPath, 'get');

    if (params) {
      rb.query('engagementIds', params?.engagementIds);
      rb.query('clientIds', params?.clientIds);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<IdName>>;
        })
      );
  }

  getEngagementsMedias(params?: { engagementIds: number[]; clientIds?: number[] }): Observable<Array<IdName>> {
    return this.getEngagementsMedias$Response(params).pipe(map((r: StrictHttpResponse<Array<IdName>>) => r.body as Array<IdName>));
  }
}
