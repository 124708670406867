<div *ngIf="permissionList.nodes.length > 0;"
      class="container mx-auto">
    <form [formGroup]="permissionsForm">
        <div class="flex">
            <table>
                <thead>
                    <tr class="border border-gray-200 text-left">
                        <th class="h-[53px] bg-gray-200"
                            colspan="2"></th>
                    </tr>
                    <tr class="border border-gray-200 font-bold text-left">
                        <th class="h-[73px]"
                            colspan="2"></th>
                    </tr>
                    <tr class="border border-gray-200 text-left">
                        <th class="px-6 py-4 font-normal">{{headerTitle}}</th>
                        <th class="px-6 py-4"></th>
                    </tr>
                </thead>
                <tbody formArrayName="nodes">
                    <tr class="border border-gray-200"
                        *ngFor="let node of nodes.controls; let i = index"
                        [formGroupName]="i">
                        <td class="px-6 py-4 whitespace-nowrap sticky">
                            <div class="flex items-center">
                                <app-icon *ngIf="node.disabled" stroke="#202020"
                                          fill="rgba(0,0,0,0)"
                                          iconName="lock"
                                          size="md"></app-icon>
                                <span>{{ node.get('name').value }}</span>
                                <span class="text-sm text-gray-600 ml-2"> {{ node.get('data')?.value }}</span>
                            </div>
                        </td>
                        <td class="px-6 py-4">
                            <mat-checkbox color="primary" formControlName="selectAll" (change)="onNodeSelectAll(node, $event)" [disabled]="!isEditable || node.disabled"></mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-full overflow-x-auto">
                <table class="block w-0">
                    <thead>
                        <tr class="border border-gray-200 border-l-0 text-left">
                            <th class="px-6 py-4 bg-gray-200 font-normal"
                                [attr.colspan]="calculateHeaderColSpan(header)"
                                *ngFor="let header of headerConfig; let i = index">
                                {{header.group | translate}}
                            </th>
                            <th class="px-6 py-4 bg-gray-200"
                            colspan="2"></th>
                        </tr>
                        <tr class="border border-gray-200 border-l-0 font-bold text-left">
                            <ng-container *ngFor="let header of headerConfig; let i = index">
                                <th [attr.colspan]="action.permissions.length"
                                    class="px-6 py-4"
                                    *ngFor="let action of header.actions">{{action.title | translate}}</th>
                            </ng-container>
                        </tr>
                        <tr class="border border-gray-200 border-l-0 text-left">
                            <ng-container *ngFor="let header of headerConfig; let i = index">
                                <ng-container class="px-6 py-4"
                                              *ngFor="let action of header.actions">
                                    <th class="px-6 py-4 font-normal"
                                        *ngFor="let permissions of action.permissions">{{permissions | translate}}</th>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody formArrayName="nodes">
                        <tr class="border border-gray-200 border-l-0"
                            *ngFor="let node of nodes.controls; let i = index"
                            [formGroupName]="i">
                            <td class="px-6 py-4 text-left"
                                *ngFor="let permission of permissions(i).controls; let permissionIndex = index"
                                formArrayName="permissions">
                                <label [formGroupName]="permissionIndex">
                                    <mat-checkbox *ngIf="permission.value !== null" color="primary" formControlName="value"></mat-checkbox>
                                </label>
                            </td>
                            <td class="px-6 py-4 text-left" *ngIf="actions && actions.length > 0">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item *ngFor="let action of actions" (click)="onActionClick(action, node.value)">
                                        <span>{{ getTranslatedActionKey(action) | translate }}</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>