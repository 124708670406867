import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  selector: 'flow-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
})
export class SidenavItemComponent implements OnInit {
  @Input() label: string;
  @Input() url: string;
  @Input() iconName: string;
  @Input() navMini: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  isActive(path: string): boolean {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    };
    return this.router.isActive(path, matchOptions);
  }
}
