export enum CountryEnum {
  England = 1,
  Sweden = 2,
  Norway = 3,
  Denmark = 4,
  Finland = 5,
  Estonia = 6,
  Germany = 7,
  Spain = 8,
  Netherlands = 9,
  Belgium = 10,
  Botswana = 11,
  Israel = 12,
  Ireland = 13,
  Indonesia = 14,
  HongKong = 15,
  France = 16,
  UnitedKingdom = 17,
  Czechia = 18,
  UnitedStates = 19,
  Malta = 20,
  Austria = 21,
  Singapore = 22,
  Switzerland = 23,
  Portugal = 24,
  Poland = 25,
  Australia = 26,
  UnitedArabEmirates = 27,
  Italy = 28,
  Canada = 29,
  CaboVerde = 30,
  China = 31,
  Cyprus = 32,
  Luxembourg = 33,
  Montenegro = 34,
  NewZealand = 35,
  Slovenia = 36,
  Thailand = 37,
  AlandIslands = 38,
  Russia = 39,
  Iceland = 40,
  Taiwan = 41,
  Malaysia = 42,
}