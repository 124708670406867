import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap, takeUntil, Subject, from, concatMap, catchError, of, filter } from 'rxjs';
import { uniqueId } from 'lodash-es';

import { Customer, ImageToken, MediaFile, Tag } from '@api/index';
import { MediaApi } from '@api/services/media-api';
import { TranslateConfigService } from '@core/translate-config.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { Media } from '@core/services/media.service';
import { Client } from '@api/models/client';
import { SelectedFile } from '@app/models/media-files';
import { CustomSnackbarComponent } from '@shared/custom-snackbar/custom-snackbar.component';
import { ClientApi } from '@api/services/client-api';

enum UploadStatus {
  success = 'success',
  error = 'error'
}

interface ModalData {
  media?: MediaFile;
  customerIdModal?: number;
  isDefault?: boolean;
}

@Component({
  selector: 'flow-media-create-modal',
  templateUrl: './media-create.modal.html',
  styleUrls: ['./media-create.modal.scss']
})
export class MediaCreateModal extends unsubscribeMixin() implements OnInit, OnDestroy {
  cancelUploads$ = new Subject<void>();
  form: FormGroup;
  mediasArray: any[] = [];
  uploadedFiles: Media[] = [];
  selectedFile: SelectedFile | null = null;
  clients: Client[] = [];
  customers: Customer[] = [];
  imageToken: ImageToken;
  tags: Tag[];
  disableUpload: boolean = false;
  isEdit: boolean = false;
  reset: boolean = false;
  uploadingDone: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MediaCreateModal>,
    private mediaApi: MediaApi,
    private formBuilder: FormBuilder,
    private translateConfigService: TranslateConfigService,
    private systemMessageService: SystemMessageService,
    private dialog: MatDialog,
    private clientApi: ClientApi,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.getClients();
  }

  initForm() {
    this.form = this.formBuilder.group({
      customerId: [this.data.customerIdModal, Validators.required],
      name: ['', Validators.required],
      clientId: [''],
      tags: [],
      isClientBoxChecked: [false]
    });
  }

  getClients() {
    this.clientApi.getClients([this.data.customerIdModal])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((clients) => {
        this.clients = clients;
      });
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLElement;
    fileInput.click();
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  selectFile(event: any): void {
    this.reset = false;
    this.selectedFile = event;
  }

  addFile() {
    if (this.selectedFile && this.form.get('name').valid) {
      const id = uniqueId();
      const formValues = this.form.getRawValue();
      const formBody = {
        id: id,
        name: formValues?.name,
        clientId: formValues.clientId,
        customerId: formValues.customerId,
        tags: formValues.tags,
        isClientBoxChecked: formValues?.isClientBoxChecked,
        index: id,
      };
      const updatedFile = { ...this.selectedFile, ...formBody };

      this.mediasArray.push(updatedFile);

      this.form.reset({
        customerId: this.data.customerIdModal, // Preserve customerId
        name: '',
        clientId: '',
        tags: [],
        isClientBoxChecked: false
      });

      this.tags = null;
      this.selectedFile = null;
      this.reset = true;
    }
  }

  editFile(file: any) {
    if (file) {
      this.isEdit = true;
      this.selectedFile = file;
      this.form.patchValue({
        customerId: this.data.customerIdModal,
        name: file.name,
        clientId: file.clientId,
        tags: file.tags,
        isClientBoxChecked: file.isClientBoxChecked
      });
      this.tags = file.tags;
    }
  }

  saveFile() {
    if (this.selectedFile && this.selectedFile.index) {
      const index = this.mediasArray.findIndex(control => control.index === this.selectedFile.index);
      if (index !== -1) {
        const updatedFile = {
          ...this.mediasArray[index],
          customerId: this.data.customerIdModal,
          name: this.form.get('name').value,
          clientId: this.form.get('clientId').value,
          tags: this.form.get('tags').value,
          isClientBoxChecked: this.form.get('isClientBoxChecked').value
        };
        this.mediasArray[index] = updatedFile;
        this.tags = null;
      }
    }

    this.form.reset({
      customerId: this.data.customerIdModal, // Preserve customerId
      name: '',
      clientId: '',
      tags: [],
      isClientBoxChecked: false
    });

    this.isEdit = false;
    this.selectedFile = null;
  }

  removeFile(id: string): void {
    const existingIndex = this.mediasArray.findIndex((item) => item.index === id);

    if (existingIndex !== -1) {
      this.mediasArray.splice(existingIndex, 1);
    }
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private checkStorage() {
    const isExceeded = this.checkIfStorageIsExceeded();

    if (isExceeded) {
      this.dialog.open(CustomSnackbarComponent, {
        width: '600px',
        data: {
          msg: {
            title: "notifications.error.storageExceededTitle",
            content: this.translateConfigService.instant("notifications.error.storageExceeded")
          }
        }
      });
      return;
    }
  }

  private checkIfStorageIsExceeded() {
    const customer = this.form.get('customerId').value;
    const mediaTotalSize = this.mediasArray.reduce((acc, curr) => acc + curr.selectedFile.size, 0);
    return (mediaTotalSize + customer.currentStorageSize) > this.gigabytesToBytes(customer.storageLimitSize);
  }

  private checkFilesSize() {
    for (let i = 0; i < this.mediasArray.length; i++) {
      const file = this.mediasArray[i];
      if (file.selectedFile.size > 1073741824) {
        this.systemMessageService.error(this.translateConfigService.instant("notifications.error.fileToLarge"));
        return;
      }
    }
  }

  onSave(): void {
    if (this.mediasArray.length === 0) return;
    this.disableUpload = true;

    this.checkStorage();
    this.checkFilesSize();
    this.uploadFiles();
  }

  uploadFiles() {
    this.uploadingDone = false;
    from(this.mediasArray).pipe(
      filter(media => media.status !== UploadStatus.success),
      concatMap(media => this.processFile(media).pipe(
        tap(() => this.updateStatus(media, UploadStatus.success)),
        catchError((error) => {
          this.updateStatus(media, UploadStatus.error, error.message);
          return of(null);
        }),
        takeUntil(this.cancelUploads$)
      )),
      takeUntil(this.cancelUploads$)
    ).subscribe({
      error: (error) => {
        this.systemMessageService.error(this.translateConfigService.instant(error));
      },
      complete: () => {
        this.disableUpload = false;
        this.uploadingDone = true;

        if (this.mediasArray.every(media => media.status === UploadStatus.success)) {
          this.onUploadComplete();
        }
      }
    })
  }

  updateStatus(media, status: UploadStatus, errorMessage?: string) {
    const existingFile = this.mediasArray.find(file => file.index === media.index);
    if (existingFile) {
      existingFile.status = status;
      existingFile.errorMessage = errorMessage;
    } else {
      this.mediasArray.push({ media, status, errorMessage });
    }
  }

  processFile(media) {
    const paramsString = this.formatParamsString(media);
    const uploadRequest = media?.format === 'image' ? 'mediaUploadImage' : 'mediaUploadVideo';
    media.uploadProgress = null;

    return this.mediaApi[uploadRequest]({
      file: media?.selectedFile,
      paramsString
    }).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          media.uploadProgress = Math.round((event.loaded / (event.total ?? 1)) * 100);
        }

        if (event.type === HttpEventType.Response && event?.body) {
          this.uploadedFiles.push(event?.body);
        }
      })
    );
  }


  private onUploadComplete(): void {
    const file = this.data.isDefault ? this.uploadedFiles[0] : this.uploadedFiles;
    this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate"));
    this.dialogRef.close(file);
    this.disableUpload = false;
  }

  formatParamsString(media): string {
    const tagsJson = JSON.stringify(media?.tags || []);
    let paramsString = '';
    if (media.customerId !== undefined && media.customerId !== null) {
      paramsString += `customerId=${media.customerId}`;
    }
    if (media?.name && media?.name.length > 0) {
      paramsString += `&name=${media?.name}`;
    }
    if (media?.clientId) {
      paramsString += `&clientId=${media?.clientId}`;
    }
    if (media?.selectedFile?.videoDuration) {
      paramsString += `&duration=${media?.selectedFile?.videoDuration}`;
    }
    if (!this.data.media) {
      paramsString += `&shouldCreateFolder=${media.isClientBoxChecked || false}`;
    }
    paramsString += `&tags=${tagsJson}`;
    return paramsString;
  }

  getVideoDuration(selectedFile: File, file: SelectedFile): void {
    const video = document.createElement('video');
    video.src = window.URL.createObjectURL(selectedFile);
    video.preload = 'metadata';
    video.onloadedmetadata = () => (file.videoDuration = +video.duration.toFixed(2));
  }

  gigabytesToBytes(gigabytes) {
    const bytesInGigabyte = 1024 * 1024 * 1024;
    const bytes = gigabytes * bytesInGigabyte;
    return bytes;
  }

  override ngOnDestroy(): void {
    this.cancelUploads$.next();
    this.cancelUploads$.complete();
  }
}
