import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'flow-tabs-route',
  templateUrl: './tabs-route.component.html',
  styleUrls: ['./tabs-route.component.scss'],
})
export class TabsRouteComponent {
  @Input() activeTab: number = 0;
  @Input() tabs: {
    title: string;
    subtitle?: string;
    path: string;
    disabled?: boolean;
  }[] = [];
  @Output() activeTabChange = new EventEmitter<number>();

  onTabClick(tab: { path: string; title: string; disabled?: boolean }, index: number) {
    this.activeTab = index;
    this.activeTabChange.emit(index);
  }
}
