<div
  class="bg-[url('/assets/Flow.jpg')] bg-cover bg-center h-[100vh] w-full py-4 px-8 flex items-center justify-center">

  <main class="flex flex-col items-center h-fit p-6 rounded bg-white">
    <img alt="Company Logo" class="object-contain w-28 max-w-full aspect-[2.67]" loading="lazy"
         src="assets/svg/logo.svg"/>

    <div *ngIf="!uploading else uploadSpinner" class="flex justify-center">
      <ng-container *ngIf="!invalidToken">
        <section class="flex flex-col mt-10 mr-4 w-[365px]">
          <header class="flex flex-col justify-center text-sm text-neutral-800">
            <h1 class="text-base font-medium leading-none">Welcome</h1>
            <p class="mt-2 leading-4">You have been provided with a unique link for uploading your media file
              content.</p>
            <p class="mt-2 leading-none">Please use the form below.</p>
            <p class="mt-2 text-xs leading-none text-zinc-600">Up to {{ settings.limitForNumberOfLinks }} files</p>
          </header>

          <form aria-label="File upload form" class="flex flex-col  mt-8 w-full max-w-[365px]">
            <div (click)="triggerFileInput()"
                 (dragleave)="onDragLeave($event)"
                 (dragover)="onDragOver($event)"
                 (drop)="onDrop($event)"
                 *ngIf="+settings.limitForNumberOfLinks > filesToUpload.length"
                 [ngClass]="{'border-2 border-gray-300 border-dashed': isDragging}"
                 class="flex w-full min-h-40 h-fit px-2 py-4 rounded border border-gray-400 flex-col justify-center items-center">

              <input #fileInput (change)="selectFile($event)" accept="image/*, video/*" class="hidden" multiple
                     name="fileInput" type="file"/>

              <div class="w-full h-[78px] flex justify-center items-center">
                <img [src]="'../../../../assets/TechLife.png'"
                     class="w-fit h-fit max-h-[78px] rounded"/>
              </div>

              <div class="flex-col justify-start items-center gap-2 flex">
                <div class="w-full text-center">
                <span
                  class="text-gray-900 text-center text-xs font-semibold leading-3">{{ 'createMedia.dropFiles' | translate }} </span>
                  <span
                    class="text-brand-bright-orange text-center text-xs font-semibold underline leading-3 cursor-pointer">{{ 'createMedia.selectFromLibrary' | translate }}</span>
                </div>
                <div
                  class="m-0 text-gray-800 text-center text-xs font-normal  leading-[1rem]">{{ 'createMedia.supports' | translate }}
                </div>
                <div class="m-0 text-gray-800 text-center text-xs font-normal  leading-[1rem]">Maximum file
                  size {{ settings.fileSize }} MB
                </div>
              </div>
            </div>

            <button (click)="upload()"
                    [disabled]="filesToUpload.length < 1"
                    class="mt-6"
                    color="primary"
                    mat-flat-button
                    type="button">Transfer
            </button>
          </form>
        </section>

        <section *ngIf="filesToUpload.length > 0 " class="mt-10 overflow-auto p-2 w-[365px]">
          <article *ngFor="let file of filesToUpload; let i = index"
                   class="flex flex-col justify-center pb-1 w-full border-b border-neutral-100">
            <div class="flex gap-10 justify-between items-center w-full">
              <div class="flex gap-2 justify-center items-center self-stretch my-auto whitespace-nowrap">
                <img *ngIf="file.format==='image'" [src]="file.filePreview" class="w-[50px]" loading="lazy"/>
                <div *ngIf="file.format==='video'"
                     class="max-w-[50px]  flex items-center justify-center text-center">
                  <mat-icon color="primary">play_circle</mat-icon>
                </div>
                <div class="flex flex-col justify-center self-stretch my-auto w-[102px]">
                  <div class="text-sm leading-none text-neutral-800">{{ file.name }}</div>
                  <div class="text-xs leading-none text-zinc-600">{{ file.fileSize | filesize }}</div>
                </div>
              </div>
              <button
                (click)="removeFile(i)"
                class="flex overflow-hidden gap-2 items-center self-stretch p-2 my-auto w-10 h-10 rounded">
                <app-icon [ngStyle]="{cursor:'pointer'}"
                          iconName="x"
                          size="md"
                          stroke="#202020">
                </app-icon>
              </button>
            </div>
          </article>
        </section>
      </ng-container>

      <ng-container *ngIf="invalidToken">
        <div class="flex flex-col items-center gap-2">
          <h3>The provided link is invalid</h3>
          <p>Please contact support for more info</p>
        </div>
      </ng-container>
    </div>
  </main>

  <ng-template #uploadSpinner>
    <div class="w-[300px] flex flex-col items-center justify-center mt-4">
      <p *ngIf="uploadProgress<100">Uploading files. Please don't close bowser window</p>
      <p *ngIf="uploadProgress===100">Upload Completed. You may close this window now.</p>
      <mat-progress-spinner
        [value]="uploadProgress"
        class="example-margin"
        color="primary"
        mode="determinate">
      </mat-progress-spinner>
    </div>
  </ng-template>
</div>

