<div class="flex items-center justify-between">
        <div>
                <h2 class="reach-section-title">{{ 'reach.vehicles.vehiclePerDay' | translate }}</h2>
        </div>
        <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                        <div class="flex flex-col">
                                <form [formGroup]="form"  class="p-2">
                                        <mat-form-field appearance="outline" class="w-full">
                                                <mat-label>{{'peopleCount.date' | translate}}</mat-label>
                                                <input matInput formControlName="date" [matDatepicker]="picker">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                </form>
                        </div>
                </mat-menu>
        </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between py-4">
        <div>
            <div>{{selectedDate}}</div>
        </div>
</div>
<mat-divider></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate" class="flex justify-center flex-col items-center mt-5">
        <img src="../../../../../assets/no-chart-data.png" />
        <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
        <canvas baseChart height="50"></canvas>
</div>
<ng-template #chartTemplate>
        <div>
                <canvas [data]="chartData"
                        baseChart
                        type='line'
                        [options]="chartOptions"
                        height="90vh">
                </canvas>
        </div>
</ng-template>
