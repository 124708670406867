import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { AssignPermissionsRequest, BaseService, PermissionsConfig, Permissions } from "@api/index";
import { ApiConfiguration } from "@api/api-configuration";

@Injectable({
    providedIn: 'root'
})

export class CustomerPermissionApi extends BaseService {
    private baseUrl = `${this.rootUrl}/customerPermissions`;

    constructor(
        config: ApiConfiguration,
        http: HttpClient
    ) {
        super(config, http);
    }

    assignPermissionsToCustomer(customerId: number, request: AssignPermissionsRequest): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${customerId}`, request);
    }

    getPermissionsForCustomer(customerId: number): Observable<Permissions[]> {
        const url = `${this.baseUrl}/${customerId}`;
        return this.http.get<Permissions[]>(url);
    }

    getPermissionsForCustomers(customerIds: number[]): Observable<PermissionsConfig> {
        let params = new HttpParams();
        customerIds.forEach(id => {
            params = params.append('customerIds', id.toString());
        });

        return this.http.get<PermissionsConfig>(`${this.baseUrl}/batch`, { params });
    }
}