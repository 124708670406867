import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { Breadcrumb } from 'src/app/models/breadcrumb';

@Component({
  selector: 'flow-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  @Input() isModal: boolean = false;

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbs$
    .subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    })
  }
  goToFolder(breadcrumbId:number){
    this.breadcrumbService.removeBreadcrumb(breadcrumbId);
  }
}
