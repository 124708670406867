import { Injectable, ComponentRef, ViewContainerRef, Type, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, takeUntil } from 'rxjs';
import { PopoverComponent } from './popover.component';
import { BookingOptions } from '@api/models/booking';

@Injectable({
  providedIn: 'root'
})
export class PopoverService implements OnDestroy {
  private popoverComponentRef: ComponentRef<PopoverComponent>;
  private closeSubject$ = new Subject<void>();
  private overlayRef: OverlayRef;
  viewRef!: ViewContainerRef;
  backdropClass = 'bg-gray-900/10';

  constructor(private overlay: Overlay) { }

  open(contentComponent: Type<any>, options: BookingOptions): Subject<void> {
    // Close any existing popover before opening a new one
    if (this.popoverComponentRef) {
      this.close();
    }

    // Create and attach the popover component
    this.createComponent(contentComponent);

    // Open the popover and set its target and options
    this.popoverComponentRef.instance.openPopover(options.target, options);

    // Set the close event handler directly on the instance
    this.popoverComponentRef.instance.closePopover = () => {
      this.close();
    };

    return this.closeSubject$;
  }

  private createComponent(contentComponent: Type<any>) {
    // Create the popover component and set its content and options
    this.popoverComponentRef = this.viewRef.createComponent(PopoverComponent);
    this.popoverComponentRef.instance.contentComponent = contentComponent;

    // Attach the overlay
    this.attachOverlay();

    // Append the popover component to the DOM
    const domElem = (this.popoverComponentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  private attachOverlay() {
    if (this.overlayRef) {
      return;
    }

    // Create and configure the overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: this.backdropClass,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });

    // Handle backdrop click event
    this.overlayRef.backdropClick()
      .pipe(takeUntil(this.closeSubject$))
      .subscribe(() => this.close());

    // Attach the popover component to the overlay
    const overlayPortal = new ComponentPortal(PopoverComponent);
    this.overlayRef.attach(overlayPortal);
  }

  private detachOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  close() {
    if (this.popoverComponentRef) {
      this.detachOverlay();
      this.popoverComponentRef.destroy();
      this.closeSubject$.next();
    }
  }

  ngOnDestroy() {
    this.closeSubject$.next();
    this.closeSubject$.complete();
  }
}
