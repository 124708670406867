import { ChangeDetectorRef, ViewRef } from '@angular/core';
import { Observable, Subject, defer } from 'rxjs';
import { finalize } from 'rxjs/operators';

export const indicateLoading = <T>(indicator: Subject<boolean>, cdr?: ChangeDetectorRef): (source: Observable<T>) => Observable<T> =>
  (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => {
      indicator.next(true);
      markForCheck(cdr);
    }),
    finalize(() => {
      indicator.next(false);
      markForCheck(cdr);
    })
  );

const prepare = <T>(callback: () => void): (source: Observable<T>) => Observable<T> =>
  (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });


const markForCheck = (cdr: ChangeDetectorRef): void => {
  if (cdr && !(cdr as ViewRef).destroyed) {
    cdr.markForCheck();
  }
};
