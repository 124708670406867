import { createReducer, on } from '@ngrx/store';
import { BookingRouterState } from '@app/shared/models/enums/bookingRouterState.enum';
import { BookingDetailsResponse, MediaForm } from '@app/admin/engagement/book/book-models';
import {
  BookActiveStep,
  BookCopyBookingActions,
  BookCreateActions,
  BookEditActions,
  BookFormActions,
  BookMediaActions,
  BookNewBookingActions,
  BookPlacements,
  BookResetActions,
  BookSetInfoActions,
} from './book.actions';

export interface BookingState {
  details?: BookingDetailsResponse | null;
  form?: { name: string };
  media?: MediaForm | null;
  name?: string;
  bookState?: BookingRouterState | null;
  placements?: any[];
  customerId?: number;
  engagementId?: number;
  engagementType?: number;
  activeStep?: number;
  presentationTime?: number;
  isEdit?: boolean;
  isCopy?: boolean;
  continueCreateMode?: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: BookingState = {
  details: null,
  form: null,
  media: null,
  name: null,
  bookState: null,
  customerId: null,
  placements: null,
  engagementId: null,
  engagementType: null,
  activeStep: null,
  presentationTime: null,
  isEdit: null,
  isCopy: null,
  continueCreateMode: null,
  loading: false,
  error: null,
};

export const bookReducer = createReducer(
  initialState,

  on(BookSetInfoActions, (state, { data }): BookingState => ({ ...state, ...data, loading: false, error: null })),

  on(BookEditActions.editBooking, (state): BookingState => ({ ...state, loading: true })),
  on(BookEditActions.editBookingSuccess, (state, { data, bookState }): BookingState => {
    return {
      ...state,
      details: data,
      bookState,
      placements: data?.placements,
      loading: false,
      error: null,
    };
  }),
  on(BookEditActions.editBookingFailure, (state, { error }): BookingState => ({ ...state, loading: false, error })),

  on(BookCreateActions.createBooking, (state): BookingState => ({ ...state, loading: true })),
  on(BookCreateActions.createBookingSuccess, (state, { data, bookState, isCopy, engagementId }): BookingState => {
    return {
      ...state,
      details: data?.details,
      bookState,
      isCopy,
      engagementId,
      loading: false,
      error: null,
    };
  }),
  on(BookCreateActions.createBookingFailure, (state, { error }): BookingState => ({ ...state, loading: false, error })),

  on(BookNewBookingActions.newBooking, (state): BookingState => ({ ...state, loading: true })),
  on(
    BookNewBookingActions.newBookingSuccess,
    (state, { bookState, engagementId, engagementType, continueCreateMode }): BookingState => {
      return {
        ...state,
        bookState,
        engagementId,
        engagementType,
        continueCreateMode,
        loading: false,
        error: null,
      };
    }
  ),
  on(BookNewBookingActions.newBookingFailure, (state, { error }): BookingState => ({ ...state, loading: false, error })),

  on(BookCopyBookingActions.copyBooking, (state): BookingState => ({ ...state, loading: true })),
  on(BookCopyBookingActions.copyBookingSuccess, (state, { data: engagementId }): BookingState => {
    return {
      ...state,
      engagementId,
      loading: false,
      error: null,
    };
  }),
  on(BookCopyBookingActions.copyBookingFailure, (state, { error }): BookingState => ({ ...state, loading: false, error })),

  on(BookMediaActions.loadBookingMedia, (state): BookingState => ({ ...state, loading: true })),
  on(BookMediaActions.loadBookingMediaSuccess, (state, { data }): BookingState => {
    return {
      ...state,
      media: data,
      loading: false,
      error: null,
    };
  }),
  on(BookMediaActions.loadBookingMediaFailure, (state, { error }): BookingState => ({ ...state, loading: false, error })),

  on(BookFormActions, (state, { name }): BookingState => {
    return {
      ...state,
      form: { name },
      loading: false,
      error: null,
    };
  }),

  on(BookPlacements, (state, { placements }): BookingState => {
    return {
      ...state,
      placements,
      loading: false,
      error: null,
    };
  }),

  on(BookResetActions, (): BookingState => ({ ...initialState })),
  on(BookActiveStep, (state, { activeStep }): BookingState => ({ ...state, activeStep })),
);
