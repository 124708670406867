import { TargetGroupEnum } from "@api/index";

export const TargetGroupEnumTranslations = {
  [TargetGroupEnum.MenUnder18]: 'global.menUnder18',
  [TargetGroupEnum.Men18to24]: 'global.men18to24',
  [TargetGroupEnum.Men25to34]: 'global.men25to34',
  [TargetGroupEnum.Men35to44]: 'global.men35to44',
  [TargetGroupEnum.Men45to54]: 'global.men45to54',
  [TargetGroupEnum.Men55to64]: 'global.men55to64',
  [TargetGroupEnum.MenAbove65]: 'global.menAbove65',
  [TargetGroupEnum.WomenUnder18]: 'global.womenUnder18',
  [TargetGroupEnum.Women18to24]: 'global.women18to24',
  [TargetGroupEnum.Women25to34]: 'global.women25to34',
  [TargetGroupEnum.Women35to44]: 'global.women35to44',
  [TargetGroupEnum.Women45to54]: 'global.women45to54',
  [TargetGroupEnum.Women55to64]: 'global.women55to64',
  [TargetGroupEnum.WomenAbove65]: 'global.womenAbove65'
};
