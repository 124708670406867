<form *ngIf="isAdmin" [formGroup]="filterForm" class="category-form flex justify-between" (ngSubmit)="onSubmit()">
  <div class="form-field-wrapper">
    <mat-label>{{ "manage.filters.customers" | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="customerIds"
                  placeholder="{{'peopleCount.customer' | translate }}"
                  multiple>
        <mat-option *ngFor="let customer of customers"
                    [value]="customer.id">
          {{ customer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button (click)="onSubmit()"
          class="h-10 p-2 mt-8 bg-brand-chathams-blue rounded justify-start items-center gap-2 inline-flex cursor-pointer">
      <app-icon fill="none"
            [stroke]="'#fff'"
            iconName="file-check-02"
            size="md">
      </app-icon>
      <div class="text-gray-50 text-xs font-medium leading-[18px]">{{ "global.create" | translate }}</div>
  </button>
</form>


<div [hidden]="dataSource?.data?.length  == 0">
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalCount"
    [pageIndex]="params.pageNumber"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="params.pageSize"
    showFirstLastButtons>
  </mat-paginator>
  <table (matSortChange)="handleSortEvent($event)"
         [dataSource]="dataSource"
         class="table-hover"
         mat-table
         matSort
         matSortActive="name"
         matSortDirection="asc"
         [matSortDisableClear]="true">

    <ng-container matColumnDef="id">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.id' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell> {{ category.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.categoryName' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell> {{ category.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="doNotCountInStatistics">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.dontCountInStat' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngIf="category.doNotCountInStatistics; then greenCheck else redX"></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="shouldSendSmsNotifications">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.sendSms' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngIf="category.shouldSendSmsNotifications; then greenCheck else redX "></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="contactPersons">
      <th *matHeaderCellDef
          mat-header-cell
          > {{ 'poi.category.table.contactPersons' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngFor="let contact of category.contactPersons">
          <div> {{ contact.name }}
            <span *ngIf="contact.channels?.length > 0"> {{
                'poi.category.form.channels' |
                  translate
              }}</span>
            <span *ngFor="let chanel of contact.channels ,let last = last"> {{ chanel.name }} <span
              *ngIf="!last">,</span>
            </span>
          </div>
        </ng-container>
      </td>
    </ng-container>


    <ng-container matColumnDef="shouldSendSmsForInboundScreens">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.inbound' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngIf="category.shouldSendSmsForInboundScreens; then greenCheck else redX "></ng-container>

      </td>
    </ng-container>

    <ng-container matColumnDef="shouldSendSmsForOutboundScreens">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.outbound' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngIf="category.shouldSendSmsForOutboundScreens; then greenCheck else redX "></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="shouldSendSmsForNotApplicableScreens">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.otherScreensSensors' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <ng-container *ngIf="category.shouldSendSmsForNotApplicableScreens; then greenCheck else redX "></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="maxSms">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'poi.category.table.maxNumOfSms' | translate }}
      </th>
      <td *matCellDef="let category"
          mat-cell>
        <div *ngIf="category.maxMinutes">
          {{ category.maxSms }} {{ "poi.category.table.sms" |translate }} / {{ category.maxMinutes }}
          {{ "poi.category.table.minutes" | translate }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef
          class="actions-col"
          mat-header-cell></th>
      <td *matCellDef="let category"
          mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *flowHasPermission="['UpdatePoiCategoriesInsights']; disabled true;" (click)="openCreateCategoryModal($event, category.id, category.customerId)" mat-menu-item>{{ 'global.edit' | translate }}</button>
          <button *flowHasPermission="['UpdatePoiCategoriesInsights']; disabled true;" (click)="onDeleteClick($event, category)" mat-menu-item>{{ 'global.delete' | translate }}</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>

    <tr (dblclick)="openCreateCategoryModal($event, category.id, category.customerId)"
        *matRowDef="let category; columns: displayedColumns"
        class="hover:bg-gray-200 cursor-pointer"
        mat-row></tr>
  </table>
</div>

<div *ngIf="dataSource?.data?.length === 0"
     class="no-data-selected-container">

    <img src="../../../../../assets/no-data.png"/>
    <p>{{'global.noDataFound' | translate }}</p>
    <p class="no-data-subtitle">{{(filterForm.getRawValue().customerIds && filterForm.getRawValue().customerIds.length > 0 ? 'poi.category.noCategoryData' : 'poi.category.pleaseSelectCategory') | translate}}</p>

</div>


<ng-template #redX>
  <app-icon
    fill="none"
    iconName="red-x"
    size="md"></app-icon>
</ng-template>

<ng-template #greenCheck>
  <app-icon
    fill="none"
    iconName="green-check-mark"
    size="md"></app-icon>
</ng-template>
