<div class="page-header" xmlns="http://www.w3.org/1999/html">
  <h1 class="page-title">{{(data.type == 'ai' ? 'screens.changeAiVersion' : 'screens.changeAdvertiserVersion') | translate}}</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full form-field-readonly" *ngIf="data.type == 'ai'">
      <mat-label>{{'editScreen.form.aiVersion' | translate}}</mat-label>
      <mat-select formControlName="aiVersion">
        <mat-option (onSelectionChange)="selectAI(version)" *ngFor="let version of aiVersions"
                    [value]="version">
          {{version}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full form-field-readonly"  *ngIf="data.type == 'ad'">
      <mat-label>{{'editScreen.form.adsVersion' | translate}}</mat-label>
      <mat-select formControlName="advertiserVersion">
        <mat-option (onSelectionChange)="selectAd(version)" *ngFor="let version of advertiserVersions"
                    [value]="version">
          {{version}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <div class="mat-dialog-actions dialog-action">
    <button type="button"
            mat-stroked-button
            class="btn-cancel"
            (click)="onCancel()"
    >
      {{'global.cancel' | translate}}
    </button>
    <button [disabled]="form.invalid"
            mat-flat-button
            class="btn-save"
            color="primary"
            (click)="onSaveClick()">
      <mat-icon class="mr-2"
                inline="true">save</mat-icon>{{'global.save' | translate}}
    </button>
  </div>

</form>
