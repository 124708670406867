<div #dropdownContainer class="dropdown-container">
    <div class="text-neutral-800 text-sm font-normal font-['Inter'] leading-[0.875rem] mb-[0.5rem]">
        {{label}}
        <span *ngIf="control?.hasValidator(requiredValidator)">*</span>
    </div>
    <div #dropdownToggle
         (click)="toggleDropdown()"
         [ngClass]="control?.invalid && (control?.dirty || control?.touched) ? 'has-error' : 'border-zinc-300'"
         class="dropdown-container-input">
        <div class="w-full text-[0.813rem] font-normal font-['Inter'] leading-[1.5rem] truncate"
             [ngClass]="disabled ? 'disabled' : (selectedNames.length > 0 ? 'text-neutral-800 cursor-pointer' : 'text-zinc-400 cursor-pointer')">
            {{selectedNames.length > 0 ? (selectedNames.join(', ') | translate) : placeholder }}
        </div>
        <div class="w-6 h-6 relative">
            <app-icon fill="#595959"
                      [ngStyle]="{cursor:'pointer'}"
                      iconName="arrow-down"
                      size="md">
            </app-icon>
        </div>
    </div>
    <div #dropdownMenu
         class="dropdown-menu"
         *ngIf="dropdownOpen && options && options.length > 0">
        <!-- <input *ngIf="enableSearch" type="text" placeholder="Search..." (input)="filterOptions($event)" class="w-full p-2 border-b border-gray-300 outline-none"> -->
        <div *ngIf="multiSelect"
             class="py-[0.25rem] flex flex-col gap-1"
             (click)="toggleSelectAll()">
            <div class="dropdown-item">
                <div class="w-[1.313rem] h-[1.313rem] rounded border border-zinc-300"
                     [ngClass]="selectAll ? 'bg-sky-800' : 'bg-white'">
                    <app-icon *ngIf="selectAll"
                              [ngStyle]="{cursor:'pointer'}"
                              stroke="#FAFAFA"
                              width="1.25rem"
                              height="1.25rem"
                              iconName="checked">
                    </app-icon>
                </div>
                <span class="flex-grow">{{'global.all' | translate}}</span>
            </div>
            <div class="parent-container px-4">
                <div class="all-separator"></div>
            </div>
        </div>
        <div class="dropdown-item-container">
            <ng-container *ngFor="let option of filteredOptions">
                <div *flowHasPermission="option.permissions; disabled true;"
                    class="dropdown-item"
                    (click)="selectOption(option)">
                    <div *ngIf="multiSelect"
                         class="w-[1.313rem] h-[1.313rem] rounded border border-zinc-300"
                         [ngClass]="option.selected ? 'bg-sky-800' : 'bg-white'">
                        <app-icon *ngIf="option.selected"
                                  [ngStyle]="{cursor:'pointer'}"
                                  stroke="#FAFAFA"
                                  width="1.25rem"
                                  height="1.25rem"
                                  iconName="checked">
                        </app-icon>
                    </div>
                    <span class="flex-grow">{{ option.name | translate }}</span>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="error-message">
        <ng-content select="[error-messages]"></ng-content>
    </div>
</div>