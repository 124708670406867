<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ (editMode ? "screens.screenGroup.editTitle" : "screens.createGroup") | translate }}</h1>
</div>

<flow-stepper *ngIf="!(loading$.init  | async) || !editMode"
              [steps]="steps"
              [(activeStep)]="selectedIndex"
              (activeStepChange)="selectedTabChange($event)"
              [isEdit]="editMode">


  <div header class="h-10 justify-end items-start inline-flex gap-2">
    <button class="action-button border-neutral-100" [routerLink]="['/screens']">
      <app-icon iconName="x-square"
                stroke="#202020"
                size="md"
                [ngStyle]="{'cursor': 'pointer'}"></app-icon>
      <div class="action-button-text text-black">
        {{'global.cancel' | translate}}
      </div>
    </button>
    <button
      [disabled]="(loading$.save | async) || form.invalid || detailsForm.invalid"
      class="action-button"
      [ngClass]="form.invalid || detailsForm.invalid ? 'border-neutral-100' : 'border-brand-chathams-blue'"
      (click)="onSaveClick()">
      <app-icon iconName="save"
                [stroke]="form.invalid || detailsForm.invalid ? '#EBEBE4' : '#1f5792'"
                fill="#fff"
                size="md"
                [ngStyle]="{'cursor': 'pointer'}"></app-icon>
      <span class="action-button-text"
            [ngClass]="form.invalid || detailsForm.invalid ? 'text-[#EBEBE4]' : 'text-brand-chathams-blue'">
          {{(isEdit ? 'global.saveChanges' : 'global.add') | translate}}
        </span>
    </button>
  </div>

  <flow-tabs-item [error]="generalInfoValidationErrors.length > 0">
    <form class="w-full" [formGroup]="form">
      <div *ngIf="generalInfoValidationErrors?.length > 0"
           class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50"
           role="alert">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{ 'createScreen.validationErrors.requirementsMet' | translate }}</span>
        </div>
        <ul *ngFor="let validation of generalInfoValidationErrors" class="mt-1.5 list-disc list-inside">
          <li>{{ validation }}</li>
        </ul>
      </div>
      <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
        <div class="justify-start items-center gap-2 flex">
          <app-icon iconName="annotation-info"
                    size="md"
                    class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                    stroke="#598fae"></app-icon>
        </div>
        <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}
        </div>
      </div>

      <div class="grid grid-cols-3 gap-x-10 input-override">

        <div class="grid grid-cols-1 gap-4 col-span-1">

          <div class="flex flex-col">
            <mat-label>{{'screens.screenGroup.form.groupName' | translate}} *</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <input matInput
                     type="text"
                     formControlName="humanUniqueIdentifier">
            </mat-form-field>
          </div>

          <div class="flex flex-col" *ngIf="isAdmin">
            <mat-label>{{'createScreen.form.customer' | translate}}</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-select formControlName="customerId">
                <mat-option *ngFor="let customer of customers"
                            (onSelectionChange)="updateScreens($event)"
                            [value]="customer.id">
                  {{customer.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex flex-col">
            <mat-label>{{'createScreen.form.information' | translate}}</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <textarea matInput
                     formControlName="information"
                     type="text"
              ></textarea>
            </mat-form-field>
          </div>


        </div>

        <div class="grid grid-cols-2 gap-4 col-span-2">
          <div class="row row-col text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
            <p>{{ 'screens.screenGroup.infoNote' | translate }}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button
                (click)="onNextTab(1)"
                class="btn-save"
                color="primary"
                mat-flat-button>
                {{ "global.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </flow-tabs-item>
  <flow-tabs-item [error]="detailsValidationErrors.length > 0">
    <form [formGroup]="detailsForm">
      <div *ngIf="detailsValidationErrors?.length > 0"
           class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50"
           role="alert">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{ 'createScreen.validationErrors.requirementsMet' | translate }}</span>
        </div>
        <ul *ngFor="let validation of detailsValidationErrors" class="mt-1.5 list-disc list-inside">
          <li>{{ validation }}</li>
        </ul>
      </div>
      <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
        <div class="justify-start items-center gap-2 flex">
          <app-icon iconName="annotation-info"
                    size="md"
                    class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                    stroke="#598fae"></app-icon>
        </div>
        <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'createScreen.details' | translate}}
        </div>
      </div>

      <div class="grid grid-cols-3 gap-x-10 input-override">
        <div class="grid grid-cols-1 gap-4 col-span-1">

          <div class="flex flex-col">
            <mat-label>{{'screens.screenGroup.form.screenOrientation' | translate}}  *</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-select formControlName="orientation">
                <mat-option *ngFor="let orientation of orientations"
                            (onSelectionChange)="getScreensWithOrientation($event, orientation.id)"
                            [value]="orientation.id">
                  {{orientation.name | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex flex-col">
            <mat-label>{{'screens.screenGroup.form.selectScreens' | translate}}</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-select formControlName="screenIds"
                          multiple>
                <div class="select-deselect"
                     (click)="selectDeselectAllScreens(true)">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            size="md"
                            iconName="check"></app-icon>
                  <span class="ml-1">{{'global.selectAll' | translate}}</span>
                </div>
                <mat-divider></mat-divider>
                <div class="select-deselect"
                     (click)="selectDeselectAllScreens(false)">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            size="md"
                            iconName="close"></app-icon>
                  <span class="ml-1">{{'global.deselectAll' | translate}}</span>
                </div>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let screen of screensWithOrientation"
                            [disabled]="!screenGroup?.canBeDeleted && editMode"
                            [value]="screen.id">
                  {{screen.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-hint class="mt-0.5">
              <div>{{'screens.screenGroup.hint' | translate}}</div>
              <div *ngIf="!screenGroup?.canBeDeleted && editMode" style="color: red;">{{'screens.screenGroup.errorHint' | translate}}</div>

            </mat-hint>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 col-span-2">
          <div class="row row-col text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
            <p>{{ 'screens.screenGroup.detailsNote' | translate }}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button
                (click)="onNextTab(0)"
                class="btn-cancel mr-3"
                mat-stroked-button>
                {{ "global.back" | translate }}
              </button>
            </div>
          </div>
        </div>

      </div>

    </form>

  </flow-tabs-item>
</flow-stepper>

