<div class="w-full flex" [ngClass]="{'background-style' : !hideSection}">
  <div class="flex flex-wrap gap-4">
    <form [formGroup]="statisticsService.form" class="input-override">
      <ng-container *ngFor="let input of initData | keyvalue : returnZero" >
        <div *ngIf="input.value.type != 'checkbox' && !(input.value.type == 'datepicker' || input.value.type == 'calendar') && !statisticsService.form.controls[input.key]?.disabled" class="mr-5 mb-4">
          <mat-label>
            <span>{{ "reports.form."+ input.key | translate }}</span>
            <span *ngIf="statisticsService.form.controls[input.key]?.errors && statisticsService.form.controls[input.key]?.errors.required"> *</span>
          </mat-label>
          <mat-form-field (click)="preventDefault($event)"
                          class="input collapsed-input inline"
                          [ngClass]="{'fade-in':showAnimation&&input.value.formControlName==='mediaFile'}"
                          appearance="outline">
            <!-- Dropdown type START -->
            <mat-select *ngIf="input.value.type == 'dropdown'"
                        [formControlName]="input.value.formControlName"
                        [panelClass]="getPanelClass(input.value)"
                        [multiple]="input.value.isMultipleDropdown"
                        (selectionChange)="onFilterChange($event, input.value.formControlName)"
                        placeholder="{{input.value.label | translate}}">
              <div *ngIf="input.value.hasSelectAll"
                    class="select-deselect"
                    (click)="selectAllItems(statisticsService.form, input.value.formControlName)">
                <app-icon [ngStyle]="{cursor:'pointer'}"
                          size="md"
                          iconName="check"></app-icon>
                <span class="ml-1">{{'global.selectAll' | translate}}</span>
              </div>
              <mat-divider *ngIf="input.value.hasSelectAll"></mat-divider>
              <div *ngIf="input.value.hasSelectAll"
                    class="select-deselect"
                    (click)="clearAllItems(statisticsService.form, input.value.formControlName)">
                <app-icon [ngStyle]="{cursor:'pointer'}"
                          size="md"
                          iconName="close"></app-icon>
                <span class="ml-1">{{'global.deselectAll' | translate}}</span>
              </div>
              <mat-divider *ngIf="input.value.hasSelectAll"></mat-divider>
              <input matInput
                      *ngIf="input.value.search"
                      class="search-input"
                      placeholder="Search"
                      [formControlName]="'searchTerm'+input.value.formControlName"
                      (keydown)="stopEventPropagation($event)" />
              <mat-divider *ngIf="input.value.search"></mat-divider>
              <div *ngIf="input.value.search && input.value.formControlName==='bookingNumber' || input.value.formControlName==='bookingNumbers'" class="pl-3">
                <span class="text-sm">{{'exposures.lastSearchResults' | translate:{count: input.value.data.length} }}</span>
                <br>
                <i class="text-xs text-[#595959]">{{ 'exposures.useSearchForMore' | translate }}</i>
                <mat-divider></mat-divider>
              </div>
              <mat-option *ngFor="let data of input.value.filteredData"
                          [value]="data.id">
                <div id="JSONContainer"
                      *ngIf="!input.value.hasParams"
                      [innerHTML]="input.value.isTranslatable ? (data.name == 'reports.form.includeDeletedPOI' ? '<strong>' + (data.name | translate) + '</strong>' : (data.name | translate)) : data.name"></div>
                <div id="timeframe"
                      *ngIf="input.value.hasParams">{{ data.name.split('-')[1] ? (data.name.split('-')[0] | translate: { month:
                  data.name.split('-')[1] | translate }) : data.name | translate }}</div>
              </mat-option>
              <div class="pl-3 text-sm text-[#1F5792] italic underline cursor-pointer" *ngIf="input.value.formControlName === 'bookingNumber'  || input.value.formControlName === 'bookingNumbers'" (click)="loadMoreItems()">
                <mat-divider></mat-divider>
                <span class="pt-3 pb-3">{{ 'exposures.showMore' | translate }}</span>
              </div>
            </mat-select>
            <!-- Dropdown type END -->
            <!-- Basic input type START -->
            <input *ngIf="input.value.type == 'text'"
                    matInput
                    [type]="input.value.type"
                    [formControlName]="input.value.formControlName">
            <!-- Basic input type END -->
          </mat-form-field>
          <!-- Calendar type START -->
        </div>
        <div>
          <flow-datetime-picker *ngIf="input.value.type == 'calendar' || (input.value.type == 'datepicker' && ((statisticsService.form.get('timeframe') && statisticsService.form.get('timeframe').value == 5) || !statisticsService.form.get('timeframe')))"
                                [input]="input.value"
                                (filterChangeEvent)="onFilterChange(null, $event)">
          </flow-datetime-picker>
        </div>
        <!-- Calendar type END -->
        <!-- Checkbox type START -->
        <mat-checkbox *ngIf="input.value.type == 'checkbox'"
                      class="checkbox"
                      color="primary"
                      [formControlName]="input.value.formControlName"
                      (change)="onFilterChange(null, input.value.formControlName)">
          {{ input.value.label | translate }}
        </mat-checkbox>
        <!-- Checkbox type END -->
      </ng-container>
      <mat-checkbox *ngIf="!isInFolder && showFolder"
                    [checked]="isChecked"
                    color="primary"
                    class="mr-3 show-folder-class"
                    (change)="_showHideFolderClick($event)">
        {{'media.showFolders' | translate}}
      </mat-checkbox>
    </form>
  </div>
  <div class="flex" [ngClass]="{'gap-2': !downloadReportDisableBtn}" *ngIf="!hideSection">
    <div class="pt-8">
      <div (click)="createReport()"
           class="h-10 p-2 bg-brand-chathams-blue rounded justify-start items-center gap-2 inline-flex"
           [ngClass]="!statisticsService.form.valid ? 'disabled' : 'cursor-pointer'">
          <app-icon fill="none"
                [stroke]="'#fff'"
                iconName="file-check-02"
                size="md">
          </app-icon>
          <div class="text-gray-50 text-xs font-medium leading-[18px]">{{ "global.create" | translate }}</div>
      </div>
    </div>
    <div class="pt-8">
      <ng-container *ngIf="!downloadReportDisableBtn">
        <div *flowHasPermission="permissions; disabled true"
              (click)="downloadReport()"
              class="reports-download-button">
          <app-icon fill="none"
                [stroke]="'#fff'"
                iconName="download-02"
                size="md">
          </app-icon>
          <div class="reports-download-button-text">.xls</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

