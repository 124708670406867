import { Component } from '@angular/core';


@Component({
  selector: 'flow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

}
