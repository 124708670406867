import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { IdName } from '@api/models/idname';
import { MediaFilterRequest } from '@api/models/medias/medias';
import { AdminApi } from '@api/services/admin-api';
import { BookingApi } from '@api/services/bookings-api';
import { StatisticsApi } from '@api/services/statistics-api';
import { BookingsFiltersComponent } from '@app/admin/engagement/bookings/bookings-filters/bookings-filters.component';
import { AuthService } from '@app/core/auth/auth.service';
import { EnumService } from '@app/core/services/enum.service';
import { MediaService } from '@app/core/services/media.service';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { DateTime } from 'luxon';
import { Observable, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'flow-media-filters',
  templateUrl: './media-filters.component.html',
  styleUrls: ['./media-filters.component.scss']
})
export class MediaFiltersComponent extends unsubscribeMixin() implements OnInit, OnDestroy {
  destroy$ = new Subject();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  showPeriod = true;
  customers: IdName[] = [];
  clients$: Observable<{ id: number, name: string }[]>;
  tags$: Observable<{ id: number, name: string }[]>;
  bookingsTotal = 0;
  disableShow = false;
  customerId: number;
  today: string = new Date().toISOString();
  types: IdName[] = [];
  isReady: boolean = false;

  form = this.fb.group({
    currentMonth: [null],
    filteredStartDate: [null],
    filteredEndDate: [null],
    text: [''],
    filterTypes: [null],
    customerIds: [null],
    clientIds: [null],
    tags: [null],
    isShowFolder: [true]
  })

  constructor(
    private fb: FormBuilder,
    private statisticsApi: StatisticsApi,
    private bookingApi: BookingApi,
    private adminApi: AdminApi,
    private mediaService: MediaService,
    private authService: AuthService,
    private enumService: EnumService,
    public dialogRef: MatDialogRef<BookingsFiltersComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCustomers();
    this.mediaService.selectedFilters$
      .pipe(take(1))
      .subscribe((filters: MediaFilterRequest) => {
        this.form.patchValue(filters)
      })
  }

  formUpdate() {
    this.form.valueChanges.pipe(
      startWith({
        filteredStartDate: DateTime.local().toISODate(),
        filteredEndDate:  DateTime.local().toISODate(),
        isShowFolder: true
      }),
      switchMap((filters: any) => this.bookingApi.getBookingTotal(filters)),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (total: number) => {
        this.bookingsTotal = total
      },
      error: (error) => { console.log(error) }
    })
  }

  clearEndDate() {
    this.form.get('filteredEndDate').reset()
  }

  // ==== Workaround to bind the selected value because mat-calendar doesn't have ControlValueAccessor
  get filteredStartDate(): string | undefined {
    return this.form?.get('filteredStartDate')?.value;
  }

  set filteredStartDate(value: string | undefined) {
    this.form?.get('filteredStartDate')?.setValue(value);
    this.form?.get('currentMonth')?.setValue(value);
  }

  get filteredEndDate(): string | undefined {
    return this.form?.get('filteredEndDate')?.value;
  }

  set filteredEndDate(value: string | undefined) {
    this.form?.get('filteredEndDate')?.setValue(value);
    this.form?.get('currentMonth')?.setValue(value);
  }

  getCustomers() {
    const user = this.authService.userData;
    this.customerId = user?.currentCustomerId || user?.customerId;
    const callFunction = this.customerId ? this.adminApi.getCustomerById(this.customerId) : this.statisticsApi.getCustomers();
    callFunction.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((customer: any) => {
        this.types = this.enumService.mediaTypes;
        this.customers = this.customerId ? [customer] : customer;

        this.form.get('customerIds').enable();
        this.form.get('filterTypes').enable();
        if (this.customerId) {
          this.form.get('customerIds').setValue([this.customerId]);
          this.form.get('customerIds').disable();
          this.onCustomerSelect([this.customerId]);
        } else {
          this.onCustomerSelect(this.customers.map(c => c.id));
        }
      })
  }

  onCustomerSelect(customerIds) {
    const customers = customerIds.value && (customerIds.value.length > 0 ? customerIds.value : this.customers.map(c => c.id)) || customerIds;
    this.clients$ = this.onSelectChange('clientIds', this.statisticsApi.getClients(customers));
    this.tags$ = this.onSelectChange('tags', this.statisticsApi.getTags(customers));
  }

onSelectChange(property: string, apiCall: Observable<any>) {
    return apiCall.pipe(
      tap((res) => {
        const input = this.form?.get(property);

        if (res.length > 0) {
          input.enable();
        } else {
          input.reset();
          input.disable();
        }
      })
    )
  }

  togglePeriod() {
    this.showPeriod = !this.showPeriod;
  }

  clearAll() {
    this.mediaService.setFilters(null);
    this.form.reset({
      currentMonth: null,
      filteredStartDate: null,
      filteredEndDate: null,
      text: '',
      customerIds: this.customerId ? [this.customerId] : null,
      filterTypes: null,
      clientIds: null,
      tags: null,
      isShowFolder: true
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.mediaService.setFilters(this.form.value);
    this.dialogRef.close(this.form.value);
  }

  override ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
