import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateConfigService } from '@core/translate-config.service';
import { BookValidityModalAction, BookValidityModalData } from '../../book-models';

@Component({
  selector: 'flow-book-validity-modal',
  templateUrl: './book-validity-modal.component.html',
})
export class BookValidityModalComponent implements OnInit {
  disable: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BookValidityModalData,
    public dialogRef: MatDialogRef<BookValidityModalComponent>,
    public dialog: MatDialog,
    public translateService: TranslateConfigService
  ) {}

  ngOnInit(): void {
    this.disable = this.data.formInvalid;
  }

  onSave(): void {
    this.closeDialog(BookValidityModalAction.SAVE, this.data.save);
  }

  onDiscard(): void {
    this.closeDialog(BookValidityModalAction.DISCARD, this.data.discard);
  }

  onCancel(): void {
    this.closeDialog(BookValidityModalAction.CANCEL);
  }

  private closeDialog(action: BookValidityModalAction, actionFunction?: Function): void {
    this.dialogRef.close(action);

    if (actionFunction) {
      actionFunction();
    }
  }
}
