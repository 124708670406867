import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { StatisticsApi } from 'src/_api';
import { TotalNumberOfExposuresResponse } from 'src/_api/models/exposures/TotalNumberOfExposures.model';
import { EnumService } from 'src/app/core/services/enum.service';
import { StatisticsFilterExposures } from 'src/app/models/statistics-filter-exposures';
import { StatisticsService } from '../../statistics.service';


@Component({
  selector: 'flow-view-chart-total-exposures',
  templateUrl: './view-chart-total-exposures.component.html',
  styleUrls: ['./view-chart-total-exposures.component.scss'],
  providers: [DatePipe]
})
export class ViewChartTotalExposuresComponent implements OnChanges {
  @Input() filter: StatisticsFilterExposures;
  @Input() totalNumberOfExposures: TotalNumberOfExposuresResponse;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public chartPlugins = [DataLabelsPlugin];
  public weekDays = [];
  public chartData: ChartData<'bar', number[], string | string[]> = null;
  public range = new FormGroup({
    start: new FormControl<DateTime | null>(DateTime.local().startOf('week')),
    end: new FormControl<DateTime | null>(DateTime.local().endOf('week')),
  });
  public rangeCompare = new FormGroup({
    start: new FormControl<DateTime | null>(null),
    end: new FormControl<DateTime | null>(null),
  });

  public minDate;
  public maxDate;
  public disableWeekToCompare = false;
  isMonth: boolean = false;
  compareChart: boolean = false;
  numberOfOriginalWeek: number;
  numberOfComparedWeek: number;
  firstWeek: string;
  secondWeek: string;
  weekStartDate: string;
  weekEndDate: string;
  noChartData: boolean = false;

  public chartOptions: ChartConfiguration['options'] = {};

  constructor(protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected translateService: TranslateService,
    protected enumService: EnumService,
    private datePipe: DatePipe) {
    this.weekDays = enumService.weekDays;
    this.setChartOptions();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.totalNumberOfExposures)
      this.updateChartData();
  }
  updateChartData() {
    if (this.totalNumberOfExposures) {
      this.calcWeekStartEnd(this.totalNumberOfExposures.startDate);
      
      this.disableWeekToCompare = (Object?.keys(this.totalNumberOfExposures.exposedWeeks).length < 2) ?? false;
      this.maxDate = new Date(this.totalNumberOfExposures.endDate);
      this.minDate = new Date(this.totalNumberOfExposures.startDate);
      this.range.setValue({
        start: DateTime.fromISO(this.minDate.toISOString()).startOf('week'),
        end: DateTime.fromISO(this.maxDate.toISOString()).endOf('week')
      });
      this.noChartData = false;
    } else {
      this.disableWeekToCompare = true;
      this.maxDate = null;
      this.minDate = null;
      this.range = new FormGroup({
        start: new FormControl<DateTime | null>(DateTime.local().startOf('week')),
        end: new FormControl<DateTime | null>(DateTime.local().endOf('week')),
      });
      this.noChartData = true;
    }

    const weekNumber = this.range.value?.start?.weekNumber;
    const weekNumberCompare = this.rangeCompare.value?.start?.weekNumber;
    const dataWeek = !this.totalNumberOfExposures ? [] : this.totalNumberOfExposures.exposedWeeks[weekNumber]?.map(entry => entry.numberOfVisitors);
    const dataSetWeek = {
      label: `${this.datePipe.transform(this.totalNumberOfExposures?.startDate, 'yyyy-MM-dd')} - ${this.datePipe.transform(this.totalNumberOfExposures?.endDate, 'yyyy-MM-dd')}`,
      data: dataWeek,
      backgroundColor: '#FA6200',
      barPercentage: 0.5
    }
    this.chartData = {
      labels: this.weekDays.map((w) => this.translateService.instant(w.name)),
      datasets: [dataSetWeek]
    }
    if (weekNumberCompare) {
      const dataWeekToCompare = !this.totalNumberOfExposures ? [] : this.totalNumberOfExposures.exposedWeeks[weekNumberCompare]?.map(entry => entry.numberOfVisitors);
      const dataSetWeekToCompare = {
        label: this.translateService.instant('exposures.totalNumberOfExposuresForWeekToCompare'),
        data: dataWeekToCompare,
        backgroundColor: 'rgb(245, 169, 181)',
        barPercentage: 0.5
      }
      this.chartData.datasets.push(dataSetWeekToCompare);
      this.chart.update();
    }
  }

  calcWeekStartEnd(startDate) {
    const date = DateTime.fromISO(startDate);
    this.weekStartDate = date.startOf('week').toISO();
    this.weekEndDate = date.endOf('week').toISO();
  }

  onWeekSelected() {
    this.updateChartData()
  }

  onWeekCompareSelected() {
    this.updateChartData();
    this.compareChart = true;
    this.numberOfOriginalWeek = this.range.value.start.weekNumber;
    this.numberOfComparedWeek = this.rangeCompare.value.start.weekNumber;
    this.firstWeek = `${this.translateService.instant('peopleCount.week')} ${this.numberOfOriginalWeek}: ${this.range.value.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this.range.value.end.toFormat('yyyy-MM-dd')}`;
    this.secondWeek = `${this.translateService.instant('peopleCount.week')} ${this.numberOfComparedWeek}: ${this.rangeCompare.value.start.startOf('week').toFormat('yyyy-MM-dd')} - ${this.rangeCompare.value.end.toFormat('yyyy-MM-dd')}`;
  }

  onWeekMonthToggleChange(value: string) {
    this.isMonth = value === 'month' ? true : false;
  }

  private setChartOptions() {
    this.chartOptions = {

      scales: {
        x: {
          type: 'category', // This line is important for X-axis to recognize labels
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        },
        y: {
          grace: '5%',
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        }

      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 3,
            boxHeight: 30,
            textAlign: 'left',
            padding: 4
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    }
  }
}
