import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(private authService: AuthService) { }

  hasPermissions (permissions: string[]) {
    const user = this.authService.userData;
    const availablePermissions = user.permissions.map(item => item.trim());
    let hasPermission = false;

    if (availablePermissions) {
      for (const checkPermission of permissions) {
        const permissionFound = availablePermissions.find(x => x.toLowerCase() === checkPermission.toLowerCase());

        if (permissionFound) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
      }
    }

    return hasPermission;
  }
}
