import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  UTCToLocalDateTime(datetime:any){
    const utcDate = new Date(datetime);
    const offsetMinutes = utcDate.getTimezoneOffset();
    const localTimeMilliseconds = utcDate.getTime() - (offsetMinutes * 60000);
    const localDate = new Date(localTimeMilliseconds);

    return localDate;
  }
}
