<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{'channels.title' | translate}}</h1>
  <div *ngIf="isAdmin" class="flex-1 ">
    <button mat-flat-button color="primary" *flowHasPermission="['UpdateChannelAdmin']; disabled true;" [routerLink]="['/channels/create']" >
      {{'global.add' | translate}}
    </button>
  </div>
</div>

<form *ngIf="isAdmin"
      [formGroup]="filterForm"
      class="channel-form bg-white p-4 pb-0 mb-2.5 rounded-md flex justify-between">
  <div class="mr-5 mb-4 flex flex-col">
    <mat-label>{{ "manage.filters.customers" | translate }}</mat-label>
    <mat-form-field>
      <mat-select formControlName="customers"
                  placeholder="{{'channels.customer' | translate}}"
                  multiple>
        <mat-option *ngFor="let customer of customers"
                    [value]="customer.id">
          {{ customer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div *ngIf="dataSource?.data?.length">
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalCount"
    [pageIndex]="params.pageNumber"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="params.pageSize"
    showFirstLastButtons>
  </mat-paginator>

  <table
         mat-table
         [dataSource]="dataSource"
         (matSortChange)="handleSortEvent($event)"
         multiTemplateDataRows
         class="table-hover"
         matSort
         matSortActive="name"
         matSortDirection="asc"
        >

    <ng-container matColumnDef="id">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> {{'channels.table.id' | translate}} </th>
      <td mat-cell
          *matCellDef="let channel"> {{channel.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> {{'channels.table.name' | translate}} </th>
      <td mat-cell
          *matCellDef="let channel">
        <div class="flex items-center gap-[4px]">
          <div class="traffic-light" [ngClass]="channel.isActive ? 'bg-[#177578] ' : 'bg-[#FC4B47]'"></div>
          <div>{{channel.name}}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> {{'channels.table.customer' | translate}} </th>
      <td mat-cell
          *matCellDef="let channel">
        {{channel.customerName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef>{{'channels.table.status' | translate}}</th>
      <td mat-cell
          *matCellDef="let channel">
        <mat-slide-toggle *flowHasPermission="['UpdateChannelAdmin']; disabled true;"
                          mat-icon-button
                          [matTooltip]="'channels.table.activate' | translate"
                          matTooltipPosition="above"
                          (click)="onToggle($event, channel, !channel.isActive)"
                          [(ngModel)]="channel.isActive"
                          color="primary"
                          labelPosition="before">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="actions-col"></th>
      <td mat-cell
          *matCellDef="let channel">

        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a *flowHasPermission="['UpdateChannelAdmin']; disabled true;"
             [routerLink]="['/channels', channel.id]"
             mat-menu-item>
            {{'global.edit' | translate}}
          </a>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row
        class="bg-gray-50"
        *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row
        *matRowDef="let channel; columns: displayedColumns; let odd = odd"
        [ngClass]="{'bg-gray-50': odd}"
        (dblclick)="redirectToEditPage(channel.id)">
    </tr>

  </table>
</div>

<div *ngIf="dataSource?.data?.length === 0"
     [style.height.px]="200"
     class="flex justify-center items-center">
  <h2>{{'channels.emptyMessage' | translate}}</h2>
</div>
