<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ "settings.settings" | translate }}</h1>
</div>

<flow-stepper (activeStepChange)="selectedTabChange($event)"
              *ngIf="!(loading$.init  | async)"
              [(activeStep)]="selectedIndex"
              [steps]="steps">

  <div class="h-10 justify-end items-start inline-flex gap-2" header>
    <button
      (click)="onEditClick()"
      *ngIf="!isEditSelected"
      class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer border-neutral-100">
      <app-icon [ngStyle]="{'cursor': 'pointer'}"
                iconName="edit-pencil"
                size="md"
                stroke="#202020"></app-icon>
      <div class="text-xs font-semibold capitalize leading-none text-black">
        {{ 'global.edit' | translate }}
      </div>
    </button>
    <button
      (click)="onCancelClick()"
      *ngIf="isEditSelected"
      class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer border-neutral-100">
      <app-icon [ngStyle]="{'cursor': 'pointer'}"
                iconName="x-square"
                size="md"
                stroke="#202020"></app-icon>
      <div class="text-xs font-semibold capitalize leading-none text-black">
        {{ 'global.cancel' | translate }}
      </div>
    </button>
    <button
      (click)="onSaveClick()"
      *ngIf="isEditSelected"
      [disabled]="(loading$.save | async) || form.invalid"
      [ngClass]="form.invalid ? 'border-neutral-100' : 'border-brand-chathams-blue'"
      class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer">
      <app-icon [ngStyle]="{'cursor': 'pointer'}"
                [stroke]="form.invalid  ? '#EBEBE4' : '#1f5792'"
                fill="#fff"
                iconName="save"
                size="md"></app-icon>
      <span [ngClass]="form.invalid  ? 'text-[#EBEBE4]' : 'text-brand-chathams-blue'"
            class="text-xs font-semibold capitalize leading-none">
              {{ 'global.saveChanges' | translate }}
            </span>
    </button>
  </div>

  <form [formGroup]="form">
    <flow-tabs-item>
      <div class="flex flex-col gap-4 min-w-[360px]">
        <ng-container *ngTemplateOutlet="settingTitle"></ng-container>
        <div class="flex flex-col input-override">
          <mat-label>{{ 'settings.standardRatePanel.costPerSMSLabel' | translate }}</mat-label>
          <mat-form-field>
            <input formControlName="cost"
                   matInput
                   type="number">
          </mat-form-field>
        </div>
        <div class="flex flex-col input-override">
          <mat-label>{{ 'settings.standardRatePanel.currencyLabel' | translate }}</mat-label>
          <mat-form-field>
            <input class="toUppercase"
                   formControlName="currency"
                   matInput
                   maxlength="3"
                   type="text">
          </mat-form-field>
        </div>
      </div>
      <div class="flex justify-between w-full">

        <div class="flex flex-col p-4 text-xs">
          <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
          <p>{{ 'settings.SMSNote' | translate }}</p>
        </div>
        <div>
          <button
            (click)="onNextTab(1)"
            color="primary"
            mat-flat-button>
            {{ "global.next" | translate }}
          </button>
        </div>

      </div>
    </flow-tabs-item>

    <flow-tabs-item>
      <div class="flex flex-col gap-4 min-w-[360px]">
        <ng-container *ngTemplateOutlet="settingTitle"></ng-container>
        <div class="flex flex-col input-override">
          <mat-label>{{ 'settings.frequencyOfWeatherData.frequency' | translate }}</mat-label>
          <mat-form-field>
            <input formControlName="weatherApiConfigurationFrequency"
                   matInput
                   min="1"
                   type="number">
          </mat-form-field>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex flex-col p-4 text-xs">
          <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
          <p>{{ 'settings.weatherDataNote' | translate }}</p>
        </div>
        <div>
          <button
            (click)="onNextTab(0)"
            class="mr-2"
            mat-stroked-button>
            {{ "global.back" | translate }}
          </button>
          <button
            (click)="onNextTab(2)"
            color="primary"
            mat-flat-button>
            {{ "global.next" | translate }}
          </button>
        </div>
      </div>
    </flow-tabs-item>

    <flow-tabs-item>
      <div class="flex gap-4 flex-col min-w-[600px]">
        <ng-container *ngTemplateOutlet="settingTitle"></ng-container>
        <div>
          <p class="text-s mb-1 font-semibold">{{ 'settings.sensitivitySettingMA.high' | translate }}</p>
          <div class="flex items-baseline">
            <span class="mr-2">{{ "marketingAutomation.activateIfNoPeopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsHighLevelX"
                matInput
                type="number">
            </mat-form-field>
            <span class="ml-2"> {{ "marketingAutomation.minutes" | translate }}</span>
          </div>
          <div class="flex items-baseline">
            <span class="mr-2">{{ 'marketingAutomation.activateIf' | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsHighLevelY"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.peopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsHighLevelZ"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.minutes" | translate }}</span>
          </div>
        </div>
        <div>
          <p class="text-s mb-1 font-semibold">{{ 'settings.sensitivitySettingMA.medium' | translate }}</p>
          <div class="flex items-baseline">
            <span class="mr-2">{{ "marketingAutomation.activateIfNoPeopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsMediumLevelX"
                matInput
                type="number">
            </mat-form-field>
            <span class="ml-2"> {{ "marketingAutomation.minutes" | translate }}</span>
          </div>
          <div class="flex items-baseline">
            <span class="mr-2">{{ 'marketingAutomation.activateIf' | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsMediumLevelY"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.peopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsMediumLevelZ"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.minutes" | translate }}</span>
          </div>
        </div>
        <div>
          <p class="text-s mb-1 font-semibold">{{ 'settings.sensitivitySettingMA.low' | translate }}</p>
          <div class="flex items-baseline">
            <span class="mr-2">{{ "marketingAutomation.activateIfNoPeopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsLowLevelX"
                matInput
                type="number">
            </mat-form-field>
            <span class="ml-2"> {{ "marketingAutomation.minutes" | translate }}</span>
          </div>
          <div class="flex items-baseline">
            <span class="mr-2">{{ 'marketingAutomation.activateIf' | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsLowLevelY"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.peopleInFront" | translate }}</span>
            <mat-form-field appearance="outline" class="marketing-automation w-16">
              <input
                [min]="1"
                formControlName="maSettingsLowLevelZ"
                matInput
                type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.minutes" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="flex flex-col p-4 text-xs">
          <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
          <p>{{ 'settings.marketingAutomationNote' | translate }}</p>
        </div>
        <div>
          <button
            (click)="onNextTab(1)"
            mat-stroked-button>
            {{ "global.back" | translate }}
          </button>
        </div>
      </div>
    </flow-tabs-item>

  </form>
</flow-stepper>


<ng-template #settingTitle>
  <div class="w-[330px] rounded justify-start items-center gap-2 inline-flex">
    <div class="justify-start items-center gap-2 flex">
      <app-icon class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                fill="none"
                iconName="settings"
                size="md"
                stroke="#598fae"></app-icon>
    </div>
    <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{ 'settings.settings' | translate }}
    </div>
  </div>
</ng-template>


