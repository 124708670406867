<div class="page-header">
  <h1 class="page-title">
    {{ "reach.title" | translate }}
  </h1>
</div>
<br/>

<flow-helper-card *ngIf="!consentGiven"
                  consentKey="reach-consent"
                  description="reach.info"
                  title="reach.title">
</flow-helper-card>

<mat-tab-group (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="selectedIndex" animationDuration="0ms"
               class="mat-tabs-override">
  <mat-tab [disabled]="disableReachAnalytics" [label]="'sidebar.overview' | translate">
    <br/>

    <flow-dashboard-filter [form]="form"
                           [filteredChannels]="filteredChannels"
                           [screens]="screens"
                           [customers]="customers"
                           [timeframeTypes]="timeframeTypes"
                           [isAdmin]="isAdmin"
                           (onSubmit)="onSubmit()">
    </flow-dashboard-filter>

    <div *ngIf="!isChannels; else channelsSelected"
         class="no-data-selected-container flex flex-col justify-center items-center">
      <img src="../../../../assets/no-data.png"/>
      <p>{{ "global.noDataSelected" | translate }}</p>
      <p class="no-data-subtitle">{{ "peopleCount.noData" | translate }}</p>
    </div>
    <ng-template #channelsSelected>
      <div class="items-book">
        <div (click)="setActiveStep(0)" [ngClass]="{ 'active-stepper-book': activeStep === 0 }" class="stepper-book">
          <div class="header-stepper-book">
            <div class="header-title-stepper-book">{{ "reach.people" | translate }}</div>
          </div>
        </div>
        <div (click)="setActiveStep(1)" [ngClass]="{ 'active-stepper-book': activeStep === 1 }" class="stepper-book">
          <div class="header-stepper-book">
            <div class="header-title-stepper-book">{{ "reach.vehicles.vehicles" | translate }}</div>
          </div>
        </div>
      </div>
      <ng-container [ngSwitch]="activeStep">
        <div *ngSwitchCase="0" class="step-container-book">
          <div id="metrics-section">
            <flow-metrics-box-details [metricsData]="targetGroupWithLabels"
                                      [period]="periodWeek"
                                      detailItemName="label"
                                      detailItemValue="currentWeek"
                                      sectionTitle="reach.metrics">
              <div class="title">{{ "sidebar.reach" | translate }}</div>
            </flow-metrics-box-details>
          </div>
          <flow-people-count-target-group-weekly-monthly [compareChart]=compare
                                                         [compareStatisticsData]=compareStatistics
                                                         [end]=end
                                                         [rangeCompare]=range
                                                         [start]=start
                                                         [statisticsData]=statistics>

          </flow-people-count-target-group-weekly-monthly>

          <flow-people-count-gender [compareChart]=compare
                                    [compareStatisticsData]=compareStatistics
                                    [end]="end"
                                    [rangeCompare]=range
                                    [start]="start"
                                    [statisticsData]=statistics
                                    [timeframe]="form.get('timeframe').value"
          >
          </flow-people-count-gender>

          <flow-people-count-target-group-daily [channels]="form.get('channels').value"
                                                [customerId]="form.get('customer').value.id"
                                                [end]="form.get('end').value"
                                                [filter]="filter"
                                                [screenIds]="form.get('screens').value"
                                                [start]="form.get('start').value">
          </flow-people-count-target-group-daily>
        </div>
        <div *ngSwitchCase="1" class="step-container-book">
          <div id="metrics-section">
            <flow-metrics-box-details [metricsData]="vehiclesData"
                                      [periodTitle]="'peopleCount.thisWeek' | translate"
                                      [period]="periodWeek"
                                      detailItemName="label"
                                      detailItemValue="value"
                                      sectionTitle="reach.metrics">
              <div class="title">{{ "sidebar.reach" | translate }}</div>
            </flow-metrics-box-details>
          </div>
          <flow-vehicles-weekly [channels]="form.get('channels').value"
                                [compareMode]="compare"
                                [customerId]="form.get('customer').value.id"
                                [end]="end"
                                [rangeCompare]="range"
                                [screenIds]="form.get('screens').value"
                                [start]="start"
                                [vehiclesCompareData]=vehiclesDataCompare
                                [vehiclesData]=vehiclesData
          ></flow-vehicles-weekly>
          <flow-vehicles-daily [channels]="form.get('channels').value"
                               [customerId]="form.get('customer').value.id"
                               [screenIds]="form.get('screens').value"
                               [end]="end"
                               [start]="start"
          ></flow-vehicles-daily>
        </div>
      </ng-container>
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="disableReachReportsAnalytics" [label]="'sidebar.reports' | translate">
    <br/>
    <ng-container *ngIf="selectedIndex === 1">
      <flow-reports [permissions]="['ExportReachReportsAnalytics']" [reportType]=ActiveReportEnum.Reach></flow-reports>
    </ng-container>
  </mat-tab>
</mat-tab-group>

