<nav mat-tab-nav-bar class="border-none">
  <a
    *ngFor="let tab of tabs; let index = index"
    class="flex flex-col items-start bg-gray-50 h-12 min-w-16 px-4 opacity-100"
    [ngClass]="{ 'opacity-50': tab.disabled, 'bg-white': rla.isActive }"
    routerLinkActive
    [routerLink]="tab.path"
    [active]="rla.isActive"
    [disabled]="tab.disabled"
    (click)="onTabClick(index)"
    mat-tab-link
    #rla="routerLinkActive">
    <div class="flex flex-col items-start" [ngClass]="{ 'opacity-50': tab.disabled }">
      <span class="leading-4 text-xs text-gray-800">{{ tab.subtitle | translate }}</span>
      <span class="leading-none text-sm text-gray-900 font-semibold">{{ tab.title | translate }}</span>
    </div>
  </a>
</nav>