import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { DetailMedia, StandardMedia } from '@api/index';
import { TriggerMediaModal } from '@shared/trigger-media-model/trigger-media-model.component';
import { ViewMediaModal } from '@shared/view-media-modal/view-media.modal';
import { MediaCreateModal } from '@app/admin/media/media-create/media-create-modal/media-create.modal';
import { MediaSelectModal } from '@admin/media/media-select.modal';
import { Media } from './media.service';

@Injectable({
  providedIn: 'root',
})
export class MediaModalService {
  constructor(private dialog: MatDialog) { }

  openCreateMediaModal(data?: any): Observable<Media> {
    const dialogRef = this.dialog.open(MediaCreateModal, {
      width: '410px',
      data: {
        ...data,
      },
    });

    return dialogRef.afterClosed()
  }

  openSelectMediaModal(data?: any): Observable<Media> {
    const dialogRef = this.dialog.open(MediaSelectModal, {
      width: '54%',
      height: '85%',
      data: {
        ...data,
      },
    });

    return dialogRef.afterClosed()
  }

  setModal(media: Media, form: any, propertyName: string, list: any[] , isDefault: boolean, isSelect: boolean){
    if (media) {
      const mediaObject = {
        mediaId: media.id,
        name: media.name,
        type: media.type,
        duration: media.duration,
        imageUrl: media.imageUrl,
        thumbnailImageUrl: media.thumbnailImageUrl,
        filename: media.filename,      
        ...(media.type === 2 && {
          [isDefault ? 'defaultMediaPresentationTime' : 'presentationTime']: media.duration
        }),
      };

      const timeField = propertyName === 'defaultMediaDetails' ? 'defaultMediaPresentationTime' : 'presentationTime';

      if (media.type === 2) {
        form.get(timeField).setValue(media.duration)
        form.get(timeField)?.disable();
      } 
      else {
        form.get(timeField)?.enable();
      }

      const findMedia = list.find((sm) => sm?.mediaId === media.id);
      form.markAsDirty();
      form.get(propertyName).patchValue(findMedia || mediaObject);
      
      if (!findMedia) {
        list.unshift(mediaObject);
      }
      
    } else {
      return;
    }
  }
  
  openViewMediaModal(standardMedia: StandardMedia | DetailMedia): void {
    this.dialog.open(ViewMediaModal, {
      width: '80vw',
      maxHeight: '80vh',
      data: {
        title: standardMedia.name + ' - ' + standardMedia.filename,
        media: {
          imageUrl: standardMedia.imageUrl,
          thumbnailImageUrl: standardMedia.thumbnailImageUrl,
          type: standardMedia?.type
        }
      }
    });
  }
  openTriggerMediaModal(triggers?: any, updateTriggersCallback?: (triggers: any) => void) {
    const dialogRef = this.dialog.open(TriggerMediaModal, {
      autoFocus: false,
      data: {
        triggers
      },
    });

    dialogRef.afterClosed().subscribe((triggerForm: any) => {
      if (triggerForm) {
        triggers = {
          weatherConditions: (triggerForm?.selectedIcons && triggerForm?.selectedIcons.length > 0) ? triggerForm?.selectedIcons : null,
          temperature: (triggerForm?.temperature && Object.keys(triggerForm?.temperature).length > 0) ? triggerForm?.temperature : null
        };
        updateTriggersCallback(triggers);
      }
    });
  }
}
