import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { IdName } from "@api/models/idname";

@Component({
  selector: 'flow-mood-chart',
  templateUrl: './mood-chart.component.html',
  styleUrls: ['./mood-chart.component.scss']
})
export class MoodChartComponent implements OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() screens!: IdName[];
  @Input() selectedDate!: DateTime;
  @Input() selectedScreenIds!: number[];
  @Input() moodData!: any;
  @Output() onScreenSelectionChange = new EventEmitter<any>();
  @Output() onDateChange = new EventEmitter<any>();

  form: FormGroup = this.formBuilder.group({
    date: [null],
    screenIds: [this.screens ? [this.screens[0]?.id] : []]
  });

  public weekDays = [];
  public chartData: ChartData<'line', number[], string | string[]> = null;
  legends: any[] = [];
  isoDate: string = DateTime.now().toISODate();
  noChartData: boolean = false;
  neutralColor = 'rgb(239, 194, 169)';
  happyColor = 'rgb(23, 117, 120)';
  sadColor = 'rgb(162, 195, 214)';
  surpriseColor = 'rgb(31, 87, 146)';
  angryColor = 'rgb(252, 75, 71)';

  public chartOptions: ChartConfiguration['options'] = {
    elements: {
      line: { tension: 0.5 }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: { display: false }
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        ticks: {
          padding: 10,
        }
      },
      x: {
        grid: {
          tickColor: 'white',
          color: '#F5F5F5',
          lineWidth: 2
        },
        ticks: {
          padding: 20
        },
      }
    }
  };

  constructor(
    private formBuilder: FormBuilder, 
    protected translateService: TranslateService
  ) { }

  getIconForLabel(label: any): string {
    switch (label) {
      case this.translateService.instant('peopleCount.neutral'):
        return 'neutral-icon';
      case this.translateService.instant('peopleCount.happy'):
        return 'happy-icon';
      case this.translateService.instant('peopleCount.sad'):
        return 'sad-icon';
      case this.translateService.instant('peopleCount.surprised'):
        return 'surprised-icon';
      case this.translateService.instant('peopleCount.angry'):
        return 'angry-icon';
      default:
        return '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.screens?.currentValue) {
      this.form.get('screenIds').setValue(changes?.screens?.currentValue.map(screen => screen.id));
    }

    if (changes?.moodData?.currentValue && (changes.moodData.currentValue !== changes.moodData.previousValue)) {
      this.updateChartData(this.moodData);
      this.isoDate = this.selectedDate.toFormat('yyyy-MM-dd');
      this.form.get('date').setValue(this.selectedDate);  
    }
  }

  screenSelectionChange(event: MatSelectChange) {
    this.onScreenSelectionChange.emit(event?.value);
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    this.onDateChange.emit(event?.value);
  }

  updateChartData(data) {
    if (data.moodTrackingsHours.length) {
      this.noChartData = false;

      const hoursWithData = data.moodTrackingsHours.map((item) => Number(DateTime.fromISO(item.time, { setZone: true }).hour));
      const startHour = Math.min(...hoursWithData);
      const endHour = Math.max(...hoursWithData);

      const statHours = [];
      for (let i = startHour; i <= endHour; i++) statHours.push(i);
      const moodCountsByHour = {};

      statHours.forEach(hour => {
        moodCountsByHour[hour] = {
          'Neutral': 0,
          'Happy': 0,
          'Sad': 0,
          'Surprised': 0,
          'Angry': 0
        };
      });

      data.moodTrackingsHours.forEach(item => {
        const hour = Number(DateTime.fromISO(item.time, { setZone: true }).hour);
        item.moodTrackingCounts.forEach(countItem => {
          moodCountsByHour[hour][countItem.faceMoodType] = countItem.count;
        });
      });

      this.chartData = {
        labels: statHours.map(h => `${h}:00`),
        datasets: [
          {
            backgroundColor: this.neutralColor,
            borderColor: this.neutralColor,
            data: statHours.map(hour => moodCountsByHour[hour]['Neutral'] || 0),
            fill: false,
            hoverBackgroundColor: this.neutralColor,
            label: 'Neutral',
            pointBackgroundColor: this.neutralColor,
            pointRadius: 0
          },
          {
            backgroundColor: this.happyColor,
            borderColor: this.happyColor,
            data: statHours.map(hour => moodCountsByHour[hour]['Happy'] || 0),
            fill: false,
            hoverBackgroundColor: this.happyColor,
            label: 'Happy',
            pointBackgroundColor: this.happyColor,
            pointRadius: 0
          },
          {
            backgroundColor: this.sadColor,
            borderColor: this.sadColor,
            data: statHours.map(hour => moodCountsByHour[hour]['Sad'] || 0),
            fill: false,
            hoverBackgroundColor: this.sadColor,
            label: 'Sad',
            pointBackgroundColor: this.sadColor,
            pointRadius: 0,
          },
          {
            backgroundColor: this.surpriseColor,
            borderColor: this.surpriseColor,
            data: statHours.map(hour => moodCountsByHour[hour]['Surprised'] || 0),
            fill: false,
            hoverBackgroundColor: this.surpriseColor,
            label: 'Surprised',
            pointBackgroundColor: this.surpriseColor,
            pointRadius: 0,
          },
          {
            backgroundColor: this.angryColor,
            borderColor: this.angryColor,
            data: statHours.map(hour => moodCountsByHour[hour]['Angry'] || 0),
            fill: false,
            hoverBackgroundColor: this.angryColor,
            label: 'Angry',
            pointBackgroundColor: this.angryColor,
            pointRadius: 0,
          },
        ]
      }
      this.chart?.update();
      this.createCustomLegend();
    } else {
      this.noChartData = true;
    }
  }
  createCustomLegend(): void {
    this.legends = this.chartData.datasets.map((dataset, index) => {
      return {
        text: dataset.label,
        icon: this.getLabel(dataset.label),
        fillStyle: dataset.borderColor,
        hidden: false,
        datasetIndex: index
      };
    });
  }
  toggleDataset(datasetIndex: number): void {
    const chart = this.chart.chart;
    const meta = chart.getDatasetMeta(datasetIndex);

    meta.hidden = !meta.hidden;
    this.legends[datasetIndex].hidden = meta.hidden;
    chart.update();
  }
  getLabel(label) {
    const icon = this.getIconForLabel(label);
    return `${icon}`
  }
}
