<h1 mat-dialog-title>
  {{ 'moveFileToFolderModal.title' | translate }}
</h1>
<div mat-dialog-content
     class="py-2">
  <div>
    {{ 'moveFileToFolderModal.text' | translate }}
  </div>
  <form [formGroup]="form" *ngIf="form">
    <br>
    <mat-form-field class="w-full">
      <mat-label>{{'moveFileToFolderModal.selectFolder' | translate}}</mat-label>
      <mat-select formControlName="folder">
        <mat-option *ngFor="let folder of folders"
                    [value]="folder">
          {{folder.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-button
          mat-dialog-close
          type="button">{{'global.cancel' | translate}}</button>
  <button (click)="moveFile()"
          [disabled]="form.invalid"
          mat-raised-button
          color="primary"
          type="button">{{'global.move' | translate}}</button>
</div>