import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  AfterContentInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { TabsItemComponent } from './tabs-item/tabs-item.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'flow-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterContentInit {
  protected destroy$ = new Subject();
  @Input() selectedIndex = 0;
  @Output() selectedTabChange: EventEmitter<number> = new EventEmitter<number>()

  @ContentChildren(TabsItemComponent, { descendants: true }) tabsList!: QueryList<TabsItemComponent>;
  disabledTabs: boolean[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.tabsList.forEach((tab, index) => {
      tab.disabledChange.pipe(takeUntil(this.destroy$)).subscribe((isDisabled: boolean) => {
        this.disabledTabs[index] = isDisabled;
        this.cdr.markForCheck();
      });
    });
  }

  selectedTab(index: number) {
    this.selectedIndex = index
    this.selectedTabChange.next(index);
  }
}
