export enum MediaTypes {
    Image = 1,
    Video = 2,
    Folder = 3
  }

  export const MediaTypesTranslations = {
    [MediaTypes.Image]: 'global.image',
    [MediaTypes.Video]: 'global.video',
    [MediaTypes.Folder]: 'global.folder'
  };