<div class="w-full">
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalCount"
    [pageIndex]="params.pageNumber"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="params.pageSize"
    showFirstLastButtons></mat-paginator>
  <table
    (matSortChange)="handleSortEvent($event)"
    [dataSource]="dataSource"
    class="table-hover"
    mat-table
    matSort
    matSortActive="humanUniqueIdentifier"
    matSortDirection="asc"
    multiTemplateDataRows
    [matSortDisableClear]="true">

    <ng-container matColumnDef="id">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'screens.table.id' | translate }}
      </th>
      <td *matCellDef="let screenGroup"
          mat-cell> {{ screenGroup.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="humanUniqueIdentifier">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'screens.table.name' | translate }}
      </th>
      <td *matCellDef="let screenGroup"
          mat-cell>
        <div class="flex items-center gap-[4px]">
          <div *ngIf="!screenGroup?.isGroupAlive" class="traffic-light bg-[#FC4B47]">
          </div>
          <div *ngIf="screenGroup?.isGroupAlive" class="traffic-light bg-[#177578]">
          </div>
          <div> {{ screenGroup.humanUniqueIdentifier }}</div>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="layout">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'screens.table.screenLayout' | translate }}
      </th>
      <td *matCellDef="let screenGroup"
          mat-cell>
        <div *ngIf="screenGroup.orientation == 1">
              <app-icon
                fill="none"
                iconName="portrait"
                matTooltip="{{'screens.table.portrait' | translate}}"
                matTooltipPosition="left"
                size="md">
              </app-icon>
            </div>
        <div *ngIf="screenGroup.orientation == 2">
              <app-icon
                fill="none"
                iconName="landscape"
                matTooltip="{{'screens.table.landscape' | translate}}"
                matTooltipPosition="left"
                size="md">
              </app-icon>
            </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="devices">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'screens.screenGroup.devices' | translate }}
      </th>
      <td *matCellDef="let screenGroup"
          mat-cell>
          {{screenGroup?.devices}}
      </td>
    </ng-container>


    <ng-container matColumnDef="enable">
      <th *matHeaderCellDef
          mat-header-cell
          mat-sort-header> {{ 'screens.enable' | translate }}
      </th>
      <td *matCellDef="let screenGroup"
          mat-cell>
        <mat-slide-toggle
          (click)="onToggle($event, screenGroup, !screenGroup.enable)"
          [(ngModel)]="screenGroup.enable"
          color="primary">
        </mat-slide-toggle>
      </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef
          class="actions-col"
          mat-header-cell></th>
      <td *matCellDef="let screenGroup"
          [style.width]="'1px'"
          mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a *flowHasPermission="['UpdateScreensAdmin']; disabled true;" [routerLink]="['/screenGroup', screenGroup.id]" mat-menu-item>{{ 'global.edit' | translate }}</a>
          <button *flowHasPermission="['UpdateScreensAdmin']; disabled true;" class="text-red-500" (click)="onDeleteClick($event, screenGroup)" mat-menu-item>{{ 'global.delete' | translate }}</button>
        </mat-menu>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <tr (dblclick)="showScreensInScreenGroup(screenGroup.id)"
        *matRowDef="let screenGroup; columns: displayedColumns;"
        mat-row
        class="hover:bg-gray-200 cursor-pointer"
    ></tr>
  </table>
</div>
