<mat-radio-group [disabled]="disabled" [ngModel]="selectedValue" (ngModelChange)="onSelect($event)" class="flex gap-4 mb-8">
  <mat-radio-button
    *ngFor="let device of deviceOptions"
    [value]="device.value"
    class="max-w-40 min-w-32 flex flex-col items-center justify-center text-center border rounded cursor-pointer p-2"
    [ngClass]="{
        'ring-brand-chathams-blue ring-offset-0 ring-2': selectedValue === device.value,
        'border-gray-300': selectedValue !== device.value,
        'ring-chathams-blue-600': disabled
      }">
    <div class="text-sm font-semibold">{{ device.label | translate }}</div>
    <div class="text-xs opacity-80 whitespace-normal break-words">{{ device.description | translate }}</div>
  </mat-radio-button>
</mat-radio-group>
