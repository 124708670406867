<div class="tabs-group">
  <mat-tab-group
    class="mat-tabs-override"
    [(selectedIndex)]="selectedIndex"
    [animationDuration]="animationDuration"
    [disableRipple]="disableRipple"
    (selectedTabChange)="selectedTab($event)">
    <mat-tab *ngFor="let tab of tabsList" [disabled]="tab?.disabled">
      <ng-template mat-tab-label>
        <div class="flex flex-col text-left" [ngClass]="{ 'opacity-50': tab?.disabled }">
          <span class="leading-4 text-xs text-gray-800">{{ tab.subTitle | translate }}</span>
          <span class="leading-none text-sm text-gray-900 font-semibold">{{ tab.title | translate }}</span>
        </div>
      </ng-template>
      <ng-container [ngTemplateOutlet]="tab.content"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
