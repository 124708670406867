import { Component, Input } from '@angular/core';

@Component({
  selector: 'flow-metrics-box-details',
  templateUrl: './metrics-box-details.component.html',
  styleUrls: ['./metrics-box-details.component.scss']
})
export class MetricsBoxDetailsComponent {

  @Input() periodTitle: any;
  @Input() metricsData: any[] = [];
  @Input() period: string = '';
  @Input() detailItemName: string = '';
  @Input() detailItemValue: string = '';
  @Input() sectionTitle: string = '';

}
