import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BREADCRUMBS_KEY, LANGUAGE_KEY, STORAGE_KEY_AUTH, STORAGE_KEY_USER } from 'src/app/constants';
import { environment } from '../../../environments/environment';
import { CurrentUser } from './current-user';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '../translations';
import { TranslationsEnum } from '@api/models/translations-enum';
import { UserLoginResponse } from '@api/models/user-login-response';
import { AuthResponse, User } from '@api/models/user';
import jwtDecode from 'jwt-decode';
import { UsersApi } from '@api/index';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() userChanged = new EventEmitter<User>();

  private loggedInSubject = new Subject<boolean>();
  private userSubject = new Subject<User>();
  private apiPath = environment.apiBaseUrl;
  authToken: UserLoginResponse;
  previousUrl: string;
  user: CurrentUser;
  isLoggedIn$ = this.loggedInSubject.asObservable();
  user$ = this.userSubject.asObservable();

  constructor(
    private router: Router,
    private userApi: UsersApi,
    private translateService: TranslateService,
  ) {
    this.checkForUser();
  }

  login(email: string, password: string): Observable<AuthResponse> {
    return this.userApi.login({ email, password }).pipe(
      switchMap((response: UserLoginResponse) => {
        this.processToken(response, email);
        if (response.customers && response.customers.length > 1) {
          return of<AuthResponse>({ multipleCustomersResponse: { multipleCustomers: true, customers: response.customers } })
        } else {
          const customerId = (response.customers !== null) ? response.customers[0].id : 0;
          return this.userProfile(customerId, email).pipe(
            map(result => {
              return { user: result } as AuthResponse
            })
          );
        }
      }),
      map((authResponse: AuthResponse) => {
        if (authResponse.user) {
          this.setLoggedInState(true);
          this.userChanged.emit(authResponse.user);
        }
        return authResponse;
      })
    );
  }

  getUser() {
    return this.user;
  }

  formatExpireDate(dateObj: Date) {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  register(email: string, password: string, code: string) {
    return this.userApi.register({ email, password, code });
  }

  email(code: string) {
    return this.userApi.verify({ code });
  }

  userProfile(customerId: number, email: string): Observable<CurrentUser> {
    return this.userApi.selectProfile(customerId, email).pipe(
      map(profile => {
        this.setCurrentUser(profile);
        this.setLoggedInState(true);

        return this.user;
      })
    );
  }

  get userData() {
    return this.user;
  }

  logout(): void {
    this.setLoggedInState(false)
    localStorage.removeItem(STORAGE_KEY_USER);
    localStorage.removeItem(STORAGE_KEY_AUTH);
    localStorage.removeItem(LANGUAGE_KEY);
    localStorage.removeItem(BREADCRUMBS_KEY);
    this.user = null;
    this.authToken = null;
    this.userChanged.emit();
  }

  setCurrentUser(currentUser: CurrentUser, currentCustomerId?: number) {
    const { id, roleId, customerId, name, fullName, permissions } = currentUser;
    this.user.id = id;
    this.user.roleId = roleId;
    this.user.currentCustomerId = currentCustomerId || null;
    this.user.customerId = customerId;
    this.user.fullName = name || fullName;
    this.user.permissions = permissions;

    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(this.user));
  }

  setCustomer(currUser: CurrentUser, currentCustomerId: number): CurrentUser {
    this.setCurrentUser(currUser, currentCustomerId);
    return this.user;
  }

  checkForUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    const token = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH));
    if (!token || this.tokenExpired(token)) {
      this.logout();
      return of(null);
    } else {
      this.authToken = token;
      const user = JSON.parse(localStorage.getItem(STORAGE_KEY_USER));
      if (!user) {
        this.logout();
        return of(null);
      }
      this.user = user;
      return of(this.user);
    }
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  tokenExpired(authToken?: UserLoginResponse): boolean {
    const expirationDate = new Date(authToken?.expires || this.authToken?.expires);
    const now = new Date();
    const hasExpired = expirationDate < now;
    return hasExpired;
  }

  setLoggedInState(loggedIn: boolean) {
    this.loggedInSubject.next(loggedIn);
  }

  setUser(user) {
    this.userSubject.next(user);
  }

  handleUnauthorized(error: any) {
    this.logout();
    this.previousUrl = this.router.routerState.snapshot.url;
    this.router.navigateByUrl('/login');
    return throwError(() => new Error(error));
  }

  resetPassword(email?: string) {
    return this.userApi.resetPassword({ email: email || this.user?.email })
  }

  updateUserLanguage(languageId: number) {
    return this.userApi.updateLanguage({ userId: this.user.id, languageId })
  }

  private processToken(response: UserLoginResponse, email: string): void {
    const decoded = jwtDecode(response.token);
    const dateObj = new Date(decoded['exp'] * 1000);
    const formattedDate = this.formatExpireDate(dateObj);

    this.authToken = {
      token: response.token,
      refreshToken: response.refreshToken,
      expires: formattedDate
    };

    this.user = { email: email, fullName: response.fullName };
    localStorage.setItem(STORAGE_KEY_AUTH, JSON.stringify(this.authToken));
    localStorage.setItem(LANGUAGE_KEY, Translations[response?.language || TranslationsEnum.English]);
    this.translateService.use(Translations[response?.language || TranslationsEnum.English]);
    this.setUser(this.user);
  }
}

export enum UserRoleEnum {
  Admin = 1,
  Customer = 2
}
