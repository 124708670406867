<div *ngIf="media" class="relative flex justify-center overflow-hidden  cursor-pointer" [ngClass]="{'w-16 h-12 rounded-md bg-gray-100': fixedSize}">
  <div *ngIf="name" class="absolute bottom-0 w-full h-[14px] bg-black bg-opacity-50 leading-3 z-50 px-1 pointer-events-none">
    <span class="text-gray-50 text-[10px]">{{ media?.name }}</span>
  </div>

  <ng-container *ngIf="!action.observed">
    <div class="flex justify-center w-full h-full" (click)="openViewMedia(media, $event)">
      <ng-container *ngTemplateOutlet="mediaPreview"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="action.observed">
    <ng-container *ngTemplateOutlet="mediaPreviewMenu"></ng-container>
  </ng-container>
</div>

<ng-template #mediaPreview>
  <img *ngIf="type === MediaTypeEnum.Image && media?.thumbnailImageUrl" [src]="apiBaseUrl + media?.thumbnailImageUrl" class="h-full" />
  <div *ngIf="type === MediaTypeEnum.Video" class="max-w-[75px] h-[50px] p-4 flex items-center justify-center text-center">
    <mat-icon>play_circle</mat-icon>
  </div>
</ng-template>

<ng-template #mediaPreviewMenu>
  <div class="flex justify-center w-full h-full" [matMenuTriggerFor]="menu">
    <ng-container *ngTemplateOutlet="mediaPreview"></ng-container>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openViewMedia(media, $event)">
        <span>{{ 'media.table.preview' | translate }}</span>
      </button>
      <button mat-menu-item (click)="openCreateMedia(media)">
        <span>{{ 'editSegment.form.createNewMedia' | translate }}</span>
      </button>
      <button mat-menu-item (click)="openSelectMedia(media)">
        <span>{{ 'editSegment.form.chooseMedia' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</ng-template>
