import { Injectable } from "@angular/core";
import { TargetGroupEnum } from "@api/index";

@Injectable({
  providedIn: 'root'
})
export class TargetGroupHelper {
  getTargetGroupColor(group: TargetGroupEnum, opacity = 1) {
    switch (group) {
      case TargetGroupEnum.WomenUnder18:
        return `rgb(71, 92, 122, ${opacity})`
      case TargetGroupEnum.Women18to24:
        return `rgb(104, 93, 121, ${opacity})`
      case TargetGroupEnum.Women25to34:
        return `rgb(171, 108, 130, ${opacity})`
      case TargetGroupEnum.Women35to44:
        return `rgb(216, 115, 127, ${opacity})`
      case TargetGroupEnum.Women45to54:
        return `rgb(252, 187, 109, ${opacity})`
      case TargetGroupEnum.Women55to64:
        return `rgb(42, 155, 204, ${opacity})`
      case TargetGroupEnum.WomenAbove65:
        return `rgb(176, 143, 76, ${opacity})`
      case TargetGroupEnum.MenUnder18:
        return `rgb(85, 95, 176, ${opacity})`
      case TargetGroupEnum.Men18to24:
        return `rgb(70, 66, 85, ${opacity})`
      case TargetGroupEnum.Men25to34:
        return `rgb(121, 102, 93, ${opacity})`
      case TargetGroupEnum.Men35to44:
        return `rgb(85, 71, 65, ${opacity})`
      case TargetGroupEnum.Men45to54:
        return `rgb(112, 121, 93, ${opacity})`
      case TargetGroupEnum.Men55to64:
        return `rgb(244, 204, 164, ${opacity})`
      case TargetGroupEnum.MenAbove65:
        return `rgb(229, 160, 114, ${opacity})`
      case TargetGroupEnum.Man:
        return `rgba(33, 150, 243, ${opacity})`
      case TargetGroupEnum.Woman:
        return `rgba(244, 67, 54, ${opacity})`
      case TargetGroupEnum.OpportunityToSee:
        return `rgb(255, 243, 219, ${opacity})`
      case TargetGroupEnum.OpportunityToSeeCompare:
        return `rgb(193, 54, 56, ${opacity})`
      default:
        return 'rgba(0, 0, 0, 0.1)'
    }
  }

  getChartColors(group: TargetGroupEnum, opacity = 1) {
    const color = this.getTargetGroupColor(group, opacity)

    return {
      backgroundColor: color,
      hoverBackgroundColor: color,
      borderColor: color
    }
  }
}
