import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { Translations } from './translations';
import { TranslationsEnum } from 'src/_api/models/translations-enum';
import { EnumService } from './services/enum.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  private readonly LANGUAGE_KEY = 'language';
  private translations = []

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    public enumService: EnumService) {
    const savedLanguage = localStorage.getItem(this.LANGUAGE_KEY);
    const defaultLanguage = savedLanguage || Translations[TranslationsEnum.English];
    this.translateService.use(defaultLanguage);
    this.translations = enumService.translations
  }

  get currentLanguage() {
    return this.translateService.currentLang;
  }

  changeLanguage(type: string) {
    const findTranslation = this.translations.find((t) => t.name === type)
    this.authService.updateUserLanguage(findTranslation.id).subscribe(() => this.setLanguage(type))
  }

  instant(key: string, text?: any) {
    if (key === undefined) {
      key = " ";
    }
    return this.translateService?.instant(key, { text: text })
  }
  instantWithParams(key: string, interpolateParams?: Object) {
    if (key === undefined) {
      key = " ";
    }
    return this.translateService?.instant(key, interpolateParams)
  }
  setLanguage(type: string) {
    this.translateService.use(type);
    localStorage.setItem(this.LANGUAGE_KEY, type);
  }
}
