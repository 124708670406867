/* tslint:disable */
/* eslint-disable */
import { MediaFile } from './media-file';
import {IdName} from "./idname";
import {Customer} from "./customer";
import { DateTime } from 'luxon';

export interface PersonOfInterestResponse {
  items: PersonOfInterest [];
  numberOfMaxPois: number
  numberOfPois: number
  pageSize: number
  totalCount: number
  totalPages: number
  currentPage: number
  hasNext: boolean
  hasPrevious:boolean
}

export interface PersonOfInterest {
  customerId?: null | number;
  id?: number;
  channelId?: null | number;
  mediaFile?: MediaFile;
  name?: null | string;
  fileName: string;
  createdDate: DateTime | string;
  size: number
  width: number,
  height: number,
  customer: IdName | Customer
}
