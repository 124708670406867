import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from "@angular/material/paginator";
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, catchError, Subject, takeUntil, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { GlobalService } from '@core/services/global.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { indicateLoading } from "@core/indicate-loading";
import { PermissionsService } from '@core/services/permissions.service';
import { BackendErrorsTranslate } from '@core/be-errors-translations';
import { Screen, ScreenApi } from '@api/index';
import { TrackingEnum } from '@api/models/tracking.enum';
import { PaginatorParams } from "@api/models/paginator";
import { BackendErrorsEnum } from '@api/models/be-errors-enum';
import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { ContentData, CustomSnackbarComponent } from '@shared/custom-snackbar/custom-snackbar.component';
import { BulkUpdateAiAddComponent } from "./bulk-update-ai-add/bulk-update-ai-add.component";
import { ScreenAliveStatusEnum } from '../start/start.models';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { DatePipe } from '@angular/common';
import { ScreenAsleepStatusEnum, ScreenServicesStateData } from './screen.model';

@Component({
  selector: 'flow-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['checkbox', 'id','status', 'humanUniqueIdentifier', 'customerName', 'type', 'channelName', 'channelDescription', 'trackingType', 'coordinates', 'layout', 'defaultMediaName'];
  dataSource: MatTableDataSource<ScreenListItem>;
  isAdmin: boolean;
  hideBox = false;
  TrackingEnum = TrackingEnum;
  screenData: Screen[] = [];
  screenStatuses = [];
  customerId: number;
  isAllSelected = false;
  screenIds: number[] = [];
  selectedScreens: any[] = [];
  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  params: ScreenParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'humanUniqueIdentifier',
    sortDirection: 'asc'
  }
  selectedIndex: number = 0;
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];
  showScreensFromGroup: boolean = false;
  consentKey = 'screen-consent';
  consentGiven = false;
  protected destroy$ = new Subject();

  loading$ = {
    init: new BehaviorSubject(true),
  }
  protected readonly indicateLoading = indicateLoading;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    public platform: Platform,
    private screenApi: ScreenApi,
    private globalService: GlobalService,
    public systemMessageService: SystemMessageService,
    private permissionsService: PermissionsService,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService, 
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.checkConsent();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    if (!this.isAdmin) {
      this.displayedColumns.shift();
      this.displayedColumns = this.displayedColumns.concat(['actions']);
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.customerId = userCustomerId;
      let params = this.params;
      if (this.customerId) {
        params.customerIds = [this.customerId]
      }
      this.getScreens(params);
    } else {
      this.displayedColumns = this.displayedColumns.concat(['aiVersion', 'advertiserVersion', 'powerSchedule', 'enable', 'actions']);
      this.getScreens(this.params);
    }
  }

  getScreens(params?: ScreenParams): void {
    this.screenData = [];
    this.screenApi.getScreens(params)
      .pipe(takeUntil(this.destroy$),
        indicateLoading(this.loading$.init))
      .subscribe((data: any) => {
        this.params = { ...this.params, pageNumber: data.currentPage };
        this.screenData = data.items;        
        this.totalCount = data.totalCount
        this.dataSource = new MatTableDataSource(this.screenData);
        this.computeScreenStatuses(this.screenData);
      })
  }

  selectScreens(event: MatCheckboxChange, screen?: ScreenListItem,) {
    this.isAllSelected = event.checked;
    if (!screen && event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = true;
        this.screenIds.push(m.id)
        this.selectedScreens.push(m)
      });
    } else if (!screen && !event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = false;
      });
      this.screenIds = [];
      this.selectedScreens = [];
    } else if (screen && event.checked) {
      screen.isSelected = event.checked;
      this.screenIds.push(screen.id);
      this.selectedScreens.push(screen)
    } else if (screen && !event.checked) {
      screen.isSelected = event.checked;
      let index = this.screenIds.indexOf(screen.id);
      if (index !== -1) {
        this.screenIds.splice(index, 1);
        this.selectedScreens.splice(index, 1);
      }
    }
  }

  onDeleteClick(screen: ScreenListItem): void {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveScreen(screen, dialogRef),
        logic: true
      }
    })
  }

  private onRemoveScreen(screen: ScreenListItem, dialogRef: MatDialogRef<ConfirmationModalComponent, any>) {
    this.screenApi.removeScreen(screen.id)
      .pipe(
        catchError((error) => {
          dialogRef.close();
          return throwError(() => error);
        }),
        takeUntil(this.destroy$
      ))
      .subscribe({
        next: () => {
          this.dialog.closeAll();
          this.getScreens(this.params);
        },
        error: (error: ScreenBackendError) => {
          if ( error?.errorType && (error?.errorType === BackendErrorsEnum.ScreenInUse)) {
            this.openErrorModal(error)
          }
        }
      });
  }

  openErrorModal(error: ScreenBackendError) {
    const message = this.setErrorMessage(error);

    this.dialog.open(CustomSnackbarComponent, {
      width: '600px',
      data: {
        msg: message,
      },
    });
  }

  setErrorMessage(error: ScreenBackendError) {
    const screenName = error.screenName;
    const listContent = error.screenUsedAtEngagement.map((screen: string): string => {
      const html = '<li><strong>' + screenName + ':</strong> ' + screen + '</li>';
      return html;
    }).join('');

    return {
      title: 'screens.cannotDelete',
      content: this.translateService.instant(BackendErrorsTranslate[error?.errorType]),
      preText: this.translateService.instant('screens.usedScreenList'),
      listContent,
    } as ContentData;
  }

  selectedTabChange($event: any) {
    this.selectedIndex = $event.index;
    this.isAllSelected = false
    this.screenIds = [];
    this.selectedScreens = [];
    this.dataSource.data.filter(s => {
      s.isSelected = false
    })
    this.showScreensFromGroup = false

    if ($event.index === 0) {
      this.params.pageNumber = 0;
      this.params.pageSize = 10;
      let params = this.params;
      if (this.customerId)
        params.customerIds = [this.customerId];
      this.getScreens(this.params);
    }
  }

  redirectToEditPage(screen: any): void {
    if (this.permissionsService.hasPermissions(['UpdateScreensAdmin'])) {
      if (screen.screens) {
        return;
      }
      this.router.navigate(['/screens', screen.id]);
    }
  }
  openBulkUpdateModal(event: MouseEvent, type) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(BulkUpdateAiAddComponent, {

      maxHeight: 700, width: '600px', data: {
        screens: this.selectedScreens, screenId: this.screenIds, type: type
      }
    })

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.save) {
          this.getScreens(this.params)
          this.screenIds = []
          this.selectedScreens = []
          this.isAllSelected = false
        }
      })
  }
  redirectToMaps(latitude: number, longitude: number) {
    if (latitude && longitude) {
      let url = `https://maps.google.com/?q=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }


  handlePageEvent(e: PageEvent) {
    this.params.pageNumber = e.pageIndex;
    this.params.pageSize = e.pageSize;
    let params = this.params;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(this.params);
  }

  handleSortEvent(sort: Sort) {
    this.params.pageNumber = 0;
    this.params.sortBy = sort?.active || this.params.sortBy;
    this.params.sortDirection = sort?.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc';
    let params = this.params;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(this.params);
  }

  onToggle($event, screen, isActive: boolean) {
    $event.stopPropagation();

    this.screenApi.activateScreen(screen.id, { activate: isActive })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  showScreensInGroup($event: number) {
    this.params.pageNumber = 0;
    this.params.pageSize = 10;
    let params = this.params;
    if ($event)
      params.screenGroupId = $event;
    if (this.customerId)
      params.customerIds = [this.customerId];
    this.getScreens(params)
    this.showScreensFromGroup = !!$event;
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }

  computeScreenStatuses(screens: ScreenListItem[]): void {
    this.dataSource.data = screens.map(screen => {
      const status = this.serviceStatus(screen);
      return { ...screen, status } as ScreenListItem;
    });
  }

  serviceStatus(screen: Screen): { color: string; label: string; tooltip?: string } {
    let tooltipMsg;
    if (screen.aliveStatus === ScreenAliveStatusEnum.NotAlive) {
      tooltipMsg = this.getOfflineTooltipMessage(screen);
      
      return { color: 'bg-brand-red text-white', label: 'global.offline', tooltip: tooltipMsg };
    }
  
    if (screen.asleepStatus === ScreenAsleepStatusEnum.Asleep) {
      tooltipMsg = this.translateConfigService.instant('screens.sleepModeTooltip');
      
      return { color: 'bg-gray-800 text-white', label: 'global.sleep', tooltip: tooltipMsg };
    }
  
    if (screen.screenServicesStateData && this.areAnyServicesDown(screen.screenServicesStateData)) {
      tooltipMsg = this.getServicesTooltipMessage(screen.screenServicesStateData);

      return { color: 'bg-yellow-500 text-black', label: 'global.warning', tooltip: tooltipMsg };
    }
     
    screen.asleepStatus === ScreenAsleepStatusEnum.Awake &&
    screen.aliveStatus === ScreenAliveStatusEnum.Alive &&
    !this.areAnyServicesDown(screen.screenServicesStateData)

    return { color: 'bg-brand-green text-white', label: 'global.online' };
  
  }

  private getOfflineTooltipMessage(screen: Screen): string {
    if (screen.lastAliveDate) {
      const formattedDate = this.getFormattedDate(screen.lastAliveDate);
      return this.translateConfigService.instantWithParams('screens.errorTooltip', { lastAliveDate: formattedDate });
    }
    return this.translateConfigService.instantWithParams('screens.errorTooltip', { lastAliveDate: '/' });
  }

  private getFormattedDate(date: Date | string): string {
    return date ? this.datePipe.transform(date, 'YYYY-MM-dd HH:mm:ss') : '';
  }

  private areAnyServicesDown(screenServicesStateData: ScreenServicesStateData): boolean {
    return [
      screenServicesStateData?.advertiserServiceIsAlive,
      screenServicesStateData?.aiServiceIsAlive,
      screenServicesStateData?.redisIsAlive
    ].includes(false);
  }

  private getServicesTooltipMessage(screenServicesStateData: ScreenServicesStateData): string {
    if (this.hasAnyAliveDate(screenServicesStateData)) {      
      return this.getYellowTooltipMsg(screenServicesStateData);
    } else if (screenServicesStateData.aiFps.fps < screenServicesStateData.aiFps.fpsThreshold) {
      return this.translateConfigService.instantWithParams('screens.yellowLightFPSTooltip');
    } else {
      return this.translateConfigService.instantWithParams('screens.yellowLightTooltip');
    }
  }

  private hasAnyAliveDate(data: ScreenServicesStateData): boolean {
    return !!(data.aiServiceLastAliveDate || data.advertiserServiceLastAliveDate || data.redisLastAliveDate);
  }

  private getYellowTooltipMsg(screenServicesStateData: ScreenServicesStateData): string {
    const tooltipParts: string[] = [];

    const baseMessage = this.translateConfigService.instant('screens.yellowLightTooltip1');
    if (baseMessage) {
      tooltipParts.push(baseMessage);
    }

    const dateMap = [
      { date: screenServicesStateData.advertiserServiceLastAliveDate, translationKey: 'screens.advertiserLastAlive' },
      { date: screenServicesStateData.aiServiceLastAliveDate, translationKey: 'screens.aiLastAlive' },
      { date: screenServicesStateData.redisLastAliveDate, translationKey: 'screens.redisLastAlive' }
    ];
  
    dateMap.forEach(item => {
      if (item.date) {
        const formattedDate = this.getFormattedDate(item.date);
        tooltipParts.push(
          this.translateConfigService.instantWithParams(item.translationKey, { date: formattedDate })
        );
      }
    });

    return tooltipParts.join('\n');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

interface ScreenListItem extends Screen {
  customerName?: string;
  isSelected?: boolean;
  channelName?: string;
  defaultMediaName?: string;
  layout?: string;
  isGroup?: boolean;
  canBeDeleted?: boolean;
  screens?: ScreenListItem[] | MatTableDataSource<ScreenListItem>;
}

interface ScreenParams extends PaginatorParams {
  customerIds?: number[]
  screenGroupId?: number
}

interface ScreenBackendError {
  canBeDeleted: boolean;
  errorType: string | number;
  screenName: any;
  screenUsedAtEngagement: any[];
}