import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, takeUntil, tap, catchError, filter, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';

import { BookEngagement, Bookings, EngagementTypeEnum, BookingSlot, Placement } from '@api/index';
import { BOOK_SLOT_STATUS } from '@api/models/booking/engagement-slot';
import { UpdateAdvertisement } from '@api/models/booking/update-advertisment';
import { GeneralInfoForm, MarketingAutomationFormGroup, MediaFormGroup } from './book-form.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { TranslateConfigService } from '@core/translate-config.service';
import { BookFormActions, BookMediaActions } from '@store/book/book.actions';
import { BookingApiService } from '@api/services/booking-api.service';
import { BookingInfo, BookValidityModalAction } from '../book-models';
import { BookValidityModalComponent } from '../modals/book-validity-modal/book-validity-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BookService {
  private destroy = new Subject();
  private destroy$ = this.destroy.asObservable();

  private bookings: BehaviorSubject<Bookings[]> = new BehaviorSubject<Bookings[]>([]);
  private bookingInfo = new BehaviorSubject<any>(null);

  private validityModal = new Subject<boolean>();
  public validityModal$ = this.validityModal.asObservable();

  constructor(
    private bookingApiService: BookingApiService,
    public generalInfoForm: GeneralInfoForm,
    public mediaForm: MediaFormGroup,
    public marketingAutomationForm: MarketingAutomationFormGroup,
    public router: Router,
    private store: Store,
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService,
    private systemMessageService: SystemMessageService,
    private dialog: MatDialog
  ) {}

  getBookings() {
    return this.bookings.asObservable().pipe(
      filter(bookings => bookings.length > 0),
      tap(bookings => {
        const activeBookings = this.setActiveBookings(bookings);
        const selectedBookings = this.markSelectedBookings(activeBookings);
        this.generalInfoForm.get('bookings').patchValue(selectedBookings);
      })
    );
  }

  setBookings(bookings: Bookings[] | null) {
    const currentBookings = this.bookings.getValue();
    const uniqueBookings = this.filterDuplicates(currentBookings, bookings);
    this.bookings.next(uniqueBookings);
  }

  resetBookings() {
    this.bookings.next([]);
  }

  getBookingInfo(): Observable<BookingInfo> {
    return this.bookingInfo.asObservable().pipe(tap(bookingInfo => console.log('bookingInfo', bookingInfo)));
  }

  setBookingInfo(bookingInfo: BookingInfo) {
    this.bookingInfo.next({
      ...this.bookingInfo.getValue(),
      ...bookingInfo,
    });
  }

  selectBooking(booking: Bookings, day: DateTime) {
    const updatedScreenSlots = this.toggleAddRemoveSlot(booking, day);

    const bookings = this.bookings.getValue().reduce((acc, item) => {
      if (booking.device.id === item.device.id && booking.isScreenGroup === item.isScreenGroup) {
        acc.push({ ...booking, engagementSlots: updatedScreenSlots });
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    this.bookings.next(bookings);
  }

  markSelectedBookings(bookings: Bookings[]) {
    return bookings.reduce((acc, booking) => {
      const hasSelectedSlots = booking.engagementSlots.some(slot => slot.isSelected);
      if (hasSelectedSlots) {
        acc.push({
          ...booking,
          engagementSlots: booking.engagementSlots.filter(slot => slot.isSelected),
        });
      }
      return acc;
    }, []);
  }

  selectWeeklyBooking(checked: boolean, weekDates: DateTime[], booking: Bookings) {
    const selectedSlots = this.matchSelectedSlotsWithWeekDates(booking, weekDates);
    let updatedSlotsList;

    if (checked) {
      const filterTodayDate = slots => {
        const today = DateTime.now().startOf('day'); // Today's date at the start of the day
        let filtered = slots
          .filter(x => {
            return DateTime.fromISO(x.date) >= today && x.isBooked === BOOK_SLOT_STATUS.IS_BOOKED_BY_USER;
          })
          .map(slot => ({
            ...slot,
            isSelected: checked,
          }));
        return filtered;
      };

      const filteredAfterToday = filterTodayDate(selectedSlots);
      const uniqueUpdatedSlots = [...new Map([...booking?.engagementSlots, ...filteredAfterToday].map(slot => [slot.date, slot])).values()];
      updatedSlotsList = uniqueUpdatedSlots;
    } else {
      updatedSlotsList = this.removeSlots(booking.engagementSlots, weekDates);
    }

    const bookings = this.bookings.getValue().reduce((acc, item) => {
      if (booking.device.id === item.device.id && booking.isScreenGroup === item.isScreenGroup) {
        if (checked) {
          acc.push({ ...item, engagementSlots: updatedSlotsList });
        } else {
          acc.push({
            ...item,
            engagementSlots: item.engagementSlots.filter(x => {
              return !updatedSlotsList.includes(x);
            }),
          });
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    this.bookings.next(bookings);
  }

  removeSlots(slots, weekDays) {
    const today = DateTime.now().startOf('day');

    const filterIsBookedSlots = slots.filter(x => {
      return x.isBooked === BOOK_SLOT_STATUS.IS_BOOKED_BY_USER && DateTime.fromISO(x.date).startOf('day') >= today;
    });

    const weekDates = weekDays.map(day => day.toISODate());
    const filteredSlots = slots.filter(slot => {
      const isoDate = DateTime.fromISO(slot.date).startOf('day').toISODate();
      return weekDates.includes(isoDate);
    });

    const removedSlots = filteredSlots.filter(slot => {
      return filterIsBookedSlots.includes(slot);
    });

    return removedSlots;
  }

  filterDuplicates(state: any, data: any) {
    const combinedData = [...state, ...(data?.bookings || data)];

    const uniqueData = combinedData.reduce((acc, item) => {
      const existing = acc.find(t => {
        return this.checkIfBookingIsExisting(t, item);
      });

      if (existing) {
        // Check for duplicates in engagementSlots and take the new one
        const mergedEngagementSlots = [...existing.engagementSlots];

        item.engagementSlots.forEach(newSlot => {
          const existingSlotIndex = mergedEngagementSlots.findIndex(slot => {
            const slotDate = DateTime.fromISO(slot.date).startOf('day').toISODate();
            const newSlotDate = DateTime.fromISO(newSlot.date).startOf('day').toISODate();
            return slotDate === newSlotDate;
          });

          if (existingSlotIndex === -1) {
            mergedEngagementSlots.push(newSlot);
          }
        });

        // Create a new object to avoid mutating the existing one
        const mergedItem = {
          ...existing,
          engagementSlots: mergedEngagementSlots,
        };
        // Replace the existing item with the merged one
        acc = acc.map(t => (this.checkIfBookingIsExisting(t, item) ? mergedItem : t));
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    return uniqueData;
  }

  checkIfBookingIsExisting(acc: Bookings, item: Bookings) {
    return acc.device.id === item.device.id && acc.isScreenGroup === item.isScreenGroup;
  }

  toggleAddRemoveSlot(booking: Bookings, day) {
    // check if slot exists
    const existingSlotIndex = booking.engagementSlots.findIndex(slot => {
      const slotDate = DateTime.fromISO(slot.date).startOf('day');
      return slotDate.equals(day.startOf('day'));
    });

    // create new screen slots. immutable
    let updatedScreenSlots = [...booking.engagementSlots];

    if (existingSlotIndex > -1) {
      // if slot exists, isSelected is toggled
      updatedScreenSlots[existingSlotIndex] = {
        ...updatedScreenSlots[existingSlotIndex],
        isSelected: !updatedScreenSlots[existingSlotIndex].isSelected,
      };
    } else {
      // if slot does not exist, add it
      updatedScreenSlots.push({
        date: day.toISODate(),
        engagementId: null,
        engagementSlotId: null,
        isBooked: BOOK_SLOT_STATUS.IS_BOOKED_BY_USER,
        isSelected: true,
      });
    }

    return updatedScreenSlots;
  }

  // set the isBooked value to the booking engagements.
  mapBookingEngagementSlots(bookings: Bookings[], isBooked: BOOK_SLOT_STATUS, isSelected?: boolean): Bookings[] {
    return bookings.map(booking => {
      const slots = booking.engagementSlots.map(slot => ({
        ...slot,
        isBooked,
        isSelected,
      }));

      return {
        ...booking,
        engagementSlots: slots,
      };
    });
  }

  matchSelectedSlotsWithWeekDates(screen, weekDays) {
    const weekItems = weekDays.map(day => {
      // Find all items for the current day
      const matchingItems = screen.engagementSlots.filter(item => DateTime.fromISO(item.date).hasSame(day, 'day'));

      // Use the latest item for the day or an empty object if none exist
      if (matchingItems.length > 0) {
        return matchingItems.reduce((latest, current) => {
          if (DateTime.fromISO(current.date) > DateTime.fromISO(latest.date)) {
            return current;
          } else {
            return latest;
          }
        });
      }

      return {
        date: day.toISODate(),
        engagementId: null,
        engagementSlotId: null,
        isBooked: BOOK_SLOT_STATUS.IS_BOOKED_BY_USER,
      };
    });

    return weekItems.filter(days => !!days);
  }

  getActiveStep() {
    const step = {
      'general-info': 0,
      'media': 1,
      'marketing-automation': 2,
    };

    const currentRoute = this.router.url.split('?')[0].split('/').pop();
    return step[currentRoute];
  }

  publishMediaApi(mediaForm: any, engagementId: number, engagementType: number) {
    if (engagementType === EngagementTypeEnum.Segment) {
      let mediaRequest = this.setMediaRequest(mediaForm, engagementType);
      return this.bookingApiService.updateMediaSegment(engagementId, mediaRequest);
    } else {
      let playlistRequest = this.setPlaylistRequest(mediaForm);
      return this.bookingApiService.updateMediaPlaylist(engagementId, playlistRequest);
    }
  }

  setPlaylistRequest(mediaForm: any) {
    let mediaRequest: UpdateAdvertisement = {
      playlistMediaFiles:
        mediaForm.advertisementDetails.map((adv, index) => {
          return {
            id: adv.id,
            isRemoved: adv.isRemoved,
            presentationTime: adv.presentationTime,
            mediaId: adv.mediaDetails?.mediaId,
            mediaName: adv.mediaDetails?.name,
            mediaType: adv.mediaDetails?.type,
            triggers: adv.triggers || null,
            orderId: index + 1,
          };
        }) || [],
    };
    return mediaRequest;
  }

  setMediaRequest(mediaForm: any, engagementType?: number) {
    let mediaRequest: UpdateAdvertisement = {
      advertisements:
        mediaForm.advertisementDetails.map(adv => {
          const isSegment = engagementType == EngagementTypeEnum.Segment;
          return {
            id: adv.id,
            isRemoved: adv.isRemoved,
            presentationTime: adv.presentationTime,
            mediaId: adv.mediaDetails?.mediaId,
            name: adv.mediaDetails?.name,
            triggers: adv.triggers || null,
            ...(isSegment && { targetGroups: adv.targetGroups }),
          };
        }) || [],
      defaultMediaId: mediaForm.defaultMediaDetails?.mediaId || null,
      defaultMediaPresentationTime: mediaForm.defaultMediaPresentationTime || mediaForm.defaultMediaDetails?.duration || null,
    };
    return mediaRequest;
  }

  formatBookingRequest(bookForm: any): BookEngagement {
    const bookingSlots = bookForm.bookings
      .map((booking: Bookings) => ({
        screenIds: booking.screens.map(s => s.id),
        engagementSlots: booking.engagementSlots
          .filter(slot => slot.isBooked === BOOK_SLOT_STATUS.IS_BOOKED_BY_USER)
          .map(slot => ({
            startDate: slot?.date,
          })),
      }))
      .filter(booking => booking.engagementSlots.length > 0) as BookingSlot[];

    return {
      customerId: bookForm.customer || 0,
      engagementType: bookForm.engagementType || null,
      name: bookForm.name || '',
      bookingSlots: bookingSlots || [],
    };
  }

  setActiveBookings(bookings: any) {
    return this.filterBookings(bookings);
  }

  filterPlacements(placements) {
    const filteredPlacements = placements.reduce((acc, item) => {
      // Check if the item with this id already exists in the accumulator
      const existingItem = acc.find(({ id }) => id === item.id);

      if (existingItem) {
        // If it exists, push the screen to the screens array
        existingItem.screenIds.push(item.screen.id);
      } else {
        // If it doesn't exist, create a new entry in the accumulator
        const { screen, ...rest } = item;
        acc.push({
          ...rest,
          screenIds: [screen.id],
        });
      }

      return acc;
    }, []);
    return filteredPlacements;
  }

  private filterBookings(bookings) {
    return bookings
      .map(booking => ({
        ...booking,
        engagementSlots: booking.engagementSlots.filter(slot => slot.isBooked === BOOK_SLOT_STATUS.IS_BOOKED_BY_USER),
      }))
      .filter(b => b.engagementSlots.length > 0);
  }

  getScreenIds(formPlacements: number[], placements: Placement[]) {
    return (
      (formPlacements &&
        placements
          .filter(placement => {
            return formPlacements.find(formPlacementId => formPlacementId === placement.id);
          })
          .flatMap(placement => placement.screenIds)) ||
      placements.flatMap(placement => placement.screenIds)
    );
  }

  formatFormFilters(data: any, customerId: number) {
    const channels = data?.channels.filter(c => c.isSelected).map(c => c.id) || [];
    const placements = data?.placements.filter(p => p.isSelected).map(c => c.id) || [];
    const bookingFormData = {
      customer: data?.customer.id || customerId || 0,
      channels,
      placements,
    };

    return bookingFormData;
  }

  formatForm(data: any, isCopy: boolean) {
    const engagementType = data?.engagementType === EngagementTypeEnum.Segment ? 'segment' : 'playlist';
    let engagementName = data?.booking?.name || data?.[engagementType]?.name;

    if (isCopy) {
      engagementName = `${engagementName} - ${this.translateService.instant('global.copy')}`;
    }

    const bookingFormData = {
      ...(engagementName && { name: engagementName }),
    };

    return bookingFormData;
  }

  openValidityModal(activeForm: { form: FormGroup; onSave: () => void; onDiscardChanges: () => void }) {
    const dialogRef = this.dialog.open(BookValidityModalComponent, {
      width: '500px',
      data: {
        formInvalid: activeForm?.form?.invalid,
        save: () => {
          activeForm.onSave();
        },
        discard: () => {
          activeForm.onDiscardChanges();
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((action?: BookValidityModalAction) => {
        if (
          action === BookValidityModalAction.CANCEL ||
          action === undefined ||
          (action === BookValidityModalAction.SAVE && !activeForm.form.valid)
        ) {
          return;
        }
      });
  }

  discardChanges(activeStep: number) {
    this.validityModal.next(true);

    switch (activeStep) {
      case 0:
        this.generalInfoForm.markAsPristine();
        // Reset the bookings observable
        this.resetBookings();
        break;
      case 1:
        this.mediaForm.markAsPristine();
        break;
      case 2:
        this.marketingAutomationForm.markAsPristine();
        break;
    }
  }

  publishBooking(activeStep: number, engagementId: number, engagementType: number) {
    switch (activeStep) {
      case 0:
        this.publishGeneralInfo(engagementId, engagementType).subscribe();
        break;
      case 1:
        this.publishMedia(engagementId, engagementType).subscribe();
        break;
      case 2:
        this.publishMarketingAuto(engagementId, engagementType).subscribe();
        break;
    }
  }

  publishGeneralInfo(engagementId: number, engagementType: number): Observable<any> {
    if (!this.generalInfoForm.valid) {
      this.systemMessageService.error(this.translateConfigService.instant('book.formErrorMessage'));
      return of(null);
    }

    const formatedBooking = this.formatBookingRequest({
      engagementType: engagementType,
      ...this.generalInfoForm.value,
    });

    return this.bookingApiService.updateGeneralInfo(engagementId, formatedBooking).pipe(
      tap(() => {
        this.handlePublishGeneralInfoSuccess(engagementType);
      }),
      catchError(error => {
        console.error('Error:', error);
        this.validityModal.next(false);
        return of(null);
      }),
      takeUntil(this.destroy$)
    );
  }

  handlePublishGeneralInfoSuccess(engagementType: any) {
    const message = this.translateConfigService.instantWithParams(
      engagementType == EngagementTypeEnum.Playlist ? 'notifications.success.playlistEdit' : 'notifications.success.segmentEdit',
      { text: this.generalInfoForm.value.name }
    );
    this.systemMessageService.success(message);
    this.generalInfoForm.markAsPristine();
    this.store.dispatch(BookFormActions(this.generalInfoForm.value));
    this.validityModal.next(true);
  }

  publishMedia(engagementId: number, engagementType: number): Observable<any> {
    if (!this.mediaForm.valid) {
      this.systemMessageService.error(this.translateConfigService.instant('book.mediaErrorMessage'));
      return of(null);
    }

    const { presentationTime, ...form } = this.mediaForm.getRawValue();

    return this.publishMediaApi(form, engagementId, engagementType).pipe(
      tap((data: any) => {
        this.handlePublishMediaSuccess(engagementType, data);
      }),
      catchError(error => {
        console.error('Error:', error);
        this.validityModal.next(false);
        return of(null);
      }),
      takeUntil(this.destroy$)
    );
  }

  handlePublishMediaSuccess(engagementType: any, data: any) {
    this.mediaForm.patchValue(data);
    this.mediaForm.markAsPristine();
    this.store.dispatch(BookMediaActions.loadBookingMediaSuccess({ data }));

    const message = this.translateConfigService.instantWithParams(
      engagementType == EngagementTypeEnum.Playlist ? 'notifications.success.playlistEdit' : 'notifications.success.segmentEdit',
      { text: this.generalInfoForm.value.name }
    );
    this.systemMessageService.success(message);
    this.validityModal.next(true);
  }

  publishMarketingAuto(engagementId: number, engagementType: number): Observable<any> {
    if (!this.marketingAutomationForm.valid) {
      this.systemMessageService.error(this.translateConfigService.instant('book.mediaErrorMessage'));
      return of(null);
    }

    let marketingAutomationFormData = this.marketingAutomationForm.getRawValue();

    const marketingAutomationData = {
      isEnabled: marketingAutomationFormData.isEnabled,
      marketingAutomation: {
        advancedSettings: marketingAutomationFormData.marketingAutomation.advancedSettings,
        childPlayListId: marketingAutomationFormData.marketingAutomation.childPlayListId,
        engagementName: marketingAutomationFormData.marketingAutomation.engagementName || this.generalInfoForm.value.name,
        sensitivitySettings: marketingAutomationFormData.marketingAutomation.sensitivitySettings,
        hasAdvancedSettings: marketingAutomationFormData.marketingAutomation.hasAdvancedSettings || false,
        playlistMediaFiles: marketingAutomationFormData.marketingAutomation.playlistMediaFiles.map((m, index) => {
          return {
            ...m,
            mediaId: m?.media?.mediaId,
            mediaName: m?.media?.name,
            mediaType: m?.media?.type,
            orderId: index + 1,
          };
        }),
      },
    };

    return this.bookingApiService.updateMarketingAutomation(marketingAutomationData, engagementId).pipe(
      tap((data: any) => {
        this.handlePublishMarketingAutoSuccess(data);
      }),
      catchError(error => {
        console.error('Error:', error);
        this.validityModal.next(false);
        return of(null);
      }),
      takeUntil(this.destroy$)
    );
  }

  handlePublishMarketingAutoSuccess(data: any) {
    const message = this.translateConfigService.instantWithParams('notifications.success.segmentEdit', {
      text: this.generalInfoForm.value.name,
    });
    this.systemMessageService.success(message);
    this.marketingAutomationForm
      .get('marketingAutomation')
      ?.get('playlistMediaFiles')
      .patchValue(data.childPlaylistDetails.childPlaylistMediaFiles);
    this.marketingAutomationForm.markAsPristine();
    this.validityModal.next(true);
  }

  destroyBooking() {
    this.destroy.next(true);

    // Reset last stored values in BehaviourSubects
    this.bookings.next([]);
    this.bookingInfo.next(null);
  }
}
