import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AuthService, UserRoleEnum} from "@core/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Platform} from "@angular/cdk/platform";
import {ScreenApi} from "@api/services/screen-api";
import {SystemMessageService} from "@core/services/system-message.service";
import {unsubscribeMixin} from "@core/unsubscribe";
import {takeUntil} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {PaginatorParams} from "@api/models/paginator";
import {Sort} from "@angular/material/sort";
import {PageEvent} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";
import {
  DeleteScreneGroupModalComponent
} from "@admin/screen/delete-screne-group-modal/delete-screne-group-modal.component";
import { ScreenGroupApi } from '@api/services/screen-group-api';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'flow-screen-group-table',
  templateUrl: './screen-group-table.component.html',
  styleUrls: ['./screen-group-table.component.scss']
})
export class ScreenGroupTableComponent extends unsubscribeMixin() implements OnInit {

  @Output()
  public showScreensInGroup = new EventEmitter(null);

  displayedColumns: string[] = ['id', 'humanUniqueIdentifier', 'layout', 'devices'];
  customerId: number[];
  screenGroupData = []
  dataSource: MatTableDataSource<any>
  screenGroupId:number
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];

  params: screenGroupParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'humanUniqueIdentifier',
    sortDirection: 'asc'
  }

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public platform: Platform,
    private screenGroupApi: ScreenGroupApi,
    public systemMessageService: SystemMessageService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    if (!this.isAdmin) {
      this.displayedColumns.shift()
      this.displayedColumns = this.displayedColumns.concat(['actions']);
      const userCustomerId = user.roleId === UserRoleEnum.Admin ? user.currentCustomerId : user.customerId;
      this.customerId = [userCustomerId]
      this.getScreenGroups({...this.params, customerIds: this.customerId});
    } else {
      this.displayedColumns = this.displayedColumns.concat(['enable', 'actions']);
      this.getScreenGroups();
    }
  }

  getScreenGroups(params?: screenGroupParams): void {
    this.screenGroupData = [];
    this.screenGroupApi.getScreenGroups(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.params = {...this.params, pageNumber: data.currentPage};
        this.screenGroupData = data.items
        this.totalCount = data.totalCount
        this.dataSource = new MatTableDataSource(this.screenGroupData);

      })
  }

  showScreensInScreenGroup(screenGroupId: number): void {
    this.showScreensInGroup.emit(screenGroupId);
  }

  onDeleteClick($event: MouseEvent, screen) {
    if(screen?.canBeDeleted == false ) {
      this.dialog.open(DeleteScreneGroupModalComponent, {width: '500px'})
    } else {
      this.dialog.open(ConfirmationModalComponent, {
        width: '500px',
        data: {
          remove: () => this.onRemoveScreenGroup(screen),
          logic: true
  
        }
      })
    }
  }

  onRemoveScreenGroup(screen) {
    this.screenGroupApi.deleteScreenGroup(screen).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      this.systemMessageService.success(this.translateService.instant('notifications.success.screenGroupDelete'))
      if (!this.isAdmin) {
        this.getScreenGroups({...this.params, customerIds: this.customerId})
      }
      else {
        this.displayedColumns = this.displayedColumns.concat(['enable', 'actions']);
        this.getScreenGroups();
      }
      this.dialog.closeAll();
    });
  }

  onToggle($event, screenGroup, isActive: boolean) {
    $event.stopPropagation();

    this.screenGroupApi.activateScreenGroup(screenGroup.id, {activate: isActive})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( );
  }


  handleSortEvent(e: Sort) {
    this.params = { ...this.params, customerIds: this.customerId, pageNumber: 0, pageSize: 10, sortBy: e?.active, sortDirection: e?.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getScreenGroups(this.params)
  }

  handlePageEvent(e: PageEvent) {
    this.params = { ...this.params,  customerIds: this.customerId, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.getScreenGroups(this.params)
  }
}

interface screenGroupParams extends PaginatorParams  {
  customerIds?: number[]
}
