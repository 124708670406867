<h1 mat-dialog-title>
    <mat-icon class="warning-icon">warning</mat-icon>
    <span>{{ data.msg.title | translate }}</span>
    <a class="mat-dialog-close-button"
       mat-icon-button
       [mat-dialog-close]="true"
       tabindex="0">
        <mat-icon color="warn">close</mat-icon>
    </a>
</h1>
<div mat-dialog-content
     class="py-2">
    <div class="flex flex-col h-full">
        <div class="mb-2">{{data.msg.content}}</div>
        <div class="mb-2">{{data.msg.preText}}</div>
        <div class="flex-1 max-h-[50vh] overflow-auto">
            <ul class="bullet-class" [innerHTML]="data.msg.listContent"></ul>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button
            mat-dialog-close
            type="button">{{'global.cancel' | translate}}</button>
</div>