import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fromEvent, map, Subscription} from "rxjs";
import {CurrentUser} from "@core/auth/current-user";
import {StyleManager} from "@core/services/style-manager.service";
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {MediaMatcher} from "@angular/cdk/layout";
import {AppService} from "@app/app.service";
import {TranslateConfigService} from "@core/translate-config.service";
import {AuthService} from "@core/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {LanguageModalComponent} from "@shared/language-modal/language-modal.component";
import {environment} from "@environments/environment";

@Component({
  selector: 'flow-private-layot',
  templateUrl: './private-layot.component.html',
  styleUrls: ['./private-layot.component.scss']
})
export class PrivateLayotComponent implements OnInit {
  @ViewChild('top') top: ElementRef;

  routesWithoutDashboard = ['/login', '/register', '/confirm-email', '/choose-tenant'];
  currentLanguage: string = "";
  currentRoute: string;
  private loggedInSubscription: Subscription;
  public isLoggedIn: boolean;
  private _modeOverQueryListener: (event: MediaQueryListEvent) => void;
  showNavigateToTopBtn = false;
  showNavSettings = false;
  currentUser: CurrentUser;
  initials=''

  constructor(
    private styleManager: StyleManager,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public appService: AppService,
    private transalteConfigService: TranslateConfigService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {
    this.isLoggedIn = authService.isLoggedIn();
    this.currentRoute = this.router.url;
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.appService.modeOverQuery = media.matchMedia('(max-width: 1200px)');
    this._modeOverQueryListener = (event: MediaQueryListEvent) => {
      this.appService.navClosed = event.matches;
      changeDetectorRef.detectChanges();
    };
    this.appService.modeOverQuery.addEventListener('change', this._modeOverQueryListener);
    this.appService.isDark = this.styleManager.isDark;
    this.currentLanguage = this.transalteConfigService.currentLanguage;
  }

  ngAfterViewInit(): void {
    const content = document.querySelector('.content-container');
    const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));

    scroll$.subscribe(element => {
      const topElement = this.top.nativeElement;

      this.showNavigateToTopBtn = topElement.getBoundingClientRect().bottom < 0;
    });
  }

  ngOnInit(): void {
    if(!this.isLoggedIn)
      this.authService.user$.subscribe(user => {
        this.currentUser = user
        this.initials = this.getInitials(this.currentUser?.fullName)
      });

    this.currentUser = this.authService.getUser()
    this.initials = this.getInitials(this.currentUser?.fullName)

    this.appService.navClosed = this.appService.modeOverQuery.matches;
    this.loggedInSubscription = this.authService.isLoggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
      this.currentUser = this.authService.getUser()
      this.initials = this.getInitials(this.currentUser?.fullName)
      this.currentLanguage = this.transalteConfigService.currentLanguage;
    });
  }

  ngOnDestroy(): void {
    this.appService.modeOverQuery.removeEventListener('change', this._modeOverQueryListener);
    this.loggedInSubscription.unsubscribe();
  }

  toggleSidenav() {
    this.appService.navClosed = !this.appService.navClosed;
  }

  toggleDarkTheme() {
    this.styleManager.toggleDarkTheme();
    this.appService.isDark = !this.appService.isDark;
  }

  changeLanguage(lang: string) {
    this.transalteConfigService.changeLanguage(lang);
    this.currentLanguage = lang;
  }

  openLanguageModal(): void {
    this.dialog.open(LanguageModalComponent, {
      width: '1000px',
      height: '70vh',
      data: {
        changeLanguage: (lang: string) => this.changeLanguage(lang)
      }
    });
  }

  navigateToWiki() {
    window.open(environment.wikiUrl, '_blank').focus();
  }

  scrollToTop() {
    this.top.nativeElement.scrollIntoView({block: "start", behavior: 'smooth'});
  }

  navigationInterceptor(event: RouterEvent): void {
    const hasToolbarFormActions = this.appService.hasToolbarFormActions;
    if (event instanceof NavigationStart) {
      this.appService.hasToolbarFormActions = false;
      this.appService.navHidden = this.routesWithoutDashboard.some(route => event.url.includes(route));
    }
    if (event instanceof NavigationEnd) {
      // on navigation end
    }
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.appService.hasToolbarFormActions = hasToolbarFormActions;
    }
  }

  showSettings(e) {
    e.stopPropagation();
    e.preventDefault()
    this.showNavSettings = true
  }

  closeSettings(e) {
    this.showNavSettings = false
  }
  redirectToHomePage() {
    this.router.navigate(['/home']);
  }
  private getInitials(name) {
    return name?.split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }

}
