<div>
  <h1 *ngIf="!editMode" class="category-title">{{ 'poi.category.addCategory' | translate }}</h1>
  <h1 *ngIf="editMode" class="category-title">{{ 'poi.category.editCategory' | translate }}</h1>
</div>
<div class="form-container w-full flex gap-10 mt-[40px]">
  <div class="w-[362px]">
    <form [formGroup]="form" class="w-[362px] flex flex-col gap-4">
      <div class="self-stretch py-2 rounded justify-start items-center gap-2 inline-flex">
        <div class="justify-start items-center gap-2 flex">
          <app-icon iconName="annotation-info"
                    size="md"
                    class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                    stroke="#598fae"></app-icon>
        </div>
        <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'book.generalInformation' | translate}}
        </div>
      </div>

      <div *ngIf="isAdmin">
        <mat-label>{{ 'poi.form.customer' | translate }} *</mat-label>
        <mat-form-field appearance="outline" class="w-full">
          <mat-select formControlName="customerId" placeholder="{{'poi.form.customer' | translate}}">
            <mat-option (onSelectionChange)="changeCustomer(customer.id, $event)"
                        *ngFor="let customer of customers"
                        [value]="customer.id">
              {{ customer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-label>{{ 'poi.form.name' | translate }} *</mat-label>
        <mat-form-field appearance="outline" class="w-full">
          <input formControlName="name"
                 matInput
                 type="text"
                 placeholder="{{ 'poi.form.name' | translate }}"
          >
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-checkbox class="flex items-start" color="primary"
                      formControlName="doNotCountInStatistics">
          <span class="break-words whitespace-normal text-[#202020] text-sm font-normal leading-[18px]">{{ 'poi.category.form.doNotCountInStatistics' | translate }}</span>
        </mat-checkbox>
      </div>

      <div class="w-full flex">
        <mat-checkbox class="flex items-start" color="primary"
                      (click)="shouldSendSms()"
                      formControlName="shouldSendSmsNotifications">
          <span class="break-words whitespace-normal text-[#202020] text-sm font-normal leading-[18px]">{{ 'poi.category.form.sendSms' | translate }}</span>
        </mat-checkbox>
        <app-icon [matTooltip]="'poi.category.form.tooltip' | translate"
                  iconName="info"
                  size="sm"
                  style="margin-left: 5px;"
        ></app-icon>
      </div>

      <div class="flex flex-col gap-4" *ngIf="form.get('shouldSendSmsNotifications').value">
        <div>
          <mat-label><span style="display:  flex"> {{ 'poi.category.form.contactPerson' | translate }} *</span>
          </mat-label>
          <mat-form-field [hideRequiredMarker]="true" appearance="outline" class="w-full">

            <mat-select formControlName="contactPersonIds"
                        placeholder="{{ 'poi.category.form.contactPerson' | translate }}"
                        multiple>
              <mat-option *ngFor="let contact of contactPersons"
                          [value]="contact.id">
                {{ contact.name }}<span
                *ngIf="contact?.channels.length > 0"> ({{ 'poi.category.form.channels' | translate }}<span
                *ngFor="let chanel of contact?.channels,let last = last "> {{ chanel.name }}<span *ngIf="!last">,</span> </span>)</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-checkbox class="flex items-start"
                      color="primary"
                      formControlName="shouldSendSmsForInboundScreens">
          <span class="break-words whitespace-normal text-[#202020] text-sm font-normal leading-[18px]">{{ 'poi.category.form.smsInbound' | translate }}</span>
        </mat-checkbox>

        <mat-checkbox class="flex items-start"
                      color="primary"
                      formControlName="shouldSendSmsForOutboundScreens">
          <span class="break-words whitespace-normal text-[#202020] text-sm font-normal leading-[18px]">{{ 'poi.category.form.smsOutbound' | translate }}</span>
        </mat-checkbox>

        <mat-checkbox class="flex items-start"
                      color="primary"
                      formControlName="shouldSendSmsForNotApplicableScreens">
          <span class="break-words whitespace-normal text-[#202020] text-sm font-normal leading-[18px]">{{ 'poi.category.form.smsSensor' | translate }}</span>
        </mat-checkbox>

        <p>
          {{ 'poi.category.form.sendMaxSms' | translate }}
          <span class="mr-1 ml-1">
            <mat-form-field appearance="outline" style="width: 4rem">
              <input [min]="1" formControlName="maxSms" matInput type="number">
            </mat-form-field>
          </span>
          {{ 'poi.category.form.notificationWithin' | translate }}
          <span>
            <mat-form-field appearance="outline" style="width: 4rem">
              <input [min]="1" formControlName="maxMinutes" matInput type="number">
            </mat-form-field>
            {{ 'poi.category.form.minutes' | translate }}
          </span>
          .
        </p>
      </div>
    </form>
  </div>
  <div class="w-full flex justify-between">
    <div class="w-[378px] p-4 bg-white rounded flex-col justify-start items-start gap-2 inline-flex">
      <div class="h-4 px-2 border-l-4 border-slate-500 justify-center items-start gap-2 inline-flex">
        <div class="text-zinc-600 text-xs font-normal font-['Inter'] leading-[18px]">{{'global.note' | translate}}</div>
      </div>
      <div class="w-full text-[#595959] text-xs font-normal leading-[18px]">{{'poi.category.note' | translate}}</div>
    </div>
    <div class="w-fit flex items-start justify-end gap-2">
      <button (click)="onClose()"
              class="btn-cancel"
              mat-stroked-button
              type="button">
        {{ 'global.cancel' | translate }}
      </button>
      <button
        (click)="onSave()"
        [disabled]="form.invalid"
        class="btn-save"
        color="primary"
        mat-flat-button
        type="button">
        {{ (editMode ? 'global.save' : 'poi.add' ) | translate }}
      </button>
    </div>
  </div>
</div>

