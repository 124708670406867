import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[flowRange]'
})
export class RangeDirective implements OnInit {
  @Input("relevantValues") relevantValues;
  @Input("newSelectionValues") newSelectionValues;
  @Output() temperatureValuesChange = new EventEmitter<any>();
  position_min = 0;
  position_max = 0;
  currentValue = 0;
  defaultValues = { min: -50, max: 50 };

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const slider = this.renderer.createElement("div");
    this.renderer.addClass(slider, "slider");

    const track = this.renderer.createElement("div");
    this.renderer.addClass(track, "track");

    const range = this.renderer.createElement("div");
    this.renderer.addClass(range, "range");

    const relevant_range = this.renderer.createElement("div");
    this.renderer.addClass(relevant_range, "relevant-range");

    // Add relevant values
    for (let i = this.defaultValues.min; i <= this.defaultValues.max; i += 5) {
      const value = this.renderer.createElement("div");
      this.renderer.addClass(value, "relevant-value");
      value.textContent = i.toString();
      this.renderer.appendChild(relevant_range, value);
    }
    // relevant values end

    var percent_relevant_left =
      ((this.relevantValues.min - this.defaultValues.min) /
        (this.defaultValues.max - this.defaultValues.min)) *
      100;
    relevant_range.style.left = percent_relevant_left + "%";

    var percent_relevant_right =
      ((this.defaultValues.max - this.relevantValues.max) /
        (this.defaultValues.max - this.defaultValues.min)) *
      100;
    relevant_range.style.right = percent_relevant_right + "%";

    const thumb_left = this.renderer.createElement("div");
    this.renderer.addClass(thumb_left, "thumb");
    this.renderer.addClass(thumb_left, "left");
    this.renderer.setAttribute(
      thumb_left,
      "data-min",
      this.newSelectionValues.min || -50
    );
    var percent_left =
      (((this.newSelectionValues.min || -50) - this.defaultValues.min) /
        (this.defaultValues.max - this.defaultValues.min)) *
      100;
    thumb_left.style.left = percent_left + "%";
    range.style.left = percent_left + "%";

    const thumb_right = this.renderer.createElement("div");
    this.renderer.addClass(thumb_right, "thumb");
    this.renderer.addClass(thumb_right, "right");
    this.renderer.setAttribute(
      thumb_right,
      "data-max",
      this.newSelectionValues.max || 50
    );
    var percent_right =
      (((this.newSelectionValues.max || 50) - this.defaultValues.min) /
        (this.defaultValues.max - this.defaultValues.min)) * 100;
    thumb_right.style.right = 100 - percent_right + "%";
    range.style.right = 100 - percent_right + "%";

    this.renderer.appendChild(slider, track);
    this.renderer.appendChild(slider, range);
    this.renderer.appendChild(slider, relevant_range);
    this.renderer.appendChild(slider, thumb_left);
    this.renderer.appendChild(slider, thumb_right);

    const range_left = this.renderer.createElement("input");
    this.renderer.setAttribute(range_left, "type", "range");
    this.renderer.setAttribute(range_left, "min", this.defaultValues.min.toString());
    this.renderer.setAttribute(range_left, "max", this.defaultValues.max.toString());
    this.renderer.setAttribute(range_left, "step", "1");
    this.renderer.addClass(range_left, "input-left");
    this.renderer.setAttribute(
      range_left,
      "value",
      this.newSelectionValues.min || -50
    );

    const range_right = this.renderer.createElement("input");
    this.renderer.setAttribute(range_right, "type", "range");
    this.renderer.setAttribute(range_right, "min", this.defaultValues.min.toString());
    this.renderer.setAttribute(range_right, "max", this.defaultValues.max.toString());
    this.renderer.setAttribute(range_right, "step", "1");
    this.renderer.addClass(range_right, "input-right");
    this.renderer.setAttribute(
      range_right,
      "value",
      this.newSelectionValues.max || 50
    );

    this.elementRef.nativeElement.style = "display:none";
    this.renderer.appendChild(
      this.renderer.parentNode(this.elementRef.nativeElement),
      range_left
    );
    this.renderer.appendChild(
      this.renderer.parentNode(this.elementRef.nativeElement),
      range_right
    );
    this.renderer.appendChild(
      this.renderer.parentNode(this.elementRef.nativeElement),
      slider
    );

    this.renderer.listen(range_left, "input", event => {
      let positon = this.updateLefRange(range, range_right, range_left, thumb_left, event, event.target.value);
      if (positon)
        return true;
      else
        return false;
    });

    this.renderer.listen(range_right, "input", event => {
      let positon = this.updateRightRange(range, range_right, range_left, thumb_right, event, event.target.value);
      if (positon)
        return true;
      else
        return false;
    });
  }
  private roundToNearestMultipleOfFive(num: number): number {
    if (num >= 0) {
      return Math.ceil(num / 5) * 5;
    } else {
      return Math.floor(num / 5) * 5;
    }
  }
  updateLefRange(range, range_right, range_left, thumb_left, event, currentValue) {
    let position = +currentValue;
    const roundedPosition = this.roundToNearestMultipleOfFive(position);
    const rightValue = this.roundToNearestMultipleOfFive(Number(range_right.value));

    if (roundedPosition >= rightValue) {
      var percent = (((this.defaultValues.max + (rightValue - 5)) / 5) * 25) + 2;
      thumb_left.style.left = percent + "px";
      range.style.left = percent + "px";
      range_left.value = rightValue - 5;
      if (event)
        event.preventDefault();
      this.temperatureValuesChange.emit({min: rightValue - 5, max: rightValue});
      this.renderer.setAttribute(
        range_left,
        "value",
        (rightValue - 5).toString()
      );
      return false;
    }

    if (position < this.relevantValues.min) {
      var percent = (((this.defaultValues.max + roundedPosition) / 5) * 25) + 2;
      thumb_left.style.left = percent + "px";
      range.style.left = percent + "px";
      range_left.value = this.relevantValues.min;
      if (event)
        event.preventDefault();
      this.temperatureValuesChange.emit({min: this.relevantValues.min, max: rightValue});
      this.renderer.setAttribute(
        range_left,
        "value",
        this.relevantValues.min
      );
      return false;
    }

    thumb_left.setAttribute("data-min", +roundedPosition);
    var percent = (((this.defaultValues.max + roundedPosition) / 5) * 25) + 2;
    thumb_left.style.left = percent + "px";
    range.style.left = percent + "px";
    this.temperatureValuesChange.emit({min: roundedPosition, max: rightValue});
    this.renderer.setAttribute(
      range_left,
      "value",
      roundedPosition.toString()
    );
    return roundedPosition;
  }
  updateRightRange(range, range_right, range_left, thumb_right, event, currentValue) {
    let position = +currentValue;
    const roundedPosition = this.roundToNearestMultipleOfFive(position);
    const leftValue = this.roundToNearestMultipleOfFive(Number(range_left.value));

    if (roundedPosition <= leftValue) {
      var percent = (((this.defaultValues.max - (leftValue + 5)) / 5) * 25) + 1;
      thumb_right.style.right = percent + "px";
      range.style.right = percent + "px";
      range_right.value = leftValue+5;
      if (event)
        event.preventDefault();
      this.temperatureValuesChange.emit({min: leftValue, max: leftValue+5});
      this.renderer.setAttribute(
        range_right,
        "value",
        (leftValue + 5).toString()
      );
      return false;
    }
    if (roundedPosition > this.relevantValues.max) {
      var percent = (((this.defaultValues.max - roundedPosition) / 5) * 25) + 1;
      thumb_right.style.right = percent + "px";
      range.style.right = percent + "px";
      range_right.value = this.relevantValues.max;
      if (event)
        event.preventDefault();
      this.temperatureValuesChange.emit({min: leftValue, max: this.relevantValues.max});
      this.renderer.setAttribute(
        range_right,
        "value",
        this.relevantValues.max
      );
      return false;
    }

    thumb_right.setAttribute("data-max", +roundedPosition);
    var percent = (((this.defaultValues.max - roundedPosition) / 5) * 25) + 1;
    thumb_right.style.right = percent + "px";
    range.style.right = percent + "px";
    this.temperatureValuesChange.emit({min: leftValue, max: roundedPosition});
    this.renderer.setAttribute(
      range_right,
      "value",
      roundedPosition.toString()
    );
    return roundedPosition;
  }
}