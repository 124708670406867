import { FormControl, FormGroup } from '@angular/forms';
import { Channel, Customer, TimeframeEnum } from 'src/_api';
import { IdName } from '@api/models/idname';
import { DateTime } from 'luxon';

export const peopleTargetGroups = [
  // { label: 'Oidentifierad', value: NotSet },
  { label: 'peopleCount.targetGroups.under18', value: 1 },
  { label: 'peopleCount.targetGroups.18-24', value: 2 },
  { label: 'peopleCount.targetGroups.25-34', value: 3 },
  { label: 'peopleCount.targetGroups.35-44', value: 4 },
  { label: 'peopleCount.targetGroups.45-54', value: 5 },
  { label: 'peopleCount.targetGroups.55-64', value: 6 },
  { label: 'peopleCount.targetGroups.65+', value: 7 },
  { label: 'peopleCount.targetGroups.under18', value: 8 },
  { label: 'peopleCount.targetGroups.18-24', value: 9 },
  { label: 'peopleCount.targetGroups.25-34', value: 10 },
  { label: 'peopleCount.targetGroups.35-44', value: 11 },
  { label: 'peopleCount.targetGroups.45-54', value: 12 },
  { label: 'peopleCount.targetGroups.55-64', value: 13 },
  { label: 'peopleCount.targetGroups.65+', value: 14 },
];

export interface PeopleCounterStatisticsResponse {
  averageTimeReportPerHour: AverageTimeInChannelPerHour[];
  metricBoxLanes: MetricBoxLanes;
  topInboundForPeriod: IdName;
  topOutBoundForPeriod: IdName;
}
export interface MetricBoxLanes {
  daily: MetricBoxDaily;
  weekly: MetricBoxWeekly;
  topInboundForPeriod: IdName;
  topOutBoundForPeriod: IdName;
}
export interface MetricBoxDaily {
  totalToday: number;
  totalLastWeekCurrentDay: number;
  todayVsLastWeekDayPercentage: number;
  genderDistribution: GenderEnum;
  lastWeekCurrentDayTopGroup?: TopGroup;
  todayTopGroup?: TopGroup;
  currentPeopleInChannel?: number;
}
export interface MetricBoxWeekly {
  totalCurrentWeek: number;
  totalLastWeek: number;
  todayVsLastWeekPercentage: number;
  genderDistribution: GenderEnum;
  currentWeekTopGroup?: TopGroup;
  previousWeekTopGroup?: TopGroup;
}
export interface GenderEnum {
  Male?: number;
  Female?: number;
}
export interface TopGroup {
  gender: number;
  ageSpan: string;
}
export interface TopScreen {
  name: string;
  isSensor: boolean;
}
export interface GenderDistributionThisWeek {
  percentageOfMen: number;
  percentageOfWomen: number;
}
export interface AgeDistributionThisWeek {
  ageRangeDistribution: Array<AgeRangeDistribution>;
  total: number;
}
export interface AgeRangeDistribution {
  ageRange: string;
  totalMen: number;
  totalWomen: number;
  total: number;
}
export interface VisitorsPerHourWeekly {
  currentWeek: Array<HourlySeparatedTarget>;
  lastWeek: Array<HourlySeparatedTarget>;
}
export interface HourlySeparatedTarget {
  hour: number;
  totalMen: number;
  totalWomen: number;
}

export interface AverageTimeInChannelPerHour {
  hour: number;
  averageTimeInChannel: string;
  averageTimeInChannelMinutes: number;
}

export interface PeopleCountFormGroup {
  customer: FormControl<Customer>;
  channels: FormControl<Channel[]>;
  screens: FormControl<IdName[]>;
  timeframe: FormControl<number>;
  start: FormControl<DateTime>;
  end: FormControl<DateTime>;
  rangeCompare: FormGroup<{ start: FormControl<DateTime>; end: FormControl<DateTime> }>;
}

export interface VisitorsResponse {
  visitors: VisitorsPerHour[];
  compareVisitors: VisitorsPerHour[];
}

export interface VisitorsPerHour {
  hour: number;
  totalVisitors: number;
}

export interface VisitorsPerHourFormated {
  hours: string[];
  totalVisitors: number[];
}

export interface AgeDistribution {
  ageRange: string;
  totalMen: number;
  totalWomen: number;
  total: number;
}

export interface AgeRangeDistributionResponse {
  ageRangeDistribution: AgeDistribution[];
  total: number;
}

export interface GenderDistributionResponse {
  totalMen: number;
  totalWomen: number;
}

export interface AgeAndGenderDistribution {
  ageDistribution: AgeRangeDistributionResponse;
  compareAgeDistribution: AgeRangeDistributionResponse;
  compareGenderDistribution: GenderDistributionResponse;
  genderDistribution: GenderDistributionResponse;
  timeFrame: TimeframeEnum;
}

export interface AgeGenderChartData {
  menArray: number[];
  totalMen: number;
  womanArray: number[];
  totalWomen: number;
  totalCount: number;
  labels: string[];
}
