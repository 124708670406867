import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfiguration, BaseService, BatchPermissionAssignmentRequest, PermissionsConfig } from '@api/index';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionApi extends BaseService  {
  private baseUrl = `${this.rootUrl}/userpermissions`;

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  getPermissionsForUserInCustomers(userId: number, customerId?: number): Observable<PermissionsConfig> {
    let params: any = {};
    if (customerId) params.customerId = customerId;
    return this.http.get<PermissionsConfig>(`${this.baseUrl}/${userId}/customer-permissions`, {params});
  }

  assignPermissionsToUser(userId: number, customerId: number, permissions: string[]): Observable<any> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.post(url, { customerId, permissions });
  }

  assignPermissionsToUsers(request: BatchPermissionAssignmentRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/batch`, request);
  }

  getUserPermissions(customerId: number): Observable<PermissionsConfig> {
    const params = new HttpParams().set('customerId', customerId.toString());
    return this.http.get<PermissionsConfig>(`${this.baseUrl}`, { params });
  }
}
