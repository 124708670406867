import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {IdName} from "../../../../../_api/models/idname";

@Component({
  selector: 'flow-delete-category-warning-modal',
  templateUrl: './delete-category-warning-modal.component.html',
  styleUrls: ['./delete-category-warning-modal.component.scss']
})
export class DeleteCategoryWarningModalComponent implements OnInit {
  pois: IdName[]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.pois = this.data.pois
  }


}
