import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { SystemMessageService } from '../../services/system-message.service';
import { unsubscribeMixin } from '../../unsubscribe';
import { AuthService } from '../auth.service';
import { TranslateConfigService } from '../../translate-config.service';
import { AuthResponse } from '@api/models/user';

@Component({
  selector: 'flow-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends unsubscribeMixin() implements OnInit {
  loadingLogin = false;
  navigating = false;
  email: string;
  password: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService
  ) {
    super();
  }

  ngOnInit() { }

  onLogin(email: string, password: string) {
    this.loadingLogin = true;
    this.authService.login(email, password).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (response: AuthResponse) => {
        this.loadingLogin = false;
        this.navigating = true;
        if (response.multipleCustomersResponse && !response.user) {
          this.router.navigate(['/choose-tenant'], { state: { customers: response.multipleCustomersResponse.customers } });
        }
        else {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.loginSuccess", response.user.email));
          if (this.authService.previousUrl) {
            this.router.navigate([this.authService.previousUrl]);
          } else {
            this.router.navigate(['/home']);
          }
        }
      },
      error: () => {
        this.loadingLogin = false;
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
