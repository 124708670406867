import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Permissions } from '@app/shared/models/enums/permissions.enum';

@Component({
  selector: 'flow-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent {
  @Input() navMini = false;

  sidnevMenu = [
    {
      label: '',
      permissions: [null],
      children: [
        {
          label: 'sidebar.home',
          url: '/home',
          activeLink: '/',
          iconName: 'home-icon',
          permissions: [Permissions.All],
        },
      ],
    },
    {
      label: 'sidebar.sections.cms',
      permissions: [Permissions.ViewBookingCms, Permissions.ViewMediaCms],
      children: [
        {
          label: 'sidebar.manage',
          url: '/bookings',
          activeLink: '/bookings/list',
          iconName: 'overview-icon',
          permissions: [Permissions.ViewBookingCms],
        },
        {
          label: 'sidebar.media',
          url: '/medias',
          activeLink: '/medias',
          iconName: 'media-icon',
          permissions: [Permissions.ViewMediaCms],
        },
        {
          label: 'sidebar.mediaTransfer',
          url: '/media-transfer',
          activeLink: '/media-transfer',
          iconName: 'transfer',
          permissions: [Permissions.ViewMediaTransfer],
        },
      ],
    },
    {
      label: 'sidebar.sections.analytics',
      permissions: [
        Permissions.ViewReachAnalytics,
        Permissions.ViewReachReportsAnalytics,
        Permissions.ViewExposuresAnalytics,
        Permissions.ViewExposuresReportsAnalytics,
      ],
      children: [
        {
          label: 'sidebar.reach',
          url: '/statistics/reach',
          activeLink: '/statistics/reach/',
          iconName: 'reach-icon',
          permissions: [Permissions.ViewReachAnalytics, Permissions.ViewReachReportsAnalytics],
        },
        {
          label: 'sidebar.exposures',
          url: '/statistics/views',
          activeLink: '/statistics/views',
          iconName: 'exposures-icon',
          permissions: [Permissions.ViewExposuresAnalytics, Permissions.ViewExposuresReportsAnalytics],
        },
      ],
    },
    {
      label: 'sidebar.sections.visitorInsights',
      permissions: [
        Permissions.ViewPeopleCounterInsights,
        Permissions.ViewPeopleCounterReportsInsights,
        Permissions.ViewPoiInsights,
        Permissions.ViewPoiCategoriesInsights,
        Permissions.ViewPoiReportsInsights,
      ],
      children: [
        {
          label: 'sidebar.peopleCount',
          url: '/statistics/people-count',
          activeLink: '/statistics/people-count',
          iconName: 'people-counter-icon',
          permissions: [Permissions.ViewPeopleCounterInsights, Permissions.ViewPeopleCounterReportsInsights],
        },
        {
          label: 'sidebar.poi',
          url: '/person-of-interest',
          activeLink: '/person-of-interest',
          iconName: 'poi-icon',
          permissions: [Permissions.ViewPoiInsights, Permissions.ViewPoiCategoriesInsights, Permissions.ViewPoiReportsInsights],
        },
      ],
    },
  ];
}
