import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreadcrumbService } from '@app/core/services/breadcrumb.service';
import { Media } from '@app/core/services/media.service';

interface ModalData {
  customerIdModal: number;
  isDefault: boolean;
  multipleSelect: boolean;
}

@Component({
  selector: 'flow-media-select-modal',
  templateUrl: './media-select.modal.html',
  styleUrls: ['./media-select.modal.scss']
})
export class MediaSelectModal implements OnInit, OnDestroy {

  form: FormGroup;
  selectedMedias: Media[] = [];
  multipleSelect: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public dialogRef: MatDialogRef<MediaSelectModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
  }

  ngOnInit(): void {
    this.multipleSelect = this.data.multipleSelect;
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clearBreadcrumbs();
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSelected(media: Media | Media[]): void {
    this.selectedMedias = Array.isArray(media) ? media : [media];
  }

  onAdd(): void {
    this.dialogRef.close(this.selectedMedias);
  }
}

