const reach = [
  'channelIds',
  'placement',
  'screenIds',
  'includeDemographicalBreakdown',
  'includeHourlyBreakdown',
  'includeVehicleData',
];

const exposures = [
  'channelIds',
  'placement',
  'tracking',
  'screenIds',
  'bookingTypes',
  'clientIds',
  'bookingNumbers',
  'mediaFiles',
  'includePrimaryOrSecoundaryExposureBreakdown',
  'includeShareOfVoice',
  'includeExtendedStatistics',
  'includeReach',
  'includeDemographicalBreakdown',
];

const peopleCounter = [
  'channelIds',
  'placement',
  'tracking',
  'screenIds',
  'includeDemographicalBreakdown',
  'includeHourlyBreakdown',
];

const poi = [
  'poiCategoryName',
  'poiName',
  'channelIds',
  'placement',
  'tracking',
  'screenIds',
  'includePOIAdministrativeDetails',
  'includeSMSNotifications',
];

const filterConfig = { reach, exposures, peopleCounter, poi };
export default filterConfig;