import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/_api';
import { AuthService, UserRoleEnum } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  previousUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkForUser().pipe(
      map(
        (user: User) => {
          if (!route.data || !route.data['authorize']) {
            this.previousUrl = state.url;
            return true;
          }
          if (user.roleId !== UserRoleEnum.Admin) {
            return false;
          }
          if (user.currentCustomerId) {
            return false;
          }
          return true;
        }
      )

    );
  }
}
