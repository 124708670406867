import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { DateTime } from "luxon";
import { MediaFilterRequest } from "@api/models/medias/medias";
import { Customer, ImageToken, MediaFile, MediaTypeEnum, Tag } from "@api/index";
import { IdName } from "@api/models/idname";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private selectedFilters: BehaviorSubject<MediaFilterRequest> = new BehaviorSubject<MediaFilterRequest>(null);
  selectedFilters$ = this.selectedFilters.asObservable();

  private totalMedias: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalMedias$ = this.totalMedias.asObservable();

  setFilters(filters: MediaFilterRequest) {
    this.selectedFilters.next(filters)
  }

  setTotalMedias (total: number) {
    this.totalMedias.next(total)
  }

  constructor() { }

  getMedia(mediaFile: MediaFile | any, token: ImageToken): Media {
    if (!mediaFile || !token) {
      return null;
    }
    const mediaBaseUrl = `${environment.apiBaseUrl}/admin/medias`;
    const tbSize: ThumbnailSize = this.getThumbnailSize(mediaFile, 75);

    let mediaUrl: string, thumbnailUrl: string;
    if (mediaFile.type === MediaTypeEnum.Image) {
      mediaUrl = `${mediaBaseUrl}/token/${token.token}/images/${mediaFile.mediaId || mediaFile.id}`;
      thumbnailUrl = tbSize ? `${mediaBaseUrl}/images/${mediaFile.mediaId || mediaFile.id}/width/${tbSize.width}/height/${tbSize.height}/token/${token.token}` : null;
    } else if (mediaFile.type === MediaTypeEnum.Video) {

      mediaUrl = `${mediaBaseUrl}/token/${token.token}/videos/${mediaFile.mediaId || mediaFile.id}`;
    }

    return {
      name: mediaFile.name,
      tags: mediaFile.tags,
      customer: mediaFile.customer,
      client: mediaFile.client,
      filename: mediaFile.filename,
      created: mediaFile.created,
      id: mediaFile.mediaId || mediaFile.id,
      type: mediaFile.type,
      size: mediaFile.size,
      width: mediaFile.width,
      height: mediaFile.height,
      imageUrl: mediaUrl,
      thumbnailImageUrl: thumbnailUrl,
      duration: Number(mediaFile.duration?.toFixed(2)),
      lastUsed: mediaFile.lastUsed,
    }
  }

  getPoiMedia(mediaFile: MediaFile){
    const mediaBaseUrl = `/personOfInterests/images`;
    const tbSize: ThumbnailSize = this.getThumbnailSize(mediaFile, 75);
    let mediaUrl: string, thumbnailUrl: string;
    mediaUrl = `${mediaBaseUrl}/${mediaFile.id}`;
    thumbnailUrl = tbSize ? `${mediaBaseUrl}/${mediaFile.id}/width/${tbSize.width}/height/${tbSize.height}` : null;

    return {
      name: mediaFile.name,
      tags: mediaFile.tags,
      customer: mediaFile.customer,
      filename: mediaFile.filename,
      created:null,
      id: mediaFile.id,
      type: 1,
      size: mediaFile.size,
      width: mediaFile.width,
      height: mediaFile.height,
      imageUrl: mediaUrl,
      thumbnailImageUrl: thumbnailUrl
    }
  }

  getThumbnailSize(mediaFile: MediaFile, desiredWidth: number): ThumbnailSize {
    const fileAspectRatio = mediaFile.height / mediaFile.width;
    if (Number.isNaN(fileAspectRatio)) {
      return null;
    }
    return {
      width: desiredWidth,
      height: Math.round(fileAspectRatio * desiredWidth)
    };
  }
}
export function CustomEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const valid = emailRegex.test(control.value);
    return valid ? null : { invalidEmail: true };
  };
}
export interface Media {
  id: number;
  name: string;
  filename: string;
  type: number;
  created: DateTime;
  height?: number;
  width?: number;
  size?: number;
  duration?: number;
  tags: Tag[];
  customer: Customer;
  impressions?: number;
  lastUsed?: DateTime;
  client?: IdName;
  imageUrl: string;
  thumbnailImageUrl?: string | null;
  mediaCount?: number;
  thumbnailUrl?: string;
  folder?: IdName | null;
}

interface ThumbnailSize {
  width: number;
  height: number;
}
