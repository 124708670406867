<div [formGroup]="statisticsService.form" class="form">
    <mat-form-field *ngIf="input.type == 'calendar'"
                    (click)="preventDefault($event)"
                    class="input"
                    appearance="outline">
        <mat-label>{{input.label | translate }}</mat-label>
        <mat-date-range-input [formGroupName]="input.formControlName"
                              [rangePicker]="picker">
            <input matStartDate
                   [formControlName]="input.formGroup[0].formControlName"
                   [placeholder]="input.formGroup[0].placeholder | translate">
            <input matEndDate
                   [formControlName]="input.formGroup[1].formControlName"
                   [placeholder]="input.formGroup[1].placeholder | translate">
        </mat-date-range-input>
        <app-icon *ngIf="statisticsService.form.get(input.formControlName).get(input.formGroup[0].formControlName).value"
                  [ngStyle]="{cursor: 'pointer', position: 'absolute', right:'-9px', top:'7px'}"
                  (click)="clearDatePicker(input.formControlName, input.type == 'calendar')"
                  size="xl"
                  iconName="close"></app-icon>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div *ngIf="input.type == 'datepicker'" class="datepicker-styles">
       <mat-label>{{ input.label | translate }} *</mat-label>
       <mat-form-field
                    (click)="preventDefault($event)"
                    class="input select-override inline">
              <input matInput
                     [matDatepicker]="picker"
                     [formControlName]="input.formControlName"
                     [attr.min]="input.formControlName === 'start' ? statisticsService.form.get('start').value?.toString() : null"
                     [attr.max]="input.formControlName === 'end' ? statisticsService.form.get('end').value?.toString() : null"
                     (dateChange)="onFilterChange(input.formControlName)" />
              <app-icon *ngIf="statisticsService.form.get(input.formControlName).value"
                     [ngStyle]="{cursor: 'pointer', position: 'absolute', right:'-9px', top:'7px'}"
                     (click)="clearDatePicker(input.formControlName, input.type == 'calendar')"
                     size="xl"
                     iconName="close"></app-icon>
              <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
       </mat-form-field>
    </div>
</div>
