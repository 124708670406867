import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[flowTimeFormat]'
})
export class TimeFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 4) {
      value = value.substring(0, 4);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + ':' + value.substring(2);
    }

    input.value = value;
  }

  @HostListener('blur', ['$event']) onBlur(event: FocusEvent) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length === 1) {
      value = '0' + value + '00';
    } else if (value.length === 2) {
      value = value + '00';
    } else if (value.length === 3) {
      value = value.substring(0, 2) + '0' + value.substring(2);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + ':' + value.substring(2);
    }

    const [hours, minutes] = value.split(':').map(Number);
    if (hours > 23 || minutes > 59) {
      input.value = '';
    } else {
      input.value = value;
    }
  }
}
