/* tslint:disable */

import { EnumObject } from "@app/core/services/enum.service";
import { Customer } from "./customer";
import { UserRoleEnum } from "@app/core/auth/auth.service";

/* eslint-disable */
export interface User {
  customerIds?: null | number[];
  currentCustomerId?: null | number;
  email?: null | string;
  id?: number;
  name?: null | string;
  roleId?: null | number;
  groupId?: null | number;
  permissions?: string[];
}

export interface UserTableModel extends User {
  customers?: null | Customer[];
}

export interface UserCreateModel extends User {
  customerProfiles?: null | CustomerProfiles[];
}

export interface CustomerProfiles {
  customerId: number;
  name: string;
  profileName: string;
  profileId?: number;
  groupId?: number;
  isRemoved?: boolean;
}

export interface UserUpdateRequest {
  id?: number;
  name?: string;
  customerProfiles?: null | CustomerProfiles[];
}

export interface MultipleCustomersResponse {
  multipleCustomers: true;
  customers: { id: number; name: string }[];
}

export interface AuthResponse {
  user?: User;
  multipleCustomersResponse?: MultipleCustomersResponse;
}

export interface UserListItem
{
  id: number;
  email: string;
  role: EnumObject<UserRoleEnum>;
  roleId?: null | number;
  customers: CustomerResponse[];
  profiles: Profile[];
}

export interface CustomerResponse {
  id: number;
  name: string;
  profileName?: string;
}

export interface Profile {
  id: number;
  name: string;
  customerName?: string;
}