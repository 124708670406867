// EXAMPLE USAGE: *flowHasPermission="['ViewBookingCms', 'UpdateBookingCms']; operation 'OR'; disabled true"
// operation 'OR' is optional. It defaults to 'AND'
// disable is optional. It defaults to hidding the element. If disable true it adds opacity to element to simulate disabled state

import { Directive, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { STORAGE_KEY_USER } from '@app/constants';
import { UserRoleEnum } from '@app/core/auth/auth.service';
import { CurrentUser } from '@app/core/auth/current-user';
import { Permissions } from '../models/enums/permissions.enum';

type Operation = 'AND' | 'OR';

@Directive({
  selector: '[flowHasPermission]',
})
export class HasPermissionDirective implements OnInit {
  permissions!: string[];
  availablePermissions!: string[];
  logicalOp: Operation = 'AND';
  disabled = false;
  adminNav = false;
  userRole = UserRoleEnum.Admin;

  @Input()
  set flowHasPermission(permissions: any) {
    this.permissions = permissions;
  }

  @Input()
  set flowHasPermissionOperation(operation: Operation) {
    this.logicalOp = operation;
  }

  @Input()
  set flowHasPermissionDisabled(disabled: boolean) {
    this.disabled = disabled;
  }

  @Input()
  set flowHasPermissionAdminNav(adminNav: boolean) {
    this.adminNav = adminNav;
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private renderer: Renderer2) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem(STORAGE_KEY_USER)) as CurrentUser;
    this.userRole = user?.roleId;
    if (user) {
      this.availablePermissions = user.permissions.map(item => item.trim());
      this.updateView();
    }
  }

  updateView() {
    if (this.hasPermission()) {
      this.showComponent();
    } else {
      if (this.disabled || this.adminNav) {
        this.disableComponent();
      } else {
        this.removeComponent();
      }
    }
  }

  hasPermission() {
    let hasPermission = false;
    if (this.userRole == UserRoleEnum.Admin) return true;

    if (this.availablePermissions) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.availablePermissions.find(x => x.toLowerCase() === checkPermission.toLowerCase());

        if (checkPermission == Permissions.All) {
          hasPermission = true;
          break;
        }

        if (permissionFound) {
          hasPermission = true;

          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }

  showComponent() {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }

  removeComponent() {
    this.viewContainerRef.clear();
  }

  createSvg() {
    // Create the SVG element
    const svgElement = `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g id="lock-01">
            <path id="Icon" d="M14.1667 8.33333V6.66667C14.1667 4.36548 12.3012 2.5 10 2.5C7.69885 2.5 5.83337 4.36548 5.83337 6.66667V8.33333M10 12.0833V13.75M7.33337 17.5H12.6667C14.0668 17.5 14.7669 17.5 15.3017 17.2275C15.7721 16.9878 16.1545 16.6054 16.3942 16.135C16.6667 15.6002 16.6667 14.9001 16.6667 13.5V12.3333C16.6667 10.9332 16.6667 10.2331 16.3942 9.69836C16.1545 9.22795 15.7721 8.8455 15.3017 8.60582C14.7669 8.33333 14.0668 8.33333 12.6667 8.33333H7.33337C5.93324 8.33333 5.23318 8.33333 4.6984 8.60582C4.22799 8.8455 3.84554 9.22795 3.60586 9.69836C3.33337 10.2331 3.33337 10.9332 3.33337 12.3333V13.5C3.33337 14.9001 3.33337 15.6002 3.60586 16.135C3.84554 16.6054 4.22799 16.9878 4.6984 17.2275C5.23318 17.5 5.93324 17.5 7.33337 17.5Z" stroke="#BBBBBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>
      `;

    return svgElement;
  }

  createTextContent(viewRootElement: HTMLElement) {
    // Create a span element for the original content
    const contentElement = this.renderer.createElement('span');
    this.renderer.appendChild(contentElement, viewRootElement.firstChild);

    return contentElement;
  }

  disableComponent() {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];

    if (this.disabled && !this.adminNav) {
      viewRootElement.setAttribute('style', 'opacity: 0.5; pointer-events: none');
    }

    if (!this.disabled && this.adminNav) {
      const svg = this.createSvg();

      // Append the SVG and the original content to the container
      viewRootElement.innerHTML = `${svg} `;
    }

    if (this.disabled && this.adminNav) {
      const containerElement = this.renderer.createElement('div');
      this.renderer.setStyle(containerElement, 'display', 'flex');
      this.renderer.setStyle(containerElement, 'align-items', 'center');
      this.renderer.setStyle(containerElement, 'gap', '8px');

      // Create the SVG element
      const svgElement = this.createSvg();

      // Create a span element for the original content
      const contentElement = this.createTextContent(viewRootElement);

      // Append the SVG and the original content to the container
      containerElement.innerHTML = `${svgElement} `;
      this.renderer.appendChild(containerElement, contentElement);

      // Clear the original content and append the container element
      viewRootElement.innerHTML = '';
      this.renderer.appendChild(viewRootElement, containerElement);

      // Set the styles for the disabled state
      this.renderer.setStyle(viewRootElement, 'opacity', '0.5');
      this.renderer.setStyle(viewRootElement, 'pointer-events', 'none');
      this.renderer.setStyle(viewRootElement, 'background', '#f5f5f5');
    }
  }
}
