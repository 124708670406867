<div class="flex items-center justify-between">
  <div>
    <h2 class="reach-section-title">{{ "reach.reachDailyPerHour" | translate }}</h2>
  </div>
  <div>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div class="weekly-view">
        <mat-form-field  class="mt-4">
          <mat-label>{{'peopleCount.date' | translate}}</mat-label>
          <input matInput
                [max]="maxDate"
                [min]="minDate"
                [matDatepicker]="picker"
                [formControl]="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-menu>
  </div>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline selected-day">
        <span>{{ selectedDay }}</span>
</div>
<mat-divider></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate" class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
  <canvas baseChart height="50"></canvas>
</div>
<ng-template #chartTemplate>
  <canvas baseChart
    [data]="chartDataWithTranslation"
    [type]="'line'"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    height="80vh">
  </canvas>
</ng-template>
