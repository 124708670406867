import { AuthService, UserRoleEnum } from 'src/app/core/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flow-media-storage-info',
  templateUrl: './media-storage-info.component.html',
  styleUrls: ['./media-storage-info.component.scss']
})
export class MediaStorageInfoComponent implements OnInit {

  isAdmin: boolean;
  @Input() storageLimitSize: number;
  @Input() sizeOfMediaLibrary: number;
  @Input() currentFolderSize: number;
  @Input() sizeOfMediaAndFolders: number;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const user = this.authService.user
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
  }

  gigabytesToBytes(gigabytes) {
    const bytesInGigabyte = 1024 * 1024 * 1024;
    const bytes = gigabytes * bytesInGigabyte;
    return bytes;
  }

  calculateProgress(value1, value2) {
    if (value2) {
      return (value1 * 100) / this.gigabytesToBytes(value2);
    }
    return 100
  }
}
