import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { AbstractViewsComponent, GroupByData } from '../abstract-views-component';
import { EngagementTypeEnum, StatisticsEngagementMediaFilesResponse, StatisticsEngagementMediaFilesScreenDay, TargetGroupEnum } from 'src/_api';
import { takeUntil } from 'rxjs';
import { isEqual } from 'lodash-es';
import { ChartWeekday } from '../../statistics.service';
import { TargetGroupEnumTranslations } from 'src/app/admin/target-group/target-group-enum-translations';


const mapEmptyToNull = value => value || null

@Component({
  selector: 'flow-views-monthly',
  templateUrl: './views-monthly.component.html',
  styleUrls: ['./views-monthly.component.scss'],
})
export class ViewsMonthlyComponent extends AbstractViewsComponent {
  chartDataWithTranslation: ChartData<'bar'> = null;

  override chartOptions: ChartConfiguration['options'] = {
    ...this.chartOptions,
    plugins: {
      ...this.chartOptions.plugins,
      datalabels: {
        ...this.chartOptions.plugins.datalabels,
        rotation: -67
      }
    }
  };
  date = new FormControl(DateTime.local());

  onMonthChange(value: DateTime) {
    this.date.setValue(value);
    this.onPeriodChange()
  }

  getColumnType(): 'date' {
    return 'date'
  }

  override onPeriodChange() {
    this.updateData()
  }

  override updateData(): void {
    const { startTime, endTime } = this.getStartAndEndTime()
    if (!startTime || !endTime || !this.selected) return

    if (this.isTimespanInCurrentMonth()) {
      // Statistics is in current month, no need to fetch new data
      if (this.currentMonthStatistics?.engagementMediaFileId === this.selected.id) {
        this.statistics = this.currentMonthStatistics.statistics
        this.chartData = this.mapChartData(this.currentMonthStatistics.statistics)
        this.chart.update()
        this.chartDataWithTranslation = { ...this.getChartDataWithTranslations() }
      }
      return
    }
    this.statsRequest?.unsubscribe();
    let apiCall: string;
    let id: string;

    if (this.selected.engagementType === EngagementTypeEnum.Segment) {
      apiCall = "getAdvertisementStatistics";
      id = "advertisementId";
    }
    if (this.selected.engagementType === EngagementTypeEnum.Playlist) {
      apiCall = "getPlaylistMediaSettingsStatistics";
      id = "playlistMediaFileId";
    }
    this.statsRequest = this.statisticsApi[apiCall]({
      [id]: this.selected.id,
      body: {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
      }
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stats) => {
        if (this.statistics?.perScreenDay && isEqual(stats.perScreenDay, this.statistics.perScreenDay)) {
          return;
        }
        this.statistics = stats
        this.chartData = this.mapChartData(stats)
        this.chart.update();
        this.chartDataWithTranslation = { ...this.getChartDataWithTranslations() }
      });

  }

  override mapChartData(stats: StatisticsEngagementMediaFilesResponse): ChartData<'bar'> {
    const { perScreenDay, targetGroups } = stats
    const { startTime, endTime } = this.getStartAndEndTime()
    const days = this.statisticsService.buildPeriod(startTime, endTime, this.getColumnType());

    let datasets = []
    switch (this.groupByFC.value) {
      case GroupByData.Total:
      default:
        datasets = this.mapByTotal(days, perScreenDay, targetGroups)
        break;
    }
    const labels: string[] = days.map(day => day.label)
    return {
      labels,
      datasets
    };
  }

  override mapByTotal(days: ChartWeekday[], perDay: StatisticsEngagementMediaFilesScreenDay[], targetGroups: TargetGroupEnum[]) {
    if (this.selected.engagementType === EngagementTypeEnum.Playlist) {
      const targetGroups = perDay.reduce((acc, curr) => {
        curr.counts?.forEach(count => {
          if (!acc.includes(count.targetGroup) && count.targetGroup) {
            acc.push(count.targetGroup)
          }
        })
        return acc
      }, [])

      return targetGroups.map(targetGroupId => ({
        label: TargetGroupEnumTranslations[targetGroupId],
        data: days.map(day => {
          const counts = perDay.filter(d => d.date === day.utcString);
          const mapDaysCounts = counts.map((day) => day?.counts);
          const multipleCounts = mapDaysCounts.map((c) => {
            const countsForTargetGroup = c.find((count) => count.targetGroup === targetGroupId)
            return countsForTargetGroup?.count ?? 0
          })
          const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
          return add;
        }).map(mapEmptyToNull),
        ...this.targetGroupHelper.getChartColors(targetGroupId)

      }))
    }

    const primaryViews2 = days.reduce((acc, day) => {
      const counts = perDay.filter(d => d.date === day.utcString);
      const mapDaysCounts = counts.map((day) => day?.counts);
      const multipleCounts = mapDaysCounts.map((c) => {
        return c.filter((i) => targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0);
      })
      const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
      acc.push(add)
      return acc
    }, [])

    const secondaryViews2 = days.reduce((acc, day) => {
      const counts = perDay.filter(d => d.date === day.utcString);
      const mapDaysCounts = counts.map((day) => day?.counts);
      const multipleCounts = mapDaysCounts.map((c) => {
        return c.filter((i) => !targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0);
      })
      const add = multipleCounts.reduce((sum, curr) => sum + curr, 0)
      acc.push(add)
      return acc
    }, [])

    return [
      {
        label: 'exposures.primaryExposures',
        data: primaryViews2.map(mapEmptyToNull)
      },
      {
        label: 'exposures.secondaryExposures',
        data: secondaryViews2.map(mapEmptyToNull)
      }
    ]
  }


  getStartAndEndTime() {
    const month = this.date.value
    const day = month.startOf('day');
    const startTime = day.startOf('month');
    const endTime = day.endOf('month');

    return {
      startTime,
      endTime,
    }
  }

  getChartDataWithTranslations(): ChartData<'bar'> {
    if (this.chartData) {
      const data = {
        ...this.chartData,
        datasets: this.chartData.datasets.map((d) => ({ ...d, label: this.translateConfigService.instant(d.label) }))
      }
      return data
    }
    return { labels: [], datasets: [] }
  }
}
