import { Platform } from "@angular/cdk/platform";
import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { PaginatorParams } from "@api/models/paginator";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  of,
  Subscription,
  takeUntil
} from "rxjs";
import { AdminApi, Customer, PersonOfInteresetApi } from "../../../../../_api";
import { Category } from "../../../../../_api/models/category";
import { IdName } from "../../../../../_api/models/idname";
import { AuthService, UserRoleEnum } from "../../../../core/auth/auth.service";
import { indicateLoading } from "../../../../core/indicate-loading";
import { unsubscribeMixin } from "../../../../core/unsubscribe";
import { ConfirmationModalComponent } from "../../../../shared/confirmation-modal/confirmation-modal.component";
import {
  DeleteCategoryWarningModalComponent
} from "../delete-category-warning-modal/delete-category-warning-modal.component";

@Component({
  selector: 'flow-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends unsubscribeMixin() implements OnInit, OnChanges {
  displayedColumns: string[] = ['id', 'name', 'doNotCountInStatistics','shouldSendSmsNotifications',
    'contactPersons','shouldSendSmsForInboundScreens','shouldSendSmsForOutboundScreens','shouldSendSmsForNotApplicableScreens','maxSms', 'actions'];
  dataSource: MatTableDataSource<CategoryListItem>;
  customers: Customer[];
  isAdmin: boolean;
  userCustomers: Customer[]
  filterForm: FormGroup<CategoriesFilterFormGroup>;
  filterValues: CategoriesFilterValues;
  filterRequest: Subscription;
  customerId: number;
  panelOpenState = true;
  hideBox = false;
  loading$ = {
    channel: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }
  categoriesResponse: CategoryListItem []

  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  }
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];

  @Input() deletedPOI: boolean = false;
  @ViewChild('table') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    public platform: Platform,
    public poiApi: PersonOfInteresetApi,
    public dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFilter();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    if (!this.isAdmin) {
      this.customerId = user?.currentCustomerId || user?.customerId;
      this.filterForm.get('customerIds').setValue([this.customerId]);
      this.filterValues = { customerIds: [this.customerId] };
      this.onSubmit();
    } else {
      this.getCategories(this.params);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.deletedPOI.currentValue && !this.isAdmin) {
        this.getCategories({...this.params, customerIds: [this.customerId]});
      }
  }
  onSubmit() {
    if(this.filterValues) {
      this.onFilterChanges(this.filterValues)
    }
  }

  getCategories(params: CategoriesFilterValues): void {
    this.filterRequest?.unsubscribe();
    this.filterRequest = forkJoin([
      params.customerIds && params.customerIds.length > 0 ? this.poiApi.getCategories(params) : of(null),
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin ? this.adminApi.getCustomerById(this.customerId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe), indicateLoading(this.loading$.channel))
      .subscribe(([categoriesResponse, customersResponse, customerResponse]) => {
        const customersList = customersResponse || [customerResponse]
        this.totalCount = categoriesResponse?.totalCount
        if (!this.customers || this.customers?.length === 0) {
          this.customers = customersList.map((c) => ({ id: c.id, name: c.name }))
        }
          this.dataSource = new MatTableDataSource(categoriesResponse?.items || []);
      });
  }


  openCreateCategoryModal(e, categoryId: number, customerId: number){
    e.stopPropagation()
    this.router.navigate(['/categories', categoryId, customerId]);
  }


  onDeleteClick(e, category){
    e.preventDefault();
    e.stopPropagation();

    if(category.personsOfInterest.length > 0){
      this.dialog.open(DeleteCategoryWarningModalComponent, {
        width: '500px',
        data: {
          pois: category.personsOfInterest
        }
      })
    } else {
      this.dialog.open(ConfirmationModalComponent, {
        width: '500px',
        data: {
          remove: () => this.onRemovePOI(category),
          logic: true

        }
      })
    }

  }

  private onRemovePOI(category) {
    this.poiApi.deleteCategory( category )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialog.closeAll();
        this.params = { ...this.params,...this.filterValues };
        this.getCategories(this.params);
      });
  }

  private initFilter(): void {
    this.filterForm = this.formBuilder.group({
      customerIds: this.formBuilder.control(null)
    });
    this.filterForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(50),
      distinctUntilChanged(),
    ).subscribe((value) => this.filterValues = { customerIds: value.customerIds });
  }

  private onFilterChanges = (values: CategoriesFilterValues): void => {
    this.filterValues = values;
    this.customerId = values.customerIds[0];
    this.getCategories({...this.params, customerIds: values.customerIds});

  }

  handlePageEvent($event: PageEvent) {
    this.params = { ...this.params, ...this.filterValues, pageNumber: $event.pageIndex, pageSize: $event.pageSize };
    this.getCategories(this.params);
  }

  handleSortEvent($event: Sort) {
    this.params = { ...this.params,...this.filterValues, pageNumber: 0, pageSize: 10, sortBy: $event?.active, sortDirection: $event?.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getCategories({ ...this.params });
  }
}

interface CategoriesFilterFormGroup {
  customerIds: FormControl<number[]>;
}

interface CategoriesFilterValues extends PaginatorParams{
  customerIds?: number[]
}

interface CategoryListItem extends Category {
  customerName?: string;
  personsOfInterest?: IdName[]
}
