<div class="form-field-wrapper flex flex-col w-[280px]">
  <mat-label>{{label}}</mat-label>
  <mat-form-field (click)="!disabled && picker.open()">
    <mat-date-range-input
      [rangePicker]="picker"
      [dateFilter]="dateFilter"
      [formGroup]="range"
      [max]="maxDate"
      [disabled]="disabled"
      [min]="minDate">
      <input matStartDate #dateRangeStart formControlName="start" readonly placeholder="Start date" />
      <input
        matEndDate
        #dateRangeEnd
        formControlName="end"
        readonly
        placeholder="End date"
        (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)" />
    </mat-date-range-input>
    <!-- Clear Button -->
    <button mat-icon-button matSuffix type="button" (click)="clearDateRange($event)">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-datepicker-toggle
      matSuffix
      *ngIf="showClearBtn&&!range.controls.start.value &&!range.controls.end.value|| !showClearBtn"
      [disabled]="disabled"
      [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
