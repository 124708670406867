import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiConfiguration } from "@api/api-configuration";
import { BaseService, Customer, SaveCustomerRequest } from "@api/index";
import { PaginationResult } from "@api/models/paginator";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CustomerApi extends BaseService {

    private baseUrl = `${this.rootUrl}/customers`;

    constructor(
        config: ApiConfiguration,
        http: HttpClient
    ) {
        super(config, http);
    }

    getCustomers(): Observable<any> {
        return this.http.get<Customer[]>(`${this.baseUrl}`);
    }

    getCustomersList(params?: any): Observable<any> {
        return this.http.get<PaginationResult<Customer>>(`${this.baseUrl}/customerslist`, {params});
      }

    getCustomerById(id: number): Observable<any> {
        return this.http.get<Customer>(`${this.baseUrl}/${id}`);
    }

    saveCustomer(request: SaveCustomerRequest): Observable<Customer> {
        return this.http.post<Customer>(`${this.baseUrl}`, request);
    }

    removeCustomer(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

}